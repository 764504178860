import { Trash2, Edit, Folder as FolderIcon, MoreVertical } from 'react-feather';
import { Dropdown, Menu, Modal } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import SCard from 'components/Standard/SCard';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import { setEditingFolder } from 'redux/ui/libraryPage/operations';
import {
  editingFolderSaved,
  setCurrentFolderId,
  setCurrentInteractionId,
  updateEditingFolder
} from 'redux/ui/libraryPage/reducer';
import Icon from 'components/Icon';
import { FolderContainer, FolderContent, SettingButton, SettingContainer } from './styled';

const { confirm } = Modal;

const Folder = ({
  name,
  isSelected,
  id,
  selectFolder,
  selectInteraction,
  onDelete,
  onEdit,
  isEditing,
  updateEditingFolder,
  editingFolderSaved,
  updateFolder
}) => {
  const { t } = useTranslation();
  const handleClick = ({ key }) => {
    switch (key) {
      case 'edit':
        onEdit(id);
        break;
      case 'delete':
        confirm({
          title: t('libraryPage.libraryItem.deleteFolderModal.title'),
          content: t('libraryPage.libraryItem.deleteFolderModal.description'),
          okText: t('libraryPage.libraryItem.deleteFolderModal.buttons.delete'),
          cancelText: t('libraryPage.libraryItem.deleteFolderModal.buttons.cancel'),
          okType: 'danger',
          onOk: async () => {
            if (isSelected) {
              selectFolder(null);
            }

            if (isEditing) {
              editingFolderSaved();
            }

            return onDelete({ id });
          },
          onCancel: () => {}
        });
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('libraryPage.libraryItem.dropdown.edit')}</span>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <Icon icon={Trash2} />
        <span>{t('libraryPage.libraryItem.dropdown.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const ConditionalSettingContainer = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MANAGE_LIBRARY] },
    SettingContainer
  );

  return (
    <SCard bodyPadding="0px" bordered>
      <FolderContainer
        isSelected={isSelected}
        onClick={() => {
          if (!isSelected) selectInteraction(undefined);
          selectFolder(id);
        }}
      >
        <Icon icon={FolderIcon} size="50px" />
        <FolderContent>
          {isEditing ? (
            <EditableLabel
              initialState="edit"
              value={name}
              onSave={(name, { final }) => {
                updateEditingFolder({ name });
                if (final) {
                  updateFolder({ id, name });
                }
              }}
            />
          ) : (
            name
          )}
        </FolderContent>
        <ConditionalSettingContainer onClick={e => e.stopPropagation()}>
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingButton
              icon={<Icon icon={MoreVertical} />}
              background={isSelected && '#EAEAEA '}
            />
          </Dropdown>
        </ConditionalSettingContainer>
      </FolderContainer>
    </SCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSelected: state.uiLibraryPage.currentFolderId === ownProps.id,
    isEditing: state.uiLibraryPage.editingFolderId === ownProps.id
  };
};

const mapDispatchToProps = {
  updateEditingFolder,
  onEdit: setEditingFolder,
  editingFolderSaved,
  selectFolder: setCurrentFolderId,
  onDelete: reviewLibrariesResource.operations.deleteById,
  updateFolder: reviewLibrariesResource.operations.updateById,
  selectInteraction: setCurrentInteractionId
};

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
