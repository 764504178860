import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  editableGroup: {},
  currentChecklist: {},
  currentChecklistQuestionGroups: [],
  currentChecklistQuestions: [],
  addingQuestionToGroup: {},
  weight: {},
  formulas: {},
  addingQuestionGroup: false,
  selectingQuestionsFromSaved: false,
  selectedQuestionsFromSaved: [],
  sortedQuestionGroups: [],
  sortedQuestionGroupBindings: {},
  editingColorZonesBeforePublish: {},
  currentCalculationFurmulas: [],
  addingCalculationFurmulasData: [],
  addingSubGroupData: [],
  sortedQuestionGroupSubgroupBindings: [],
  employees: {}
};

export const setCurrentChecklist = createAction('uiChecklistEditor/setCurrentChecklist');
export const setEditableGroup = createAction('uiChecklistEditor/setEditableGroup');
export const setAddingQuestionToGroup = createAction('uiChecklistEditor/setAddingQuestionToGroup');
export const setWeight = createAction('uiChecklistEditor/setWeight');
export const setFormulas = createAction('uiChecklistEditor/setFormulas');
export const setAddingCalculationFurmulasData = createAction(
  'uiChecklistEditor/setAddingCalculationFurmulasData'
);
export const setAddingSubGroupData = createAction('uiChecklistEditor/setAddingSubGroupData');
export const setSelectingQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectingQuestionsFromSaved'
);
export const setSelectedQuestionsFromSaved = createAction(
  'uiChecklistEditor/setSelectedQuestionsFromSaved'
);
export const setAddingQuestionGroup = createAction('uiChecklistEditor/setAddingQuestionGroup');
export const setSortedQuestionGroups = createAction('uiChecklistEditor/setSortedQuestionGroups');
export const updateSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateSortedQuestionGroupBindings'
);
export const updateMultipleSortedQuestionGroupBindings = createAction(
  'uiChecklistEditor/updateMultipleSortedQuestionGroupBindings'
);
export const setEditingColorZonesBeforePublish = createAction(
  'uiChecklistEditor/setEditingColorZonesBeforePublish'
);

export const updateSortedQuestionGroupSubgroupBindings = createAction(
  'uiChecklistEditor/updateSortedQuestionGroupSubgroupBindings'
);
export const setEmployees = createAction('uiChecklistEditor/setEmployees');

export const uiChecklistEditorReducer = createReducer(defaultState, {
  [setCurrentChecklist]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },
  [setEditableGroup]: (state, { payload }) => {
    return {
      ...state,
      editableGroup: payload
    };
  },
  [setAddingQuestionToGroup]: (state, { payload }) => ({
    ...state,
    addingQuestionToGroup: payload
  }),
  [setWeight]: (state, { payload }) => ({
    ...state,
    weight: { ...state.weight, ...payload }
  }),
  [setFormulas]: (state, { payload }) => {
    return {
      ...state,
      formulas: { ...state.formulas, ...payload }
    };
  },
  [setSelectingQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectingQuestionsFromSaved: payload,
    selectedQuestionsFromSaved: payload ? state.selectedQuestionsFromSaved : []
  }),
  [setSelectedQuestionsFromSaved]: (state, { payload }) => ({
    ...state,
    selectedQuestionsFromSaved: payload
  }),
  [setAddingQuestionGroup]: (state, { payload }) => ({ ...state, addingQuestionGroup: payload }),
  [setSortedQuestionGroups]: (state, { payload }) => ({ ...state, sortedQuestionGroups: payload }),
  [updateSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: {
      ...state.sortedQuestionGroupBindings,
      [payload.questionGroupId]: payload.bindings
    }
  }),
  [updateMultipleSortedQuestionGroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupBindings: { ...state.sortedQuestionGroupBindings, ...payload }
  }),
  [setEditingColorZonesBeforePublish]: (state, { payload }) => ({
    ...state,
    editingColorZonesBeforePublish: payload
  }),
  [setAddingCalculationFurmulasData]: (state, { payload }) => {
    return {
      ...state,
      addingCalculationFurmulasData: payload
    };
  },
  [setAddingSubGroupData]: (state, { payload }) => {
    return {
      ...state,
      addingSubGroupData: payload
    };
  },
  [updateSortedQuestionGroupSubgroupBindings]: (state, { payload }) => ({
    ...state,
    sortedQuestionGroupSubgroupBindings: {
      ...state.sortedQuestionGroupSubgroupBindings,
      [payload.questionSubGroupId]: payload.bindings
    }
  }),
  [setEmployees]: (state, { payload }) => {
    return {
      ...state,
      employees: { ...state.employees, ...payload }
    };
  }
});
