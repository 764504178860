export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'cargado con éxito',
      loadFailed: 'No se pudo cargar el archivo',
      fileFormat: 'Usted puede cargar los archivos en formato mp3 o wav hasta 30 mb/archivo'
    },
    buttons: { loadCalls: 'Cargar las llamadas' },
    table: {
      columns: {
        operator: 'Operador',
        direction: 'Tipo de llamada',
        startedAt: 'Fecha de llamada',
        startedAtPlaceholder: 'Elija la hora',
        clientPhoneNumber: 'Número de teléfono',
        duration: 'Duración de la llamada',
        actions: 'Acción'
      },
      uinstalledCallDirection: 'No instalado',
      confirmChange: {
        title: 'Los cambios no se guardarán',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      buttons: {
        cancel: 'Cancelar',
        edit: 'Redactar',
        delete: 'Eliminar'
      },
      confirmDelete: {
        title: '¿Está seguro?',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      }
    }
  }
};
