import React, { useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import SRow from 'components/Standard/SRow';
import { WidgetTitle } from 'components/WidgetTitle';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import EmptyWidget from '../../Components/EmptyWidget';
import TableView from './Views/TableView';

const TableReviewsCountByOperatorsWithKpi = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();

  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);

  const { id, type, filters, name } = widgetData;
  const [loadingExport, setLoadingExport] = useState(false);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const checklistDefinitionAverageScoreByOperatorsHistory = get(
    widgetValue,
    'checklist_definition_average_score_by_operators_history',
    []
  );

  const checklistDefinitionAverageScore = get(widgetValue, 'checklist_definition_average_score');

  const reviewsCountByOperatorsHistory = get(widgetValue, 'reviews_count_by_operators_history', []);

  const reviewsCountByOperators = get(widgetValue, 'reviews_count_by_operators', []);

  const checklistDefinitionAverageScoreByOperators = get(
    widgetValue,
    'checklist_definition_average_score_by_operators',
    []
  );

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const tableColumns = prepareColumns({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    timeFrom,
    timeTo,
    usersByIds,
    unitsByIds,
    checklistDefinition,
    checklistDefinitionAverageScore,
    checklistDefinitionAverageScoreByOperatorsHistory,
    checklistDefinitionAverageScoreByOperators,
    reviewsCountByOperatorsHistory,
    reviewsCountByOperators
  });

  const isEmptyWidgetData = tableRows.length === 0;

  const flattenColumns = tableColumns.reduce((acc, column) => {
    return [...acc, ...(column?.children || [column])];
  }, []);

  const body = {
    widgetType: type,
    widgetData: {
      columns: flattenColumns.map(item => {
        return {
          ...item,
          title: ['name', 'unitName'].includes(item.key) ? t(item?.title?.props?.i18nKey) : item.key
        };
      }),
      rows: tableRows
    }
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={16}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type
                  })}
                </TimePeriod>
              </SCol>

              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
              </SCol>
            </Row>
            <TableView
              columns={tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableRows}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsCountByOperatorsWithKpi;
