import CallContent from 'components/CallContent';
import { isEqual, omit } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadChainById } from 'redux/ui/clientInteractionPage/operations';
import ClientInteractionChainPage from './ClientInteractionChainPage';

const CommunicationChainPage = ({ id, clientInteraction, offsetTop, affixTarget, shouldLoad = true, fromDrawer = false, chain = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramsId } = useParams();
  let callId;
  
  if (chain) {
    callId = clientInteraction.id;
  } else {
    callId = id || paramsId;
  }

  useEffect(() => {
    dispatch(
      loadChainById({
        id: callId,
        include: 'operator.unit,status,custom_fields',
        fromDrawer
      })
    );
  }, [callId]);
  
  const call = useSelector(
    state => state.phoneCallsResource.byIds[callId],
    (next, prev) => isEqual(omit(prev, ['loading']), omit(next, ['loading']))
  );

  const loading = useSelector(state => {
    const call = state.phoneCallsResource.byIds[callId];
    const { loading, loadByIdStarted } = state.phoneCallsResource;
    return loading || loadByIdStarted || !call || call?.loading;
  });

  localStorage.removeItem("totres")

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.callPage.title')}</title>
      </Helmet>
      <ClientInteractionChainPage
        affixTarget={affixTarget}
        Content={CallContent}
        clientInteraction={call}
        loading={loading}
        offsetTop={offsetTop}
        fromDrawer={fromDrawer}
        shouldLoad={shouldLoad}
        id={callId}
        reviewId={id}
        chain={true}
      />
    </>
  );
};

export default React.memo(CommunicationChainPage, isEqual);
