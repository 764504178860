import { put, post, destroy } from 'redux-bees';

const questionToGroupSubgroupBindingsEndpoints = apiPath => ({
  createQuestionToGroupSubgroupBinding: {
    method: post,
    path: `${apiPath}/checklist/question_to_group_subgroup_bindings`
  },
  updateQuestionToGroupSubgroupBinding: {
    method: put,
    path: `${apiPath}/checklist/question_to_group_subgroup_bindings/:id`
  },
  deleteQuestionToGroupSubgroupBinding: {
    method: destroy,
    path: `${apiPath}/checklist/question_to_group_subgroup_bindings/:id`
  }
});

export default questionToGroupSubgroupBindingsEndpoints;
