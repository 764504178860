import { ChevronDown, ChevronRight, Edit, MoreVertical, Trash2 } from 'react-feather';
import { Col, Dropdown, Menu, Modal, Row } from 'antd';
import { CHECKLIST_DEFINITION_STATUS, RATING_METHOD } from 'core/utils/constants';
import { usePrevious } from 'core/utils/hooks';
import { find, get, isEmpty, isEqual, max, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { checklistQuestionGroupSubgroupsResource } from 'redux/resources/checklistQuestionGroupSubgroups';
import {
  getQuestionGroupSubgroupBindings,
  getQuestionGroupSubgroupBindingsIds,
  getQuestionGroupSubgroupQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import {
  setAddingQuestionToGroup,
  updateSortedQuestionGroupSubgroupBindings,
  setAddingCalculationFurmulasData,
  setAddingSubGroupData,
} from 'redux/ui/checklistEditor/reducer';
import uniqid from 'uniqid';
import Icon from 'components/Icon';
import LabelWithInfo from 'components/LabelWithInfo';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import Question from './Question';
import {
  MarginLeftButton,
  QestionGroupCard,
  StyledButton,
  StyledInput,
  ValueCol,
  ValuesContainer
} from './styled';

const { confirm } = Modal;

const QuestionSubGroup = ({
  sumRates,
  sumPercentage,
  questionSubGroup,
  currentChecklist,
  provided,
  questionGroupSubgroupBindings = [],
  questionGroupSubgroupBindingsIds = [],
  setEditableChecklistDefinition,
  deleteQuestionGroup,
  setAddingQuestionToGroup,
  sortedBindings = [],
  updateSortedQuestionGroupSubgroupBindings,
  loading,
  isDragging,
  isDraggingGlobal,
  isDraggingOver,
  totalPercentage,
  clickToQuestion,
  setAddingSubGroupData,
  isLast,
  dragAndDropDisabled
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  
  const prevQuestionGroupSubgroupBindingsIds = usePrevious(questionGroupSubgroupBindingsIds);

  useEffect(() => {
    if (!loading && !isEqual(prevQuestionGroupSubgroupBindingsIds, questionGroupSubgroupBindingsIds)) {
      updateSortedQuestionGroupSubgroupBindings({
        questionSubGroupId: questionSubGroup.id,
        bindings: questionGroupSubgroupBindings
      })
    }
  }, [questionGroupSubgroupBindingsIds]);

  const id = get(questionSubGroup, 'id', '');
  const name = get(questionSubGroup, 'name', '');
  const ratingMethod = get(
    currentChecklist,
    'ratingMethod',
    t('checklistsPage.questionGroup.ratingMethod')
  );
  const status = get(currentChecklist, 'status', 'archived');

  const onAddQuestion = () => {
    setAddingQuestionToGroup({ questionGroup: questionSubGroup });
  };

  const onAddQuestionGroup = () => {
    setAddingSubGroupData({ id: uniqid() });
  };

  const deleteGroupOrHideRootGroup = async ({ id, currentChecklist }) => {
    return deleteQuestionGroup({ id });
  };

  const handlMenuClick = e => {
    switch (e.key) {
      case 'edit':
        setAddingSubGroupData(questionSubGroup);
        return;

      case 'delete':
        return confirm({
          okText: t('checklistsPage.questionGroup.confirmDelete.ok'),
          cancelText: t('checklistsPage.questionGroup.confirmDelete.cancel'),
          title: t('checklistsPage.questionGroup.confirmDelete.title'),
          content:
            currentChecklist.questionGroupsIds.length !== 1
              ? t('checklistsPage.questionGroup.confirmDelete.description')
              : null,
          okType: 'danger',
          onOk: async () => {
            await deleteGroupOrHideRootGroup({ id, currentChecklist });
            setEditableChecklistDefinition({ id: get(currentChecklist, 'id', '') });
          }
        });

      default:
        console.log();
    }
  };

  const menu = (
    <Menu onClick={e => handlMenuClick(e)}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('checklistsPage.subGroup.menu.edit')}</span>
      </Menu.Item>
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.subGroup.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Col key={questionSubGroup.id}>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ paddingLeft: '30px' }}
      >
        <Col>
          {currentChecklist.isGroupable && (
            <QestionGroupCard onClick={() => setCollapsed(!collapsed)} isDragging={isDragging} style={{ backgroundColor: '#EFE9FF' }}>
              <Row type="flex" align="middle">
                <SCol flex="auto" wrap={false}>
                  <Icon icon={collapsed ? ChevronRight : ChevronDown} style={{ marginRight: 8 }} />
                  <SText ellipsis strong title={name}>
                    {name}
                  </SText>
                </SCol>
                <ValuesContainer flex="none">
                  <ValueCol wrap={false}>
                    <LabelWithInfo infoTitle={t('checklistsPage.subGroup.sumRatesInfo')}>
                      {t('checklistsPage.subGroup.sumRates')}
                    </LabelWithInfo>
                    <StyledInput
                      disabled={status !== CHECKLIST_DEFINITION_STATUS.DRAFT.value}
                      value={sumRates}
                      onClick={e => e.stopPropagation()}
                    />
                  </ValueCol>
                  {ratingMethod === RATING_METHOD.WEIGHTED && (
                    <ValueCol>
                      <LabelWithInfo
                        infoTitle={t('checklistsPage.questionGroup.sumPercentageInfo')}
                      >
                        {t('checklistsPage.questionGroup.sumSubPercentage')}
                      </LabelWithInfo>
                      <StyledInput value={`${sumPercentage}%`} onClick={e => e.stopPropagation()} />
                    </ValueCol>
                  )}
                  <div onClick={e => e.stopPropagation()}>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                    </Dropdown>
                  </div>
                </ValuesContainer>
              </Row>
            </QestionGroupCard>
          )}
        </Col>
        {!collapsed &&
          sortedBindings.map(({ id, questionId }, i) => (
            <Draggable key={id} draggableId={id} index={i} isDragDisabled={dragAndDropDisabled}>
              {(provided, snapshot) => {
                return (
                  <Question
                    sumPercentage={totalPercentage}
                    questionId={questionId}
                    bindingId={id}
                    questionGroupId={questionSubGroup.id}
                    ratingMethod={ratingMethod}
                    currentChecklist={currentChecklist}
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    clickToQuestion={clickToQuestion}
                    type="subgroup"
                  />
                );
              }}
            </Draggable>
          ))}
        {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value &&
          !isDragging &&
          !isDraggingOver &&
          !isDraggingGlobal && (
            <Row type="flex" justify="end" style={{ margin: '4px 0px' }}>
              {!isLast && (
                <StyledButton type="primary" ghost onClick={onAddQuestion}>
                  {t('checklistsPage.questionGroup.buttons.addQuestion')}
                </StyledButton>
              )}
            </Row>
          )}
        {provided.placeholder}
      </div>
    </Col>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    currentChecklist,
    editableGroup,
    sortedQuestionGroupSubgroupBindings,
    addingQuestionGroup
  } = state.uiChecklistEditor;
  const questionGroupSubgroupBindings = getQuestionGroupSubgroupBindings(state, ownProps.questionSubGroup);
  const questionGroupSubgroupBindingsIds = getQuestionGroupSubgroupBindingsIds(state, ownProps.questionSubGroup);
  const sortedBindings = get(sortedQuestionGroupSubgroupBindings, ownProps.questionSubGroup.id, []).filter(Boolean);

  
  const { ratingCalculation, ratingMethod } = currentChecklist;
  const { loading } = state.questionsResource;
  const {
    updateByIdStarted,
    createStarted,
    byIds: bindingsByIds
  } = state.questionToGroupSubgroupBindingsResource;

  const questionSubGroup = get(ownProps, 'questionSubGroup', {});

  
  const questionGroupBindings = getQuestionGroupSubgroupBindings(state, ownProps.questionSubGroup);
  
  const questionGroupsQuestions = getQuestionGroupSubgroupQuestions(state, questionSubGroup);

  const allRatingValues = questionGroupsQuestions.map(question => ({
    ...question,
    value: max(question.ratingValues),
    percentage: find(questionGroupBindings, { questionId: question.id }).percentage
  }));

  const sumPercentage = sumBy(questionGroupBindings, 'percentage');
  const totalPercentage = sumBy(state.questionToGroupSubgroupBindingsResource.byIds, 'percentage');
 
  let sumRates = 0;
  for (const obj of Object.values(allRatingValues)) {
    if (typeof obj.max === 'number') {
      sumRates += obj.max;
    }
  }
  // const sumRates = calculateQuestions({
  //   questions: allRatingValues,
  //   ratingCalculation,
  //   ratingMethod
  // });

  return {
    sumRates,
    sumPercentage,
    totalPercentage,
    questionGroupSubgroupBindings,
    questionGroupSubgroupBindingsIds,
    sortedBindings,
    bindingsByIds,
    loading:
      loading ||
      !isEmpty(editableGroup) ||
      updateByIdStarted ||
      createStarted ||
      !isEmpty(addingQuestionGroup)
  };
};

const mapDispatchToProps = {
  deleteQuestionGroup: checklistQuestionGroupSubgroupsResource.operations.deleteById,
  setEditableChecklistDefinition,
  setAddingQuestionToGroup,
  updateSortedQuestionGroupSubgroupBindings,
  updateCurrentChecklist: checklistDefinitionsResource.operations.updateById,
  setAddingCalculationFurmulasData,
  setAddingSubGroupData,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSubGroup);
