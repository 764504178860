import { bitrixClientInteractionsListReducer } from 'redux/lists/bitrixClientInteractionsList';
import { bitrixReviewsListReducer } from 'redux/lists/bitrixReviewsListReducer';
import { calibrationSessionsListReducer } from 'redux/lists/calibrationSessionsListReducer';
import { callsListReducer } from 'redux/lists/callsListReducer';
import { checklistDefinitionsListReducer } from 'redux/lists/checklistDefinitionsList';
import { clientInteractionsListReducer } from 'redux/lists/clientInteractionsList';
import { foldersListReducer } from 'redux/lists/foldersListReducer';
import { questionsListReducer } from 'redux/lists/questionsList';
import { reviewsListReducer } from 'redux/lists/reviewsListReducer';
import { getTasksListReducers } from 'redux/lists/tasksLists';
import { uploadedCallsReducer } from 'redux/lists/uploadedCallsListReducer';
import { userReviewsListReducer } from 'redux/lists/userReviewsListReducer';
import { userReviewsWithCommentsListReducer } from 'redux/lists/userReviewsWithComments';
import { usersListReducer } from 'redux/lists/usersListReducer';
import { taskDefinitionsListReducer } from 'redux/lists/taskDefinitionsListReducer';
import { usersToUnitListReducer } from 'redux/lists/usersToUnitList';
import { userAccountBindingsListReducer } from 'redux/lists/userAccountBindingsList';
import { workPlanTaskConfigurationsListReducer } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { workPlanTasksListReducer } from 'redux/lists/workPlanTasksListReducer';
import { workPlanTaskAssignmentsListReducer } from 'redux/lists/workPlanTaskAssignmentsListReducer';
import { commentTemplatesListReducer } from 'redux/lists/commentTemplatesListReducer';
import { appellationsListReducer } from 'redux/lists/appellationsListReducer';
import { unitsListReducer } from 'redux/lists/unitsListReducer';
import { communicationChainsV1Reducer } from 'redux/lists/communicationChainsV1';

export default {
  reviewsList: reviewsListReducer,
  userReviewsList: userReviewsListReducer,
  userReviewsWithCommentsList: userReviewsWithCommentsListReducer,
  callsList: callsListReducer,
  uploadedCallsList: uploadedCallsReducer,
  usersList: usersListReducer,
  bitrixReviewsList: bitrixReviewsListReducer,
  bitrixClientInteractionsList: bitrixClientInteractionsListReducer,
  clientInteractionsList: clientInteractionsListReducer,
  checklistDefinitionsList: checklistDefinitionsListReducer,
  questionsList: questionsListReducer,
  calibrationSessionsList: calibrationSessionsListReducer,
  foldersList: foldersListReducer,
  taskDefinitionsList: taskDefinitionsListReducer,
  usersToUnitList: usersToUnitListReducer,
  userAccountBindingsList: userAccountBindingsListReducer,
  workPlanTaskConfigurationsList: workPlanTaskConfigurationsListReducer,
  workPlanTasksList: workPlanTasksListReducer,
  workPlanTaskAssignmentsList: workPlanTaskAssignmentsListReducer,
  commentTemplatesList: commentTemplatesListReducer,
  appellationsList: appellationsListReducer,
  unitsList: unitsListReducer,
  communicationChainsV1: communicationChainsV1Reducer,
  ...getTasksListReducers()
};
