import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { prepareData } from './prepareFunctions';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const ReviewsCountByReviewersHistory = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;

  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));
  const preparedData = orderBy(prepareData({ widgetValue, usersByIds }), 'id', 'desc');

  return (
    <>
      {isEmpty(widgetValue) ||
      isEmpty(widgetHistory) ||
      loadingWidgetId === id ||
      isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={12} key={id}>
          <WidgetCard>
            <SMLWidgetHeader name={name} type={type} widgetData={widgetData} filters={filters} />

            <ResponsiveLine
              data={preparedData}
              margin={{ top: 20, right: 160, bottom: 70, left: 40 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                format: d => moment(d).format('DD/MM/YYYY')
              }}
              colors={colors}
              enableSlices="x"
              pointSize={10}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)'
                }
              ]}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default ReviewsCountByReviewersHistory;
