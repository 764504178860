import { AlertCircle, Edit, MoreVertical, Trash2, Copy } from 'react-feather';
import { Col, Dropdown, InputNumber, Menu, Modal, Row, Tooltip, Typography } from 'antd';
import { FireFilled } from '@ant-design/icons';
import RateValues from 'components/Inputs/RateValues';
import Icon from 'components/Icon';
import { CHECKLIST_DEFINITION_STATUS, RATING_METHOD, SCALE_TYPES } from 'core/utils/constants';
import { debounce, get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { questionToGroupSubgroupBindingsResource } from 'redux/resources/questionToGroupSubgroupBindingsResource';
import { setEditingQuestionWithBinding } from 'redux/ui/questionModal/reducer';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import {
  setWeight
} from 'redux/ui/checklistEditor/reducer';
import { MarginLeftButton, StyledCol } from './styled';

const Question = ({
  sumPercentage,
  questionId,
  bindingId,
  bindingsByIds,
  updateBinding,
  questionsByIds,
  ratingMethod,
  provided,
  weight,
  questionGroupsResource,
  questionsResource,
  setWeight,
  currentChecklist,
  isDragging,
  deleteBinding,
  setEditingQuestionWithBinding,
  clickToQuestion,
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const {
    scaleType = SCALE_TYPES.max_5.type,
    valuesDisplayType = 'numbers',
    status
  } = currentChecklist;
  const currentQuestion = get(questionsByIds, `${questionId}`, {});
  const { shortId, name, ratingValues, ratingValuesViews, questionType } = currentQuestion;

  const binding = bindingsByIds[bindingId];
  const advanced = questionType == 'advanced' ? true : false;

  const deleteFromSum = (question, questionGroupId_) => {
    let questionGroupId;
    for (const item of Object.values(bindingsByIds)) {
      if (item.questionId === question) {
        if (!!item?.questionGroupSubgroupId) {
          const questionGroupSubgroupId = item.questionGroupSubgroupId;
          for (const item_ of Object.values(questionGroupsResource)) {
            if (item_.questionGroupSubgroupsIds.includes(questionGroupSubgroupId)) {
              questionGroupId = item_.id;
              break;
            }
          }
        }

        if (Object.keys(questionGroupsResource).includes(item.questionId)) {
          questionGroupId = item.questionGroupId;
          break;
        }
      }
    }
    
    if (!!!questionGroupId) {
      for (const item of Object.values(bindingsByIds)) {
        if (item.questionId === question) {
          for (const item_ of Object.values(questionGroupsResource)) {
            if (item_.questionsIds.includes(item.questionId)) {
              questionGroupId = item.questionGroupId;
              break;
            }
          }
        }
      }
    }
    
    if (!!questionGroupId) {
      const max = questionsResource[question].max;
      if (max === 0 || !!max) {
        const newState = {
          [questionGroupId]: weight[questionGroupId] - max
        }
        setWeight(newState)
      }
    } else {
      const groupid = binding.questionGroupId;
      const maxVal = questionsByIds[binding.questionId].max;
      const currentWeight = weight[groupid];
      if (maxVal === 0 || !!maxVal) {
        const newState = {
          [groupid]: currentWeight - maxVal
        }
        setWeight(newState)
      }
    }
  }

  const handleMenuClick = e => {
    switch (e.key) {
      case 'edit':
        setEditingQuestionWithBinding({
          question: questionsByIds[questionId],
          questionToGroupBinding: binding
        });
        break;

      case 'copy':
        // * maybe will be implemented in BE
        break;

      case 'delete':
        Modal.confirm({
          maskClosable: true,
          title: t('checklistsPage.question.confirmDelete.title'),
          content: t('checklistsPage.question.confirmDelete.description'),
          okText: t('checklistsPage.question.confirmDelete.ok'),
          cancelText: t('checklistsPage.question.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => {
            // console.log("Question deleted", bindingId, binding);
            deleteBinding({ id: bindingId });
            deleteFromSum(questionId, null);
          }
        });
        break;
      default:
        console.log();
    }
  };

  const menu = (
    <Menu onClick={e => handleMenuClick(e)}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('checklistsPage.question.menu.edit')}</span>
      </Menu.Item>
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.question.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  // * binding can be undefined in moment after deleting but before creating new one
  if (!binding) {
    return (
      <Col key={questionId}>
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SCard isDragging={isDragging} rounded={false} bordered="4px">
            <Row type="flex" align="top">
              <SCol span={7} paddingTop="5px" paddingRight="16px">
                <SText strong>{name}</SText>
              </SCol>
              <StyledCol span={17} style={{ display: 'flex', alignItems: 'center' }}>
                <Row type="flex" wrap={false} justify="space-between" align="top">
                  <Col flex="auto">
                    <RateValues
                      advanced={advanced}
                      size="medium"
                      ratingValues={ratingValues}
                      ratingValuesViews={ratingValuesViews}
                      scaleType={scaleType}
                      valuesDisplayType={valuesDisplayType}
                      disabled
                    />
                  </Col>
                  <SCol flex="none" marginLeft="40px">
                    <Dropdown overlay={menu} trigger={['click']}>
                      <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                    </Dropdown>
                  </SCol>
                </Row>
              </StyledCol>
            </Row>
          </SCard>
          {provided.placeholder}
        </div>
      </Col>
    );
  }
  
  const { percentage, autofailEnabled } = binding;
  const maxPercentage = sumPercentage >= 100 ? percentage || 1 : 100;
  const updatePercentage = debounce(newPercentage => {
    if (newPercentage && sumPercentage - percentage + newPercentage <= 100) {
      updateBinding({ id: bindingId, percentage: newPercentage });
    }
  }, 200);

  const onCopyIconClick = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText('[' + shortId + ']');
  }
  
  return (
    <Col key={questionId} onClick={(e) => {clickToQuestion(e, shortId)}}>
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <SCard isDragging={isDragging} rounded={false} bordered="4px">
          <Row type="flex" align="top">
            <SCol span={3} paddingTop="5px" paddingRight="16px">
              <SText light>{shortId}</SText>
              <span style={{ cursor: 'pointer', 'padding-left': '3px'}}>
                <Icon icon={Copy} size='14'  onClick={e => onCopyIconClick(e)}/>
              </span>
            </SCol>
            <SCol span={7} paddingTop="5px" paddingRight="16px">
              <SText strong>{name}</SText>
            </SCol>
            <StyledCol span={13} style={{ display: 'flex', alignItems: 'center' }}>
              <SRow type="flex" wrap={false} justify="space-between" align="top" width="100%">
                <Col flex="auto">
                  {autofailEnabled && (
                    <Tooltip title={t('checklistsPage.question.tooltip.title')}>
                      <AlertCircle color="var(--red_primary)" style={{ marginRight: '8px' }} />
                    </Tooltip>
                  )}
                  <RateValues
                    advanced={advanced}
                    size="medium"
                    ratingValues={ratingValues}
                    ratingValuesViews={ratingValuesViews}
                    scaleType={scaleType}
                    valuesDisplayType={valuesDisplayType}
                    disabled
                  />

                  {ratingMethod === RATING_METHOD.WEIGHTED && (
                    <SRow display="block" paddingTop="8px">
                      <SText marginRight="8px">{t('checklistsPage.question.weight')}</SText>
                      <InputNumber
                        min={1}
                        max={maxPercentage}
                        onChange={updatePercentage}
                        value={percentage}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                      />
                    </SRow>
                  )}
                </Col>
                <SCol flex="none" marginLeft="40px">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                  </Dropdown>
                </SCol>
              </SRow>
            </StyledCol>
          </Row>
        </SCard>
        {provided.placeholder}
      </div>
    </Col>
  );
};

const mapStateToProps = (state, ownProps) => {
  const weight = state.uiChecklistEditor.weight;
  const checklistQuestionGroupSubgroupsResource = state.checklistQuestionGroupSubgroupsResource.byIds;
  const questionGroupsResource = state.uiChecklistEditor.sortedQuestionGroups;
  const questionsResource = state.questionsResource.byIds;
  
  return {
    weight,
    checklistQuestionGroupSubgroupsResource,
    questionGroupsResource,
    questionsResource,
    bindingsByIds: {...state.questionToGroupBindingsResource.byIds, ...state.questionToGroupSubgroupBindingsResource.byIds},
    questionsByIds: state.questionsResource.byIds,
    ...ownProps
  };
};

const mapDispatchToProps = {
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  deleteSubgroupBinding: questionToGroupSubgroupBindingsResource.operations.deleteById,
  updateSubgroupBinding: questionToGroupSubgroupBindingsResource.operations.updateById,
  setEditingQuestionWithBinding,
  setWeight
};

const mergeProps = (stateProps, dispatchProps) => {
  const groupType = get(stateProps, 'type', 'group');
  stateProps['deleteBinding'] = (groupType === 'group') ? dispatchProps.deleteBinding : dispatchProps.deleteSubgroupBinding;
  stateProps['updateBinding'] = (groupType === 'group') ? dispatchProps.updateBinding : dispatchProps.updateSubgroupBinding;
  return {
    ...stateProps,
    setEditingQuestionWithBinding: dispatchProps.setEditingQuestionWithBinding,
    setWeight: dispatchProps.setWeight
  }
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Question);
