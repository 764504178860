import { PlayCircle } from 'react-feather';
import { Button } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import styled from 'styled-components';

export const FolderContainer = styled.div`
  height: 100px;
  display: flex;
  padding: 1rem;
  font-weight: 600;
  font-size: var(--font-size);
  color: ${({ isSelected }) => (isSelected ? 'var(--primary)' : 'var(--text)')};
  /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */

  .anticon-folder-open svg {
    height: 50px;
    width: 50px;
  }

  span {
    /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
  }

  &:hover {
    cursor: pointer;
    color: var(--primary-light);
  }
`;

export const FolderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    color: var;
  }
`;

// export const FolderIcon = styled(FolderO)`
//   svg {
//     width: 50px;
//     height: 50px;
//   }
// `;

export const SettingButton = styled(Button)`
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 20px !important;
  background-color: ${props =>
    props.background ? `${props.background} !important` : 'white !important'};
`;

export const SettingContainer = styled.div`
  margin-left: auto;
`;

export const ClientInteractionContainer = styled.div`
  height: 100px;
  // background: ${props => (props.isSelected ? '#EAEAEA' : 'inherit')};
  border-radius: 4px;
  display: flex;
  padding: 1rem;
  font-weight: 600;
  font-size: var(--font-size);
  color: ${props => (props.isSelected ? 'var(--primary)' : 'var(--text)')};
  /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */

  span {
    /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
  }

  &:hover {
    cursor: pointer;
    color: var(--primary-light);
  }
`;

export const ClientInteractionIcon = styled(PlayCircle)`
  svg {
    width: 50px;
    height: 50px;
    color: var(--primary);
  }
`;

export const ClientInteractionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    color: var(--text-light);
  }
`;

export const CustomEditableLabel = styled(EditableLabel)`
  button {
    right: 0 !important;
  }
`;
