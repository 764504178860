import React from 'react';
import { message } from 'antd';
// import * as Sentry from '@sentry/browser';
import { get } from 'lodash';
import { ERRORS } from 'core/utils/constants';
import { Trans } from 'react-i18next';

const getErrorText = body => {
  const textFromBody = get(body, 'errors[0]');
  const textFromBodyDetail = get(body, 'errors[0].detail');
  return typeof textFromBody === 'string' ? textFromBody : textFromBodyDetail;
};

export const handleError = (status, body) => {
  if (!status) {
    // Sentry.captureException(body);
    return message.error(<Trans i18nKey={ERRORS.UNKNOWN_ERROR} />, 5);
  }

  return message.warning(getErrorText(body) || <Trans i18nKey={ERRORS.UNKNOWN_ERROR} />, 5);
};
