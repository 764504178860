export default {
  organizationSettingsPage: {
    tabs: {
      info: {
        tabName: 'Информация',
        form: {
          currentPaymentPlan: 'Текущий тарифный план',
          registrationDate: 'Дата регистрации',
          usersCount: 'Зарегистрировано пользователей',
          monthlyReviewsCount: 'Количество оценённых звонков за текущий месяц'
        }
      },
      organizationSettings: {
        tabName: 'Настройки организации',
        form: {
          organizationName: 'Название организации',
          organizationAddress: 'Юр. адрес',
          organizationEmail: 'Контактный e-mail',
          organizationPhoneNumber: 'Контактный номер телефона'
        },
        customFields: {
          title: 'Дополнительная информация по коммуникациям'
        }
      },
      paymentSettings: {
        tabName: 'Настройки оплаты',
        form: {
          currentPlanSlug: 'Тарифный план',
          usersCount: 'Количество пользователей',
          paymentSymbolicPeriod: 'Период оплаты',
          paymentCurrency: 'Валюта оплаты'
        }
      }
    },
    buttons: {
      updateData: 'Обновить данные',
      paymentPlansInfo: 'Информация о тарифных планах',
      createPaymentRequest: 'Запросить счёт на оплату'
    },
    selectOptions: {
      paymentSymbolicPeriod: {
        month: '1 месяц',
        year: '1 год'
      }
    },
    textHeaders: {
      paymentSumm: 'Сумма к оплате:',
      paymentPlanDescription: 'Описание тарифного плана: '
    },
    modal: {
      title: 'Информация о компании',
      buttons: {
        cancel: 'Отмена',
        send: 'Отправить'
      },
      form: {
        companyName: 'Название компании',
        companyInn: 'ИНН/УНН',
        companyPhone: 'Номер телефона',
        companyEmail: 'E-mail'
      }
    },
    messages: {
      dataSuccessfullyUpdated: 'Данные обновлены',
      requestSuccessfullySended: 'Заявка отправлена',
      requestSendedFailed: 'Не удалось отправить заявку',
      form: {
        validateMessages: {
          enterOrganizationName: 'Введите название организации',
          enterEmail: 'Пожалуйста, введите e-mail',
          worngEmail: 'Вы ввели недопустимый e-mail',
          enterPlanSlug: 'Пожалуйста, выберите тарифный план',
          enterUsersCount: 'Пожалуйста, введите количество пользователей',
          enterPaymentSymbolicPeriod: 'Пожалуйста, выберите период оплаты',
          enterPaymentCurrency: 'Пожалуйста, выберите валюту оплаты',
          enterInn: 'Пожалуйста, введите ИНН или УНН',
          enterPhoneNumber: 'Пожалуйста, введите номер телефона'
        }
      }
    },
    other: {
      policyPT1: 'Согласен с',
      policyPT2: 'договором публичной оферты',
      policyPT3: 'и политикой конфиденциальности'
    }
  }
};
