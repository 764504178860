import { Col, Row, Skeleton } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import { Info } from 'components/Info';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import STable from 'components/Standard/STable';
import UserPreview from 'components/UserPreview';
import { REVIEW_STATUSES } from 'core/utils/constants';
import { find, get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const UserReviewStatusTable = ({ memberId, currentSession, clientInteractions = [] }) => {
  const { loading, checklistDefinitionId } = currentSession;
  const { t } = useTranslation();

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  return (
    <SCol span={24} marginTop="8px">
      <SCard
        bordered
        shadowed
        loading={loading}
        bodyPadding="4px"
        headPadding="0px 16px"
        title={
          <Row type="flex" justify="space-between">
            <Col>
              <UserPreview userId={memberId} disabled showAvatar showUnit margin="0px" />
            </Col>
            <Col>
              <Row type="flex" align="middle" gutter={[4, 4]}>
                <Col>
                  <Info
                    tooltip={{
                      title: t('calibrationsPage.userReviewStatusTable.tooltip')
                    }}
                  />
                </Col>
                <Col>
                  <span style={{ fontSize: 14, fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)' }}>
                    {`${t('calibrationsPage.userReviewStatusTable.reviewedItems')} ${
                      clientInteractions.filter(clientInteraction => clientInteraction.review)
                        .length
                    }/${clientInteractions.length}`}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <STable
          loading={loading}
          dataSource={clientInteractions}
          pagination={false}
          rowKey="id"
          columns={[
            {
              title: t('general.clientInteractionType'),
              key: 'id',
              dataIndex: 'id',
              render: (id, record) => {
                const reviewId = record?.review?.id;

                return (
                  <ClientInteractionLink
                    id={reviewId || id}
                    isReview={!!reviewId}
                    communication={record}
                    checklistDefinitionId={checklistDefinitionId}
                  />
                );
              }
            },
            {
              title: t('calibrationsPage.userReviewStatusTable.table.columns.operator'),
              key: 'operator',
              dataIndex: 'operatorId',
              render: userId => (
                <UserPreview userId={userId} disabled showAvatar showUnit margin="0px" />
              )
            },
            {
              title: t('calibrationsPage.userReviewStatusTable.table.columns.name'),
              key: 'name',
              dataIndex: 'name'
            },
            {
              title: t('calibrationsPage.userReviewStatusTable.table.columns.startedAt'),
              key: 'startedAt',
              dataIndex: 'startedAt',
              render: text => moment(text).format('DD/MM/YYYY HH:mm')
            },
            {
              title: t('calibrationsPage.userReviewStatusTable.table.columns.clientPhoneNumber'),
              key: 'clientPhoneNumber',
              dataIndex: 'clientPhoneNumber'
            },
            {
              title: t('calibrationsPage.userReviewStatusTable.table.columns.isReviewed'),
              key: 'review',
              dataIndex: 'review',
              render: text =>
                text ? t(REVIEW_STATUSES.REVIEWED.title) : t(REVIEW_STATUSES.NOT_REVIEWED.title)
            }
          ]}
        />
      </SCard>
    </SCol>
  );
};

const mapStateToProps = (state, ownProps) => {
  const currentSessionLoading = get(ownProps, 'currentSession.loading', []);
  if (currentSessionLoading) return { loading: true };

  const currentSessionFolderItemsIds = get(ownProps, 'currentSession.folderItemsIds', []);
  const currentSessionReviewsIds = get(ownProps, 'currentSession.reviewsIds', []);

  const memberId = get(ownProps, 'memberId', []);

  const clientInteractionsByIds = state.clientInteractionsResource.byIds;
  const folderItemsByIds = state.folderItemsResource.byIds;
  const reviewsByIds = state.reviewsResource.byIds;
  const memberReviews = Object.values(reviewsByIds).filter(
    review => review.reviewerId === memberId && currentSessionReviewsIds.includes(review.id)
  );

  const currentSessionClientInteractions = currentSessionFolderItemsIds.map(itemId => {
    const clientInteraction =
      clientInteractionsByIds[folderItemsByIds[itemId]?.clientInteractionId];

    return {
      ...clientInteraction,
      name: folderItemsByIds[itemId]?.name,
      review: find(memberReviews, review => review.clientInteractionId === clientInteraction.id)
    };
  });

  return {
    clientInteractions: currentSessionClientInteractions
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserReviewStatusTable);
