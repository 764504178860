import { Col, Row, Spin, Typography } from 'antd';
import { audioServiceOrigin, avitoPasswordAudioLogin, avitoUsernameAudioLogin } from 'core/config';
import qs from 'qs';
import nodePath from 'path';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { useHistory } from 'react-router-dom';
import {
  setCurrentCallId,
  setUpdatingUrl,
  setUrl,
  updatePlayerState
} from 'redux/ui/recordPlayer/reducer';
import openSocket from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { isEqual, pick } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import truncateString from 'core/utils/truncateString';
import SModal from 'components/Standard/SModal';
import { downloadCallFromMediaUrl } from 'core/api';
import AudioTrack from './AudioTrack';
import RecordControls from './RecordControls';

const { Text } = Typography;

const basicAuthUrl = 'https://www.avito.ru/export/speech-analytics/';
const basicAuthCredentials = `Basic ${btoa(
  `${avitoUsernameAudioLogin}:${avitoPasswordAudioLogin}`
)}`;

const RecordPlayer = ({
  messages,
  onMessageClick,
  review,
  call,
  comments,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    playbackRate,
    isPlaying,
    wasPlayed,
    forcePlayed,
    playedSeconds,
    url,
    updatingUrl
  } = useSelector(
    state =>
      pick(state.uiRecordPlayer, [
        'playbackRate',
        'isPlaying',
        'wasPlayed',
        'forcePlayed',
        'playedSeconds',
        'url',
        'updatingUrl'
      ]),
    isEqual
  );
  const [getMediaUrl, setGetMediaUrl] = useState(null);
  const [getMediaUrlDone, setGetMediaUrlDone] = useState(true);
  const { phoneCallMediaUrl, mediaUrl } = call;
  const unsafeRecordUrl = phoneCallMediaUrl || mediaUrl;
  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const { t } = useTranslation();
  const player = useRef();

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    if (call) dispatch(setCurrentCallId(call.id));
    if (call.duration) dispatch(updatePlayerState({ duration: call.duration }));
  }, [call.id, call.duration]);

  const fetchMediaData = async () => {
    try {
      const mediaUrl = url || unsafeRecordUrl;

      const response = mediaUrl.includes(basicAuthUrl)
        ? await fetch(downloadCallFromMediaUrl, {
            method: 'POST',
            body: JSON.stringify({ media_url: mediaUrl }),
            headers: { Authorization: basicAuthCredentials }
          })
        : await fetch(downloadCallFromMediaUrl, {
            method: 'POST',
            body: JSON.stringify({ media_url: mediaUrl })
          });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const contentType = response.headers.get('content-type');
      const blob = await response.blob();

      if (contentType && contentType.startsWith('audio/')) {
        setGetMediaUrl(URL.createObjectURL(blob));
        setGetMediaUrlDone(false);
      } else {
        setGetMediaUrlDone(false);
        setGetMediaUrl(null);
      }
    } catch (e) {
      setGetMediaUrlDone(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          dispatch(
            updatePlayerState({
              playedSeconds: parseFloat(t),
              wasPlayed: false
            })
          );
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      dispatch(updatePlayerState({ forcePlayed: false }));
    }
  }, [playedSeconds]);

  const handleProgress = useCallback(
    async ({ playedSeconds, played, loaded }) => {
      if (playedSeconds === 0 && !wasPlayed) {
        return;
      }
      await dispatch(updatePlayerState({ playedSeconds, played }));
      setBufferedPercentage(loaded * 100);
    },
    [dispatch]
  );

  const initSocket = ({ isReload = false }) => {
    dispatch(setUpdatingUrl(true));
    const audioServiceOriginUrl = new URL(audioServiceOrigin);
    const socket = openSocket(audioServiceOriginUrl.origin, {
      reconnectionAttempts: 5,
      path: nodePath.join(audioServiceOriginUrl.pathname, 'socket.io')
    });
    socket.on('connect_error', () => dispatch(setUrl(null)));
    socket.on('connect_failed', () => dispatch(setUrl(null)));
    socket.on('disconnect', () => dispatch(setUrl(null)));
    socket.on('record trade error', () => dispatch(setUrl(null)));

    socket.emit('trade record url', { recordUrl: unsafeRecordUrl, callId: call.id });

    socket.on('traded record', ({ path }) => {
      const audioServiceRecordUrl = new URL(
        nodePath.join(audioServiceOriginUrl.pathname, path),
        audioServiceOriginUrl.origin
      );
      dispatch(setUrl(audioServiceRecordUrl.href));
      if (playedSeconds && isReload) setTime(playedSeconds);
    });
    return socket;
  };

  useEffect(() => {
    fetchMediaData();
    const socket = initSocket({ isReload: false });
    return () => {
      socket.emit('leave player page');
      setGetMediaUrl(null);
      setGetMediaUrlDone(true);
      dispatch(setUrl(null));
      dispatch(setUpdatingUrl(true));
      dispatch(updatePlayerState({ isPlaying: false }));
    };
  }, [unsafeRecordUrl, call.id]);

  const onPlay = () => {
    dispatch(updatePlayerState({ wasPlayed: true }));
    // ! remove autoplay attr from audio tag cuz it can cause double sound on some devices
    const audio = document.querySelector('audio');
    if (audio) {
      try {
        audio.removeAttribute('autoplay');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onError = error => {
    console.log('error', error);
    SModal.warning({
      title: 'Не удается воспроизвести запись',
      onOk: () => {
        initSocket({ isReload: true });
      },
      closable: true,
      okText: 'Перезагрузить плеер',
      content: (
        <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
          <Col span={24}>
            <Text>
              Нажмите &quot;Перезагрузить плеер&quot;, чтобы повторить попытку воспроизведения, или
              обратитесь в службу техподдержки CRM или телефонии со ссылкой на запись:
            </Text>
          </Col>
          <Col span={24}>
            <Text
              copyable={{
                text: unsafeRecordUrl,
                tooltips: [
                  t('constants.errors.loadingRecordError.tooltip.copy'),
                  t('constants.errors.loadingRecordError.tooltip.copied')
                ]
              }}
              className="truncated"
            >
              <a href={unsafeRecordUrl} target="_blank" rel="noreferrer">
                {truncateString(unsafeRecordUrl, 40)}
              </a>
            </Text>
          </Col>
        </Row>
      )
    });
  };

  const loading = updatingUrl || getMediaUrlDone;

  return (
    <div>
      {/* Спиннер загрузки аудио */}
      {loading && (
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={[8, 8]}
          style={{ margin: '-4px', height: '134px', width: '100%' }}
        >
          <Col>
            <Spin spinning tip={t('components.recordPlayer.loading')} />
          </Col>
        </Row>
      )}
      {/* Загрузка аудио с базовой авторизацией */}
      {!updatingUrl && getMediaUrl && !getMediaUrlDone && (
        <>
          <Player
            url={getMediaUrl}
            onProgress={handleProgress}
            style={{ display: 'none' }}
            onDuration={duration => dispatch(updatePlayerState({ duration }))}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            onError={onError}
            onPlay={onPlay}
          />
          <AudioTrack
            comments={comments}
            setTime={setTime}
            messages={messages}
            onMessageClick={onMessageClick}
            bufferedPercentage={bufferedPercentage}
          />
        </>
      )}
      {/* Загрузка аудио без базовой авторизации */}
      {!updatingUrl && !getMediaUrl && !getMediaUrlDone && (
        <>
          <Player
            url={url || unsafeRecordUrl}
            onProgress={handleProgress}
            style={{ display: 'none' }}
            onDuration={duration => dispatch(updatePlayerState({ duration }))}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            onError={onError}
            onPlay={onPlay}
            config={
              (url || unsafeRecordUrl).includes(basicAuthUrl) && {
                file: {
                  forceAudio: true,
                  forceHLS: true,
                  hlsOptions: {
                    xhrSetup(xhr: XMLHttpRequest, url: string) {
                      xhr.open('GET', url, true);
                      xhr.setRequestHeader('Authorization', basicAuthCredentials);
                    }
                  }
                }
              }
            }
          />
          <AudioTrack
            comments={comments}
            setTime={setTime}
            messages={messages}
            onMessageClick={onMessageClick}
            bufferedPercentage={bufferedPercentage}
          />
        </>
      )}

      <RecordControls
        basicAuthUrl={basicAuthUrl}
        basicAuthCredentials={basicAuthCredentials}
        call={call}
        reviewId={review ? review.id : null}
        setTime={setTime}
        allowAttachTags={allowAttachTags}
        showTags={showTags}
        shouldLoad={shouldLoad}
        recordUrl={getMediaUrl && !getMediaUrlDone ? getMediaUrl : url || unsafeRecordUrl}
        reloadFunction={() => {
          initSocket({ isReload: true });
        }}
      />
    </div>
  );
};

export default React.memo(RecordPlayer, isEqual);
