import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { selectSearch } from 'core/utils/selectSearch';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';

const ChecklistDefinitionSelector = ({ filters, userId, onChangeFilters }) => {
  const { t } = useTranslation();

  const checklistDefinitions = useSelector(
    state =>
      Object.values(
        getChecklistsDefinitionsByStatuses(state, {
          statuses: [
            CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
            CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
          ]
        })
      ),
    isEqual
  );

  return (
    <ChecklistDefinitionsSelect
      checklistDefinitions={[
        { id: 'all-questions', name: t('userAnalyticsPage.mainControlsPanel.questionValues') },
        ...checklistDefinitions
      ]}
      value={filters?.checklistDefinitionsIds}
      placeholder={t('userAnalyticsPage.mainControlsPanel.checklistDefinitionsEmpty')}
      onChange={id => {
        onChangeFilters({
          userId,
          filters: {
            ...filters,
            checklistDefinitionsIds: id
          }
        });
      }}
      style={{ width: '100%' }}
      showSearch
      allowAll
      filterOption={(input, option) => selectSearch({ input, option, searchProp: 'searchValue' })}
    />
  );
};

export default ChecklistDefinitionSelector;
