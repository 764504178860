import { message, Spin } from 'antd';
import QuestionModal from 'components/Questions/QuestionModal';
import { basePosition, getNewPosition, moveItemInArray } from 'core/utils/dnd';
import objectsDifference from 'core/utils/objectsDifference';
import { findIndex, get, isEmpty, isEqual, last, omit, sortBy, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { questionToGroupSubgroupBindingsResource } from 'redux/resources/questionToGroupSubgroupBindingsResource';
import {
  getChecklistDefinitionQuestionGroups,
  getChecklistDefinitionQuestionSubGroups,
  getChecklistDefinitionQuestions,
  getChecklistDefinitionCalculationFurmulas
} from 'redux/selectors/checklistItems/checklistItems';
import {
  setSortedQuestionGroups,
  updateMultipleSortedQuestionGroupBindings,
  updateSortedQuestionGroupBindings,
  updateSortedQuestionGroupSubgroupBindings
} from 'redux/ui/checklistEditor/reducer';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import { checklistQuestionGroupSubgroupsResource } from 'redux/resources/checklistQuestionGroupSubgroups';
import AddQuestionModal from './AddQuestionModal';
import EditQuestionGroupModal from './EditQuestionGroupModal';
import QuestionGroup from './QuestionGroup';
import QuestionsFromLibraryModal from './QuestionsFromLibraryModal';
import CalculationFurmulaEditor from './CalculationFurmulaEditor';
import AddCalculatorFormulaModal from './AddCalculatorFormulaModal';
import AddSubGroupModal from './AddSubGroupModal';

const ChecklistItems = ({
  currentChecklist,
  currentChecklistQuestionGroups,
  questionGroupsByIds,
  questionsByIds,
  bindingsByIds,
  bindingsSubgroupByIds,
  deleteBinding,
  createBinding,
  updateBinding,
  updateQuestionGroup,
  createQuestion,
  updateQuestion,
  loading,
  setSortedQuestionGroups,
  sortedQuestionGroups,
  sortedQuestionGroupBindings,
  sortedQuestionGroupSubgroupBindings,
  updateSortedQuestionGroupBindings,
  updateSortedQuestionGroupSubgroupBindings,
  updateMultipleSortedQuestionGroupBindings,
  setEditingQuestion,
  loadingChecklist,
  calculationFurmulas,
  updateSubgroupBinding,
  createSubgroupBinding,
  deleteSubgroupBinding
}) => {
  const dispatch = useDispatch();
  const questionGroupsBottom = get(last(sortedQuestionGroups), 'position', basePosition);
  const currentChecklistQuestionSubGroups = useSelector(
    state => sortBy(getChecklistDefinitionQuestionSubGroups(state, currentChecklist), 'position'),
    isEqual
  );
  const questionSubGroupsBottom = get(
    last(currentChecklistQuestionSubGroups),
    'position',
    basePosition
  );
  const subgroupsIds = useSelector(
    state => state.checklistQuestionGroupSubgroupsResource.byIds,
    isEqual
  );

  const [dragAndDrop, setDragAndDrop] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) {
      setSortedQuestionGroups(sortBy(currentChecklistQuestionGroups, 'position'));
    }
  }, [loading, currentChecklistQuestionGroups.length]);

  const reorderGroups = async ({ draggableId, destination, source }) => {
    const oldQuestionGroup = { ...questionGroupsByIds[draggableId] };

    const simulated = moveItemInArray(sortedQuestionGroups, source.index, destination.index);

    // * consider moving to dnd utils
    const top = get(simulated[destination.index - 1], 'position', 0);

    const bottom = get(simulated[destination.index + 1], 'position', questionGroupsBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      destinationIndex: destination.index,
      listBottom: questionGroupsBottom
    });

    const newQuestionGroup = { ...oldQuestionGroup, position: newPosition };

    setSortedQuestionGroups(simulated);

    await updateQuestionGroup(newQuestionGroup);
    setDragAndDrop(false);
  };

  const reorderSubGroups = async ({ draggableId, destination, source }) => {
    const oldQuestionGroup = { ...questionGroupsByIds[destination.droppableId] };
    const isDifferentGroupWithSubgropups = oldQuestionGroup.questionGroupSubgroupsIds;
    const isDifferentGroup = destination.droppableId !== source.droppableId;
    // Отключаем возможность при попытке перемещения подгруппы из группы с подгруппами в группу без подгрупп
    if (typeof isDifferentGroupWithSubgropups !== 'undefined') {
      if (
        (isDifferentGroup && isDifferentGroupWithSubgropups.length !== 0) ||
        (isDifferentGroup && isDifferentGroupWithSubgropups.length === 0)
      ) {
        setDragAndDrop(false);
        message.error(t('checklistsPage.messages.errorDragAndDropQuestionSubgroup'));
        return;
      }
    }

    const oldQuestionSubGroup = { ...subgroupsIds[draggableId] };
    const sortedChecklistQuestionSubGroups = currentChecklistQuestionSubGroups.filter(e => {
      if (e.questionGroupId === oldQuestionSubGroup.questionGroupId) {
        return e;
      }
    });
    const simulated = moveItemInArray(
      sortedChecklistQuestionSubGroups,
      source.index,
      destination.index
    );
    // * consider moving to dnd utils
    const top = get(simulated[destination.index - 1], 'position', 0);

    const bottom = get(simulated[destination.index + 1], 'position', questionSubGroupsBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      destinationIndex: destination.index,
      listBottom: questionSubGroupsBottom
    });

    const newQuestionGroupSubgroup = { ...oldQuestionSubGroup, position: newPosition };

    await dispatch(
      checklistQuestionGroupSubgroupsResource.operations.updateById(newQuestionGroupSubgroup)
    );
    setDragAndDrop(false);
  };

  const reorderQuestions = async ({ draggableId, destination, source }) => {
    const oldQuestionGroup = { ...questionGroupsByIds[destination.droppableId] };
    const isDifferentGroupWithSubgropups = oldQuestionGroup.questionGroupSubgroupsIds;
    // Отключаем возможность при попытке перемещения критерия из группы без подгрупп в группу с подгруппами
    if (
      typeof isDifferentGroupWithSubgropups !== 'undefined' &&
      isDifferentGroupWithSubgropups.length !== 0
    ) {
      setDragAndDrop(false);
      message.error(t('checklistsPage.messages.errorDragAndDropQuestion'));
      return;
    }
    // Выводим ошибку на экран если что то пошло не так
    if (
      typeof isDifferentGroupWithSubgropups === 'undefined' &&
      Object.keys(oldQuestionGroup).length === 0
    ) {
      message.error(t('errorPages.internalServerError.title'));
      return;
    }

    const destinationGroupId = destination.droppableId;
    const isDifferentGroup = destination.droppableId !== source.droppableId;

    const oldBinding = get(bindingsByIds, draggableId, {});

    const questionGroupBindings = sortedQuestionGroupBindings[destinationGroupId] || [];

    const simulated = moveItemInArray(questionGroupBindings, source.index, destination.index);

    const listBottom = get(
      last(questionGroupBindings),
      'position',
      isDifferentGroup ? basePosition : 0
    );
    const top = get(simulated[destination.index - 1], 'position', 0);
    const bottom = get(simulated[destination.index + 1], 'position', listBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      listBottom,
      destinationIndex: destination.index
    });

    const newBinding = {
      ...oldBinding,
      position: newPosition
    };

    const newBindings = [...simulated];
    newBindings.splice(findIndex(simulated, { id: oldBinding.id }), 1, newBinding);

    if (isDifferentGroup) {
      const newBinding = {
        ...oldBinding,
        // id: uniqueId(),
        questionGroupId: destinationGroupId,
        position: newPosition,
        percentage: 1
      };

      // const oldBindings = get(sortedQuestionGroupBindings, source.droppableId, []);

      updateMultipleSortedQuestionGroupBindings({
        [destinationGroupId]: sortBy([...questionGroupBindings, newBinding], 'position'),
        [source.droppableId]: get(sortedQuestionGroupBindings, source.droppableId, []).filter(
          ({ id }) => id !== oldBinding?.id
        )
      });
      return (
        (await Promise.all([createBinding(newBinding), deleteBinding(oldBinding)])) &&
        setDragAndDrop(false)
      );
    }

    await updateSortedQuestionGroupBindings({
      questionGroupId: destinationGroupId,
      bindings: newBindings
    });
    await updateBinding(newBinding);
    setDragAndDrop(false);
  };

  const reorderQuestionsForSubgroups = async ({ draggableId, destination, source }) => {
    const destinationGroupSubgroupId = destination.droppableId;
    const isDifferentGroup = destination.droppableId !== source.droppableId;

    const oldBinding = get(bindingsSubgroupByIds, draggableId, {});

    const questionGroupSubgroupBindings =
      sortedQuestionGroupSubgroupBindings[destinationGroupSubgroupId] || [];

    const simulated = moveItemInArray(
      questionGroupSubgroupBindings,
      source.index,
      destination.index
    );

    const listBottom = get(
      last(questionGroupSubgroupBindings),
      'position',
      isDifferentGroup ? basePosition : 0
    );

    const top = get(simulated[destination.index - 1], 'position', 0);
    const bottom = get(simulated[destination.index + 1], 'position', listBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      listBottom,
      destinationIndex: destination.index
    });

    const newBinding = {
      ...oldBinding,
      position: newPosition
    };

    const newBindings = [...simulated];
    newBindings.splice(findIndex(simulated, { id: oldBinding.id }), 1, newBinding);

    if (isDifferentGroup) {
      const newBinding = {
        ...oldBinding,
        // id: uniqueId(),
        questionGroupSubgroupId: destinationGroupSubgroupId,
        position: newPosition,
        percentage: 1
      };

      // const oldBindings = get(sortedQuestionGroupSubgroupBindings, source.droppableId, []);

      await updateSortedQuestionGroupSubgroupBindings({
        [destinationGroupSubgroupId]: sortBy(
          [...questionGroupSubgroupBindings, newBinding],
          'position'
        ),
        [source.droppableId]: get(
          sortedQuestionGroupSubgroupBindings,
          source.droppableId,
          []
        ).filter(({ id }) => id !== oldBinding?.id)
      });
      return (
        (await Promise.all([
          createSubgroupBinding(newBinding),
          deleteSubgroupBinding(oldBinding)
        ])) && setDragAndDrop(false)
      );
    }

    updateSortedQuestionGroupSubgroupBindings({
      questionGroupSubgroupId: destinationGroupSubgroupId,
      bindings: newBindings
    });
    await updateSubgroupBinding(newBinding);
    setDragAndDrop(false);
  };

  const onDragEnd = async ({ draggableId, destination, source, type }) => {
    // dropped nowhere
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    setDragAndDrop(true);

    if (type === 'container') {
      await reorderGroups({ draggableId, destination, source });
    } else if (subgroupsIds[draggableId]) {
      await reorderSubGroups({ draggableId, destination, source });
    } else {
      // Проверяем где мы перемещаем критерий (в подгруппе/в группе)
      get(bindingsSubgroupByIds, draggableId, null) !== null
        ? await reorderQuestionsForSubgroups({ draggableId, destination, source })
        : await reorderQuestions({ draggableId, destination, source });
    }
  };

  // * consider moving to ui operations
  const createQuestionWithBinding = async ({ question, questionToGroupBinding }) => {
    const oldQuestion = omit(questionsByIds[question.id], 'valuesDisplayType');
    const oldBinding =
      bindingsByIds[questionToGroupBinding.id] || bindingsSubgroupByIds[questionToGroupBinding.id];
    const isNewQuestion = isEmpty(oldQuestion);
    const isQuestionNeedsUpdate = !isEqual(omit(question, 'valuesDisplayType'), oldQuestion);
    const isNewBinding = isNewQuestion; // isEmpty(oldBinding);
    const isBindingNeedsUpdate = !!oldBinding && !isEqual(questionToGroupBinding, oldBinding);
    const createdQuestion = isNewQuestion
      ? await createQuestion(question)
      : isQuestionNeedsUpdate &&
        (await updateQuestion({
          ...objectsDifference(omit(question, 'valuesDisplayType'), oldQuestion, [
            'colorZones',
            'standardComments'
          ]),
          id: question.id
        }));

    if (isQuestionNeedsUpdate && isEmpty(createdQuestion)) return;
    if (questionToGroupBinding?.questionGroupSubgroupId) {
      const createdBinding = isNewBinding
        ? await createSubgroupBinding({
            ...questionToGroupBinding,
            questionId: get(createdQuestion, 'id', oldQuestion.id)
          })
        : isBindingNeedsUpdate &&
          (await updateSubgroupBinding({
            ...objectsDifference(questionToGroupBinding, oldBinding),
            id: questionToGroupBinding.id
          }));
    } else {
      const createdBinding = isNewBinding
        ? await createBinding({
            ...questionToGroupBinding,
            questionId: get(createdQuestion, 'id', oldQuestion.id)
          })
        : isBindingNeedsUpdate &&
          (await updateBinding({
            ...objectsDifference(questionToGroupBinding, oldBinding),
            id: questionToGroupBinding.id
          }));
    }
    if (createdQuestion) {
      message.success(
        `${t('checklistsPage.checklistItems.messages.question')} ${
          isNewQuestion
            ? t('checklistsPage.checklistItems.messages.questionSuccessfullyCreated')
            : t('checklistsPage.checklistItems.messages.questionSuccessfullyUpdated')
        }`
      );
    } else if ((isNewQuestion || isQuestionNeedsUpdate) && !createdQuestion) {
      message.error(t('checklistsPage.checklistItems.messages.createQuestionFailed'));
    }

    setEditingQuestion({});
  };

  const createMultipleQuestionsWithBindings = async ({ questionToGroupBindings }, type) => {
    if (type === 'checklist-question-group-subgroups') {
      await Promise.all(questionToGroupBindings.map(binding => createSubgroupBinding(binding)));
    } else {
      await Promise.all(questionToGroupBindings.map(binding => createBinding(binding)));
    }
  };

  const editorState = {};

  const clickToQuestion = (event, shortId) => {
    if ((event.ctrlKey || event.metaKey) && isFunction(editorState?.setCommentState)) {
      insertShortId(event, shortId);
    }
  };

  const insertShortId = async (event, shortId) => {
    event.stopPropagation();
    let position = editorState.commentState.selectionStart;
    const startStr = editorState.commentState.text.slice(0, position);
    const endStr = editorState.commentState.text.slice(position);
    const checkLetterOpen = startStr.lastIndexOf('[');
    const checkLetterClose = startStr.lastIndexOf(']');
    if (checkLetterClose < checkLetterOpen && endStr.indexOf(']') !== -1) {
      const checkLetterOpen = endStr.indexOf('[');
      const checkLetterClose = endStr.indexOf(']');
      if (checkLetterOpen === -1 || checkLetterOpen > checkLetterClose) {
        position = endStr.indexOf(']') + startStr.length + 1;
      }
    }
    editorState.target.focus();
    editorState.target.selectionStart = position;
    editorState.target.selectionEnd = position;
    document.execCommand('insertText', false, `[${shortId}]`);
  };

  const setActivFormulaEditor = (commentState, setCommentState, target) => {
    editorState.commentState = commentState;
    editorState.setCommentState = setCommentState;
    editorState.target = target;
  };

  return (
    <>
      <Spin spinning={loadingChecklist} style={{ margin: '16px 0' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="top-container" direction="vertical" type="container">
            {(provided, contextSnapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortedQuestionGroups.map((questionGroup, i) => (
                  <Draggable
                    index={i}
                    key={questionGroup.id}
                    draggableId={questionGroup.id}
                    isDragDisabled={dragAndDrop}
                  >
                    {(provided, { isDragging }) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Droppable
                          direction="vertical"
                          droppableId={questionGroup.id}
                          key={questionGroup.id}
                          type="group"
                        >
                          {(provided, { isDraggingOver }) => {
                            return (
                              <QuestionGroup
                                questionGroup={questionGroup}
                                currentChecklist={currentChecklist}
                                currentChecklistQuestionGroupsByIds={currentChecklistQuestionGroups}
                                provided={provided}
                                listBottom={questionGroupsBottom}
                                isDragging={isDragging}
                                isDraggingGlobal={contextSnapshot.isDraggingOver}
                                isDraggingOver={isDraggingOver}
                                clickToQuestion={clickToQuestion}
                                dragAndDropDisabled={dragAndDrop}
                              />
                            );
                          }}
                        </Droppable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Spin>
      <AddQuestionModal currentChecklist={currentChecklist} />
      <QuestionsFromLibraryModal onSubmit={createMultipleQuestionsWithBindings} />
      <QuestionModal onSubmit={createQuestionWithBinding} currentChecklist={currentChecklist} />
      <EditQuestionGroupModal />
      {calculationFurmulas.map((calculationFurmula, i) => (
        <CalculationFurmulaEditor
          calculationFurmula={calculationFurmula}
          setActivFormulaEditor={setActivFormulaEditor}
          isLastFormula={i + 1 === calculationFurmulas.length}
          currentChecklist={currentChecklist}
        />
      ))}
      <AddCalculatorFormulaModal />
      <AddSubGroupModal />
    </>
  );
};

const mapStateToProps = state => {
  const {
    currentChecklist,
    sortedQuestionGroups,
    sortedQuestionGroupBindings,
    sortedQuestionGroupSubgroupBindings
  } = state.uiChecklistEditor;
  const { updateByIdStarted } = state.questionGroupsResource;
  return {
    currentChecklistQuestionGroups: getChecklistDefinitionQuestionGroups(state, currentChecklist),
    currentChecklistQuestions: getChecklistDefinitionQuestions(state, currentChecklist),
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    bindingsSubgroupByIds: state.questionToGroupSubgroupBindingsResource.byIds,
    questionGroupsByIds: state.questionGroupsResource.byIds,
    questionsByIds: state.questionsResource.byIds,
    loading: updateByIdStarted || state.checklistDefinitionsResource.loading,
    sortedQuestionGroups,
    sortedQuestionGroupBindings,
    sortedQuestionGroupSubgroupBindings,
    calculationFurmulas: getChecklistDefinitionCalculationFurmulas(state, currentChecklist)
  };
};

const mapDispatchToProps = {
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  createBinding: questionToGroupBindingsResource.operations.create,
  updateSubgroupBinding: questionToGroupSubgroupBindingsResource.operations.updateById,
  deleteSubgroupBinding: questionToGroupSubgroupBindingsResource.operations.deleteById,
  createSubgroupBinding: questionToGroupSubgroupBindingsResource.operations.create,
  updateQuestionGroup: questionGroupsResource.operations.updateById,
  createQuestion: questionsResource.operations.create,
  updateQuestion: questionsResource.operations.updateById,
  setSortedQuestionGroups,
  setEditingQuestion,
  updateSortedQuestionGroupBindings,
  updateSortedQuestionGroupSubgroupBindings,
  updateMultipleSortedQuestionGroupBindings
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItems);
