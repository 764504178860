export default {
  pagesMeta: {
    loginPage: { title: 'Log in | qolio.io' },
    registerPage: { title: 'Registration | qolio.io' },
    recoverPasswordPage: { title: 'Password recovery | qolio.io' },
    setNewPasswordPage: { title: 'Set new password | qolio.io' },
    organizationSettingsPage: { title: 'Organisation settings | qolio.io' },
    checklistEditorPage: { title: 'Score card settings | qolio.io' },
    checklistQuestionsEditor: { title: 'Criterions settings | qolio.io' },
    checklistsAndQuestionsPage: { title: 'Score cards | qolio.io' },
    callPage: { title: 'Call | qolio.io' },
    reviewPage: { title: 'Evaluation | qolio.io' },
    textCommunicationPage: { title: 'Text communication | qolio.io' },
    clientInteractionsPage: { title: 'Communication list | qolio.io' },
    calibrationsSessionsPages: {
      calibrationsSessions: { title: 'Calibration sessions | qolio.io' },
      viewCalibrationSession: { title: 'Current calibration session | qolio.io' },
      calibrationSessionResult: { title: 'Calibration session result | qolio.io' }
    },
    customCommunicationReviewPage: {
      titles: {
        review: 'Evaluation | qolio.io',
        addReview: 'Add evaluatiob | qolio.io'
      }
    },
    dashboardPage: { title: 'Analytics | qolio.io' },
    notFoundPage: { title: 'Page not found | qolio.io' },
    libraryPage: { title: 'Library | qolio.io' },
    organizationStructurePage: {
      tabs: {
        organizationStructure: { title: 'Company structure | qolio.io' },
        employees: { title: 'Employees | qolio.io' },
        permissionsAndLevels: { title: 'Access rights and employee levels | qolio.io' },
        notificationsAndStatuses: { title: 'Notifications and contact statuses | qolio.io' }
      }
    },
    integrationsSettingsPage: { title: 'Integrations | qolio.io' },
    editTaskDefinitionCardPage: {
      titles: {
        createTag: 'Create tag | qolio.io',
        updateTag: 'Edit tag | qolio.io'
      }
    },
    triggersSettingsPage: { title: 'Tags | qolio.io' },
    userSettingsPage: { title: 'User settings | qolio.io' },
    teamPage: { title: 'Team | qolio.io' },
    uploadCallsPage: { title: 'Calls download | qolio.io' },
    userAnalyticsPage: { title: 'Profile | qolio.io' },
    workPlanTaskConfigurationsPage: { title: 'Load distribution | qolio.io' },
    appealsPage: { title: 'Appeals | qolio.io' },
    appealsSettingsPage: { title: 'Appeals settings | qolio.io' },
    unitAnalyticsPage: {
      title: 'Units analytics | qolio.io'
    },
    autoFailAnalyticsPage: {
      title: 'Auto fail analysis | qolio.io'
    },
    crmEntityPage: {
      title: 'Deal/Ticket | qolio.io',
      buttons: {
        info: 'info'
      }
    }
  }
};
