  import { Col, Row } from 'antd';
  import StatusSelector from 'components/PhoneCallPlayer/RecordPlayer/StatusSelector';
  import React from 'react';
  import CommunicationInfoModal from 'components/CommunicationInfoModal';
  import { withMediaProps } from 'react-media-player';
  import { isEmpty } from 'lodash';
  import CopyLinkButton from 'components/CopyLinkButton';
  import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
  
  const RecordChainControls = ({
    call,
    reviewId,
    setTime,
    allowAttachTags = true,
    showTags = true,
    shouldLoad = true,
    recordUrl,
    reloadFunction,
    customFields
  }) => {
    const showAdditionalModal = !isEmpty(customFields) || call?.phoneCallChainId;
    return (
      <Row style={{ marginTop: "64px", marginBottom: "-14px" }} type="flex" align="middle" justify="space-between">
        <Col>
          <Row align="middle" justify="space-between">
            <Col>
              <Row type="flex" align="middle" justify="start" gutter={[6, 0]}>
                <Col>
                  <CopyLinkButton
                    reviewId={reviewId}
                    destinationId={call?.id}
                    destinationType="communication-chains"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle" justify="space-between" gutter={[8, 0]}>
            <Col>
              <AttachTag
                allowAttachTags={true}
                showTags={true}
                reviewId={reviewId}
                shouldLoad={true}
                truncate={16}
              />
            </Col>
            <Col>
              <StatusSelector call={call} allowLoadStatuses={shouldLoad} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  
  export default withMediaProps(RecordChainControls);
  