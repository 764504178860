import { Alert, Form, Input, Modal, } from 'antd';
import LabelWithInfo from 'components/LabelWithInfo';
import { get, isEmpty, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checklistFormulasResource } from 'redux/resources/checklistFormulas';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import { setAddingCalculationFurmulasData } from 'redux/ui/checklistEditor/reducer';

const defaultGroup = {};

const AddCalculatorFormulaModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentChecklist, addingCalculationFurmulasData = defaultGroup } = useSelector(
    state => state.uiChecklistEditor,
    isEqual
  );

  const checklistFormulasByIds = useSelector(state => state.checklistFormulasResource.byIds, isEqual);

  const layout = {
    labelCol: {
      span: 4
    },
    labelAlign: 'left',
    wrapperCol: {
      span: 20
    }
  };

  const checklistDefinitionId = get(currentChecklist, 'id', undefined);
  const visible = !isEmpty(addingCalculationFurmulasData);
  const isNew = isEmpty(get(checklistFormulasByIds, addingCalculationFurmulasData.id, {}));

  const createOrEditChecklistFormula = async ({
    name,
    checklistDefinitionId
  }) => {
    if (isNew) {
      await dispatch(
        checklistFormulasResource.operations.create({
          'formula_data': JSON.stringify({name,
            text: '',}),
          checklistDefinitionId
        })
      );
    } else {
      await dispatch(
        checklistFormulasResource.operations.updateById({
            id: addingCalculationFurmulasData.id,
            checklistDefinitionId,
            'formula_data': JSON.stringify({
                name,
                text: addingCalculationFurmulasData.text,
            }),
        })
      );
    }
    
    return [];
  };

  const saveAndCloseModal = async ({ name }) => {
    const savedQuestionGroup = await createOrEditChecklistFormula({
      name,
      checklistDefinitionId
    });

    if (savedQuestionGroup) {
      form.setFieldsValue({ name: '' });
      dispatch(setAddingCalculationFurmulasData());
      dispatch(setEditableChecklistDefinition({ id: get(currentChecklist, 'id', '') }));
    }
  };

  const handleSubmit = values => {
    if (checklistDefinitionId) {
      const { name } = values;
      saveAndCloseModal({ name });
    }
  };

  const closeModal = () => {
    form.setFieldsValue({ name: '' });
    dispatch(setAddingCalculationFurmulasData());
  };

  useEffect(() => {
    if (addingCalculationFurmulasData?.name) {
      form.setFieldsValue({ name: addingCalculationFurmulasData.name });
    }
  }, [addingCalculationFurmulasData?.name]);

  return (
    <Modal
      maskClosable
      visible={visible}
      title={t('checklistsPage.calculationFurmula.modal.title')}
      width={768}
      onOk={form.submit}
      onCancel={closeModal}
      okText={
        isNew
          ? t('checklistsPage.editQuestionGroupModal.ok.create')
          : t('checklistsPage.editQuestionGroupModal.ok.update')
      }
      cancelText={t('checklistsPage.editQuestionGroupModal.cancel')}
    >
      <Alert
        description={t('checklistsPage.calculationFurmula.modal.description')}
        type="info"
        style={{ marginBottom: 16 }}
      />
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{ name: addingCalculationFurmulasData?.name, percentageCalculationStartingWithZero: false }}
      >
        <Form.Item
          label={
            <LabelWithInfo
              infoTitle={t('checklistsPage.calculationFurmula.modal.namePlaceholder')}
            >
              {t('checklistsPage.editQuestionGroupModal.form.fields.name')}
            </LabelWithInfo>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t('checklistsPage.editQuestionGroupModal.form.messages.enterName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t('checklistsPage.calculationFurmula.modal.namePlaceholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCalculatorFormulaModal;
