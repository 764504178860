import createListReducer from 'core/redux/lists/listReducer';
import { communicationChainsV1Resource } from '../resources/communicationChainsV1';

const defaultState = {
  communicationChains: {}
};

const { actions, reducer, operations } = createListReducer({
  name: 'communicationChainsV1',
  defaultState,
  loader: communicationChainsV1Resource.operations.load
});

const communicationChainsV1Reducer = reducer;

export { actions, communicationChainsV1Reducer, operations };
