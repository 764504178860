export default {
  checklistsPage: {
    title: 'Lista a chequear',
    info:
      'Presione "Crear una nueva lista a chequear" para ajustar una nueva lista a chequear. En ella, en el estado "Borrrador" Usted puede redactar la cantidad de preguntas, la escala y todas las condiciones de evaluación. Para comenzar a evaluar la comunicación por esta lista a chequar, presione "Publicar". En la lista de abajo, están guardadas todas las listas a chequear en diferentes estados: Borrador, Publicado y Archivo. En el Archivo guarde aquellas listas a chequear por las cuales ya no quiere realizar evaluaciones. Presione "Crear pregunta para la lista a chequear" para crear un nuevo punto de evaluación, que luego puede ser agragado a cualquier lista a chequear. Para ver todos los puntos de todas las listas a chequear, presione el botón "Preguntas.',
    infoButton: 'Detalles aquí',
    buttons: {
      createChecklists: 'Crear una nueva lista a chequear',
      createQuestion: 'Crear pregunta para la lista a chequear',
      checklists: 'Lista a chequear',
      questions: 'Criterios'
    },
    messages: {
      question: 'Criterios',
      questionSuccessfullyCreated: 'сreado con éxito',
      questionSuccessfullyUpdated: 'actualizado con éxito',
      createQuestionfailed: 'No se logró crear la pregunta',
      updateQuestionfailed: 'No se logró actualizar la pregunta',
      errorDragAndDropQuestion: 'Este criterio no puede modificarse',
      errorDragAndDropQuestionSubgroup: 'No se puede cambiar la posición de este subgrupo'
    },
    addQuestionModal: {
      title: 'Agregar una pregunta',
      buttons: {
        libraryQuestion: 'Pregunta de la biblioteca',
        newQuestion: 'Nueva pregunta'
      },
      messages: {
        disabledByWeight:
          'Usted no puede agragar una pregunta, ya que la cantidad de preguntas de la lista a chequear alcanzó el 100%.',
        info:
          'Cree una nueva pregunta o añada una pregunta de la biblioteca. En la biblioteca encontrará preguntas qyue pueden haber sido añadidas a otra lista a chequear.'
      }
    },
    checklistInfo: {
      emptyName: 'No establecido el nombre de la lista a chequear',
      copy: '(Copia)',
      messages: {
        copySuccessfullyCreated: 'Copia creada',
        createCopyfailed: 'No se logró crear la copia',
        checklistSuccessfullyDeleted: 'Lista a chequear eliminada',
        checklistSuccessfullyPublished: 'Lista a chequear publicada',
        completedQuestionsSumm:
          'La suma de las preguntas en la lista a chequear debe alcanzar el 100%',
        emptyChecklistQuestions: 'La lista chequear debe contener preguntas para ser publicada',
        checklistSuccessfullyArchived: 'La lista a chequear se ha archivado'
      },
      confirmArchive: {
        title: '¿Guardar la lista a chequear en el archivo?',
        description:
          'Las listas a chequear archivadas no están disponibles para ser elegidas como listas a chequear para evaluar',
        ok: 'Ir al archivo',
        cancel: 'Cancelar'
      },
      confirmDelete: {
        title: '¿Eliminar la lista a chequerar?',
        description:
          'Las listas a chequear eliminadas no podrán ser restablecidas. Si esta lista no es relevante para usted, la puede archivar',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      },
      confirmPublish: {
        title: '¿Publicar la lista a chequear?',
        description:
          ' No se puede redactar la lista a chequear luego de publicarla: método y cálculo de la evaluación, escala de evalación y cantidad de preguntas. Antes de publicar, revise que todos los ajustes son correctos',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      menu: {
        setting: 'Ir al ajuste de la lista a chequear',
        copy: 'Crear una copia',
        archive: 'Guardar en el archivo',
        delete: 'Eliminar la lista a chequear'
      },
      breadcrumbs: {
        checklists: 'Listas a chequear',
        checklistSettings: 'Ajustes de la lista a chequear',
        checklistQuestions: 'Ajuste de las preguntas'
      },
      buttons: {
        publish: 'Publicar la lista a chequear',
        actions: 'Comportamiento'
      },
      items: {
        ratingMethod: 'Método de evaluación: ',
        ratingCalculation: 'Cálculo de la evaluación: ',
        scaleType: 'Escala de la evaluación: ',
        communicationTypes: 'Tipos de comunicación: ',
        units: 'Departamentos: ',
        totalChecklistScore: 'Evaluación total por la lista a chequear: ',
        checklistScore: 'Puntos por la lista a chrquear: ',
        totalSumPercentage: 'Porcentaje total de todas las preguntas de la lista a chrquear',
        sumPercentage: 'Peso de las preguntas'
      },
      modal: {
        title: 'Ajeste de la zona de evaluación',
        ok: 'Publicar',
        cancel: 'Cancelar'
      }
    },
    checklistItems: {
      messages: {
        question: 'Pregunta',
        questionSuccessfullyCreated: 'creado y añadido a la lista a chequear',
        questionSuccessfullyUpdated: 'actualizado',
        createQuestionFailed: 'No se logró crear o actualizar la pregunta'
      }
    },
    editQuestionGroupModal: {
      title: 'Ajuste del grupo de preguntas',
      ok: {
        create: 'Crear',
        update: 'Actualizar'
      },
      cancel: 'Cancelar',
      alert: {
        description:
          'Agrupe las preguntas (puntos) de la lista a chequear en grupos, para vigilar los resultados y su dinámica por una habilidad común del administrador. Esto le ayudaá a extructurar la lista a chequear y el sistema de evaluación de conocimientos y la competencia de los administradores.'
      },
      form: {
        fields: {
          name: 'Nombre',
          namePlaceholder: 'Intruduzca el nombre de los grupos de preguntas',
          nameInfo:
            'Nombre del grupo que se reflejará en la lista a chequear durante la evaluación y la analítica',
          percentageCalculationStartingWithZero: 'El grupo puede tener un porcentaje negativo',
          percentageCalculationStartingWithZeroPlaceholder:
            'En este caso, el cálculo se realiza desde cero.'
        },
        messages: { enterName: 'Introduzca el nombre, por favor' }
      }
    },
    question: {
      confirmDelete: {
        title: '¿Está seguro que quiere eliminar la pregunta de la lista a chequear?',
        description: 'Estará disponible como antes en la lista de preguntas',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      },
      menu: {
        edit: 'Redactar la pregunta',
        copy: 'Crear una copia',
        delete: 'Eliminar la pregunta'
      },
      weight: 'Peso de la pregunta',
      tooltip: { title: 'Criterio de autofile' }
    },
    questionGroup: {
      ratingMethod: 'El método de cálculo no se ha establecido',
      confirmDelete: {
        title: '¿Eliminar el grupo?',
        description:
          'Las preguntas del grupo que está eliminando se eliminarán de la lista a chrquear en curso',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      },
      menu: {
        edit: 'Redactar el grupo',
        copy: 'Crear una copia',
        delete: 'Eliminar el grupo'
      },
      sumRates: 'Evaluación del grupo',
      sumRatesInfo: 'Evaluación total del grupo',
      sumPercentage: 'Peso del grupo',
      sumSubPercentage: 'Peso del subgrupo',
      sumPercentageInfo: 'Porcentaje del peso total de todas las preguntas del grupo',
      buttons: {
        addQuestion: 'Agregar una pregunta',
        addQuestionGroup: 'Añadir grupo'
      }
    },
    calculationFurmula: {
      buttons: {
        addCalculationFurmula: 'Agregar fórmula'
      },
      menu: {
        edit: 'Editar fórmula',
        delete: 'Eliminar fórmula',
        check: 'Verificar la fórmula'
      },
      modal: {
        title: 'Configuración de fórmulas de cálculo',
        namePlaceholder: 'Ingrese el nombre de la fórmula',
        description: 'Agrega fórmulas para el cálculo y realiza un seguimiento de la dinámica de los empleados según tus propios criterios.'
      },
      confirmDelete: {
        title_1: "¿Está seguro de que desea eliminar la fórmula «",
        title_2: "» del formulario de evaluación?"
      },
      confirmCheck: {
        title_error: "Verifica la corrección de los paréntesis en esta parte de la fórmula:",
        title_ok: "No hay errores en la fórmula",
        ok: 'ok',
      }
    },
    subGroup: {
      sumRatesInfo: 'Puntuación total del grupo',
      buttons: {
        addSubGroup: 'Agregar subgrupo'
      },
      modal: {
        title: 'Ajustes del Grupo de Criterios.',
        namePlaceholder: 'Nombre del grupo.',
        description: 'Combina los criterios de la forma de evaluación en grupos para realizar un seguimiento de los resultados y su dinámica basada en una habilidad común del empleado. Esto ayuda a estructurar la propia forma de evaluación y el sistema de evaluación de los conocimientos y competencias de los empleados.'
      },
      sumRates: 'Puntuación del subgrupo',
      menu: {
        edit: 'Editar subgrupo',
        delete: '¿Eliminar subgrupo?'
      }
    },
    questionsFromLibraryModal: {
      title: 'Elija las preguntas de la biblioteca',
      ok: 'Añadir',
      cancel: 'Cancelar',
      alert: {
        title:
          'En esta lista se reflejan las preguntas solo con la escala y zona de evaluación adecuada'
      }
    }
  }
};
