import { get, put, destroy, post } from 'redux-bees';

const checklistQuestionGroupSubgroupsEndpoints = apiPath => ({
  createChecklistQuestionGroupSubgroup: { method: post, path: `${apiPath}/checklist/question_group_subgroups` },
  getChecklistQuestionGroupSubgroups: { method: get, path: `${apiPath}/checklist/question_group_subgroups` },
  updateChecklistQuestionGroupSubgroup: { method: put, path: `${apiPath}/checklist/question_group_subgroups/:id` },
  deleteChecklistQuestionGroupSubgroup: {
    method: destroy,
    path: `${apiPath}/checklist/question_group_subgroups/:id`
  }
});

export default checklistQuestionGroupSubgroupsEndpoints;