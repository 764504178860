export default {
  loginPage: {
    form: {
      title: 'Вход',
      unRegistered: 'Не зарегистрированы?',
      fields: {
        emailPlaceholder: 'Введите e-mail',
        passwordPlaceholder: 'Введите пароль'
      },
      messages: {
        enterEmail: 'Введите e-mail',
        enterPassword: 'Введите пароль',
        wrongEmail: 'Введите корректный email',
        minLengthPassword: 'Минимум 6 символов'
      },
      buttons: {
        login: 'Войти',
        forgotPassword: 'Забыли пароль?',
        registration: 'Регистрация через CRM',
        goToSite: 'Перейти на сайт',
        registrationlink: 'Пройти регистрацию'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Эффективность',
          description:
            'Все инструменты для контроля качества и оценки коммуникаций собраны на одной платформе'
        },
        motivation: {
          title: 'Мотивация',
          description: 'Вовлекайте, развивайте и мотивируйте сотрудников'
        },
        simplicity: {
          title: 'Простота',
          description:
            'Откажитесь от электронных таблиц и устаревших инструментов для оценки и контроля качества'
        },
        analytics: {
          title: 'Аналитика',
          description: 'Анализируйте показатели качества и находите новые возможности для роста'
        }
      }
    }
  }
};
