import DOMPurify from 'dompurify';
import { isString } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HtmlContent = styled.span`
  font-size: 16px;
`;

const PartContent = ({ children = '', contentType = '' }) => {
  let htmlString;
  const { t } = useTranslation();

  if (isString(contentType) && contentType.includes('html')) {
    htmlString = children;
  } else {
    // By default we think that it is text content type
    htmlString = children?.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  htmlString = htmlString
    ?.replace('<i class="hde-uncheckbox"></i>', t('general.no'))
    ?.replace('<i class="hde-checkbox"></i>', t('general.yes'));

  return (
    <HtmlContent
      className="ant-typography"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(htmlString, {
          ALLOWED_ATTR: ['target', 'href', 'src', 'style']
        })
      }}
    />
  );
};

export default PartContent;
