import { Button, Col, message, Row, Skeleton, Typography, Upload, Modal, Empty } from 'antd';
import { OutlinedButton } from 'components/Buttons';
import Search from 'components/Inputs/Search';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import { debounce, isEmpty, orderBy, pick, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { operations, actions } from 'redux/lists/userAccountBindingsList';
import uniqid from 'uniqid';
import { saveAs } from 'file-saver';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { apiUrl } from 'core/config';
import { API_V1_PATH } from 'core/api';
import useDynamicRefs from 'use-dynamic-refs';
import axios from 'axios';
import SCol from 'components/Standard/SCol';
import SList, { SListItem } from 'components/Standard/SList';
import { processJsonApiCollection } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import CustomUserAccountForm from './CustomUserAccountForm';

const { Text } = Typography;

const ManageCustomUserAccounts = ({
  integration,
  onOk,
  showActive = false,
  okText,
  okButtonProps = {}
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [handlingOk, setHandlingOk] = useState(false);
  const [editable, setEditable] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [getRef, setRef] = useDynamicRefs();

  const dispatch = useDispatch();

  const loadAndPreselectAccounts = async () => {
    setLoading(true);
    const accountsByIds = await dispatch(
      operations.load({
        pagination: 'false',
        include: 'user.unit,user.role',
        filters: { integrationId: integration.id }
      })
    );

    setAccounts(Object.values(accountsByIds));
    setLoading(false);
  };

  useEffect(() => {
    dispatch(actions.clearList());
    loadAndPreselectAccounts();
    return () => dispatch(actions.clearList());
  }, []);

  const handleOk = async () => {
    const validation = await Promise.all(
      accounts.map(account => getRef(account.id).current.validate())
    );

    if (some(validation, ({ error }) => !isEmpty(error))) {
      return;
    }

    try {
      setHandlingOk(true);
      const accountsByIds = await dispatch(
        userAccountBindingsResource.operations.importUserAccountBindings({
          id: integration.id,
          data: accounts.map(account =>
            pick(account, ['firstName', 'lastName', 'integrationUid', 'email'])
          )
        })
      );

      setAccounts(Object.values(accountsByIds));

      if (!accountsByIds) return setHandlingOk(false);

      if (onOk) {
        await onOk(accountsByIds);
      }
      setEditable(false);
    } catch (error) {
      console.log(error);
    } finally {
      setHandlingOk(false);
    }
  };

  const onDelete = (e, id) => {
    setAccounts(accounts.filter(account => account.id !== id));
  };

  const onDownloadCSV = async () => {
    try {
      const headers = {
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
        'content-type': 'multipart/form-data'
      };
      const response = await fetch(
        `${apiUrl}${API_V1_PATH}/api_integrations/${integration.id}/user_account_bindings_csv`,
        { headers }
      );
      const blob = await response.blob();
      saveAs(blob, `${integration.name || integration.id}.csv`);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleUserAccountUpdate = debounce((id, fieldName, value) => {
    setAccounts(
      accounts.reduce((acc, user) => {
        if (user.id === id) {
          return [...acc, { ...user, [fieldName]: value }];
        }

        return [...acc, user];
      }, [])
    );
  }, 300);

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  const onAddUserAccount = () => {
    setAccounts([...accounts, { integrationUid: '', email: '', id: uniqid() }]);
  };

  const onEdit = () => {
    setEditable(true);
    // onAddUserAccount();
  };

  const onUploadCSV = async ({ file, onSuccess, onError, onProgress }) => {
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data'
    };

    const uploadEndpoint = `${apiUrl}${API_V1_PATH}/api_integrations/${integration.id}/user_account_bindings_csv`;

    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };

    const body = new FormData();
    body.append('file', file);
    const hide = message.loading(t('integrationsSettingsPage.manageUserAccounts.sendingFile'), 0);
    try {
      const response = await axios.post(uploadEndpoint, body, { headers, onUploadProgress });
      hide();
      Modal.success({
        maskClosable: true,
        title: t('integrationsSettingsPage.manageUserAccounts.csvModal.title'),
        content: t('integrationsSettingsPage.manageUserAccounts.csvModal.content'),
        okText: t('integrationsSettingsPage.manageUserAccounts.csvModal.okText'),
        onCancel: () => {
          if (onOk) {
            onOk();
          }
        },
        onOk: async () => {
          if (onOk) {
            await onOk();
          }
        }
      });
      const accountsByIds = processJsonApiCollection(response.data.data);
      dispatch(userAccountBindingsResource.actions.loadSucceed(accountsByIds));
      setAccounts(Object.values(accountsByIds));
      onSuccess(response.data);
      setEditable(false);
    } catch (error) {
      console.log(error);
      hide();
      handleError(error?.response?.status, error?.response?.data);
      onError(error);
    }
  };

  const activeAccounts = accounts.filter(acc => !isEmpty(acc.userId));

  const onSearch = input => {
    setAccounts(
      orderBy(
        accounts,
        account =>
          (account.lastName + account.firstName || '')
            ?.toLowerCase()
            ?.indexOf(input.toLowerCase()) >= 0,
        ['desc']
      )
    );
  };

  return (
    <SCard bordered shadowed>
      <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
        {!isEmpty(accounts) || editable ? (
          <Col span={24}>
            <Row gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col>
                    <Row align="middle" gutter={[16, 0]}>
                      <Col>
                        <Search onSearch={onSearch} disabled={editable} />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={[24, 0]} align="middle">
                      <Col>
                        <Text type="secondary">
                          {`${t('integrationsSettingsPage.manageUserAccounts.totalUsers')}: `}
                        </Text>
                        <Text>{accounts.length}</Text>
                      </Col>
                      {showActive && (
                        <Col>
                          <Text type="secondary">
                            {`${t('integrationsSettingsPage.manageUserAccounts.activeUsers')}: `}
                          </Text>
                          <Text style={{ color: 'var(--green_primary)' }}>
                            {activeAccounts.length}
                          </Text>
                        </Col>
                      )}
                      {editable && (
                        <Col>
                          <Button
                            type="primary"
                            loading={handlingOk}
                            onClick={handleOk}
                            {...okButtonProps}
                          >
                            {isEmpty(okText) ? t('general.save') : okText}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <SListItem borderBottom="1px solid var(--gray-border)" marginBottom="6px">
                  <SCol span={24} padding="0">
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[16, 0]}
                      style={{ marginRight: 0 }}
                    >
                      {/* <Col span={1} /> */}
                      <Col span={showActive ? 6 : 8}>
                        <Text strong>
                          {t('integrationsSettingsPage.manageUserAccounts.columns.user')}
                        </Text>
                      </Col>
                      <SCol
                        span={showActive ? 6 : 8}
                        display="flex"
                        justifyContent="flex-start"
                        flex="auto"
                      >
                        <Text strong>
                          {t('integrationsSettingsPage.manageUserAccounts.columns.email')}
                        </Text>
                      </SCol>
                      {showActive && (
                        <Col span={6}>
                          <Text strong>
                            {t('integrationsSettingsPage.manageUserAccounts.columns.sync')}
                          </Text>
                        </Col>
                      )}
                      <SCol
                        span={showActive ? 6 : 8}
                        display="flex"
                        justifyContent="flex-start"
                        flex="auto"
                      >
                        <Text strong>
                          {t('integrationsSettingsPage.manageUserAccounts.columns.id')}
                        </Text>
                      </SCol>
                    </Row>
                  </SCol>
                </SListItem>

                <SList maxHeight="300px" overflow="auto" data-testid="user-settings-table">
                  {accounts.map(account => (
                    <CustomUserAccountForm
                      key={account.id}
                      ref={setRef(account.id)}
                      userAccount={account}
                      onChange={handleUserAccountUpdate}
                      onDelete={onDelete}
                      editable={editable}
                      showActive={showActive}
                    />
                  ))}
                  {editable && accounts.length <= 0 && <Empty />}
                  {editable && (
                    <SListItem padding="6px 0">
                      <SButton type="primary" size="small" onClick={onAddUserAccount}>
                        {t('integrationsSettingsPage.manageUserAccounts.add')}
                      </SButton>
                    </SListItem>
                  )}
                </SList>
              </Col>
            </Row>
          </Col>
        ) : null}
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <SButton type="link" size="big" onClick={onDownloadCSV} padding="0" textAlign="left">
                {t('integrationsSettingsPage.manageUserAccounts.downloadCSV')}
              </SButton>
            </Col>
            <Col>
              <Row align="middle" gutter={[20, 0]}>
                <Col>
                  <OutlinedButton width="160px" size="big" onClick={onEdit}>
                    {t('integrationsSettingsPage.manageUserAccounts.manual')}
                  </OutlinedButton>
                </Col>
                <Col>
                  <Upload
                    accept=".csv"
                    name="users"
                    customRequest={onUploadCSV}
                    showUploadList={false}
                  >
                    <SButton width="230px" type="primary" size="big">
                      {t('integrationsSettingsPage.manageUserAccounts.fromCSV')}
                    </SButton>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

export default ManageCustomUserAccounts;
