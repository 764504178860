export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'caricato con successo',
      loadFailed: 'Impossibile caricare il file',
      fileFormat: 'Puoi caricare file in formato mp3 o wav, fino a 30 mb/ file'
    },
    buttons: { loadCalls: 'Caricare le chiamate' },
    table: {
      columns: {
        operator: 'Operatore',
        direction: 'Tipo di chiamata',
        startedAt: 'Data di chiamata',
        startedAtPlaceholder: 'Scegli il tempo',
        clientPhoneNumber: 'Numero di telefono',
        duration: 'Durata della chiamata',
        actions: 'Azioni'
      },
      uinstalledCallDirection: 'Non installato',
      confirmChange: {
        title: 'Le modifiche non verranno salvate',
        ok: 'Continuare',
        cancel: 'Annulla'
      },
      buttons: {
        cancel: 'Annulla',
        edit: 'Modificare',
        delete: 'Elimina'
      },
      confirmDelete: {
        title: 'Sicuro?',
        ok: 'Elimina',
        cancel: 'Annulla'
      }
    }
  }
};
