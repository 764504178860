import React, { useEffect, useState } from 'react';
import { Col, Form, message, Modal, Progress, Row, Select } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { getUIClientInteractions } from 'redux/selectors/uiClientInterationsTable';
import { DeleteOutlined, InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { utils } from 'react-media-player';
import { isEqual } from 'lodash';
import UserPreview from 'components/UserPreview';
import STable from 'components/Standard/STable';
import ClientInteractionLink from 'components/ClientInteractionLink';
import {
  addCommunicationChainsEndpoint,
  checkMassCommunicationChainsEndpoint,
  createMassCommunicationChainsEndpoint,
  getCommunicationChains,
  updateCommunicationChainsEndpoint
} from 'core/api';
import Icon from 'components/Icon';
import { IconButton } from 'components/Buttons';
import { SText } from 'components/Standard';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { selectSearch } from 'core/utils/selectSearch';
import DateSpecificSelector from 'components/Inputs/DateSpecificSelector';
import SecondsSpecificSelector from 'components/Inputs/SecondsSpecificSelector';
import { getCurrentUser } from 'redux/selectors/users';
import { DeactivatedUserLinkToCommunication } from './DeactivatedLinkToCommunication';
import { SInput } from './styled';

const { Option } = Select;

const ModalWindowCheckbox = ({
  isOpen,
  setIsOpen,
  selectedModal,
  setSelectedModal,
  selection,
  hasConflicts,
  users,
  activeFilter,
  setActiveFilter,
  tableId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUser = useSelector(getCurrentUser, isEqual);
  const [allTableRows, setAllTableRows] = useState([]);
  const [selectedCommunications, setSelectedCommunications] = useState([]);
  const [loadingCommunicationChains, setLoadingCommunicationChains] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { selected } = selection;
  const { tableLoading, tableRows } = useSelector(getUIClientInteractions, shallowEqual);
  const tablesLoading = useSelector(state => state.communicationTablesResource.loading);
  const { loading: listLoading } = useSelector(state => state.clientInteractionsList, shallowEqual);
  const loading = listLoading || tableLoading || tablesLoading;
  const dataSource = hasConflicts && !loading ? [] : allTableRows;
  const activeTable = localStorage.getItem('DEALAPP_ACTIVE_TABLE_ID');
  const [communicationChains, setCommunicationChains] = useState([]);

  useEffect(() => {
    if (tableRows) {
      setAllTableRows(prevAllTableRows => {
        const mergedRows = [...prevAllTableRows, ...tableRows];

        const uniqueRows = mergedRows.reduce((acc, row) => {
          const isUnique = !acc.some(item => JSON.stringify(item) === JSON.stringify(row));
          return isUnique ? [...acc, row] : acc;
        }, []);

        return uniqueRows;
      });
    }
  }, [tableRows]);

  useEffect(() => {
    fetch(
      `${getCommunicationChains}${activeTable}?filters[communications_types]=communication_chain&pagination=false`,
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid')
        }
      }
    ).then(response =>
      response
        .json()
        .then(data => {
          const validateCommunicationChains = data.included.filter(
            item => item.attributes.client_interaction_type === 'communication_chain'
          );
          setCommunicationChains(validateCommunicationChains);
        })
        .catch(error => {
          console.log(error);
        })
    );
  }, []);

  useEffect(() => {
    const filteredCommunications = dataSource.filter(item => selected.includes(item.id));

    const uniqueCommunications = filteredCommunications.filter((item, index, self) =>
      index === self.findIndex((t) => (t.id === item.id))
    );

    setSelectedCommunications(uniqueCommunications);
  }, [selected, allTableRows]);

  const closeModal = () => {
    form.resetFields();
    setSelectedModal(null);
    setActiveFilter(null);
    setIsOpen(false);
    setSelectedItemId(null);
    setLoadingCommunicationChains(false);
  };

  const handleDelete = id => {
    const updatedChains = selectedCommunications.filter(chain => chain.id !== id);
    selection.toggle(id);
    setSelectedCommunications(updatedChains);
  };

  const handleSubmit = async formData => {
    const {
      duration,
      startedAt: startedAtFormData,
      operator,
      clientPhoneNumber,
      communicationChainId
    } = formData;

    const headers = {
      'Content-Type': 'application/json',
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid')
    };

    const durationValue = moment.utc(duration).format('HH:mm:ss.SSSZ');
    const startedAtValue = moment.utc(startedAtFormData).format('YYYY-MM-DD[T]');
    const startedAt = startedAtValue + durationValue;

    if (communicationChainId) {
      try {
        setLoadingCommunicationChains(true);
        const requests = selectedCommunications.map(chain => {
          return fetch(updateCommunicationChainsEndpoint, {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              communication_id: chain.id,
              communication_chain_id: communicationChainId
            })
          }).catch(err => {
            message.error(t('errorPages.internalServerError.title'));
            setLoadingCommunicationChains(false);
            console.log('error', err);
          });
        });
        Promise.all(requests)
          .then(() => {
            setLoadingCommunicationChains(false);
            message.success('Цепочка коммуникаций обновлена');
            closeModal();
          })
          .catch(err => {
            message.error(t('errorPages.internalServerError.title'));
            console.log('error', err);
          });
      } catch (err) {
        message.error(t('errorPages.internalServerError.title'));
        setLoadingCommunicationChains(false);
        console.log('error', err);
      }
    } else {
      try {
        setLoadingCommunicationChains(true);
        const data = {
          organization_id: organizationId,
          communication_type: 'communication_chain',
          operator_id: operator,
          started_at: startedAt,
          client_phone_number: clientPhoneNumber
        };

        const response = await fetch(addCommunicationChainsEndpoint, {
          method: 'POST',
          headers,
          body: JSON.stringify(data)
        })
          .then(response => response.json())
          .then(data => {
            return data;
          });
        const communicationChainId = response.data.id;
        const requests = selectedCommunications.map(chain => {
          return fetch(updateCommunicationChainsEndpoint, {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              communication_id: chain.id,
              communication_chain_id: communicationChainId
            })
          }).catch(err => {
            message.error(t('errorPages.internalServerError.title'));
            setLoadingCommunicationChains(false);
            console.log('error', err);
          });
        });
        Promise.all(requests)
          .then(() => {
            setLoadingCommunicationChains(false);
            message.success('Цепочка коммуникаций создана');
            closeModal();
          })
          .catch(err => {
            message.error(t('errorPages.internalServerError.title'));
            console.log('error', err);
          });
      } catch (err) {
        message.error(t('errorPages.internalServerError.title'));
        setLoadingCommunicationChains(false);
        console.log('error', err);
      }
    }
  };

  const handleSubmitActiveFilter = async formData => {
    const {
      duration,
      startedAt: startedAtFormData,
      clientPhoneNumber,
      communicationChainId
    } = formData;

    const durationValue = moment.utc(duration).format('HH:mm:ss.SSS');
    const startedAtValue = moment.utc(startedAtFormData).format('YYYY-MM-DD[T]');
    const startedAt = startedAtValue + durationValue;

    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'Content-Type': 'application/json'
    };

    let body;

    // Если выбранна ЦК
    if (communicationChainId) {
      body = {
        action: 'chain',
        organization_id: organizationId,
        user_email: currentUser.email,
        communication_table_id: tableId,
        chain_id: communicationChainId
      };
      // Создание новой ЦК
    } else {
      body = {
        action: 'chain',
        organization_id: organizationId,
        user_email: currentUser.email,
        communication_table_id: tableId,
        phone: clientPhoneNumber,
        started_at: startedAt
      };
    }

    try {
      setLoadingCommunicationChains(true);
      await fetch(createMassCommunicationChainsEndpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async data => {
          await closeModal();
          const { requestId } = data;
          let bulkActionInterval;
          let bulkActionCheckModal;
          let bulkActionPercent = 0;

          const exportModalTwo = () => {
            clearInterval(bulkActionInterval);
            const modalInstance = Modal.confirm({
              title: 'Создание цепочки коммуникаций',
              okText: 'Да',
              cancelText: 'Отменить',
              content: 'Вы уверенны что хотите прервать создание цепочки коммуникаций?',
              onOk: exportHandleOk,
              onCancel: () => exportHandleCancel(modalInstance)
            });

            return modalInstance;
          };

          const exportHandleOk = () => {
            clearInterval(bulkActionInterval);
            Modal.destroyAll();
          };
          const exportHandleCancel = async modalInstance => {
            modalInstance.destroy();
            if (bulkActionInterval) {
              clearInterval(bulkActionInterval);
            }
            bulkActionCheckModal = Modal.confirm({
              confirmLoading: true,
              title: 'Создание цепочки коммуникаций',
              autoFocusButton: null,
              okButtonProps: { style: { display: 'none' } },
              cancelText: 'Отменить',
              content: <Progress percent={bulkActionPercent} status="active" />,
              onCancel: () => exportModalTwo()
            });

            bulkActionInterval = setInterval(async () => {
              bulkActionPercent = await fetch(checkMassCommunicationChainsEndpoint, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                  request_id: requestId
                })
              })
                .then(response => response.json())
                .then(data => data.percent)
                .catch(() => {
                  clearInterval(bulkActionInterval);
                  Modal.destroyAll();
                });

              if (bulkActionPercent === 100) {
                clearInterval(bulkActionInterval);
                bulkActionCheckModal.update({
                  confirmLoading: false,
                  content: <Progress percent={bulkActionPercent} status="success" />
                });
                Modal.destroyAll();
                closeModal()
                if (communicationChainId) {
                  message.success('Цепочка коммуникаций успешно обновлена');
                } else {
                  message.success('Цепочка коммуникаций успешно создана');
                }
              } else {
                bulkActionCheckModal.update({
                  content: <Progress percent={bulkActionPercent} status="active" />
                });
              }
            }, 2000);
          };
          // modalInstance.destroy();
          bulkActionCheckModal = Modal.confirm({
            confirmLoading: true,
            title: 'Создание цепочки коммуникаций',
            autoFocusButton: null,
            okButtonProps: { style: { display: 'none' } },
            cancelText: 'Отменить',
            content: <Progress percent={bulkActionPercent} status="active" />,
            onCancel: () => exportModalTwo()
          });

          bulkActionInterval = setInterval(async () => {
            bulkActionPercent = await fetch(checkMassCommunicationChainsEndpoint, {
              method: 'POST',
              headers,
              body: JSON.stringify({
                request_id: requestId
              })
            })
              .then(response => response.json())
              .then(data => data.percent)
              .catch(() => {
                clearInterval(bulkActionInterval);
                Modal.destroyAll();
              });

            if (bulkActionPercent === 100) {
              clearInterval(bulkActionInterval);
              bulkActionCheckModal.update({
                confirmLoading: false,
                content: <Progress percent={bulkActionPercent} status="success" />
              });
              Modal.destroyAll();
              closeModal();
              if (communicationChainId) {
                message.success('Цепочка коммуникаций успешно обновлена');
              } else {
                message.success('Цепочка коммуникаций успешно создана');
              }
            } else {
              bulkActionCheckModal.update({
                content: <Progress percent={bulkActionPercent} status="active" />
              });
            }
          }, 2000);
        });
    } catch (err) {
      message.error(t('errorPages.internalServerError.title'));
      console.log('error', err);
      setLoadingCommunicationChains(false);
    }
  };

  const columns = [
    {
      title: (
        <>
          <UnorderedListOutlined />
          Тип
        </>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (type, { reviewId, operator, id, communicationType, clientInteractionType }) =>
        operator?.active ? (
          <ClientInteractionLink
            communication={{ communicationType, clientInteractionType }}
            isReview={!!reviewId}
            id={reviewId || id}
            chain // open url in new tab
          />
        ) : (
          <DeactivatedUserLinkToCommunication>
            <ClientInteractionLink
              communication={{ communicationType, clientInteractionType }}
              isReview={!!reviewId}
              id={reviewId || id}
              chain // open url in new tab
            />
          </DeactivatedUserLinkToCommunication>
        )
    },
    {
      title: 'ФИО',
      dataIndex: 'operator',
      key: 'operator',
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <UserPreview disabled userId={record.operatorId} showAvatar truncateSize={25} />
      )
    },
    {
      title: 'Направление',
      dataIndex: 'direction',
      key: 'direction',
      width: 120,
      ellipsis: true,
      render: (text, record) => <SText>{t(CALL_DIRECTION_LITERALS[record.direction])}</SText>
    },
    {
      title: 'Прод-ть',
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      ellipsis: true,
      render: (text, record) =>
        record.duration ? <Text strong>{utils.formatTime(record.duration)}</Text> : ''
    },
    {
      title: 'Номер телефона',
      dataIndex: 'clientPhoneNumber',
      key: 'clientPhoneNumber',
      width: 150,
      ellipsis: true,
      render: (text, record) => record.clientPhoneNumber
    },
    {
      title: 'Оценка',
      dataIndex: 'score',
      key: 'score',
      width: 100,
      ellipsis: true,
      render: (data, record) =>
        record.reviewStatus === 'in_progress'
          ? 'Результат проверки рассчитывается'
          : !Number.isNaN(record?.reviewChecklist?.score) &&
            (beatifyFloat(record?.reviewChecklist?.score) ?? data)
    },
    // {
    //   title: t('components.questionsList.listColumns.createdAt'),
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   width: 60,
    //   render: createdAt => <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
    // },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      render: id => (
        <DeleteOutlined
          style={{ cursor: 'pointer' }}
          onClick={() =>
            selectedCommunications.length <= 2
              ? message.info(
                  'Для создания цепочки коммуникации необходимо не менее двух коммуникаций',
                  4
                )
              : handleDelete(id)
          }
        />
      )
    }
  ];

  const initialValues = {
    duration: moment(),
    startedAt: moment()
  };

  const handleSelectChange = value => {
    setSelectedItemId(value);
  };

  const handleDisableHoursMinutes = (selectedHour, action) => {
    if (action === 'hours') {
      const currentHour = moment().hour();
      const disabledHours = [];
      for (let i = currentHour + 1; i < 24; i++) {
        disabledHours.push(i);
      }
      return disabledHours;
    }
    if (action === 'minutes') {
      if (selectedHour === moment().hour()) {
        const currentMinute = moment().minute();
        const disabledMinutes = [];
        for (let i = currentMinute + 1; i < 60; i++) {
          disabledMinutes.push(i);
        }
        return disabledMinutes;
      }
      return [];
    }
  };

  // @todo add translate
  return (
    <>
      {isOpen && selectedModal === 'communicationChains' && (
        <Modal
          width="960px"
          visible={isOpen}
          maskClosable
          destroyOnClose
          onCancel={closeModal}
          onOk={form.submit}
          confirmLoading={loadingCommunicationChains}
          title="Цепочки коммуникаций"
          cancelText="Отменить"
          okText={!selectedItemId ? 'Создать' : 'Добавить'}
        >
          <Form
            onFinish={activeFilter ? handleSubmitActiveFilter : handleSubmit}
            initialValues={initialValues}
            layout="vertical"
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="communicationChainId"
                  label={
                    <>
                      Цепочки коммуникаций
                      <IconButton
                        tooltip={{
                          title:
                            'Данное поле не обязательное. При необходимости можно выбрать нужную ЦК в которую будут добавлены выбранные коммуникации',
                          overlayStyle: { width: '393px' }
                        }}
                        button={{
                          icon: <Icon icon={InfoCircleOutlined} />,
                          size: 'icon'
                        }}
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    placeholder="Выберите ЦК"
                    loading={loading}
                    // style={{ width: 500 }}
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      selectSearch({ input, option, searchProp: 'children' })
                    }
                    onChange={handleSelectChange}
                  >
                    {communicationChains.map(item => {
                      const user = Object.values(users).find(
                        user => user.id === item.relationships.operator.data.id
                      );
                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          label={
                            item.attributes.client_phone_number
                              ? `${item?.attributes.client_phone_number} - ${user?.email || 'N/A'}`
                              : `${user?.email || 'N/A'}`
                          }
                        >
                          {item.attributes.client_phone_number
                            ? `${item?.attributes.client_phone_number} - ${user?.email || 'N/A'}`
                            : `${user?.email || 'N/A'}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {!selectedItemId && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    // label="Номер цепочки"
                    name="clientPhoneNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста введите номер цепочки'
                      }
                    ]}
                  >
                    <SInput placeholder="Номер цепочки" />
                  </Form.Item>
                  <Form.Item
                    // label="Оператор"
                    name="operator"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста выберите сотрудника'
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder="Сотрудник"
                      loading={loading}
                      // style={{ width: 200 }}
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'children' })
                      }
                    >
                      {Object.values(users).map(item => (
                        <Option key={item.email} value={item.email} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: '13px' }}>
                  <Form.Item
                    name="startedAt"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'customCommunicationPage.form.messages.requiredCommunicationDate'
                        )
                      }
                    ]}
                  >
                    <DateSpecificSelector
                      allowClear
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      showToday
                      placeholder={t('customCommunicationPage.form.fields.communicationDate')}
                      disabledDate={current => current && current > moment().endOf('day')}
                      // disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста выберите продолжительность'
                      }
                    ]}
                  >
                    <SecondsSpecificSelector
                      allowClear
                      format="HH:mm:ss"
                      style={{ width: '100%' }}
                      defaultOpenValue={moment().startOf('day')}
                      placeholder={t('customCommunicationPage.form.fields.duration')}
                      changeOnSelect
                      disabledHours={() => handleDisableHoursMinutes(null, 'hours')}
                      disabledMinutes={selectedHour =>
                        handleDisableHoursMinutes(selectedHour, 'minutes')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {/* Если это действие не для активного фильтра то отображаем Выбранные коммуникации */}
            {!activeFilter && (
              <Form.Item label="Выбранные коммуникации">
                <STable
                  size="small"
                  rowKey="id"
                  columns={columns}
                  dataSource={selectedCommunications}
                  tableLayout="fixed"
                  pagination={false}
                  loading={loading}
                  border
                  scroll={{ y: 462 }}
                  // scroll={{ y: 'calc(100vh - 266px)', x: 'max-content' }}
                  // onChange={handleTableChange}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ModalWindowCheckbox;
