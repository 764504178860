export default {
  loginPage: {
    form: {
      title: 'Entrar',
      unRegistered: '¿No registrado?',
      fields: {
        emailPlaceholder: 'Introduzca el correo electrónico',
        passwordPlaceholder: 'Introduzca la contraseña'
      },
      messages: {
        enterEmail: 'Introduzca el correo electrónico',
        enterPassword: 'Introduzca la contraseña',
        wrongEmail: 'Introduzca el correo electrónico correcto',
        minLengthPassword: 'Mínimo 6 personas'
      },
      buttons: {
        login: 'Entrar',
        forgotPassword: '¿Olvidó la contraseña?',
        registration: 'Registrarse a través de CRM',
        goToSite: 'Pasar a la página web',
        registrationlink: 'Regístrate ahora'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Eficiencia',
          description:
            'Todos los instrumentos para el control de calidad y la evaluación de la comunicación están reunidos en una plataforma'
        },
        motivation: {
          title: 'Motivación',
          description: 'Involucre, desarrolle y motive a sus empleados'
        },
        simplicity: {
          title: 'Sencillez',
          description:
            'Elimine las hojas de cálculo electrónicas y los instrumentos de evaluación y control absoletos'
        },
        analytics: {
          title: 'Analítica',
          description:
            'Analize los indicadores de calidad y encuentre nuevas posibiidades de crecimiento'
        }
      }
    }
  }
};
