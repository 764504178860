import ChecklistsList from 'components/Checklist/ChecklistsList';
import QuestionsPageList from 'components/Questions/QuestionsPageList';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SCard from 'components/Standard/SCard';
import ChecklistsAndQuestionsFilters from './ChecklistsAndQuestionsFilters';

const ChecklistsAndQuestionsPage = ({ currentList }) => {
  const { t } = useTranslation();

  const listToComponents = {
    checklists: ChecklistsList,
    questions: QuestionsPageList
  };

  const Component = listToComponents[currentList];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.checklistsAndQuestionsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <ChecklistsAndQuestionsFilters />
      </SCol>
      <SCol span={24} padding="16px">
        <SCard>
          <Component />
        </SCard>
      </SCol>
    </SRow>
  );
};

const mapStateToProps = state => ({
  currentList: state.uiChecklistsAndQuestionsPage.currentList
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistsAndQuestionsPage));
