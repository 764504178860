import { Col, Form, message, Row, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import { INTEGRATIONS } from 'core/utils/constants';
import { isEmpty, reduce, get, head, keys, has, every } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reconnectToAmo } from 'redux/entities/amoIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import SRow from 'components/Standard/SRow';
import { isUrl } from 'core/utils/isUrl';
import SInput from 'components/Standard/SInput';
import { getIntegrationRequiredFields, getPlaceholder } from 'pages/UserPage/Integrations/utils';
import MysqlFields from './MysqlFields';

const NameAndType = ({
  onSubmit,
  onUpdate,
  canEdit = false,
  onDelete,
  integration,
  onChangeIntegrationType
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: userId } = useParams();
  const { t } = useTranslation();
  const mounted = useRef(false);
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [loadingAvailableIntegrations, setLoadingAvailableIntegrations] = useState(false);
  const [availableIntegrations, setAvailableIntegrations] = useState(
    Object.values(INTEGRATIONS).map(integration => integration.type)
  );
  const loadAvailableIntegrations = async () => {
    setLoadingAvailableIntegrations(true);

    const integrations = await dispatch(integrationsResource.operations.getAvailableIntegrations());
    if (integrations) {
      setAvailableIntegrations(integrations);
    }
  };

  const {
    errors: integrationsResourceErrors,
    updateByIdStarted,
    loading,
    createStarted
  } = useSelector(state => state.integrationsResource);

  const errors = get(head(integrationsResourceErrors), 'fields');

  useEffect(() => {
    const fields = reduce(
      keys(form.getFieldsValue()).filter(fieldName => has(errors, fieldName)),
      (acc, name) => {
        return [
          ...acc,
          {
            name,
            errors: [undefined]
          }
        ];
      },
      []
    );
    form.setFields(fields);
  }, [errors]);

  useEffect(() => {
    mounted.current = true;
    loadAvailableIntegrations().then(
      () => mounted.current && setLoadingAvailableIntegrations(false)
    );
    return () => {
      dispatch(integrationsResource.actions.clearErrors());
      mounted.current = false;
    };
  }, []);

  const handleSubmit = async values => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.log('handleSubmit error', { e });
    }
  };

  const onEdit = async () => {
    if (!editing) return mounted.current && setEditing(true);

    try {
      await onUpdate(form.getFieldsValue())
        .then(_ => {
          mounted.current && setEditing(false);
          message.success(t('integrationsSettingsPage.messages.integrationSuccessfullyUpdated'));
        })
        .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const requestAmoConnection = () => {
    dispatch(reconnectToAmo());
  };

  const clearFieldFormErrors = fields => {
    const requiredFields = getIntegrationRequiredFields(form.getFieldValue('integrationType'));
    fields.forEach(({ name }) => {
      name.forEach(item => {
        if (requiredFields.includes(item)) return;
        if (form.getFieldError(item)?.length > 0)
          form.setFields([
            {
              name: item,
              errors: []
            }
          ]);
      });
    });
  };

  const buttonLoading = loading || updateByIdStarted || createStarted;

  const saveButtonText = buttonLoading ? t('general.saving') : t('general.save');
  const editOrSaveButtonText = editing ? saveButtonText : t('general.edit');
  const disabledInput = canEdit ? !editing : false;

  return (
    <SCard>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={integration}
        onFieldsChange={clearFieldFormErrors}
      >
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={[16, 0]}>
              <Col>
                <Form.Item
                  name="integrationType"
                  label={t('integrationsSettingsPage.nameAndType.type')}
                >
                  <Select
                    onChange={integrationType => {
                      form.setFieldsValue({
                        endpoint: get(
                          INTEGRATIONS,
                          `${integrationType}.endpoint`,
                          form.getFieldValue(integrationType)
                        )
                      });
                      onChangeIntegrationType && onChangeIntegrationType(integrationType);
                    }}
                    size="large"
                    placeholder={t('integrationsSettingsPage.nameAndType.integrationType')}
                    style={{ width: '304px' }}
                    disabled={canEdit}
                    loading={loadingAvailableIntegrations}
                  >
                    {availableIntegrations.map(integrationType => (
                      <Select.Option key={integrationType} value={integrationType}>
                        {t(INTEGRATIONS[integrationType].name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="name" label={t('integrationsSettingsPage.nameAndType.name')}>
                  <SInput
                    disabled={disabledInput}
                    size="large"
                    placeholder={t('integrationsSettingsPage.nameAndType.integrationName')}
                    style={{ width: '304px' }}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('integrationType') === INTEGRATIONS.amocrm.type &&
                  canEdit &&
                  !isEmpty(integration) ? (
                    <Col>
                      <SButton size="big" type="secondary" onClick={requestAmoConnection}>
                        {t('integrationsSettingsPage.integrationPage.updateConnection')}
                      </SButton>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('integrationType') === INTEGRATIONS.bitrix_crm.type &&
                  isEmpty(integration) ? (
                    <Col>
                      <Form.Item
                        name="bitrixDomain"
                        label={t('integrationsSettingsPage.nameAndType.yourB24address')}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) =>
                              value.includes('bitrix24') &&
                              value.match(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/)
                                ? Promise.resolve()
                                : Promise.reject(
                                    t('registerPage.form.buttons.bitrixPopover.wrongUrl')
                                  )
                          }
                        ]}
                      >
                        <SInput
                          placeholder="qolio.bitrix24.ru"
                          disabled={canEdit}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) => {
                  const disableEndpoint =
                    disabledInput ||
                    [form.getFieldValue('integrationType'), integration?.integrationType].includes(
                      INTEGRATIONS.usedesk.type
                    );

                  return [
                    INTEGRATIONS.usedesk.type,
                    INTEGRATIONS.zendesk.type,
                    INTEGRATIONS.infinity.type,
                    INTEGRATIONS.help_desk_eddy.type,
                    INTEGRATIONS.bright_pattern.type
                  ].includes(getFieldValue('integrationType')) ? (
                    <Col>
                      <Form.Item
                        dependencies={['integrationType']}
                        name="endpoint"
                        label="Endpoint"
                        rules={[
                          { required: true, type: 'url', warningOnly: true, validator: isUrl }
                        ]}
                      >
                        <SInput
                          placeholder={getPlaceholder('Endpoint')}
                          disabled={disableEndpoint}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null;
                }}
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [INTEGRATIONS.zendesk.type].includes(getFieldValue('integrationType')) ? (
                    <Col>
                      <Form.Item
                        name="accessToken"
                        label="Access Token"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <SInput
                          placeholder={getPlaceholder('Access Token')}
                          disabled={disabledInput}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [
                    INTEGRATIONS.sipuni.type,
                    INTEGRATIONS.binotel.type,
                    INTEGRATIONS.zendesk.type,
                    INTEGRATIONS.bright_pattern.type
                  ].includes(getFieldValue('integrationType')) ? (
                    <Col>
                      <Form.Item
                        name="clientId"
                        label="Client ID"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <SInput
                          placeholder={getPlaceholder('Client ID')}
                          disabled={disabledInput}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [
                    INTEGRATIONS.sipuni.type,
                    INTEGRATIONS.usedesk.type,
                    INTEGRATIONS.binotel.type,
                    INTEGRATIONS.help_desk_eddy.type,
                    INTEGRATIONS.bright_pattern.type
                  ].includes(getFieldValue('integrationType')) ? (
                    <Col>
                      <Form.Item
                        name="clientSecret"
                        label="Client Secret"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <SInput
                          placeholder={getPlaceholder('Client Secret')}
                          disabled={disabledInput}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              {/* Mysql Fields */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [INTEGRATIONS.bright_pattern.type].includes(getFieldValue('integrationType')) ? (
                    <MysqlFields canEdit={canEdit} editing={editing} />
                  ) : null
                }
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const formHasErrors = reduce(
              form.getFieldsError(),
              (acc, { errors }) => acc || errors.length > 0,
              false
            );

            const hasEmptyRequiredFieldValue = !every(
              getIntegrationRequiredFields(getFieldValue('integrationType')).map(field =>
                form.getFieldValue(field)
              ),
              item => !isEmpty(item)
            );

            const disableSaveEditButtonWhenCanEdit = formHasErrors || hasEmptyRequiredFieldValue;
            const disableSaveEditButtonWhenCantEdit =
              isEmpty(getFieldValue('integrationType')) || disableSaveEditButtonWhenCanEdit;
            const disableDeleteButton = isEmpty(getFieldValue('integrationType')) || buttonLoading;

            return (
              <SRow type="flex" justify="space-between" alignItems="center">
                <Col>
                  <Row type="flex" align="middle" gutter={[8, 0]}>
                    {((editing && canEdit) || isEmpty(integration)) && (
                      <Col>
                        <SButton
                          onClick={() => {
                            dispatch(integrationsResource.actions.clearErrors());
                            form.resetFields(Object.keys(form.getFieldsValue()));

                            if (isEmpty(integration?.integrationType)) {
                              history.push(`/user/${userId}/integrations-settings`);
                            } else {
                              setEditing(false);
                            }
                          }}
                          disabled={buttonLoading}
                        >
                          {t('general.cancel')}
                        </SButton>
                      </Col>
                    )}
                    <Col>
                      {canEdit ? (
                        <SButton
                          type="primary"
                          disabled={disableSaveEditButtonWhenCanEdit}
                          onClick={onEdit}
                          loading={buttonLoading}
                        >
                          {editOrSaveButtonText}
                        </SButton>
                      ) : (
                        <SButton
                          disabled={disableSaveEditButtonWhenCantEdit}
                          type="primary"
                          htmlType="submit"
                          loading={buttonLoading}
                        >
                          {saveButtonText}
                        </SButton>
                      )}
                    </Col>
                  </Row>
                </Col>
                {canEdit && (
                  <Col>
                    <SButton
                      disabled={disableDeleteButton}
                      type="link"
                      icon={<Icon icon={Trash2} />}
                      onClick={onDelete}
                    >
                      {t('integrationsSettingsPage.delete')}
                    </SButton>
                  </Col>
                )}
              </SRow>
            );
          }}
        </Form.Item>
      </Form>
    </SCard>
  );
};

export default NameAndType;
