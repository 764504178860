import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const checklistQuestionGroupSubgroupsResource = createReduxResource({
  name: 'checklistQuestionGroupSubgroups',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const checklistDefinition =
        state.checklistDefinitionsResource.byIds[resource.checklistDefinitionId];

      if (checklistDefinition) {
        updateResource({
          dispatch,
          type: 'checklistDefinitions',
          resource: {
            ...checklistDefinition,
            checklistFormulasIds: [...checklistDefinition.checklistFormulasIds, resource.id]
          }
        });
      }
    }
  }
});