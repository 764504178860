import { Button, Carousel, Col, Form, Input, Popover, Row, Typography } from 'antd';
import amo from 'assets/images/amocrm-logo.png';
import bx24 from 'assets/images/bitrix24-logo.svg';
import carouselOne from 'assets/images/carousel/img1s.png';
import carouselTwo from 'assets/images/carousel/img2s.png';
import carouselThree from 'assets/images/carousel/img3s.png';
import carouselFour from 'assets/images/carousel/img4s.png';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { ymRegToken } from 'core/config';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import { redirectToBitrix } from 'redux/entities/bitrixIntegration/operations';
import styled, { css } from 'styled-components';
import { AuthLogo, LinkToLandingPage } from '../styled';

const { Text, Title } = Typography;

const AmoSignUpButton = styled(Button)`
  &&,
  &&:focus,
  &&:hover {
    background: #0081c0;
    color: white;
    background-image: url(${amo});
    background-size: auto 100%;
    background-position: 8px center;
    background-repeat: no-repeat;
  }
`;

const BitrixSignUpButton = styled(Button)`
  &&,
  &&:focus,
  &&:hover {
    background: #2fc7f7;
    color: white;
    background-image: url(${bx24});
    background-size: auto 40%;
    background-position: 8px center;
    background-repeat: no-repeat;
  }
`;

const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

const SlideImage = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: 50vh;
`;

const sharedTextStyle = css`
  width: 100%;
  text-align: center;
  display: block;
`;

const SlideText = styled(Text)`
  ${sharedTextStyle}
`;

const SlideTitle = styled(Title)`
  ${sharedTextStyle}
`;

const StyledCarousel = styled(Carousel)`
  && {
    .slick-dots.slick-dots-bottom {
      bottom: -8px;

      li {
        opacity: 0.5;
        button {
          background: 'var(--blue_6)';
        }

        &.slick-active {
          opacity: 1;
        }
      }
    }
  }
`;

const LoginPage = ({ loading }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isBitrixDomainValid, setBitrixDomainValid] = useState(false);

  const onChange = (_, allFields) => {
    const { errors, value: bitrixDomain } = allFields[0];
    const isBitrixDomainValid = isEmpty(errors) && !isEmpty(bitrixDomain);
    setBitrixDomainValid(isBitrixDomainValid);
  };

  const onProceed = () => {
    redirectToBitrix(form.getFieldValue('bitrix-domain'));
  };

  return (
    <>
      {ymRegToken && (
        <YMInitializer accounts={[parseInt(ymRegToken)]} options={{ webvisor: true }} />
      )}
      <Helmet>
        <title>{t('pagesMeta.registerPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Col span={12}>
        <Row
          type="flex"
          gutter={[32, 32]}
          align="middle"
          justify="center"
          style={{ height: '100%' }}
        >
          <Col span={20}>
            <StyledCarousel autoplay dotsClass="blue-dots">
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.efficiency.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselOne} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.efficiency.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.motivation.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselTwo} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.motivation.description')}
                  </SlideText>
                </Col>
              </Row>
              {/*  */}
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.simplicity.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselThree} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.simplicity.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('registerPage.layout.slides.analytics.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselFour} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('registerPage.layout.slides.analytics.description')}
                  </SlideText>
                </Col>
              </Row>
            </StyledCarousel>
          </Col>
        </Row>
      </Col>

      <FormContainer span={12}>
        <SCard shadowed>
          <Row justify="space-between" type="flex" align="middle">
            <Col>
              <Title level={3}>{t('registerPage.form.title')}</Title>
            </Col>
            <Col>
              <Link to="/login">{t('registerPage.form.buttons.login')}</Link>
            </Col>
          </Row>

          <Form hideRequiredMark name="login" onFieldsChange={onChange} form={form}>
            <Row className="ant-form-item">
              <Col>
                <Text strong>{t('registerPage.form.description')}</Text>
              </Col>
            </Row>

            <Form.Item style={{ marginBottom: '8px' }}>
              <Popover
                trigger="click"
                title={t('registerPage.form.buttons.bitrixPopover.title')}
                content={
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Form.Item
                        name="bitrix-domain"
                        rules={[
                          {
                            validator: (_, value) =>
                              value.includes('bitrix24') &&
                              value.match(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/)
                                ? Promise.resolve()
                                : Promise.reject(
                                    t('registerPage.form.buttons.bitrixPopover.wrongUrl')
                                  )
                          }
                        ]}
                      >
                        <Input placeholder="qolio.bitrix24.ru" />
                      </Form.Item>
                    </Col>
                    <SCol display="flex" justifyContent="center">
                      <Button
                        type="primary"
                        disabled={!isBitrixDomainValid}
                        loading={loading}
                        onClick={onProceed}
                      >
                        {t('registerPage.form.buttons.bitrixPopover.continue')}
                      </Button>
                    </SCol>
                  </Row>
                }
              >
                <BitrixSignUpButton block>
                  {t('registerPage.form.buttons.bitrix')}
                </BitrixSignUpButton>
              </Popover>
            </Form.Item>
            <Form.Item style={{ marginBottom: '8px' }}>
              <Link to="/amo/register">
                <AmoSignUpButton block>{t('registerPage.form.buttons.amo')}</AmoSignUpButton>
              </Link>
            </Form.Item>
          </Form>
        </SCard>
      </FormContainer>
      <LinkToLandingPage />
    </>
  );
};

export default withRouter(LoginPage);
