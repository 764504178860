import { Tooltip } from 'antd';
import React from 'react';
import SText from '../../components/Standard/SText';

export const getChainNumber = (value, whiteTextColor = null) => {
  if (!value) {
    return '';
  }

  const string = value.trim();
  const currentLength = string.length; // Длина переданной строки
  const maxLength = 15; // Ограничение длины строки в 15 символов
  const substring = `${string.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
  const stringToRender = currentLength > maxLength ? substring : string; // Строка которая пойдёт на рендер

  return (
    <>
      <Tooltip title={string} placement="right">
        <SText ellipsis style={whiteTextColor ? { color: '#fff' } : ''}>
          {stringToRender}
        </SText>
      </Tooltip>
    </>
  );
};
