import { isEmpty } from 'lodash';
import { INTEGRATIONS, LINK_MANUAL_ALL_INTEGRATIONS } from 'core/utils/constants';
import i18next from 'i18next';

export const getManualLinkInfo = integrationType => {
  return {
    manualTextLink: isEmpty(INTEGRATIONS?.[integrationType]?.linkToManual)
      ? i18next.t('integrationsSettingsPage.integrationPage.manualArticles')
      : `${i18next.t('integrationsSettingsPage.integrationPage.manualLinkText')}: ${i18next.t(
          INTEGRATIONS?.[integrationType]?.name
        )}`,
    manualLink: INTEGRATIONS?.[integrationType]?.linkToManual || LINK_MANUAL_ALL_INTEGRATIONS
  };
};

export const getPlaceholder = fieldName =>
  `${i18next.t('integrationsSettingsPage.nameAndType.enterPlaceholderTemplate')} ${fieldName}`;


export const getIntegrationRequiredFields = integrationType =>
  INTEGRATIONS?.[integrationType]?.requiredFields || []
