import { Col, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import { get, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { integrationsResource } from 'redux/resources/integrations';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import { BookOpen } from 'react-feather';
import SText from 'components/Standard/SText';
import { getManualLinkInfo } from 'pages/UserPage/Integrations/utils';
import CustomFieldsManager from '../IntegrationSettingsModules/CustomFieldsManager';
import ManageCustomUserAccounts from '../IntegrationSettingsModules/ManageCustomUserAccounts';
import NameAndType from '../IntegrationSettingsModules/NameAndType';
import TestRequest from '../IntegrationSettingsModules/TestRequest';
import UrlAndToken from '../IntegrationSettingsModules/UrlAndToken';
import { INTEGRATIONS } from '../../../../core/utils/constants';

const { Title } = Typography;

const CustomIntegrationSettingsPage = () => {
  const { integrationId, id: userId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [integrationType, setIntegrationType] = useState();

  const loadIntegration = async () => {
    setLoading(true);
    await dispatch(integrationsResource.operations.loadById({ id: integrationId }));
    setLoading(false);
  };

  const updateIntegration = useCallback(
    async ({ name, ...otherFields }) => {
      return dispatch(
        integrationsResource.operations.updateById({ id: integrationId, name, ...otherFields })
      );
    },
    [dispatch, integrationId]
  );

  useEffect(() => {
    loadIntegration();
  }, []);

  const integration = useSelector(
    state => get(state.integrationsResource.byIds, integrationId),
    isEqual
  );

  const onDelete = useCallback(() => {
    Modal.confirm({
      maskClosable: true,
      title: t('integrationsSettingsPage.deleteConfirm.title'),
      okText: t('integrationsSettingsPage.deleteConfirm.ok'),
      okType: 'danger',
      onOk: async () => {
        setLoading(true);
        await dispatch(integrationsResource.operations.deleteById({ id: integrationId }));
        history.push(`/user/${userId}/integrations-settings`);
        setLoading(false);
      }
    });
  }, [dispatch, integrationId]);

  const { manualTextLink, manualLink } = getManualLinkInfo(
    integration?.integrationType || integrationType
  );

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[0, 32]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <SRow type="flex" wrap={false} justify="space-between">
                  <SCol flex="auto">
                    <Title level={4}>
                      {`${t('integrationsSettingsPage.integrationPage.title')}: ${
                        integration?.name
                          ? integration.name
                          : t(INTEGRATIONS[integration?.integrationType]?.name)
                      }`}
                    </Title>
                  </SCol>
                  <SCol flex="none">
                    <Tooltip title={manualTextLink}>
                      <a target="_blank" rel="noreferrer" href={manualLink}>
                        <SRow>
                          <SCol display="flex" alignItems="center">
                            <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
                            <SText ellipsis color="var(--link-color)">
                              {manualTextLink}
                            </SText>
                          </SCol>
                        </SRow>
                      </a>
                    </Tooltip>
                  </SCol>
                </SRow>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <NameAndType
                    canEdit
                    onUpdate={updateIntegration}
                    onDelete={onDelete}
                    integration={integration}
                    onChangeIntegrationType={type => setIntegrationType(type)}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.integrationPage.users')}</Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <ManageCustomUserAccounts showActive integration={integration} />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.integrationPage.integrationSettings')}
                </Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <UrlAndToken integration={integration} />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.integrationPage.customFieldsSettings.title')}
                </Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <CustomFieldsManager />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.integrationPage.testRequest.title')}
                </Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <TestRequest integration={integration} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomIntegrationSettingsPage;
