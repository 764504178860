import { Col, Input, message, Modal, Row } from 'antd';
import { TruncatedButton } from 'components/Truncated';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { get, isEmpty, throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { search } from 'redux/entities/search/operations';
import { actions } from 'redux/lists/clientInteractionsList';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { customFieldsResource } from 'redux/resources/customFields';
import { salePipelinesResource } from 'redux/resources/salesPipelines';
import { statusesResource } from 'redux/resources/status';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { usersResource } from 'redux/resources/users';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { updateTable } from 'redux/ui/clientInteractions/operations';
import {
  saveTableFilters,
  setDefaultState,
  setSearchState
} from 'redux/ui/clientInteractions/reducer';
import styled from 'styled-components';
import { isNullUndefEmptyStr } from 'core/utils/isNullUndefEmptyStrObjectProps';
import SRow from 'components/Standard/SRow';
import ClientInteractionsMeta from '../ClientInteractionsMeta';
import AddCustomCommunicationButton from './AddCustomCommunicationButton';
import CustomFieldsFilters from './CustomFieldsFilters';
import { FilterContainer } from './styled';
import TableConfigurations from './TableConfigurations/TableConfigurations';
import TableGeneralFilters from './TableGeneralFilters';
import TableReviewsFilters from './TableReviewsFilters';
import WorkPlanTaskFilter from './WorkPlanTaskFilter';
import { communicationChainsV1Resource } from 'redux/resources/communicationChainsV1';
// import { loadChecklistDefinitionsWithIncludes } from '../../../redux/ui/checklists/operations';

const PADDING_RIGHT = 8;

const TableFilters = ({
  filters,
  customFieldFilters = {},
  searchLoading,
  setFilters,
  disabled,
  // loadChecklistDefinitionsWithIncludes,
  loadChecklistDefinitions,
  loadUsers,
  loadTaskDefinitions,
  saveTableFilters,
  tableId,
  tableSharedUsers,
  updateTable,
  updatePage,
  updateFilters,
  setSearchState,
  loadCustomFields,
  setCustomFieldFilters,
  loadStatuses,
  setDefaultState,
  loadSalePipelines,
  meta,
  searchState,
  loadWorkPlanTasks,
  selectedWorkPlanTaskConfigurationId,
  hasConflicts,
  checklistDefinitions,
  selection,
  updateFlagClientInteractions,
  setUpdateFlagClientInteractions,
  loadCommunicationChainsV1
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    if (hasConflicts) {
      setDefaultState();
    }
  }, []);

  const resetFilters = () => {
    setFilters({ clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS });
    setCustomFieldFilters({});
  };

  useEffect(() => {
    const resizeOb = new ResizeObserver(entries => {
      // since we are observing only a single element, so we access the first element in entries array
      const el = entries[0].target;
      // current width & height
      const { offsetWidth, clientWidth } = el;
      const newScrollbarWidth = (offsetWidth || 0) - (clientWidth || 0);
      setScrollbarWidth(newScrollbarWidth);
    });

    resizeOb.observe(ref.current);

    return () => {
      ref.current && resizeOb.unobserve(ref.current);
    };
  }, []);

  useEffect(() => {
    // loadChecklistDefinitionsWithIncludes(checklistDefinitions);
    // loadChecklistDefinitions({ pagination: 'false' });
    loadChecklistDefinitions({
      include: [
        'question_groups.question_to_group_bindings.question',
        'question_groups.question_group_subgroups.question_to_group_subgroup_bindings.question'
      ].join(','),
      pagination: 'false'
    });
    loadUsers({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name',
      'filters[with_inactive]': 'true'
    });
    loadStatuses({ pagination: 'false' });
    loadTaskDefinitions({ pagination: 'false' });
    loadCustomFields({ pagination: 'false' });
    loadSalePipelines({ pagination: 'false', include: 'statuses' });
    loadWorkPlanTasks({ pagination: 'false', include: 'last-task.assignments' });
    loadCommunicationChainsV1();

    return setDefaultState;
  }, []);

  useEffect(() => {
    const { length, showResult } = searchState;
    // TODO: translate
    if (showResult) {
      const bySearchText = `Найдено по поиску: ${
        length === 20 ? t('clientInteractionsPage.tableFilters.search.result.bigResult') : length
      }. По заданным фильтрам: ${meta.totalCount}`;

      message.info({
        content: `${bySearchText}`,
        key: 'searchMessage',
        duration: 4
      });

      setSearchState({ ...searchState, showResult: false });
    }
  }, [meta.totalCount]);

  const ConditionalAddCustomCommunicationButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    AddCustomCommunicationButton
  );

  const onUpdateTable = async () => {
    selection.unSelectAll();
    const updateFilters = isEmpty(filters?.taskAssignmentsIds)
      ? filters
      : { taskAssignmentsIds: filters?.taskAssignmentsIds };

    const clearedEmptyCustomFieldFiltersProps = data => {
      let newData = {};
      Object.keys(data).forEach(f => {
        if (!isNullUndefEmptyStr(data[f])) newData = { ...newData, [f]: data[f] };
      });
      return newData;
    };

    const clearedCustomFieldFilters = clearedEmptyCustomFieldFiltersProps(customFieldFilters);

    saveTableFilters({ filters: updateFilters, customFieldFilters: clearedCustomFieldFilters });
    const onOk = async () => {
      try {
        await updateTable({
          id: tableId,
          filters: updateFilters,
          pagination: false,
          customFieldFilters: clearedCustomFieldFilters
        });
        await updatePage({
          size: '25',
          number: '1'
        });
        if (isEmpty(filters?.taskAssignmentsIds)) {
          message.success(
            t('clientInteractionsPage.tableFilters.messages.tableSuccessfullyUpdated')
          );
        } else {
          message.success(t('messages.success.filtersApplied'));
        }
      } catch (error) {
        console.log(error);
        message.error(t('clientInteractionsPage.tableFilters.messages.updateTableFailed'));
      }
    };

    return isEmpty(tableSharedUsers)
      ? onOk()
      : Modal.confirm({
          maskClosable: true,
          title: t('clientInteractionsPage.tableFilters.shareConfirm.title'),
          content: t('clientInteractionsPage.tableFilters.shareConfirm.description'),
          okText: t('clientInteractionsPage.tableFilters.shareConfirm.ok'),
          cancelText: t('clientInteractionsPage.tableFilters.shareConfirm.cancel'),
          onOk
        });
  };

  const onSearch = throttle(
    async e => {
      e.persist();
      const value = get(e, 'target.value', '');
      updateFilters({ clientPhoneNumber: value });
    },
    500,
    { trailing: false }
  );

  return (
    <>
      <Row>
        <ClientInteractionsMeta hasConflicts={hasConflicts} />
      </Row>
      <SRow paddingRight={`${PADDING_RIGHT + scrollbarWidth}px`}>
        <FilterContainer style={{ marginTop: 0 }}>
          <Input
            placeholder={t(
              'clientInteractionsPage.tableFilters.search.clientPhoneNumberPlaceholder'
            )}
            disabled={hasConflicts}
            value={filters.clientPhoneNumber}
            loading={searchLoading}
            onChange={onSearch}
          />
        </FilterContainer>
      </SRow>
      <SRow
        gutter={[8, 8]}
        style={{ margin: '-4px' }}
        paddingRight={`${PADDING_RIGHT + scrollbarWidth}px`}
      >
        <Col span={24}>
          <TruncatedButton
            type="primary"
            block
            onClick={onUpdateTable}
            disabled={disabled || hasConflicts}
          >
            {t('clientInteractionsPage.tableFilters.buttons.applyFilters')}
          </TruncatedButton>
        </Col>

        <Col span={24}>
          <TruncatedButton
            type="ghost"
            block
            style={{ background: 'white' }}
            onClick={resetFilters}
            disabled={disabled || selectedWorkPlanTaskConfigurationId}
          >
            {t('clientInteractionsPage.tableFilters.buttons.resetFilters')}
          </TruncatedButton>
        </Col>

        <Col span={24}>
          <ConditionalAddCustomCommunicationButton />
        </Col>
      </SRow>
      <FiltersSection style={{ paddingRight: PADDING_RIGHT + scrollbarWidth }}>
        <TableConfigurations
          hasConflicts={hasConflicts}
          selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
        />
        <WorkPlanTaskFilter
          selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
        />
      </FiltersSection>
      <FiltersContainer ref={ref}>
        <FiltersSection style={{ marginTop: 0 }}>
          <TableGeneralFilters
            hasConflicts={hasConflicts}
            selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
          />
        </FiltersSection>

        {/* Filters Section inside */}
        <CustomFieldsFilters
          hasConflicts={hasConflicts}
          selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
        />

        <FiltersSection style={{ marginBottom: 0 }}>
          <TableReviewsFilters
            hasConflicts={hasConflicts}
            selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
          />
        </FiltersSection>
      </FiltersContainer>
    </>
  );
};

const FiltersSection = styled(Row)`
  margin: 16px 0;
`;

const FiltersContainer = styled.div`
  /* 394px - magic number всего контента что сверху  */
  max-height: calc(100vh - 394px);
  padding-right: ${PADDING_RIGHT || 0}px;
  overflow-y: auto;
`;

export const mapStateToProps = state => {
  const currentUserId = state.reduxTokenAuth.currentUser.attributes.id;
  const communicationTables = state.communicationTablesResource.byIds;
  const {
    tableLoading,
    tableId,
    tableFilters,
    tableCustomFieldFilters,
    tableSharedUsers,
    searchCategory,
    searchState,
    meta
  } = state.uiClientInteractions;
  const isShared = currentUserId !== get(communicationTables, `${tableId}.creatorId`, '');

  return {
    customFields: state.customFieldsResource.byIds,
    searchLoading: state.search.loading,
    filters: state.clientInteractionsList.filters,
    checklistDefinitions: state.checklistDefinitionsResource.byIds,
    customFieldFilters: state.clientInteractionsList.customFieldFilters,
    savedTableFilters: get(state.communicationTablesResource.byIds, `${tableId}.filters`, {}),
    // checklistDefinitions: state.checklistDefinitionsResource.byIds,
    tableSharedUsers,
    tableFilters,
    tableCustomFieldFilters,
    tableId,
    isShared,
    searchCategory,
    searchState,
    meta,
    disabled: tableLoading || isEmpty(tableId) || isShared
  };
};

export const mapDispatchToProps = {
  updateFilters: actions.updateFilters,
  setFilters: actions.setFilters,
  setCustomFieldFilters: actions.setCustomFieldFilters,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.load,
  // loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude,
  loadUsers: usersResource.operations.load,
  loadTaskDefinitions: taskDefinitionsResource.operations.load,
  saveTableFilters,
  updateTable,
  updatePage: actions.updatePage,
  setSearchState,
  search,
  loadCustomFields: customFieldsResource.operations.load,
  loadStatuses: statusesResource.operations.load,
  setDefaultState,
  loadSalePipelines: salePipelinesResource.operations.load,
  loadWorkPlanTasks: workPlanTaskConfigurationsResource.operations.load,
  loadCommunicationChainsV1: communicationChainsV1Resource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
