import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, isEqual } from 'lodash';
import HowToItUseButton from 'components/HowToItUseButton';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SButton from 'components/Standard/SButton';
import {
  createUnitDashboardPageWidget,
  updateUnitsDashboardPageFilters
} from 'redux/entities/dashboardPages/unitDashboardPage/operations';
import { BaseFilter } from 'pages/DashboardPages/Components/BaseFilter';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';

const Filters = ({ hasConflicts }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(state => state.dashboardPages.loading);
  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByStatuses(state, {
        statuses: [
          CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
        ]
      }),
    isEqual
  );
  const dashboardPage = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.scores_by_units', {}),
    isEqual
  );
  const { filters, page } = dashboardPage;

  const onChange = updatedFilter => {
    dispatch(
      updateUnitsDashboardPageFilters({
        filters: {
          ...filters,
          ...updatedFilter
        },
        page: {
          number: page?.page,
          size: 5
        }
      })
    );
  };

  const addNewWidget = () => {
    dispatch(
      createUnitDashboardPageWidget({
        dashboardPageId: dashboardPage?.id,
        filters,
        page
      })
    );
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between" wrap={false}>
        <SCol flex="auto">
          <BaseFilter
            onChange={onChange}
            filters={filters}
            loading={loading}
            hasConflicts={hasConflicts}
            checklistDefinitions={checklistDefinitions}
          />
        </SCol>
        <SCol flex="none">
          <HowToItUseButton
            videoLink="https://intercom.help/qolio/ru/articles/5822384-динамика-оценок-по-отделам"
            docsLink="https://intercom.help/qolio/ru/articles/5810559-отчёт-динамика-оценок-по-отделам"
            tourCode={300914}
          />
        </SCol>
        <SCol flex="none" type="flex" align="middle">
          <SButton
            disabled={loading || hasConflicts || isEmpty(filters?.checklistDefinitionsIds)}
            marginLeft="16px"
            type="primary"
            onClick={addNewWidget}
            loading={loading}
          >
            {t('dashboardPages.addReport')}
          </SButton>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
