import { Col, Modal, Row, Skeleton } from 'antd';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import { get, isEmpty, isEqual, last, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import uniqid from 'uniqid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { basePosition, getNewPosition, moveItemInArray } from 'core/utils/dnd';
import CustomFieldEditor from './CustomFieldEditor';

const CustomFieldsManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customFieldsResource.operations.load({ pagination: false }));
  }, []);

  const [editingCustomField, setEditingCustomField] = useState(null);
  const [addingCustomField, setAddingCustomField] = useState(null);
  const loading = useSelector(state => state.customFieldsResource.loading);

  const customFields = useSelector(
    state => orderBy(Object.values(state.customFieldsResource.byIds), 'created_at'),
    (current, prev) => isEqual(current, prev) && current?.length === prev?.length
  );

  const sortedArrayWithSort = customFields
    .filter(customField => customField.sort !== null)
    .sort((a, b) => a.sort - b.sort);
  const sortedArrayWithoutSort = customFields.filter(customField => customField.sort === null);
  // Используем 2 массива для того чтобы сначала выводить поля у которых есть значение sort а после уже поля у которых sort = null
  const sortedCustomFields = [...sortedArrayWithSort, ...sortedArrayWithoutSort];

  const containsNullSort = sortedCustomFields.some(obj => obj.sort === null || obj.sort === '');

  const getMaxSort = data => {
    const sortValues = data
      .map(item => item.sort)
      .filter(value => value !== null && value !== undefined);

    if (sortValues.length === 0) {
      return 0;
    }

    return Math.max(...sortValues);
  };

  // Срабатывает всего 1 раз для того чтобы обновить значения sort у кастомных полей
  const setSortForCustomFields = () => {
    let maxSort = getMaxSort(sortedCustomFields);
    if (containsNullSort) {
      Promise.all(
        sortedCustomFields.map(async customField => {
          if (customField.sort === null || customField.sort === '') {
            maxSort += basePosition;
            const updatedField = { ...customField, sort: maxSort };
            await dispatch(customFieldsResource.operations.updateById(updatedField));
          }
        })
      )
        .then(() => {
          dispatch(customFieldsResource.operations.load({ pagination: false }));
        })
        .catch(error => {
          console.error('Ошибка при обновлении данных:', error);
        });
    }
  };

  useEffect(() => {
    if (!isEmpty(sortedCustomFields)) {
      setSortForCustomFields();
    }
  }, [loading]);

  const listBottom = parseInt(get(last(sortedCustomFields), 'sort', basePosition)) || 0;

  const onDeleteCustomField = customField => {
    if (customField.id === addingCustomField?.id) {
      return setAddingCustomField(null);
    }
    Modal.confirm({
      maskClosable: true,
      title: `${t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.title'
      )} ${customField.name}?`,
      okText: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.okText'
      ),
      okType: 'danger',
      onOk: () => {
        dispatch(customFieldsResource.operations.deleteById({ id: customField.id }));
        setAddingCustomField(null);
        setEditingCustomField(null);
      }
    });
  };

  const onSubmitCustomField = async customField => {
    if (customField.id === addingCustomField?.id) {
      // create custom field from state
      const newCustomField = {
        ...customField,
        sort: listBottom + basePosition // При создании нового custom field задаём ему sort
      };
      const resource = await dispatch(customFieldsResource.operations.create(newCustomField));
      if (resource) {
        setAddingCustomField(null);
      }
      return;
    }

    if (customField.id === editingCustomField?.id) {
      // update custom field props
      const result = await dispatch(customFieldsResource.operations.updateById(customField));
      if (result) {
        setEditingCustomField(null);
      }
      return;
    }
    setEditingCustomField(customField);
  };

  const onAddCustomField = () => {
    setAddingCustomField({
      id: uniqid(),
      fieldType: null,
      key: '',
      name: '',
      usedForFilters: true,
      usedForReviews: true
    });
  };

  const handleOnDragEnd = async ({ draggableId, source, destination }) => {
    // При перемещении вне области перемещения
    if (!destination) {
      return;
    }
    // Если не был перемещён
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const oldCustomField = sortedCustomFields.find(obj => obj.id === draggableId);

    const simulated = moveItemInArray(sortedCustomFields, source.index, destination.index);

    const top = parseInt(get(simulated[destination.index - 1], 'sort', 0));
    const bottom = parseInt(get(simulated[destination.index + 1], 'sort', listBottom));

    const newSort = getNewPosition({
      top,
      bottom,
      destinationIndex: destination.index,
      listBottom: parseInt(listBottom)
    });

    const newCustomField = { ...oldCustomField, sort: newSort };

    // console.log(
    //   'simulated', simulated,
    //   '\nlistBottom', listBottom,
    //   '\ntop', top,
    //   '\nbottom', bottom,
    //   '\nnewSort', newSort,
    //   '\noldCustomField', oldCustomField,
    //   '\nnewCustomField', newCustomField,
    // );

    await dispatch(customFieldsResource.operations.updateById(newCustomField));
  };

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  return (
    <SCard bordered shadowed>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="customFields">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Row>
                <Col span={24}>
                  <Row gutter={[0, 32]} style={{ marginBottom: '-16px' }}>
                    {sortedCustomFields.map((customField, index) => (
                      <Draggable key={customField.id} draggableId={customField.id} index={index}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CustomFieldEditor
                              key={customField.id}
                              customField={customField}
                              addingId={addingCustomField?.id}
                              editingId={editingCustomField?.id}
                              onDelete={onDeleteCustomField}
                              onSubmit={onSubmitCustomField}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {!isEmpty(addingCustomField) && (
                      <CustomFieldEditor
                        customField={addingCustomField}
                        addingId={addingCustomField?.id}
                        editingId={editingCustomField?.id}
                        onDelete={onDeleteCustomField}
                        onSubmit={onSubmitCustomField}
                      />
                    )}
                    {provided.placeholder}
                    <Col span={24}>
                      <Row justify="end">
                        <Col>
                          {isEmpty(addingCustomField) && isEmpty(editingCustomField) && (
                            <SButton
                              onClick={onAddCustomField}
                              type="primary"
                              size="big"
                              width="230px"
                            >
                              {t(
                                'integrationsSettingsPage.integrationPage.customFieldsSettings.addField'
                              )}
                            </SButton>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SCard>
  );
};

export default CustomFieldsManager;
