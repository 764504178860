import { get, put } from 'redux-bees';

const organizationEndpoints = apiPath => ({
  getCurrentOrganization: { method: get, path: `${apiPath}/organization/units` },
  getOrganizationInfo: { method: get, path: `${apiPath}/organization` },
  updateOrganizationInfo: { method: put, path: `${apiPath}/organization` },
  getUnitsIdsCommonForUsers: { method: get, path: `${apiPath}/organization/units/common_for_users` }
});

export default organizationEndpoints;
