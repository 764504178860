export default {
  checklistsPage: {
    title: 'Scorecards',
    info:
      'Click "Create New Scorecard" to configure the New scorecard. You can edit the number of questions, the scale and all evaluation conditions in it as a Draft. Click "Publish" to start evaluating communications on this scorecard. All scorecards are saved in different statuses in the list below: Draft, Publish and Archive. Place in the Archive those scorecards on which you no longer want to evaluate. Click "Create a Scorecard Question" for a New Evaluation Item that can be included in any scorecard. Click "Questions" to see all the scorecard questions.',
    infoButton: 'Learn more here',
    buttons: {
      createChecklists: 'Create new scorecard',
      createQuestion: 'Creare scorecard question',
      checklists: 'Scorecards',
      questions: 'Questions'
    },
    messages: {
      question: 'Question',
      questionSuccessfullyCreated: 'successfully created',
      questionSuccessfullyUpdated: 'successfully updated',
      createQuestionfailed: 'Failed to create question',
      updateQuestionfailed: 'Failed to update question',
      errorDragAndDropQuestion: 'It is impossible to change the position of this criterion',
      errorDragAndDropQuestionSubgroup: 'You cannot change the position of this subgroup'
    },
    addQuestionModal: {
      title: 'Add question',
      buttons: {
        libraryQuestion: 'Question from the library',
        newQuestion: 'New question'
      },
      messages: {
        disabledByWeight:
          'You cannot add a question because the weight of the scorecard questions has reached 100%.',
        info:
          'Create a new question or add a question from the library. \n The library contains questions that may have been added to other scorecards.'
      }
    },
    checklistInfo: {
      emptyName: 'Scorecard name is not set',
      copy: '(Copy)',
      messages: {
        copySuccessfullyCreated: 'Copy created',
        createCopyfailed: 'Failed to create copy',
        checklistSuccessfullyDeleted: 'Scorecard deleted',
        checklistSuccessfullyPublished: 'Scorecard successfully published',
        completedQuestionsSumm: 'The sum of weights of questions in the scorecard should be 100%.',
        emptyChecklistQuestions: 'To be published a scorecard should contain questions',
        checklistSuccessfullyArchived: 'Scorecard successfully archived'
      },
      confirmArchive: {
        title: 'Move scorecard to archive?',
        description:
          'Archived scorecards are not available for selection as a scorecard for evaluation',
        ok: 'Archive',
        cancel: 'Cancel'
      },
      confirmDelete: {
        title: 'Delete scorecard?',
        description:
          'Deleted scorecards cannot be restored. If this scorecard is not relevant to you, you can move it to the archive.',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      confirmPublish: {
        title: 'Publish scorecard?',
        description:
          ' Once the scorecard has been published, you will not be able to edit: the method and calculation of the evaluation, the evaluation scale, and the question weights. \n Before publishing, make sure that everything is set up correctly.',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      menu: {
        setting: 'Go to scorecard settings',
        copy: 'Make a copy',
        archive: 'Move to the archive',
        delete: 'Delete scorecard'
      },
      breadcrumbs: {
        checklists: 'Scorecards',
        checklistSettings: 'Scorecards settings',
        checklistQuestions: 'Questions settings'
      },
      buttons: {
        publish: 'Publish a scorecard',
        actions: 'Actions'
      },
      items: {
        ratingMethod: 'Evaluation method: ',
        ratingCalculation: 'Evaluation calculation: ',
        scaleType: 'Evaluation scale: ',
        communicationTypes: 'Communications types: ',
        units: 'Departments: ',
        totalChecklistScore: 'Total scorecard score: ',
        checklistScore: 'Scorecard score: ',
        totalSumPercentage: 'Total weight of all questions on the scorecard, in percent',
        sumPercentage: 'Questions weight'
      },
      modal: {
        title: 'Evaluation zones setup',
        ok: 'Publish',
        cancel: 'Cancel'
      }
    },
    checklistItems: {
      messages: {
        question: 'Question',
        questionSuccessfullyCreated: 'created and added to a scorecard',
        questionSuccessfullyUpdated: 'updated',
        createQuestionFailed: 'Failed to create or update question'
      }
    },
    editQuestionGroupModal: {
      title: 'Group of questions settings',
      ok: {
        create: 'Create',
        update: 'Update'
      },
      cancel: 'Cancel',
      alert: {
        description:
          'Combine scorecard questions (items) into groups to keep track of the results and their dynamics on one common skill of an employee. This helps structure the scorecard itself, the employee knowledge and the assessment system competency.'
      },
      form: {
        fields: {
          name: 'Name',
          namePlaceholder: 'Ener group of questions name',
          nameInfo:
            'The name of the group to be displayed on the scorecard for evaluation and analytics',
          percentageCalculationStartingWithZero: 'The group may have a negative percentage',
          percentageCalculationStartingWithZeroPlaceholder:
            'In this case, the calculation is made from zero.'
        },
        messages: { enterName: 'Please enter name' }
      }
    },
    question: {
      confirmDelete: {
        title: 'Are you sure you want to remove the question from the  scorecard?',
        description: 'It will still be available in the question list.',
        ok: 'Remove',
        cancel: 'Cancel'
      },
      menu: {
        edit: 'Edit question',
        copy: 'Create a copy',
        delete: 'Delete question'
      },
      weight: 'Question weight',
      tooltip: { title: 'Question with autofail' }
    },
    questionGroup: {
      ratingMethod: 'Calculation method is not set',
      confirmDelete: {
        title: 'Delete the group?',
        description:
          'Questions in the group being deleted will also be removed from the current scorecard.',
        ok: 'Delete',
        cancel: 'Cancel'
      },
      menu: {
        edit: 'Edit group',
        copy: 'Create a copy',
        delete: 'Delete group'
      },
      sumRates: 'Group score',
      sumRatesInfo: 'Total group score',
      sumPercentage: 'Group weight',
      sumSubPercentage: 'Weight of subgroup',
      sumPercentageInfo: 'Total weight of all questions in the group, in percent',
      buttons: {
        addQuestion: 'Add question',
        addQuestionGroup: 'Add group'
      }
    },
    calculationFurmula: {
      buttons: {
        addCalculationFurmula: 'Add formula'
      },
      menu: {
        edit: 'Edit formula',
        delete: 'Delete formula',
        check: 'Check the formula'
      },
      modal: {
        title: 'Calculation formula settings',
        namePlaceholder: 'Enter formula name',
        description: 'Add formulas for calculation and track employees\' dynamics based on your own criteria'
      },
      confirmDelete: {
        title_1: "Are you sure you want to delete the formula «",
        title_2: "» from evaluation form?"
      },
      confirmCheck: {
        title_error: "Check the correctness of the parentheses in this part of the formula:",
        title_ok: "There are no errors in the formula",
        ok: 'ok',
      }
    },
    subGroup: {
      sumRatesInfo: 'Total score for the group',
      buttons: {
        addSubGroup: 'Add subgroup'
      },
      modal: {
        title: 'Group Criteria Settings.',
        namePlaceholder: 'Group Name.',
        description: 'Combine assessment form criteria into groups to track results and their dynamics based on a common skill of the employee. This helps structure the assessment form itself and the evaluation system of employee knowledge and competencies.'
      },
      sumRates: 'Subgroup score',
      menu: {
        edit: 'Edit subgroup',
        delete: 'Delete subgroup?'
      }
    },
    questionsFromLibraryModal: {
      title: 'Select questions from the library',
      ok: 'Add',
      cancel: 'Cancel',
      alert: {
        title: 'This list only shows questions with the appropriate scale and evaluation zones.'
      }
    }
  }
};
