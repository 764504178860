import { Checkbox, Radio } from 'antd';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { isEqual, isNil } from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setQuestionIdToAppealComment,
  setQuestionIdToAppealObjection
} from 'redux/ui/checklistManager/reducer';
import { useTranslation } from 'react-i18next';

// const QuestionAppealStatus = ({ appealId, questionId, objection, questionHasAnswer }) => {
const QuestionAppealStatus = ({ appealId, questionId, objection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appeal = useSelector(state => state.appellationsResource.byIds[appealId], isEqual);
  const currentUser = useSelector(getCurrentUser, isEqual);
  const toggleStatus = e => {
    const value = e.target.checked;
    dispatch(
      setQuestionIdToAppealObjection({ questionId, objection: value ? { new: true } : null })
    );

    if (!value) {
      dispatch(setQuestionIdToAppealComment({ questionId, comment: null }));
    }
  };

  // if (appealId === 'new' && questionHasAnswer) {
  if (appealId === 'new') {
    return (
      <SCol flex="none" type="flex" align="center" paddingTop="9px">
        <Checkbox checked={objection?.new} status={objection?.new} onChange={toggleStatus}>
          <SText fontWeight="400" fontSize="14px">
            {t('general.disagree')}
          </SText>
        </Checkbox>
      </SCol>
    );
  }

  if (
    objection &&
    ((appeal?.assignedToId === currentUser?.id && isNil(objection?.accepted)) ||
      (appeal?.assignedToId === currentUser?.id && !appeal?.questionObjectionsProcessed))
  ) {
    return (
      <SCol flex="none" type="flex" align="center" paddingTop="9px" fontWeight="400">
        <Radio.Group
          disabled={appeal?.closed}
          onChange={e => {
            dispatch(
              setQuestionIdToAppealObjection({
                questionId,
                objection: { ...objection, accepted: e.target.value }
              })
            );
          }}
          value={objection?.accepted}
        >
          <Radio value>{t('general.accept')}</Radio>
          <Radio value={false}>{t('general.reject')}</Radio>
        </Radio.Group>
      </SCol>
    );
  }

  if (objection && !isNil(objection?.accepted)) {
    return (
      <SCol flex="none" type="flex" align="center" paddingTop="9px" fontWeight="400">
        <SText fontWeight="400" fontSize="14px">
          {objection?.accepted ? t('appealsPage.appealAccepted') : t('appealsPage.appealDenied')}
        </SText>
      </SCol>
    );
  }

  return null;
};

export default QuestionAppealStatus;
