import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import {
  loadCommunicationTables,
  loadTableData,
  updateTable
} from 'redux/ui/clientInteractions/operations';
import { expandTableRow } from 'redux/ui/clientInteractions/reducer';
import { Button, ConfigProvider, Empty } from 'antd';
import { MinusSquare, PlusSquare } from 'react-feather';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { SCol, SRow, SText } from 'components/Standard';
import WarningIcon from 'assets/images/warning-icon.svg';
import { getUIClientInteractions } from 'redux/selectors/uiClientInterationsTable';
import ColumnsRender from './ColumnsRender';
import { ClientInteractionsStyledTable } from './styled';
import { StyledEmpty } from '../../DashboardPage/styled';

const sortableColumns = ['duration', 'startedAt', 'reviewCreatedAt'];
const tableScroll = { y: 'calc(100vh - 148px)', x: 'max-content' };

// Позиционирование плюсика у коммуникации с вложенными проверками когда в конфигурации есть чекбокс
const checkboxColumnStyles = {
  position: 'absolute',
  top: '50%',
  right: '-10px',
  transform: 'translate(-50%, -50%)'
};

// Позиционирование плюсика когда нет чекбокса
const columnStyles = {
  float: 'left'
};

const shouldCellUpdate = (reRender = false) => (record, prevRecord) =>
  !isEqual(record.reviews, prevRecord.reviews) || reRender;

const ClientInteractionsTable = ({
  selectedWorkPlanTaskConfigurationId,
  hasConflicts,
  selection,
  ids,
  activeFilter,
  setActiveFilter,
  isOpen,
  setIsOpen,
  selectedModal,
  setSelectedModal,
  users
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allTableRows, setAllTableRows] = useState(null);
  const [prevTableId, setPrevTableId] = useState(null);
  const selectCustomFieldsByKeys = useMemo(() => getCustomFieldsByKeysForReviews, []);

  const { page, totalCount, sort, loading: listLoading } = useSelector(
    state => state.clientInteractionsList,
    shallowEqual
  );

  const {
    tableLoading,
    tableRows,
    tableFilters,
    tableCustomFieldFilters,
    tableColumns,
    tableId
  } = useSelector(getUIClientInteractions, shallowEqual);

  useEffect(() => {
    if (tableRows) {
      if (allTableRows && allTableRows.length !== 0) {
        return;
      }
      setAllTableRows(tableRows);
    }
  }, [tableId, tableRows]);

  const expandedTableRowsKeys = useSelector(
    state => state.uiClientInteractions.expandedTableRowsKeys,
    isEqual
  );

  const customFieldsByKeys = useSelector(state => selectCustomFieldsByKeys(state, true), isEqual);

  const tablesLoading = useSelector(state => state.communicationTablesResource.loading);

  const loading = listLoading || tableLoading || tablesLoading;
  const dataSource = hasConflicts && !loading ? [] : tableRows;

  useEffect(() => {
    dispatch(loadCommunicationTables());
    return () => dispatch(actions.setDefaultState());
  }, []);

  useEffect(() => {
    if (!prevTableId) {
      setPrevTableId(tableId);
    }
    if (!loading && tableId) {
      if (prevTableId && prevTableId !== tableId) {
        selection.unSelectAll(); // При смене конфигурации очищаем выделенные чекбоксы
        setPrevTableId(null);
      }
      dispatch(
        loadTableData({
          tableId,
          page,
          filters: tableFilters,
          customFieldFilters: tableCustomFieldFilters,
          sort,
          include: 'client,operator.role,operator.unit,status,text_communication_parts.operator'
        })
      );
    }
  }, [tableId, page.number, page.size, sort]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const updateFilters = isEmpty(tableFilters?.taskAssignmentsIds)
        ? tableFilters
        : { taskAssignmentsIds: tableFilters?.taskAssignmentsIds };

      if (selectedWorkPlanTaskConfigurationId) {
        dispatch(
          updateTable({
            id: tableId,
            filters: updateFilters,
            customFieldFilters: tableCustomFieldFilters,
            page: {
              size: pagination.pageSize.toString(),
              number: pagination.current.toString()
            }
          })
        );
      }
      if (!selectedWorkPlanTaskConfigurationId) {
        dispatch(
          actions.updatePage({
            size: pagination.pageSize.toString(),
            number: pagination.current.toString()
          })
        );
      }

      dispatch(actions.setSorting(sorter));
    },
    [dispatch, selectedWorkPlanTaskConfigurationId, tableFilters]
  );

  const onExpand = useCallback(
    (e, record) => {
      e.stopPropagation();
      return !isEmpty(record.children) && dispatch(expandTableRow(record.id));
    },
    [dispatch]
  );

  const onRowExpand = useCallback(record => dispatch(expandTableRow(record.id)), [dispatch]);

  const pagination = useMemo(() => {
    return {
      pageSize: parseInt(page.size),
      current: parseInt(page.number),
      total: totalCount,
      showSizeChanger: true,
      pageSizeOptions: ['10', '25', '50']
    };
  }, [totalCount, page.size, page.number]);

  const columnRender = useCallback(
    (col, customFieldsByKeys) => (text, record) => (
      <ColumnsRender
        onRowExpand={onRowExpand}
        customFieldsByKeys={customFieldsByKeys}
        col={col}
        text={text}
        record={record}
        selection={selection}
        ids={ids}
      />
    ),
    [onRowExpand, customFieldsByKeys, selection, ids]
  );

  const columns = useMemo(
    () =>
      tableColumns.map(col => ({
        title: (
          <ColumnTitle
            columnType={col}
            customFieldsByKeys={customFieldsByKeys}
            tableView
            selection={selection}
            ids={ids}
            tableId={tableId}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedModal={selectedModal}
            setSelectedModal={setSelectedModal}
            hasConflicts={hasConflicts}
            users={users}
          />
        ),
        key: col,
        dataIndex: col,
        render: columnRender(col, customFieldsByKeys),
        shouldCellUpdate:
          col === COLUMN_TYPES.communicationType.value
            ? undefined
            : shouldCellUpdate(!isEmpty(customFieldsByKeys)),
        sorter: sortableColumns.includes(col),
        width: COLUMN_TYPES[col]?.width || 200,
        textWrap: 'word-break',
        ellipsis: true
      })),
    [tableColumns, customFieldsByKeys, selection, ids]
  );

  const renderDescription = () => {
    if (hasConflicts && !loading) {
      return (
        <SCol span={24}>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('clientInteractionsPage.conflicts.title')}
          </SText>
          <SRow align="center">
            <SText
              maxWidth="396px"
              type="secondary"
              style={{ lineHeight: '20px', marginTop: '10px' }}
            >
              {t('clientInteractionsPage.conflicts.description1')}
            </SText>
          </SRow>
          <SRow align="center">
            <SText
              maxWidth="396px"
              type="secondary"
              style={{ lineHeight: '20px', marginBottom: '34px' }}
            >
              {t('clientInteractionsPage.conflicts.description2')}
            </SText>
          </SRow>
        </SCol>
      );
    }
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <StyledEmpty
          image={
            hasConflicts && !loading ? (
              <img src={WarningIcon} alt="warning" width="70px" />
            ) : (
              Empty.PRESENTED_IMAGE_SIMPLE
            )
          }
          description={renderDescription()}
        />
      )}
    >
      <ClientInteractionsStyledTable
        expandable={{
          // * looks like row click not working
          expandRowByClick: true,
          expandedRowKeys: expandedTableRowsKeys,
          expandIcon: ({ record, expanded }) => {
            const exp = expandedTableRowsKeys.includes(record.id);

            if (isEmpty(record?.children))
              return (
                <button className="ant-table-row-expand-icon ant-table-row-expand-icon-spaced" />
              );
            if (exp || expanded) {
              return (
                <Button
                  onClick={e => onExpand(e, record)}
                  size="small"
                  type="link"
                  style={
                    tableColumns.includes('checkboxColumn') ? checkboxColumnStyles : columnStyles
                  }
                  // style={{
                  //   float: tableColumns.includes('checkboxColumn') ? 'right' : 'left',
                  //   position: 'absolute',
                  //   top: '50%',
                  //   right: '0',
                  //   transform: 'translate(-50%, -50%)'
                  // }}
                  // style={{ float: tableColumns.includes('checkboxColumn') ? 'right' : 'left' }}
                  icon={<Icon icon={MinusSquare} />}
                />
              );
            }

            return (
              <Button
                size="small"
                type="link"
                style={
                  tableColumns.includes('checkboxColumn') ? checkboxColumnStyles : columnStyles
                }
                // style={{
                //   position: 'absolute',
                //   top: '50%',
                //   right: '-10px',
                //   transform: 'translate(-50%, -50%)'
                // }}
                // style={{ float: tableColumns.includes('checkboxColumn') ? 'right' : 'left' }}
                onClick={e => onExpand(e, record)}
                icon={<Icon icon={expanded ? MinusSquare : PlusSquare} />}
              />
            );
          }
        }}
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        border
        scroll={tableScroll}
        pagination={hasConflicts ? false : pagination}
        onChange={handleTableChange}
      />
    </ConfigProvider>
  );
};

export default ClientInteractionsTable;
