import * as actions from './reducer';

export const setEditingFolder = id => {
  return async (dispatch, getState) => {
    const state = getState();
    const folder = state.reviewLibrariesResource.byIds[id];
    dispatch(actions.setEditingFolder(folder));
  };
};
export const setEditingItem = id => {
  return async (dispatch, getState) => {
    const state = getState();
    const folder = state.reviewLibraryItemsResource.byIds[id];
    dispatch(actions.setEditingItem(folder));
  };
};
