import {
  InfoCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Menu, message, Modal, Tooltip, Typography } from 'antd';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { isEmpty, keyBy, some, get, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/questionsList';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import {
  getQuestionChecklistDefinitions,
  getQuestionChecklistDefinitionsSubgroups
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import UserPreview from 'components/UserPreview';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { IconButton } from 'components/Buttons';
import Icon from 'components/Icon';
import { Edit, Trash2 } from 'react-feather';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { loadChecklistDefinitionsWithIncludes } from '../../redux/ui/checklists/operations';

const { Text } = Typography;

const QuestionsPageList = ({
  setEditingQuestion,
  page,
  loading,
  updatePage,
  loadQuestions,
  questions,
  questionsByIds,
  deleteQuestion,
  deleteBinding,
  filters = {},
  clearList,
  totalCount,
  loadChecklistDefinitionsWithIncludes,
  checklistDefinitions,
  loadChecklistDefinitions,
  checklistDefinitionsByIds
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchFlag, setSearchFlag] = useState(false);

  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  useEffect(() => {
    loadChecklistDefinitionsWithIncludes(checklistDefinitions);
    loadQuestions({
      page,
      filters,
      sort: '-created_at',
      include: 'question-to-group-bindings.question-group.checklist-definition',
      search_input: searchText
    });
    loadChecklistDefinitions({
      include: [
        'question_groups.question_to_group_bindings.question',
        'question_groups.question_group_subgroups.question_to_group_subgroup_bindings.question',
      ].join(','),
      pagination: 'false'
    });
  }, [page.number, page.size, searchFlag]);

  const tryToDeleteQuestion = async ({ id, checklistDefinitions = [] }) => {
    const question = questionsByIds[id];

    if (
      some(
        checklistDefinitions,
        ({ status }) =>
          status === CHECKLIST_DEFINITION_STATUS.ARCHIVED.value ||
          status === CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      )
    ) {
      return message.warning(t('components.questionsList.messages.blockedDeleteQuestion'));
    }

    Modal.confirm({
      maskClosable: true,
      title: t('components.questionsList.confirmDelete.title'),
      content: t('components.questionsList.confirmDelete.description'),
      okText: t('general.delete'),
      cancelText: t('general.cancel'),
      okType: 'danger',
      onOk: async () => {
        if (!isEmpty(checklistDefinitions)) {
          await Promise.all[question.questionToGroupBindingsIds.map(id => deleteBinding({ id }))];
        }

        await deleteQuestion({ id });
      }
    });
  };

  const handleMenuClick = ({ key, id, checklistDefinitions }) => {
    const actions = {
      edit: () => setEditingQuestion(questionsByIds[id]),
      delete: () => tryToDeleteQuestion({ id, checklistDefinitions })
      //   TODO: add copy
    };

    actions[key]();
  };

  // const getMenu = ({ id, checklistDefinitions }) => (
  const getMenu = ({ id, checklistDefinitions, disabled }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id, checklistDefinitions })}>
      <Menu.Item key="edit" disabled={disabled}>
        <Icon icon={Edit} />
        <span>{t('components.questionsList.menu.edit')}</span>
      </Menu.Item>

      <Menu.Item
        key="delete"
        style={disabled ? '' : { color: 'var(--red_primary)' }}
        disabled={disabled}
      >
        <Icon icon={Trash2} />
        <span>{t('components.questionsList.menu.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const questionChecklistsNames = ({ questionId }) => {
    const questionWithChecklistDefinitionsByIds = keyBy(questions, 'id');

    return get(questionWithChecklistDefinitionsByIds, [questionId, 'checklistDefinitions'], []).map(
      checklistDefinition => checklistDefinition?.name
    );
  };

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    updatePage({ size: pageSize.toString(), number: current.toString() });
  };

  const handleSearch = () => {
    setSearchFlag(!searchFlag);
    // Выставляем 1 страницу при поиске
    updatePage({ size: page?.size, number: '1' });
  };

  const handleInputChange = e => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const columns = [
    {
      title: t('components.questionsList.listColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: {
        showTitle: false
      },
      render: (name, { text }) => (
        <>
          <Tooltip title={name}>
            <SText>{name}</SText>
          </Tooltip>
          {!isEmpty(text) && (
            <IconButton
              tooltip={{ title: text }}
              button={{
                icon: <InfoCircleOutlined />,
                size: 'icon'
              }}
            />
          )}
        </>
      )
    },
    {
      title: t('components.questionsList.listColumns.author'),
      dataIndex: 'creatorId',
      key: 'creatorId',
      width: 150,
      ellipsis: true,
      render: creatorId => <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
    },
    {
      title: t('components.questionsList.listColumns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 60,
      render: createdAt => <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
    },
    {
      title: t('components.questionsList.listColumns.isAddedToChecklist'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status, { id, checklistDefinitions }) => {
        return (
          <>
            <Text>
              {checklistDefinitions[0]
                ? questionChecklistsNames({ questionId: id })[0]
                : t('components.questionsList.isNotAddedToChecklist')}
            </Text>
            {questionChecklistsNames({ questionId: id }).length > 1 && (
              <Tooltip
                title={questionChecklistsNames({ questionId: id })
                  .filter(item => item !== questionChecklistsNames({ questionId: id })[0])
                  .join(', ')}
              >
                <Button type="link" style={{ padding: 4 }}>
                  {`${t('components.questionsList.and')} ${questionChecklistsNames({
                    questionId: id
                  }).length - 1}`}
                </Button>
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 25,
      fixed: 'right',
      // render: (checklistDefinitions, { id }) => {
      render: (status, { id, checklistDefinitions }) => {
        const disabled = Object.values(checklistDefinitions).find(
          item => item?.status === 'published'
        );
        return (
          <Dropdown overlay={getMenu({ id, checklistDefinitions, disabled })} trigger={['click']}>
            <Button icon={<Icon icon={MoreOutlined} />} />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col style={{ paddingBottom: '14px' }}>
        <Input
          value={searchText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={handleSearch}>
          {loading ? <LoadingOutlined /> : <SearchOutlined />}
        </Button>
      </Col>
      <STable
        dataSource={questions}
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        border
        pagination={{
          pageSize: parseInt(page.size),
          current: parseInt(page.number),
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={handleTableChange}
        // * NOTE: it doesn't work on resize
        scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { ids, loading: questionsList, page, totalCount } = state.questionsList;
  const {
    loading: loadingChecklistDefinitions,
    byIds: checklistDefinitionsByIds
  } = state.checklistDefinitionsResource;

  const loading = questionsList || loadingChecklistDefinitions;

  const { byIds } = state.questionsResource;
  const questions = uniq(ids).reduce((acc, id) => {
    const question = byIds[id];

    if (!question) return acc;

    const data = {
      ...byIds[id],
      checklistDefinitions: [
        ...getQuestionChecklistDefinitions(state, question),
        ...getQuestionChecklistDefinitionsSubgroups(state, question)
      ]
    };

    return [...acc, data];
  }, []);

  return {
    questions,
    questionsByIds: byIds,
    loading,
    page,
    totalCount: parseInt(totalCount) || 0,
    checklistDefinitions: state.checklistDefinitionsResource.byIds,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {
  setEditingQuestion,
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadQuestions: operations.load,
  updateQuestion: questionsResource.operations.updateById,
  deleteQuestion: questionsResource.operations.deleteById,
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  loadChecklistDefinitionsWithIncludes,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.load
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsPageList));
