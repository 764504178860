import React from 'react';
import { Typography, Tooltip } from 'antd';
import { keyBy } from 'lodash';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { Trans } from 'react-i18next';
import ClientInteractionLink from 'components/ClientInteractionLink';
import { stringify } from 'core/utils/queryString';
import { valueFormatter } from 'core/utils/valueFormatter';
import { getUserName } from 'components/UserPreview/getUserName';
import moment from 'moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { value } from 'lodash/seq';
import { percentForTags } from 'core/utils/addPercentForTags';
import {
  getFilters,
  getOrderedGroupQuestionsAndQuestionsAvito,
  valueToColorZoneColor
} from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ currentChecklist, checklistItems, filters, widgetMeta, type }) => {
  const { viewMode, viewType } = filters;

  const getOrderString = ({ orderString }) => {
    if (orderString === 'asc') return 'ascend';
    if (orderString === 'desc') return 'descend';
  };

  const getSorterId = ({ itemId }) => {
    if (viewMode === WIDGET_VIEW_MODES.GROUPS.value) {
      return getOrderString({
        orderString: widgetMeta[`group_score_${itemId.replace(/-/gi, '_')}`]
      });
    }
    if (viewMode === WIDGET_VIEW_MODES.SUBGROUPS.value) {
      return getOrderString({
        orderString: widgetMeta[`subgroup_score_${itemId.replace(/-/gi, '_')}`]
      });
    }
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) {
      return getOrderString({
        orderString: widgetMeta[`answer_score_${itemId.replace(/-/gi, '_')}`]
      });
    }
  };

  const targetItems = getOrderedGroupQuestionsAndQuestionsAvito({
    checklistItems,
    viewMode,
    type
  });

  const uniqueTargetItems = targetItems.reduce((acc, current) => {
    const found = acc.find(item => item.id === current.id);
    if (!found) {
      acc.push(current);
    }
    return acc;
  }, []);

  const itemsColumns = uniqueTargetItems.reduce((acc, item) => {
    const data = {
      title: (
        <Tooltip title={item.name} placement="topLeft">
          <Text>{item.name}</Text>
        </Tooltip>
      ),
      dataIndex: item.id,
      key: item.id,
      align: 'center',
      width: uniqueTargetItems.length < 8 ? null : 170,
      ellipsis: true,
      sorter: true,
      defaultSortOrder: getSorterId({ itemId: item.id }),
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (text?.value || text?.value === 0) && (
            <Text>
              {viewType !== WIDGET_VIEW_MODES.SCORE.value
                ? beatifyFloat(text?.value)
                : valueFormatter({ value: text?.value, ratingMode: currentChecklist.ratingMode })}
            </Text>
          )
        };
      }
    };
    return [...acc, data];
  }, []);

  const basicAssessment = {
    title: (
      <Tooltip
        title={
          <Trans i18nKey="dashboardPage.tableReviewsWithScoresAvito.table.columns.finalAssessment" />
        }
        placement="topLeft"
      >
        <Text>
          Базовая оценка
          {/*<Trans i18nKey="dashboardPage.tableReviewsWithScoresAvito.table.columns.finalAssessment" />*/}
        </Text>
      </Tooltip>
    ),
    dataIndex: 'basicAssessment',
    key: 'basicAssessment',
    align: 'center',
    fixed: 'right',
    width: 100,
    sorter: true,
    ellipsis: true,
    defaultSortOrder: getOrderString({ orderString: widgetMeta.basic_assessment }),
    render: text => {
      return {
        props: {
          style: {
            background: text?.color
          }
        },
        children: (text?.value || text?.value === 0) && <Text>{percentForTags(text?.value)}</Text>
      };
    }
  };

  const combinedObject = [basicAssessment];

  const tableInfo = [
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.type" />,
      dataIndex: 'reviewId',
      key: 'reviewId',
      fixed: 'left',
      width: 60,
      ellipsis: true,
      render: (text, record) => {
        return (
          <ClientInteractionLink
            isReview
            id={text}
            communication={{
              communicationType: record.communicationType,
              clientInteractionType: record.clientInteractionType
            }}
          />
        );
      }
    },
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.operatorId" />,
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children: (
            <GetUserPreview
              userId={text}
              altName={record.name}
              link={`/user/${text}/charts${stringify({
                filters: {
                  ...getFilters(filters)
                }
              })}`}
            />
          )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.communicationKey" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.communicationKey" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'client_phone_number_and_communication_id',
      key: 'client_phone_number_and_communication_id',
      width: 150,
      ellipsis: true,
      render: text => {
        const phoneNumberOrCommunicationId = text.client_phone_number
          ? text.client_phone_number
          : text.communication_id;
        return (
          <Tooltip title={phoneNumberOrCommunicationId}>
            <Text>{phoneNumberOrCommunicationId || ''}</Text>
          </Tooltip>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.clientInteractionStartedAt" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.clientInteractionStartedAt" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'clientInteractionStartedAt',
      key: 'clientInteractionStartedAt',
      defaultSortOrder: getOrderString({ orderString: widgetMeta.client_interaction_started_at }),
      width: 170,
      ellipsis: true,
      sorter: true,
      render: text => <Text>{text || ''}</Text>
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.reviewCreatedAt" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.reviewCreatedAt" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 170,
      ellipsis: true,
      sorter: true,
      defaultSortOrder: getOrderString({ orderString: widgetMeta.created_at }),
      render: text => <Text>{text || ''}</Text>
    },
    ...itemsColumns
  ];

  combinedObject.forEach(item => {
    tableInfo.push(item);
  });

  return tableInfo;
};

export const prepareRows = ({
  widgetValue,
  currentChecklist,
  checklistItems,
  filters,
  isExport,
  usersByIds,
  unitsByIds,
  questionGroupSubgroupsResource,
  questionGroupResource
}) => {
  const { viewMode, viewType } = filters;
  const { colorZones, ratingMode } = currentChecklist;

  const clietnInteractionTypeToId = {
    phone_call: 'client_phone_number',
    custom_communication: 'communication_id',
    chat: 'integration_uid'
  };

  const getQuestionColorZones = ({ questionId }) =>
    keyBy(checklistItems, 'id')[questionId]?.colorZones || [];

  const getColorZones = ({ questionId }) => {
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value)
      return getQuestionColorZones({ questionId });

    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value && ratingMode === RATING_MODE.PERCENTAGE)
      return colorZones;

    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value && ratingMode !== RATING_MODE.PERCENTAGE)
      return undefined;

    return undefined;
  };

  const sumUpBySubgroups = ({ scores }) => {
    const checklistId = currentChecklist.id;
    const ids = Object.keys(scores);
    const newScores = { ...scores };

    ids.forEach(id => {
      const subgroupObject = questionGroupSubgroupsResource.find(
        obj =>
          obj.checklistDefinitionId === checklistId &&
          obj.questionsIds &&
          obj.questionsIds.includes(id)
      );
      const groupQuestionsObject = questionGroupResource.find(
        obj =>
          obj.checklistDefinitionId === checklistId &&
          obj.questionsIds &&
          obj.questionsIds.includes(id)
      );
      const groupSubgroupQuestionsObject = questionGroupResource.find(
        obj =>
          obj.checklistDefinitionId === checklistId &&
          obj.questionGroupSubgroupsIds &&
          obj.questionGroupSubgroupsIds.includes(subgroupObject?.id)
      );

      // Сумма критериев подгруппы
      if (subgroupObject) {
        const questionsIds = subgroupObject?.questionsIds || [];
        let sum = 0;
        if (questionsIds.length > 0) {
          questionsIds.forEach(item => {
            sum += scores[item]?.value || 0;
          });
        }
        newScores[subgroupObject.id] = {};
        newScores[subgroupObject.id].value = sum;
      }
      if (groupQuestionsObject) {
        const questionsIds = groupQuestionsObject?.questionsIds || [];
        let sum = 0;
        if (questionsIds.length > 0) {
          questionsIds.forEach(item => {
            sum += scores[item]?.value || 0;
          });
        }
        newScores[groupQuestionsObject.id] = {};
        newScores[groupQuestionsObject.id].value = sum;
      }
      if (groupSubgroupQuestionsObject) {
        const groupSubgroupsQuestions = questionGroupSubgroupsResource.filter(
          obj =>
            obj.checklistDefinitionId === checklistId &&
            groupSubgroupQuestionsObject.questionGroupSubgroupsIds.includes(obj.id)
        );
        let sum = 0;
        groupSubgroupsQuestions.forEach(subgroup => {
          const questionsIds = subgroup?.questionsIds || [];
          if (questionsIds.length > 0) {
            questionsIds.forEach(item => {
              sum += scores[item]?.value || 0;
            });
          }
        });
        newScores[groupSubgroupQuestionsObject.id] = {};
        newScores[groupSubgroupQuestionsObject.id].value = sum;
      }
    });

    return newScores;
  };

  const getReviewScores = ({ scores }) => {
    if (!(!!scores && typeof scores === 'object' && Object.keys(scores).length > 0)) {
      console.log('Ошибка при получении данных виджета');
      return {};
    }
    return Object.keys(scores).reduce((acc, id) => {
      const value =
        viewType === WIDGET_VIEW_MODES.SCORE.value ? scores[id]?.score : scores[id]?.original_score;

      const valueForColorZone =
        viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
          ? scores[id]?.original_score
          : scores[id]?.score;

      return {
        ...acc,
        [id]: {
          value,
          color: valueToColorZoneColor({
            value: valueForColorZone,
            colorZones: getColorZones({ questionId: id })
          })
        }
      };
    }, {});
  };
  return widgetValue.reduce((acc, review) => {
    if (!(!!review && typeof review === 'object' && Object.keys(review).length > 0)) {
      console.log('Ошибка при получении данных виджета');
      return [];
    }

    const reviewItem = review.scores;
    let questionValue = null;
    let valueForColorZone = null;

    let valueBasicAssessment = null;

    Object.values(reviewItem).forEach(item => {
      if (item.type === 'QG') {
        valueBasicAssessment = item.score;
      }
      if (item.type === 'answer') {
        questionValue = value.original_score;
        valueForColorZone = value.score;
      }
    });

    const data = {
      key: review.review_id,
      communicationLink: `${window.location.origin}/reviews/${review.review_id}`,
      reviewId: isExport ? review.communication_type : review.review_id,
      operatorId: isExport
        ? getUserName({ user: usersByIds[review.operator_id] })
        : review.operator_id,
      unitName: unitsByIds[usersByIds[review.operator_id]?.unitId]?.name,
      name: review?.operator_name,
      communicationKey:
        review?.client_phone_number ||
        review[clietnInteractionTypeToId[review.client_interaction_type]] ||
        '',
      client_phone_number_and_communication_id: {
        client_phone_number: review.client_phone_number,
        communication_id: review.communication_id
      },
      ...sumUpBySubgroups({ scores: getReviewScores({ scores: review.scores }) }),
      checklistScore: {
        value: questionValue,
        color: valueToColorZoneColor({
          value: valueForColorZone,
          colorZones
        })
      },
      basicAssessment: {
        value: parseFloat(valueBasicAssessment),
        color: valueToColorZoneColor({
          value: valueBasicAssessment,
          colorZones
        })
      },
      clientInteractionType: review.client_interaction_type,
      clientInteractionStartedAt: moment(review.client_interaction_started_at).format(
        'DD/MM/YYYY, HH:mm'
      ),
      createdAt: moment(review.review_created_at).format('DD/MM/YYYY, HH:mm'),
      communicationType: review.communication_type
    };
    return [...acc, data];
  }, []);
};
