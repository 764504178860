import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useSelections } from 'ahooks';
import { useEffect, useState } from 'react';

const useSelectionsContext = () => {
  const [ids, setIds] = useState([]);
  const clientInteractionsListIds = useSelector(state => state.clientInteractionsList.ids, isEqual);
  // Данный useEffect отвечает за обогащение массива ids, при переходе на любую другую страницу пагинации данный массив будет обогащатся
  // это необходимо для того чтобы использовать чекбоксы на разных страницах пагинации
  useEffect(() => {
    if (clientInteractionsListIds) {
      setIds(prevIds => {
        const mergedRows = [...prevIds, ...clientInteractionsListIds];

        const uniqueIds = mergedRows.reduce((acc, row) => {
          const isUnique = !acc.some(item => JSON.stringify(item) === JSON.stringify(row));
          return isUnique ? [...acc, row] : acc;
        }, []);

        return uniqueIds;
      });
    }
  }, [clientInteractionsListIds]);
  const selection = useSelections(ids, []);
  return { ids, selection };
};

export default useSelectionsContext;
