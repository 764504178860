import { compact, get, keyBy, sum } from 'lodash';
import { ANALYTICS_WIDGETS_NAMES, RATING_MODE } from 'core/utils/constants';
import prepareUserData from 'pages/DashboardPages/TeamPage/Components/UsersTable/tableRows';
import { beatifyFloat } from 'core/utils/convertFloat';
import tableRows from 'pages/DashboardPages/TeamPage/Components/UnitTable/tableRows';
import i18next from 'i18next';

/**
 * @param widgets
 * @returns {{reviewsCountData: Object, averageScoreData: Object, ratingFlagsData: Object, previousReviewsCountData: Object, checklistColorZoneData, previousChecklistColorZoneData: Object, previousPeriodAverageScoreData: Object}}
 */
export const getDataFromWidget = ({ widgets }) => {
  const checklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.current_period`,
      get(widgets, `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}`, {})
    ),
    'id'
  );

  const previousChecklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const averageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.current_period`,
      get(widgets, `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}`)
    ),
    'id'
  );

  const previousPeriodAverageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const reviewsCountData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.current_period`,
      get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}`, {})
    ),
    'id'
  );

  const previousReviewsCountData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.previous_period`, {}),
    'id'
  );

  const ratingFlagsData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.current_period`,
      get(widgets, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}`, {})
    ),
    'id'
  );

  return {
    checklistColorZoneData,
    previousChecklistColorZoneData,
    averageScoreData,
    previousPeriodAverageScoreData,
    reviewsCountData,
    previousReviewsCountData,
    ratingFlagsData
  };
};

/**
 * @returns {({dataIndex: string, title: string, key: string})[]}
 */
export const prepareExportUserHeaderRow = ({ viewType }) => {
  return compact([
    viewType === 'employees' && {
      title: i18next.t('teamPage.exportTable.columns.employee'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.unit'),
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.averageScore'),
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS
    },
    {
      title: i18next.t('teamPage.exportTable.columns.totalCountReviews'),
      dataIndex: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS
    },
    {
      title: i18next.t('teamPage.exportTable.columns.greenZonePercentage'),
      dataIndex: 'greenZonePercentage',
      key: 'greenZonePercentage'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.grayColorZone'),
      key: 'colorZone.gray',
      dataIndex: 'colorZone.gray'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.redColorZone'),
      key: 'colorZone.red',
      dataIndex: 'colorZone.red'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.yellowColorZone'),
      key: 'colorZone.yellow',
      dataIndex: 'colorZone.yellow'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.greenColorZone'),
      key: 'colorZone.green',
      dataIndex: 'colorZone.green'
    },
    {
      title: i18next.t('teamPage.exportTable.columns.countCommentsWithoutFlag'),
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`,
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`
    },
    {
      title: i18next.t('teamPage.exportTable.columns.redComments'),
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`,
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`
    },
    {
      title: i18next.t('teamPage.exportTable.columns.yellowComments'),
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`,
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`
    },
    {
      title: i18next.t('teamPage.exportTable.columns.greenComments'),
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`,
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`
    }
  ]);
};

const changeRowsData = ({ rows, selectedChecklistDefinition, checklistDefinitionsIds }) => {
  return rows.reduce((accRows, rowData) => {
    const low = get(
      rowData,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.low`,
      0
    );
    const medium = get(
      rowData,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.medium`,
      0
    );
    const high = get(
      rowData,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.high`,
      0
    );

    const colorZoneGray =
      rowData[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS] - sum([low, medium, high]);

    return [
      ...accRows,
      {
        ...rowData,
        [ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]:
          checklistDefinitionsIds === 'all' || !checklistDefinitionsIds
            ? 'N/A'
            : `${beatifyFloat(
                rowData[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]
              )}${
                get(selectedChecklistDefinition, 'ratingMode') === RATING_MODE.PERCENTAGE ? '%' : ''
              }`,
        greenZonePercentage: `${beatifyFloat(rowData.greenZonePercentage)}%`,
        colorZone: {
          gray: colorZoneGray === 0 ? '' : colorZoneGray,
          red: low === 0 ? '' : low,
          yellow: medium === 0 ? '' : medium,
          green: high === 0 ? '' : high
        }
      }
    ];
  }, []);
};

/**
 * @param viewType
 * @param users
 * @param unitsWithReviewedUsers
 * @param widgets
 * @param filters
 * @param checklistDefinitionsByIds
 * @param usersByIds
 * @param units
 * @returns {*[]|*}
 */
export const prepareExportRows = ({
  viewType,
  users,
  unitsWithReviewedUsers,
  widgets,
  filters,
  checklistDefinitionsByIds,
  usersByIds,
  units
}) => {
  const { checklistDefinitionsIds } = filters;
  const selectedChecklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionsIds);
  const {
    checklistColorZoneData,
    previousChecklistColorZoneData,
    averageScoreData,
    previousPeriodAverageScoreData,
    reviewsCountData,
    previousReviewsCountData,
    ratingFlagsData
  } = getDataFromWidget({ widgets });

  if (viewType === 'employees') {
    return changeRowsData({
      selectedChecklistDefinition,
      checklistDefinitionsIds,
      rows: unitsWithReviewedUsers.reduce((acc, { id: unitId, name: unitName }) => {
        return [
          ...acc,
          ...prepareUserData({
            checklistColorZoneData,
            previousChecklistColorZoneData,
            reviewsCountData,
            previousReviewsCountData,
            averageScoreData,
            previousPeriodAverageScoreData,
            users: users.filter(user => user.unitId === unitId),
            ratingFlagsData
          }).reduce(
            (accRows, rowData) => [
              ...accRows,
              {
                ...rowData,
                unitName
              }
            ],
            []
          )
        ];
      }, [])
    });
  }

  return changeRowsData({
    selectedChecklistDefinition,
    checklistDefinitionsIds,
    rows: tableRows({
      checklistColorZoneData,
      previousChecklistColorZoneData,
      reviewsCountData,
      previousReviewsCountData,
      averageScoreData,
      previousPeriodAverageScoreData,
      usersByIds,
      units,
      ratingFlagsData,
      filters
    })
  });
};

export default {};
