import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SSelect from 'components/Standard/SSelect';
import SText, { STitle } from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { Helmet } from 'react-helmet';
import { selectSearch } from 'core/utils/selectSearch';
import { getUserName } from 'components/UserPreview/getUserName';
import { usersResource } from 'redux/resources/users';
import {
  getAppellationsSettings,
  updateAppellationsSettings
} from 'redux/entities/appellationsSettings/operations';
import SButton from 'components/Standard/SButton';
import Icon from 'components/Icon';
import { Plus } from 'react-feather';
import uniqid from 'uniqid';
import { find, isEmpty, isEqual, last, omit, orderBy, pickBy, reduce, some } from 'lodash';
import { getUsersWithPermissions } from 'redux/selectors/users';
import { getAppellationsSettingRules } from 'redux/selectors/appeals';
import { PERMISSIONS } from 'core/utils/constants';
import { AssignRule } from './Components/AssignRule';

const ASSIGNER_METHOD = {
  REVIEWER: 'reviewer',
  USERS_ARRAY: 'users_array',
  ASSIGNER_BY_APELLANT_RULES: 'assignee_by_appellant_rules'
};

const assignerMethodToDescriptionText = {
  [ASSIGNER_METHOD.REVIEWER]: 'appealsPage.settings.assignerMethods.reviewer.description',
  [ASSIGNER_METHOD.USERS_ARRAY]: 'appealsPage.settings.assignerMethods.usersArray.description',
  [ASSIGNER_METHOD.ASSIGNER_BY_APELLANT_RULES]:
    'appealsPage.settings.assignerMethods.assignerByApellantRules.description'
};

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const usersByIds = useSelector(
    state => getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_WATCH_APPELLATIONS] }),
    isEqual
  );
  const loadingUsers = useSelector(state => state.usersResource.loading);
  const appellationsSettings = useSelector(state => state.appellationsSettings.settings, isEqual);
  const rulesByIds = useSelector(
    state => getAppellationsSettingRules(state, appellationsSettings),
    isEqual
  );
  const [settingsState, setSettingsState] = useState(appellationsSettings);
  const [rules, setRules] = useState({});

  useEffect(() => {
    const loadSettings = async () => {
      dispatch(
        usersResource.operations.load({
          pagination: 'false'
        })
      );
      const { settings, rulesByIds } = await dispatch(getAppellationsSettings());
      setSettingsState(settings);
      setRules(rulesByIds);
    };
    dispatch(
      usersResource.operations.load({
        pagination: 'false',
        include: 'role',
        filters: {
          withRolePermissions: [PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS]
        }
      })
    );
    loadSettings();
  }, []);

  const loading = useSelector(state => state.appellationsSettings.loading);

  const updateRule = useCallback(
    rule => {
      setRules({
        ...rules,
        [rule.id]: {
          ...(rules[rule.id] || {}),
          ...rule
        }
      });
    },
    [setRules, rules]
  );

  const deleteRule = rule => setRules(omit(rules, rule.id));

  const onAddNewRule = () => {
    const id = `FE_KEY_${uniqid()}`;
    setRules({
      ...rules,
      [id]: {
        id,
        new: true,
        appellantsIds: [],
        assignersIds: [],
        description: '',
        position: (last(orderBy(Object.values(rules), 'position'))?.position || 0) + 1
      }
    });
  };

  const onSave = async () => {
    const decideRules = settingsState => {
      if (settingsState?.selectAssignerMethod !== ASSIGNER_METHOD.ASSIGNER_BY_APELLANT_RULES) {
        return { ...settingsState, assignerByAppellantRulesAttributes: [] };
      }

      const oldRules = Object.values(rulesByIds) || [];

      const oldRulesToSend = oldRules.reduce((acc, rule) => {
        const sameRule = find(rules, newRule => newRule.id === rule.id);
        const isUpdated = !!sameRule;
        const isDeleted = !isUpdated;

        if (isDeleted) {
          return [...acc, { id: rule.id, _destroy: 1 }];
        }

        return [...acc, sameRule];
      }, []);

      const newRulesToSend = Object.values(rules).reduce(
        (acc, rule) => (rule.new ? [...acc, omit(rule, ['id', 'new'])] : acc),
        []
      );

      const normilizeRulesArray = rulesArray => {
        const sorted = orderBy(rulesArray, 'position');
        // * rewrite position to prevent infinite increasing over time
        return sorted.map((rule, index) => ({
          ...omit(rule, ['type', 'appellationSettingId', 'appellationSettingsId']),
          position: index
        }));
      };

      return {
        ...settingsState,
        selectAssignerFromUsersArray: [],
        assignerByAppellantRulesAttributes: normilizeRulesArray([
          ...oldRulesToSend,
          ...newRulesToSend
        ])
      };
    };

    try {
      await dispatch(
        updateAppellationsSettings(
          omit(decideRules(settingsState), ['assignerByAppellantRulesIds'])
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const checkIsValid = settingsState => {
    if (settingsState?.selectAssignerMethod === ASSIGNER_METHOD.ASSIGNER_BY_APELLANT_RULES) {
      if (some(rules, rule => isEmpty(rule.appellantsIds) || isEmpty(rule.assignersIds))) {
        return false;
      }

      if (isEmpty(rules)) return false;
    }

    if (
      settingsState?.selectAssignerMethod === ASSIGNER_METHOD.USERS_ARRAY &&
      isEmpty(settingsState?.selectAssignerFromUsersArray)
    ) {
      return false;
    }

    if (!isEqual(rules, rulesByIds)) return true;

    if (isEqual(settingsState, appellationsSettings)) return false;

    return true;
  };

  const canSave = checkIsValid(settingsState);

  const getDisabledUsersIds = ruleId => {
    const otherRules = pickBy(rules, ({ id }) => id !== ruleId);
    return reduce(
      otherRules,
      (acc, rule) => [...acc, ...rule.assignersIds, ...rule.appellantsIds],
      []
    );
  };

  return (
    <SRow gutter={[16, 16]} padding="16px">
      <Helmet>
        <title>{t('pagesMeta.appealsSettingsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SCard bordered shadowed loading={loading}>
          <SRow gutter={[16, 16]}>
            <SCol span={14}>
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.appealDeadline')}</SText>
                </SCol>
                <SCol span={8}>
                  <InputNumber
                    min={1}
                    max={100}
                    value={settingsState?.appealDeadlineAfterReviewCreatedInDays || 1}
                    onChange={value => {
                      setSettingsState({
                        ...settingsState,
                        appealDeadlineAfterReviewCreatedInDays: value
                      });
                    }}
                  />
                </SCol>
              </SRow>
            </SCol>
            <SCol span={14} marginBottom="6px">
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.addOperatorAsWatcher')}</SText>
                </SCol>
                <SCol span={8}>
                  <Radio.Group
                    value={settingsState?.addOperatorAsWatcher}
                    onChange={e => {
                      setSettingsState({
                        ...settingsState,
                        addOperatorAsWatcher: e.target.value
                      });
                    }}
                  >
                    <Radio value>{t('general.yes')}</Radio>
                    <Radio value={false}>{t('general.no')}</Radio>
                  </Radio.Group>
                </SCol>
              </SRow>
            </SCol>
            <SCol span={14}>
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.addReviewerAsWatcher')}</SText>
                </SCol>
                <SCol span={8}>
                  <Radio.Group
                    value={settingsState?.addReviewerAsWatcher}
                    onChange={e => {
                      setSettingsState({
                        ...settingsState,
                        addReviewerAsWatcher: e.target.value
                      });
                    }}
                  >
                    <Radio value>{t('general.yes')}</Radio>
                    <Radio value={false}>{t('general.no')}</Radio>
                  </Radio.Group>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
      <SCol span={24}>
        <SCard bordered shadowed loading={loading}>
          <SRow gutter={[16, 16]} style={{ margin: '-8px' }}>
            <SCol span={24}>
              <SRow gutter={[4, 0]}>
                <SCol display="flex" alignItems="center">
                  <STitle level={5}>
                    {t('appealsPage.settings.selectAssignerFromUsersArray')}
                  </STitle>
                </SCol>
                <SCol>
                  <SSelect
                    width="304px"
                    value={settingsState?.selectAssignerMethod}
                    onChange={value =>
                      setSettingsState({ ...settingsState, selectAssignerMethod: value })
                    }
                  >
                    <Option key={ASSIGNER_METHOD.REVIEWER}>
                      {t('appealsPage.settings.assignerMethods.reviewer.title')}
                    </Option>
                    <Option key={ASSIGNER_METHOD.USERS_ARRAY}>
                      {t('appealsPage.settings.assignerMethods.usersArray.title')}
                    </Option>
                    <Option key={ASSIGNER_METHOD.ASSIGNER_BY_APELLANT_RULES}>
                      {t('appealsPage.settings.assignerMethods.assignerByApellantRules.title')}
                    </Option>
                  </SSelect>
                </SCol>
              </SRow>
            </SCol>
            <SCol span={24}>
              <SText>
                {t(assignerMethodToDescriptionText[settingsState?.selectAssignerMethod])}
              </SText>
            </SCol>
            <SCol span={24}>
              {settingsState?.selectAssignerMethod === ASSIGNER_METHOD.USERS_ARRAY && (
                <SRow gutter={[8, 8]}>
                  <SCol span={24}>
                    <SText>{t('appealsPage.settings.selectAssignerFromUsersArrayAdd')}</SText>
                  </SCol>
                  <SCol span={24}>
                    <SSelect
                      width="304px"
                      placeholder={t('general.users')}
                      allowClear
                      mode="multiple"
                      showArrow
                      maxTagCount={0}
                      loading={loadingUsers}
                      maxTagPlaceholder={selectedKeys =>
                        `${t('general.users')} ${selectedKeys.length}`
                      }
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'label' })
                      }
                      value={settingsState?.selectAssignerFromUsersArray}
                      onChange={ids =>
                        setSettingsState({ ...settingsState, selectAssignerFromUsersArray: ids })
                      }
                    >
                      {Object.values(usersByIds).map(user => (
                        <Option
                          key={user.id}
                          value={user.id}
                          label={getUserName({ user: usersByIds[user.id] })}
                        >
                          <UserPreview disabled userId={user.id} />
                        </Option>
                      ))}
                    </SSelect>
                  </SCol>
                </SRow>
              )}

              {settingsState?.selectAssignerMethod ===
                ASSIGNER_METHOD.ASSIGNER_BY_APELLANT_RULES && (
                <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
                  <SCol span={24}>
                    <SRow gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
                      {orderBy(rules, 'position').map(rule => (
                        <AssignRule
                          key={rule.id}
                          rule={rule}
                          deleteRule={deleteRule}
                          updateRule={updateRule}
                          disabledUsersIds={getDisabledUsersIds(rule.id)}
                        />
                      ))}
                    </SRow>
                  </SCol>
                  <SCol span={24}>
                    <SButton
                      type="link"
                      icon={<Icon icon={Plus} />}
                      paddingLeft="0"
                      onClick={onAddNewRule}
                    >
                      {t('appealsPage.settings.addRule')}
                    </SButton>
                  </SCol>
                </SRow>
              )}
            </SCol>
            <SCol span={24}>
              <SButton onClick={onSave} disabled={!canSave} showLoad type="primary">
                {t('general.save')}
              </SButton>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default Settings;
