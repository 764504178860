import { Drawer, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toogleVisibleDrawer } from 'redux/ui/clientInteractions/reducer';
import Columns from './Components/Columns';

const ClientInteractionsSettingsDrawer = ({
  toogleVisibleDrawer,
  isVisibleDrawer,
  communicationTablesByIds,
  loading,
  selection
}) => {
  const { t } = useTranslation();
  return (
    <Drawer
      title={t('clientInteractionsPage.drawer.title')}
      placement="right"
      width={600}
      closable
      onClose={toogleVisibleDrawer}
      visible={isVisibleDrawer}
    >
      <Spin spinning={loading || false}>
        <Columns communicationTablesByIds={communicationTablesByIds} selection={selection} />
      </Spin>
    </Drawer>
  );
};

const mapStateToProps = state => {
  const { loading, deleteByIdStarted, createStarted } = state.communicationTablesResource;
  return {
    loading: loading || deleteByIdStarted || createStarted,
    isVisibleDrawer: state.uiClientInteractions.isVisibleDrawer,
    isVisibleShareSettings: state.uiClientInteractions.isVisibleShareSettings,
    communicationTablesByIds: state.communicationTablesResource.byIds
  };
};

const mapDispatchToProps = {
  toogleVisibleDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionsSettingsDrawer);
