import { getDashboardWidgetAnalytics } from 'redux/entities/analytics/dashboard/operations';
import { dashboardWidgetsResource } from 'redux/resources/dashboardWidgets';
import { get } from 'lodash';
import extraLargeWidgets from 'pages/DashboardPage/widgetsDefinitions/extraLargeWidgets';
import axios from 'axios';
import { dashboardsResource } from 'redux/resources/dashboards';
import * as actions from './reducer';

const getWidgetNames = ({ widgetType, filters }) => {
  const { viewType, viewMode } = filters;
  const selectedWidget = extraLargeWidgets[widgetType];

  if (widgetType === 'table_reviews_count_by_reviewers')
    return get(selectedWidget, ['widget_names', viewType, viewMode], []);

  return get(extraLargeWidgets, [widgetType, 'widget_names', filters?.viewMode], []);
};

export const updateDashboardWidget = ({ ...props }) => {
  return dispatch => {
    return dispatch(
      dashboardWidgetsResource.operations.updateById({
        ...props,
        widgetNames: [...getWidgetNames({ widgetType: props.type, filters: props.filters })]
      })
    ).then(updatedWidget => {
      if (updatedWidget?.id) {
        dispatch(actions.resetUiWidgetModal());
        return dispatch(getDashboardWidgetAnalytics({ id: props.id, type: props.type })).then(
          async updatedAnalytics => {
            return {
              updatedWidget,
              updatedAnalytics: updatedAnalytics[props.id]?.data
            };
          }
        );
      }
    });
  };
};

export const loadReviewsCount = ({ options = {} }) => async (dispatch, getState) => {
  const state = getState();

  const { axiosToken } = state.uiWidgetSettingsModal.reviewsCount;
  if (axiosToken) {
    axiosToken.cancel();
  }

  const cancelTokenSource = axios.CancelToken.source();

  dispatch(actions.updateAxiosToken(cancelTokenSource));

  dispatch(actions.setReviewsCountLoading(true));

  const reviewsCount = await dispatch(
    dashboardsResource.operations.loadReviewsCount({ options, cancelTokenSource })
  );

  if (reviewsCount) {
    dispatch(actions.setReviewsCount(reviewsCount?.totalCount));
    return reviewsCount.totalCount;
  }
  return null;
};
