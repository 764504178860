export default {
  menu: {
    appMenu: {
      analytics: 'Analytics',
      reports: 'Reports',
      dashboards: 'Dashboard',
      dynamicsOfEvaluationsByUnits: 'Dynamics of evaluations by units',
      communications: 'Communication list',
      tasks: 'Tasks',
      library: 'Library',
      addReview: 'Add evaluation',
      team: 'Team',
      settings: 'Tools',
      checklistsSettings: 'Score cards',
      tagsSettings: 'Tags',
      calibrations: 'Calibration sessions',
      workPlanTaskConfigurations: 'Load distribution',
      appeals: 'Appeals',
      autoFailScores: 'Dynamics of critical errors'
    },
    userMenu: {
      profile: 'Profile',
      profileSettings: 'Profile settings',
      organizationSettings: 'Organization settings',
      organizationStructureSettings: 'Employees and access rights',
      integrationsSettings: 'Integrations',
      logout: 'Log out',
      organizationId: 'Organization code',
      copyOrganizationId: 'Copy the organization Code',
      copyOrganizationIdSuccess: "The organization's code has been copied"
    },
    helpMenu: {
      helpCenter: 'Help center',
      updates: 'Last updates',
      support: 'Contact support',
      suggestAnIdea: 'Suggest an idea',
      hotKeys: 'Hotkeys'
    },
    alerts: {
      rolesAlert: {
        title:
          '💡 The Administrator and Operator roles are basic, without the possibility to delete them. More information about roles, access rights and their settings you may read ',
        button: 'here'
      }
    },
    workPlanTasks: 'My tasks'
  }
};
