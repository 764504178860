import React, { useState } from 'react';
import { Dropdown, Menu, Tooltip, Typography } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';
import { getChainNumber } from 'core/utils/getChainNumber';

const { Link } = Typography;

const CommunicationChainsButton = ({ communicationChainsIds }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = flag => {
    setVisible(flag);
  };

  const communicationChainsByIds = useSelector(state => state.communicationChainsV1Resource.byIds);
  const communicationChains = Object.values(communicationChainsByIds).filter(
    chain => communicationChainsIds && communicationChainsIds.includes(chain.id)
  );

  const menu = (
    <Menu>
      {communicationChains &&
        communicationChains.map(communicationChain => (
          <Menu.Item key={communicationChain?.id}>
            <Text>
              <Link
                href={`/communication-chains/${communicationChain?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {getChainNumber(communicationChain?.clientPhoneNumber)}
              </Link>
            </Text>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      {communicationChainsIds && (
        <Dropdown
          overlay={menu}
          onVisibleChange={handleVisibleChange}
          visible={visible}
          trigger={['hover']}
        >
          <Tooltip title="Перейти в ЦК" placement="top">
            <IconButton icon={<Icon icon={FolderOpenOutlined} />} size="small" />
          </Tooltip>
        </Dropdown>
      )}
    </>
  );
};

export default CommunicationChainsButton;
