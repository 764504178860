import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import React, { useState } from 'react';
import { X } from 'react-feather';
import Lightbox from 'react-awesome-lightbox';
import { findIndex } from 'lodash';
import SRow from 'components/Standard/SRow';
import SButton from 'components/Standard/SButton';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import SText from 'components/Standard/SText';

const UploadedFile = ({ onDelete, uploadedFile, allFiles, style = {} }) => {
  const [visible, setVisible] = useState(false);
  const isCreator = useSelector(state => getCurrentUser(state).id === uploadedFile?.creatorId);
  const show = () => {
    setVisible(true);
  };

  const deleteFile = e => {
    e.stopPropagation();
    onDelete(uploadedFile?.id);
  };

  const onClose = e => {
    e.stopPropagation();
    setVisible(false);
  };

  return (
    <SCol
      background="var(--gray_primary)"
      borderRadius="16px"
      margin="2px 2px"
      padding="6px 12px !important"
      maxWidth="80%"
      border="1px solid #c2c4cc"
      onClick={e => {
        e.stopPropagation();
        show();
      }}
      width="fit-content"
      // style={style}
    >
      <SRow display="flex" justify="space-between" align="middle" wrap={false}>
        <SCol flex="auto" width="100%" className="truncated">
          <SButton
            height="21px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            padding="0"
            type="link"
            fontSize="14px"
            width="100%"
          >
            <SText ellipsis type="primary" color="var(--blue_6)">
              {uploadedFile?.name}
            </SText>
          </SButton>
        </SCol>
        {isCreator && onDelete && (
          <SCol flex="none">
            <SButton
              display="flex"
              alignItems="center"
              justifyContent="end"
              size="icon"
              icon={<Icon icon={X} size="16px" />}
              border="none"
              boxShadow="none"
              color="var(--blue_6)"
              onClick={deleteFile}
              style={{ backgroundColor: 'transparent' }}
            />
          </SCol>
        )}
      </SRow>
      {visible && (
        <Lightbox
          images={allFiles.map(file => ({ title: file.name, url: file.url }))}
          startIndex={findIndex(allFiles, ({ id }) => id === uploadedFile?.id)}
          onClose={onClose}
        />
      )}
    </SCol>
  );
};

export default UploadedFile;
