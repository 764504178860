import api from 'core/api';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  loadDashboardWidgetAnalyticsFailed,
  loadDashboardWidgetAnalyticsStarted,
  loadDashboardWidgetAnalyticsSucceed
} from './reducer';

const updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

export const getDashboardAnalytics = ({ dashboardId }) => {
  return async dispatch => {
    try {
      const response = await api.getDashboardAnalyticsV3({
        dashboard_id: dashboardId,
        updated_at: updatedAt
      });
      return response.body;
    } catch (error) {
      console.log(error);
    }
    return [];
  };
};

let response;

export const getDashboardWidgetAnalytics = ({ id, type }) => {
  return async dispatch => {
    await dispatch(loadDashboardWidgetAnalyticsStarted({ id }));
    try {
      const excetions = [
        'table_reviews_with_scores_avito',
        'table_checklists_scores_by_operators_avito',
        'table_checklists_scores_by_operators_avito_qg',
        'table_reviews_with_scores_avito_qg'
      ];
      if (excetions.includes(type)) {
        response = await api.getDashboardWidgetAnalyticsV3({
          widget_id: id,
          updated_at: updatedAt
        });
      } else {
        response = await api.getDashboardWidgetAnalytics({ id });
      }

      let widgetAnalyticsObject = response.body;

      if (isEmpty(widgetAnalyticsObject)) {
        widgetAnalyticsObject = {};
        widgetAnalyticsObject[id] = {};
      }
      await dispatch(loadDashboardWidgetAnalyticsSucceed(widgetAnalyticsObject));
      return widgetAnalyticsObject;
    } catch (error) {
      console.log(error);
      dispatch(loadDashboardWidgetAnalyticsFailed());
      return [];
    }
  };
};
