import React from 'react';
import { Table } from 'antd';
import SCard from 'components/Standard/SCard';

// Кол-во коммуникаций
const getAmount = (communicationType, clientInteractions) => {
  const communications = clientInteractions.filter(
    item => item?.communicationType === communicationType
  );
  return communications?.length > 0 ? communications.length : 0;
};

// Число с 1 знаком после запятой либо null
const getSlicedValue = value => {
  return value !== null && !isNaN(value) ? Number(value.toFixed(1)) : null;
};

// Отображаем либо теги либо оценку если нет тегов
const removeTagsOrScore = value => {
  if (value.fr !== null || value.cs !== null || value.ss !== null) {
    value.score = null;
    if (value.fr === null) {
      value.fr = 'N/A';
    }
    if (value.cs === null) {
      value.cs = 'N/A';
    }
    if (value.ss === null) {
      value.ss = 'N/A';
    }
  } else {
    if (value.score === null) {
      value.score = 'N/A';
    }
    value.fr = null;
    value.cs = null;
    value.ss = null;
  }

  return value;
};

// Для каждого типа коммуникации получаем значения тегов и заносим в таблицу
const getValues = (communicationType, communication, tableRows, clientInteraction) => {
  if (clientInteraction?.formulaResult?.formulasResult?.[communicationType]) {
    const key = clientInteraction?.formulaResult?.formulasResult?.[communicationType];
    communication.ss = key['навыки продаж'] || null;
    communication.cs = key['консультационные навыки'] || null;
    communication.fr = key['итоговая оценка'] || null;
    if (communication.ss === null) {
      communication.ss = key['оценка "навыки продаж"'] || null;
    }
    if (communication.cs === null) {
      communication.cs = key['оценка "консультационные навыки"'] || null;
    }
    if (
      communication.fr ||
      communication.cs ||
      communication.ss ||
      communication.score ||
      communication.amount > 0
    ) {
      tableRows.push(communication);
    }

    communication.ss = getSlicedValue(communication.ss);
    communication.cs = getSlicedValue(communication.cs);
    communication.fr = getSlicedValue(communication.fr);
    removeTagsOrScore(communication);
  } else if (communication.score || communication.amount > 0) {
    tableRows.push(communication);
  }
};

const ChainTable = ({ clientInteraction, clientInteractions }) => {
  const tableRows = [];

  let tableColumns = [
    {
      title: 'Тип коммуникации',
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: 'Кол-во',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: 'Оценка',
      dataIndex: 'score',
      key: 'score',
      align: 'center'
    },
    {
      title: 'Итоговая оценка',
      dataIndex: 'fr',
      key: 'fr',
      align: 'center'
    },
    {
      title: 'Консультационные навыки',
      dataIndex: 'cs',
      key: 'cs',
      align: 'center'
    },
    {
      title: 'Навыки продаж',
      dataIndex: 'ss',
      key: 'ss',
      align: 'center'
    }
  ];

  // const phoneCall = clientInteraction?.formulaResult?.reviewsResult?.phoneCall;
  // Получем значение для столбца "Оценка"
  const phoneCall = clientInteraction?.scoreResult?.phoneCall;
  const chat = clientInteraction?.scoreResult?.chat;
  const ticket = clientInteraction?.scoreResult?.ticket;
  const other = clientInteraction?.scoreResult?.other;
  const chain = clientInteraction?.scoreResult?.communicationChain;
  const email = clientInteraction?.scoreResult?.email;

  const calls = {
    key: '1',
    type: 'Звонки',
    amount: getAmount('phone_call', clientInteractions),
    score: getSlicedValue(phoneCall),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('phoneCall', calls, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.phoneCall) {
  //   calls.ss = clientInteraction?.formulaResult?.formulasResult?.phoneCall['навыки продаж'] || null;
  //   calls.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.phoneCall['консультационные навыки'] ||
  //     null;
  //   calls.fr =
  //     clientInteraction?.formulaResult?.formulasResult?.phoneCall['итоговая оценка'] || null;
  //   if (calls.ss === null) {
  //     calls.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.phoneCall['оценка "навыки продаж"'] ||
  //       null;
  //   }
  //   if (calls.cs === null) {
  //     calls.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.phoneCall[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   // removeTagsOrScore(calls);
  //   if (calls.fr || calls.cs || calls.ss || calls.score || calls.amount > 0) {
  //     tableRows.push(calls);
  //   }
  //
  //   calls.ss = getSlicedValue(calls.ss);
  //   calls.cs = getSlicedValue(calls.cs);
  //   calls.fr = getSlicedValue(calls.fr);
  //
  //   // if (calls.ss && !isNaN(calls.ss)) {
  //   //   calls.ss = calls.ss.toFixed(1);
  //   // }
  //   // if (calls.cs && !isNaN(calls.cs)) {
  //   //   calls.cs = calls.cs.toFixed(1);
  //   // }
  //   // if (calls.fr && !isNaN(calls.fr)) {
  //   //   calls.fr = calls.fr.toFixed(1);
  //   // }
  // } else if (calls.score || calls.amount > 0) {
  //   tableRows.push(calls);
  // }

  const chats = {
    key: '2',
    type: 'Чаты',
    amount: getAmount('chat', clientInteractions),
    score: getSlicedValue(chat),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('chat', chats, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.chat) {
  //   chats.ss = clientInteraction?.formulaResult?.formulasResult?.chat['навыки продаж'] || null;
  //   chats.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.chat['консультационные навыки'] || null;
  //   chats.fr = clientInteraction?.formulaResult?.formulasResult?.chat['итоговая оценка'] || null;
  //   if (chats.ss === null) {
  //     chats.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.chat['оценка "навыки продаж"'] || null;
  //   }
  //   if (chats.cs === null) {
  //     chats.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.chat[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   removeTagsOrScore(chats);
  //   if (chats.fr || chats.cs || chats.ss || chats.score || chats.amount > 0) {
  //     tableRows.push(chats);
  //   }
  //   if (chats.ss && !isNaN(chats.ss)) {
  //     chats.ss = chats.ss.toFixed(1);
  //   }
  //   if (chats.cs && !isNaN(chats.cs)) {
  //     chats.cs = chats.cs.toFixed(1);
  //   }
  //   if (chats.fr && !isNaN(chats.fr)) {
  //     chats.fr = chats.fr.toFixed(1);
  //   }
  // } else if (chats.score || chats.amount > 0) {
  //   tableRows.push(chats);
  // }

  const tickets = {
    key: '3',
    type: 'Тикеты',
    amount: getAmount('ticket', clientInteractions),
    score: getSlicedValue(ticket),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('ticket', tickets, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.ticket) {
  //   tickets.ss = clientInteraction?.formulaResult?.formulasResult?.ticket['навыки продаж'] || null;
  //   tickets.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.ticket['консультационные навыки'] || null;
  //   tickets.fr =
  //     clientInteraction?.formulaResult?.formulasResult?.ticket['итоговая оценка'] || null;
  //   if (tickets.ss === null) {
  //     tickets.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.ticket['оценка "навыки продаж"'] || null;
  //   }
  //   if (tickets.cs === null) {
  //     tickets.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.ticket[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   if (tickets.fr || tickets.cs || tickets.ss || tickets.score || tickets.amount > 0) {
  //     tableRows.push(tickets);
  //   }
  //   if (tickets.ss && !isNaN(tickets.ss)) {
  //     tickets.ss = tickets.ss.toFixed(1);
  //   }
  //   if (tickets.cs && !isNaN(tickets.cs)) {
  //     tickets.cs = tickets.cs.toFixed(1);
  //   }
  //   if (tickets.fr && !isNaN(tickets.fr)) {
  //     tickets.fr = tickets.fr.toFixed(1);
  //   }
  // } else if (tickets.score || tickets.amount > 0) {
  //   tableRows.push(tickets);
  // }

  const others = {
    key: '4',
    type: 'Другое',
    amount: getAmount('other', clientInteractions),
    score: getSlicedValue(other),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('other', others, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.other) {
  //   others.ss = clientInteraction?.formulaResult?.formulasResult?.other['навыки продаж'] || null;
  //   others.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.other['консультационные навыки'] || null;
  //   others.fr = clientInteraction?.formulaResult?.formulasResult?.other['итоговая оценка'] || null;
  //   if (others.ss === null) {
  //     others.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.other['оценка "навыки продаж"'] || null;
  //   }
  //   if (others.cs === null) {
  //     others.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.other[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   if (others.fr || others.cs || others.ss || others.score || others.amount > 0) {
  //     tableRows.push(others);
  //   }
  //   if (others.ss && !isNaN(others.ss)) {
  //     others.ss = others.ss.toFixed(1);
  //   }
  //   if (others.cs && !isNaN(others.cs)) {
  //     others.cs = others.cs.toFixed(1);
  //   }
  //   if (others.fr && !isNaN(others.fr)) {
  //     others.fr = others.fr.toFixed(1);
  //   }
  // } else if (others.score || others.amount > 0) {
  //   tableRows.push(others);
  // }

  const chains = {
    key: '5',
    type: 'Цепочки',
    amount: getAmount('communication_chain', clientInteractions),
    score: getSlicedValue(chain),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('communicationChain', chains, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.communicationChain) {
  //   chains.ss =
  //     clientInteraction?.formulaResult?.formulasResult?.communicationChain['навыки продаж'] || null;
  //   chains.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.communicationChain[
  //       'консультационные навыки'
  //     ] || null;
  //   chains.fr =
  //     clientInteraction?.formulaResult?.formulasResult?.communicationChain['итоговая оценка'] ||
  //     null;
  //   if (chains.ss === null) {
  //     chains.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.communicationChain[
  //         'оценка "навыки продаж"'
  //       ] || null;
  //   }
  //   if (chains.cs === null) {
  //     chains.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.communicationChain[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   if (chains.fr || chains.cs || chains.ss || chains.score || chains.amount > 0) {
  //     tableRows.push(chains);
  //   }
  //
  //   if (chains.ss && !isNaN(chains.ss)) {
  //     chains.ss = chains.ss.toFixed(1);
  //   }
  //   if (chains.cs && !isNaN(chains.cs)) {
  //     chains.cs = chains.cs.toFixed(1);
  //   }
  //   if (chains.fr && !isNaN(chains.fr)) {
  //     chains.fr = chains.fr.toFixed(1);
  //   }
  // } else if (chains.score || chains.amount > 0) {
  //   tableRows.push(chains);
  // }

  const emails = {
    key: '6',
    type: 'Письма',
    amount: getAmount('email', clientInteractions),
    score: getSlicedValue(email),
    fr: null,
    cs: null,
    ss: null
  };

  getValues('email', emails, tableRows, clientInteraction);

  // if (clientInteraction?.formulaResult?.formulasResult?.email) {
  //   emails.ss = clientInteraction?.formulaResult?.formulasResult?.email['навыки продаж'] || null;
  //   emails.cs =
  //     clientInteraction?.formulaResult?.formulasResult?.email['консультационные навыки'] || null;
  //   emails.fr = clientInteraction?.formulaResult?.formulasResult?.email['итоговая оценка'] || null;
  //   if (emails.ss === null) {
  //     emails.ss =
  //       clientInteraction?.formulaResult?.formulasResult?.email['оценка "навыки продаж"'] || null;
  //   }
  //   if (emails.cs === null) {
  //     emails.cs =
  //       clientInteraction?.formulaResult?.formulasResult?.email[
  //         'оценка "консультационные навыки"'
  //       ] || null;
  //   }
  //   if (emails.fr || emails.cs || emails.ss || emails.score || emails.amount > 0) {
  //     tableRows.push(emails);
  //   }
  //
  //   if (emails.ss && !isNaN(emails.ss)) {
  //     emails.ss = emails.ss.toFixed(1);
  //   }
  //   if (emails.cs && !isNaN(emails.cs)) {
  //     emails.cs = emails.cs.toFixed(1);
  //   }
  //   if (emails.fr && !isNaN(emails.fr)) {
  //     emails.fr = emails.fr.toFixed(1);
  //   }
  // } else if (emails.score || emails.amount > 0) {
  //   tableRows.push(emails);
  // }

  if (tableRows.every(item => item.score === null)) {
    tableColumns = tableColumns.filter(column => column.key !== 'score');
  }
  if (tableRows.every(item => item.fr === null)) {
    tableColumns = tableColumns.filter(column => column.key !== 'fr');
  }
  if (tableRows.every(item => item.ss === null)) {
    tableColumns = tableColumns.filter(column => column.key !== 'ss');
  }
  if (tableRows.every(item => item.cs === null)) {
    tableColumns = tableColumns.filter(column => column.key !== 'cs');
  }

  return (
    tableRows.length > 0 && (
      <SCard
        bordered="1px solid #e4e6ed"
        shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
        headPadding="8px 24px"
        rounded="8px"
        marginBottom="10px"
      >
        <Table columns={tableColumns} dataSource={tableRows} pagination={false} size="small" />
      </SCard>
    )
  );
};

export default ChainTable;
