import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import PhoneCallInformation from './PhoneCallInformation';
import RecordPlayer from './RecordPlayer';

const PhoneCallPlayer = ({
  call,
  review,
  comments,
  messages,
  onMessageClick,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true
}) => {
  // console.group('PhoneCallPlayer');
  const [visiblePlayear, setvisiblePlayear] = useState(true);

  useEffect(() => {
    window.changeVisiblePlayear = newVisiblePlayear => {
      setvisiblePlayear(newVisiblePlayear);
    };
  }, []);
  // console.log('inputData', {
  //   call,
  //   review,
  //   comments,
  //   messages,
  //   onMessageClick,
  //   allowAttachTags,
  //   showTags,
  //   shouldLoad
  // });
  // console.groupEnd();
  return (
    <SCard loading={!call && !comments && !review} bordered shadowed>
      <PhoneCallInformation call={call} />
      {visiblePlayear && (
        <RecordPlayer
          comments={comments}
          call={call}
          review={review}
          allowAttachTags={allowAttachTags}
          showTags={showTags}
          shouldLoad={shouldLoad}
          messages={messages}
          onMessageClick={onMessageClick}
        />
      )}
    </SCard>
  );
};

export default React.memo(PhoneCallPlayer);
