import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { verifyCredentials } from 'redux/reducers/reduxTokenAuthReducer';
import rootReducer from 'redux/reducers/rootReducer';
import { getCurrentUser } from 'redux/selectors/users';

export const configureStore = ({ initialState }) => {
  const middlewares = [thunk];

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  verifyCredentials(store);

  return store;
};
