import { get, put, destroy, post } from 'redux-bees';

const checklistFormulasEndpoints = apiPath => ({
  createChecklistFormula: { method: post, path: `${apiPath}/checklist/formulas` },
  getChecklistFormula: { method: get, path: `${apiPath}/checklist/formulas` },
  getChecklistFormulasV2: { method: get, path: `/api/v2/checklist/formulas` },
  updateChecklistFormula: { method: put, path: `${apiPath}/checklist/formulas/:id` },
  deleteChecklistFormula: {
    method: destroy,
    path: `${apiPath}/checklist/formulas/:id`
  }
});

export default checklistFormulasEndpoints;
