import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';
import { find } from 'lodash';

export const questionToGroupSubgroupBindingsResource = createReduxResource({
  name: 'questionToGroupSubgroupBindings',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const questionGroup = state.questionGroupsResource.byIds[resource.questionGroupId];
      const question = state.questionsResource.byIds[resource.questionId];

      if (questionGroup)
        updateResource({
          dispatch,
          type: 'checklistQuestionGroups',
          resource: {
            ...questionGroup,
            questionToGroupBindingsIds: [...questionGroup.questionToGroupBindingsIds, resource.id]
          }
        });

      if (question)
        updateResource({
          dispatch,
          type: 'checklistQuestions',
          resource: {
            ...question,
            questionToGroupBindingsIds: [...question.questionToGroupBindingsIds, resource.id]
          }
        });
    },
    onDeleteByIdSucceed: ({ id, dispatch, getState }) => {
      const state = getState();

      const questionGroup = find(
        state.questionGroupsResource.byIds,
        ({ questionToGroupBindingsIds }) => questionToGroupBindingsIds.includes(id)
      );
      const question = find(state.questionsResource.byIds, ({ questionToGroupBindingsIds }) =>
        questionToGroupBindingsIds.includes(id)
      );

      if (questionGroup)
        updateResource({
          dispatch,
          type: 'checklistQuestionGroups',
          resource: {
            ...questionGroup,
            questionToGroupBindingsIds: [...questionGroup.questionToGroupBindingsIds].filter(
              bindingId => id !== bindingId
            )
          }
        });

      if (question)
        updateResource({
          dispatch,
          type: 'checklistQuestions',
          resource: {
            ...question,
            questionToGroupBindingsIds: [...question.questionToGroupBindingsIds].filter(
              bindingId => id !== bindingId
            )
          }
        });
    }
  }
});
