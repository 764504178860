import { Button, Col, Form, Input, Row, Typography } from 'antd';
import Chatra from "@chatra/chatra";
import carouselOne from 'assets/images/carousel/img1s.png';
import carouselTwo from 'assets/images/carousel/img2s.png';
import carouselThree from 'assets/images/carousel/img3s.png';
import carouselFour from 'assets/images/carousel/img4s.png';
import SCard from 'components/Standard/SCard';
import AmoService from 'core/services/amocrm';
import { handleError } from 'core/services/errors';
import { AMO_COMMANDS } from 'core/utils/constants';
import { isBoxVersion } from 'core/config';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { signInUser } from 'redux/reducers/reduxTokenAuthReducer';
import { getCurrentUser } from 'redux/selectors/users';
import { useIsQiwiDomain } from 'core/utils/hooks';
import {
  AuthLogo,
  FormContainer,
  LinkToLandingPage,
  SlideImage,
  SlideText,
  SlideTitle,
  StyledCarousel
} from '../styled';

const { Title } = Typography;

const LoginForm = ({ onLoginSubmit, loading }) => {
  const { t } = useTranslation();
  const isQiwiDomain = useIsQiwiDomain();

  return (
    <Form hideRequiredMark name="login" onFinish={onLoginSubmit}>
      {isBoxVersion ? (
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('loginPage.form.messages.enterEmail') }]}
          validateTrigger={['onBlur']}
        >
          <Input placeholder={t('loginPage.form.fields.emailPlaceholder')} />
        </Form.Item>
      ) : (
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('loginPage.form.messages.enterEmail') },
            { type: 'email', message: t('loginPage.form.messages.wrongEmail') }
          ]}
          validateTrigger={['onBlur']}
        >
          <Input placeholder={t('loginPage.form.fields.emailPlaceholder')} type="email" />
        </Form.Item>
      )}
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('loginPage.form.messages.enterPassword') },
          { min: 6, message: t('loginPage.form.messages.minLengthPassword') }
        ]}
        validateTrigger={['onBlur']}
      >
        <Input type="password" placeholder={t('loginPage.form.fields.passwordPlaceholder')} />
      </Form.Item>
      <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
        <Col span={24}>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {t('loginPage.form.buttons.login')}
          </Button>
        </Col>
        {!isQiwiDomain && (
          <Col span={24}>
            <Row type="flex" align="end">
              <Col>
                <Link to="/recover-password">{t('loginPage.form.buttons.forgotPassword')}</Link>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Form>
  );
};

const LoginPage = ({ history, signInUser, loading, user }) => {
  const onLoginSubmit = async values => {
    const { email, password } = values;
    try {
      document.getElementById('chatra__iframe-wrapper').remove();
      document.getElementById('chatra').remove();
      await signInUser({ email, password });
      window.ChatraSetup = {
        clientId: email,
        customWidgetButton: '.custom-chat-button'
      };
      Chatra('restart');
      history.push('/');
    } catch (err) {
      console.log(err);
      handleError(err?.response?.status, err?.response?.data);
    }
  };

  useEffect(() => {
    return () => {
      AmoService.sendMessage({
        command: AMO_COMMANDS.SEND_USER_DATA,
        params: {
          dealappUser: {
            ...user,
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid'),
            expiry: localStorage.getItem('expiry')
          }
        }
      });
    };
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.loginPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Col span={12}>
        <Row
          type="flex"
          gutter={[32, 32]}
          align="middle"
          justify="center"
          style={{ height: '100%' }}
        >
          <Col span={20}>
            <StyledCarousel autoplay dotsClass="blue-dots">
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('loginPage.layout.slides.efficiency.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselOne} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('loginPage.layout.slides.efficiency.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('loginPage.layout.slides.motivation.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselTwo} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('loginPage.layout.slides.motivation.description')}
                  </SlideText>
                </Col>
              </Row>
              {/*  */}
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('loginPage.layout.slides.simplicity.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselThree} />
                </Col>
                <Col span={24}>
                  <SlideText strong>
                    {t('loginPage.layout.slides.simplicity.description')}
                  </SlideText>
                </Col>
              </Row>
              <Row type="flex" align="middle" gutter={[16, 16]}>
                <Col span={24}>
                  <SlideTitle>{t('loginPage.layout.slides.analytics.title')}</SlideTitle>
                </Col>
                <Col span={24}>
                  <SlideImage src={carouselFour} />
                </Col>
                <Col span={24}>
                  <SlideText strong>{t('loginPage.layout.slides.analytics.description')}</SlideText>
                </Col>
              </Row>
            </StyledCarousel>
          </Col>
        </Row>
      </Col>

      <FormContainer span={12}>
        <SCard shadowed>
          <Row justify="space-between" type="flex" align="middle">
            <Col flex="auto">
              <Title level={3}>{t('loginPage.form.title')}</Title>
            </Col>
            <Col>
              <Link to="/register">{t('loginPage.form.buttons.registration')}</Link>
            </Col>
          </Row>

          <LoginForm onLoginSubmit={onLoginSubmit} loading={loading} />
        </SCard>
      </FormContainer>

      <LinkToLandingPage />
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.reduxTokenAuth.currentUser.isLoading,
  user: getCurrentUser(state)
});

const mapDispatchToProps = {
  signInUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
