import camelcase from 'camelcase';
import { assignChildrenToParents } from 'core/utils';
import {
  difference,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
  mapValues,
  omit,
  some
} from 'lodash';
import { phoneCallsResource } from 'redux/resources/calls';
import { checklistsResource } from 'redux/resources/checklist';
import { commentsResource } from 'redux/resources/comments';
import { reviewsResource } from 'redux/resources/reviews';
import { tasksResource } from 'redux/resources/tasks';
import { getReviewTaskDefinitionsIds } from 'redux/selectors/taskDefinitions';
import * as checklistManagerActions from 'redux/ui/checklistManager/reducer';
import { addTasks } from 'redux/ui/clientInteractionPage/reducer';
import { Modal } from 'antd';
import { calculateFormulas } from 'core/api';
import { updateTableRow } from '../clientInteractions/operations';
import * as actions from './reducer';
import { appellationsResource } from '../../resources/appellations';
import createReviewWithNestedAttributes from './createReviewWithNestedAttributes';

export const mapReviewToUiReducer = ({ state, review }) => {
  const getChecklistWithAnswers = ({ state, checklistId }) => {
    const checklist = state.checklistsResource.byIds[checklistId];

    if (!isEmpty(checklist)) {
      const questionIdToAnswerValue = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'value'
      );

      const questionIdToStandardComment = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'standardComments'
      );

      return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
    }

    return { checklist: {}, questionIdToAnswerValue: {}, questionIdToStandardComment: {} };
  };

  const {
    checklist,
    questionIdToAnswerValue,
    questionIdToStandardComment
  } = getChecklistWithAnswers({
    state,
    checklistId: review.checklistId
  });
  return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
};

const createCommentFromUi = commentFromUi => async dispatch => {
  const comment = await dispatch(commentsResource.operations.create(commentFromUi));
  await dispatch(actions.deleteComment(commentFromUi));
  await dispatch(actions.addComment(comment));
};

const isCommentsEqual = (realComment, uiComment) => {
  if (realComment.commentType === 'review_comment')
    return isEqual(
      omit(realComment, ['createdAt', 'updatedAt']),
      omit(uiComment, ['createdAt', 'updatedAt'])
    );

  // * exclude position comparison for other comments
  return isEqual(
    omit(realComment, ['position', 'createdAt', 'updatedAt']),
    omit(uiComment, ['position', 'createdAt', 'updatedAt'])
  );
};

export const updateComments = ({ reviewId, commentsByIds }) => async (dispatch, getState) => {
  const state = getState();
  const review = state.reviewsResource.byIds[reviewId];

  const oldComments = review.commentsIds.reduce(
    (acc, id) =>
      state.commentsResource.byIds[id] ? [...acc, state.commentsResource.byIds[id]] : acc,
    []
  );
  const newCommentsByIds = { ...commentsByIds };

  // * running all requests in parallel
  await Promise.all([
    ...oldComments.map(comment => {
      // * delete old comments that deleted on ui
      if (isEmpty(newCommentsByIds[comment.id])) {
        delete newCommentsByIds[comment.id];
        return dispatch(commentsResource.operations.deleteById({ id: comment.id }));
      }
      // * update old comments that updated on ui
      const newComment = { ...comment, ...newCommentsByIds[comment.id] };
      delete newCommentsByIds[comment.id];
      // * check if comment needs update
      if (!isCommentsEqual(comment, newComment)) {
        return dispatch(commentsResource.operations.updateById(omit(newComment, 'ratingFlag')));
      }
      return null;
    }),
    // * create new comments (should wokr only for Review Comments, replies are sent to BE instantly)
    ...Object.values(newCommentsByIds)
      .filter(comment => comment?.commentType === 'review_comment')
      .map(comment => dispatch(createCommentFromUi({ ...comment, reviewId })))
  ]);
};

export const createTasks = ({ reviewId }) => async (dispatch, getState) => {
  const state = getState();
  const tasksByIds = state.tasksResource.byIds;
  const currentReviewTasks = state.reviewsResource.byIds[reviewId]?.tasksIds;
  const currentReviewTaskDefifnitions = currentReviewTasks.map(
    task => tasksByIds[task].taskDefinitionId
  );
  const uiTaskDefinitionsIds = get(state.uiClientInteractionPage, 'tasks.taskDefinitionsIds', []);
  const taskDefinitionsToDelete = difference(currentReviewTaskDefifnitions, uiTaskDefinitionsIds);

  const tasksToDelete = taskDefinitionsToDelete.map(
    taskDefinition => find(tasksByIds, { reviewId, taskDefinitionId: taskDefinition }).id
  );
  const tasksDefinitionsToCreate = uiTaskDefinitionsIds.filter(
    taskDefinitionId => !currentReviewTaskDefifnitions.includes(taskDefinitionId)
  );

  if (!isEmpty(tasksToDelete)) {
    await Promise.all([
      tasksToDelete.map(taskId => dispatch(tasksResource.operations.deleteById({ id: taskId })))
    ]);
  }

  if (!isEmpty(tasksDefinitionsToCreate)) {
    await Promise.all([
      tasksDefinitionsToCreate.map(taskDefinitionId =>
        dispatch(tasksResource.operations.create({ reviewId, taskDefinitionId }))
      )
    ]);
  }
};

export const loadReviewById = ({ id, ...params }) => {
  return async (dispatch, getState) => {
    await dispatch(actions.setLoading(true));
    const review = await dispatch(reviewsResource.operations.loadById({ id, ...params }));

    if (!review) {
      return;
    }

    const state = getState();
    const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
    dispatch(actions.setContentType(camelcase(clientInteraction.clientInteractionType)));
    dispatch(checklistManagerActions.setReviewState(mapReviewToUiReducer({ state, review })));
    const reviewComments = keyBy(
      review.commentsIds.map(id => state.commentsResource.byIds[id]),
      'id'
    );

    dispatch(actions.setOperatorId(review?.operatorId || clientInteraction?.operatorId));
    dispatch(actions.setComments(assignChildrenToParents({ nodesByIds: reviewComments })));
    dispatch(actions.setLoading(false));
    return review;
  };
};

export const loadAppealReviewById = ({ id, ...params }) => {
  return async (dispatch, getState) => {
    await dispatch(actions.setLoading(true));
    const appellation = await dispatch(appellationsResource.operations.loadById({ id, ...params }));

    if (!appellation) {
      return;
    }

    const state = getState();

    const review = state.reviewsResource.byIds[appellation.reviewId];
    const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
    dispatch(actions.setContentType(camelcase(clientInteraction.clientInteractionType)));
    dispatch(checklistManagerActions.setReviewState(mapReviewToUiReducer({ state, review })));
    const reviewComments = keyBy(
      [...review.commentsIds, ...appellation.commentsIds].map(
        id => state.commentsResource.byIds[id]
      ),
      'id'
    );

    dispatch(actions.setOperatorId(appellation?.operatorId || clientInteraction?.operatorId));
    dispatch(actions.setComments(assignChildrenToParents({ nodesByIds: reviewComments })));
    dispatch(actions.setLoading(false));
    return review;
  };
};

export const calculateFormulasV3 = async (id, formQuestions, formulasData) => {
  const formulasRequest = {};
  formulasRequest.review_id = id;
  formulasRequest.action = 'formula';
  formulasRequest.formula_data = formulasData;
  formulasRequest.data = formQuestions;
  const requestBody = JSON.stringify(formulasRequest);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: requestBody
  };

  return fetch(calculateFormulas, requestOptions).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });
};

const getQuestionAnswers = (
  questionIdToAnswerValue,
  questionIdToStandardComment,
  questionsWithValuesAndBindingsGroupsSubgroups
) => {
  const answers = Object.keys(questionIdToAnswerValue).reduce((result, questionId) => {
    const value = questionIdToAnswerValue[questionId];
    const standardComments = questionIdToStandardComment[questionId];

    if (isNil(value)) {
      return result;
    }

    return [...result, { questionId, value, standardComments }];
  }, []);

  // Добавляем критерии без балла в массив answers
  questionsWithValuesAndBindingsGroupsSubgroups.forEach(question => {
    if (answers.find(item => item.questionId === question.id)) {
      return;
    }
    answers.push({
      questionId: question.id,
      standardComments:
        Object.values(question.standardComments).length === 0
          ? undefined
          : question.standardComments,
      value: null
    });
  });

  return answers;
};

export const submitReview = ({
  id,
  review,
  fromDrawer = false,
  customCommunicationAttributes,
  clientInteraction = false,
  questionsWithValuesAndBindingsGroupsSubgroups,
  formQuestions,
  formulasData,
  paramsId = null
}) => {
  if (customCommunicationAttributes?.operatorId) {
    localStorage.setItem('operid', customCommunicationAttributes.operatorId);
  }
  const clientInteractionId = clientInteraction?.id;

  // * createReview
  if (isEmpty(review)) {
    return async (dispatch, getState) => {
      const state = getState();
      const {
        currentChecklist,
        questionIdToAnswerValue,
        questionIdToStandardComment
      } = state.uiChecklistManager;

      const createdReview = await dispatch(
        createReviewWithNestedAttributes({
          clientInteractionId: clientInteractionId ?? id,
          customCommunicationAttributes,
          questionsWithValuesAndBindingsGroupsSubgroups,
          currentCalibrationSessionId: paramsId
        })
      );

      const reviewId = createdReview?.body?.data?.id;
      const currentChecklistId = createdReview?.body?.data?.relationships?.checklist?.data?.id;

      const formulasResponse = await calculateFormulasV3(reviewId, formQuestions, formulasData);

      const answers = getQuestionAnswers(
        questionIdToAnswerValue,
        questionIdToStandardComment,
        questionsWithValuesAndBindingsGroupsSubgroups
      );

      await dispatch(
        checklistsResource.operations.updateById({
          id: currentChecklistId,
          checklistDefinitionId: currentChecklist?.checklistDefinitionId,
          answers,
          comment: currentChecklist?.comment,
          metadata: currentChecklist?.metadata || {}
        })
      );

      localStorage.setItem('formulas_', JSON.stringify(formulasResponse));

      if (get(createdReview, 'body.data.type') === 'reviews') {
        // const createdReviewId = get(createdReview, 'body.data.id');
        return createdReview;
      }
    };
  }

  // * updateReview
  if (!isEmpty(review)) {
    return async (dispatch, getState) => {
      const reviewId = id;
      let state = getState();
      const {
        currentChecklist,
        questionIdToAnswerValue,
        questionIdToStandardComment
      } = state.uiChecklistManager;
      const { commentsByIds } = state.uiClientInteractionPage;

      const answers = getQuestionAnswers(
        questionIdToAnswerValue,
        questionIdToStandardComment,
        questionsWithValuesAndBindingsGroupsSubgroups
      );

      const checklist = currentChecklist
        ? {
            id: currentChecklist.id,
            checklistDefinitionId: currentChecklist.checklistDefinitionId,
            answers,
            comment: currentChecklist.comment,
            metadata: currentChecklist.metadata || {}
          }
        : {};

      await dispatch(updateComments({ commentsByIds, reviewId }));

      await dispatch(createTasks({ reviewId }));

      const shouldCreateChecklist =
        checklist.checklistDefinitionId &&
        !isEmpty(questionIdToAnswerValue) &&
        some(questionIdToAnswerValue, value => !isNil(value));

      let newChecklist = {};

      if (shouldCreateChecklist) {
        state = getState();

        newChecklist = await dispatch(
          checklistsResource.operations.updateById({
            ...checklist,
            id: checklist.id || state.reviewsResource.byIds[reviewId].checklistId
          })
        );

        state = getState();
        const reviewTaskDefinitionsIds = getReviewTaskDefinitionsIds(state, reviewId);
        await dispatch(addTasks({ reviewId, taskDefinitionsIds: reviewTaskDefinitionsIds }));
      }

      await dispatch(checklistManagerActions.setReviewState({ checklist: newChecklist }));

      fromDrawer &&
        (await dispatch(
          updateTableRow({
            clientInteractionId: review?.clientInteractionId,
            review
          })
        ));

      const tempReview = { ...review };

      tempReview.cs = answers;
      Modal.destroyAll();
      return tempReview;
    };
  }
};

export const loadCallById = ({ id, fromDrawer, ...params }) => async dispatch => {
  dispatch(actions.setLoading(true));
  dispatch(checklistManagerActions.setLoading(true));

  const call = await dispatch(phoneCallsResource.operations.loadById({ id, ...params }));
  dispatch(actions.setOperatorId(call?.operatorId));

  dispatch(actions.setLoading(false));
  dispatch(checklistManagerActions.setLoading(false));

  return call;
};

export const loadChainById = ({ id, fromDrawer, ...params }) => async dispatch => {
  dispatch(actions.setLoading(true));
  dispatch(checklistManagerActions.setLoading(true));
  const modifiedParams = { ...params, chain: true };
  const chain = await dispatch(phoneCallsResource.operations.loadById({ id, ...modifiedParams }));
  dispatch(actions.setOperatorId(chain?.operatorId));

  dispatch(actions.setLoading(false));
  dispatch(checklistManagerActions.setLoading(false));

  return chain;
};

export const updateReplyComment = ({ id, ...params }) => async dispatch => {
  const comment = await dispatch(commentsResource.operations.updateById({ id, ...params }));
  await dispatch(actions.updateComment(comment));
  return comment;
};

export const deleteReplyComment = ({ id }) => async (dispatch, getState) => {
  await dispatch(commentsResource.operations.deleteById({ id }));
  const state = getState();
  const comment = get(state.uiClientInteractionPage.commentsByIds, id, {});
  const parentComment = state.uiClientInteractionPage.commentsByIds[comment.parentId];
  if (parentComment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds.filter(relationId => relationId !== id)]
      })
    );
  await dispatch(actions.deleteComment({ id }));
};

export const createReplyComment = params => async (dispatch, getState) => {
  const state = getState();
  const parentComment = state.uiClientInteractionPage.commentsByIds[params.id];
  const questionId = get(parentComment, ['metadata', 'questionId']);
  const comment = await dispatch(
    commentsResource.operations.createCommentReply({
      ...params,
      ...(questionId && { metadata: { questionId } }),
      parentComment
    })
  );

  if (comment) {
    dispatch(actions.addComment(comment));
  }

  if (parentComment && comment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds, comment.id]
      })
    );
  return comment;
};
