import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Upload, message, Button, Alert, Row, Col } from 'antd';
import styled from 'styled-components';
import { callUploadEndpoint } from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import axios from 'axios';
import { operations } from 'redux/lists/uploadedCallsListReducer';
import { phoneCallsResource } from 'redux/resources/calls';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import UploadedCallsTable from './UploadedCallsTable';

const UploadCallsPage = ({ onUploadCall, loadCalls, listPage }) => {
  const [filesList, setFilesList] = useState([]);
  const { t } = useTranslation();

  const handleChange = info => {
    let { fileList } = info;

    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        return { ...file, url: file.response.url };
      }
      return file;
    });

    // * Filter successfully uploaded files according to response from server
    fileList = fileList.filter(file => file.status !== 'done');

    setFilesList(fileList);
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data'
    };

    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };

    const body = new FormData();
    body.append('record', file);

    try {
      const response = await axios.post(callUploadEndpoint, body, { headers, onUploadProgress });
      message.success(`${file.name} ${t('uploadCallsPage.messages.successfullyLoaded')}`);
      const call = processJsonApiObject(response.data.data);
      onSuccess(response.data);
      onUploadCall(call);
      loadCalls({ page: listPage, include: 'operator' });
    } catch (error) {
      onError(error);
      console.log(error);
      message.error(`${t('uploadCallsPage.messages.loadFailed')} ${file.name}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.uploadCallsPage.title')}</title>
      </Helmet>
      <Row type="flex" justify="start" gutter={[16, 16]}>
        <Col>
          <Upload
            showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
            multiple
            onChange={handleChange}
            fileList={filesList}
            name="file"
            customRequest={customRequest}
            accept=".mp3,.wav"
          >
            <Button type="primary">{t('uploadCallsPage.buttons.loadCalls')}</Button>
          </Upload>
        </Col>
        <Col>
          <StyledAlert message={t('uploadCallsPage.messages.fileFormat')} type="info" />
        </Col>
      </Row>
      <TableContainer>
        <UploadedCallsTable />
      </TableContainer>
    </>
  );
};

const StyledAlert = styled(Alert)`
  &.ant-alert {
    padding: 5px 15px !important;
    margin-bottom: 20px;
  }
`;

const TableContainer = styled.div``;

const mapStateToProps = (state, ownProps) => {
  return {
    users: Object.values(state.usersResource.byIds),
    listPage: state.uploadedCallsList.page,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadCall: callData => dispatch(phoneCallsResource.actions.loadByIdSucceed(callData)),
    loadCalls: params => dispatch(operations.load(params))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadCallsPage));
