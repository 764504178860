import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, message, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import Text from 'antd/lib/typography/Text';
import { CHECKLIST_MANAGER_STATES } from '../core/utils/constants';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

const CopyLinkButton = ({
  reviewId,
  destinationId,
  destinationType,
  iconStyle = {},
  icon = null
}) => {
  const [visible, setVisible] = useState(false);
  const [reviewID, setReviewId] = useState(reviewId);
  const { t } = useTranslation();
  const handleVisibleChange = flag => {
    setVisible(flag);
  };
  const { checklistManagerState } = useSelector(state => state.uiChecklistManager, isEqual);

  useEffect(() => {
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
      const reviewIdInLocalStorage = localStorage.getItem('communicationLink');
      if (reviewIdInLocalStorage) {
        const { reviews, id } = JSON.parse(reviewIdInLocalStorage);
        if (id === destinationId) {
          setReviewId(reviews);
        }
      }
    }
  }, [checklistManagerState, destinationId]);

  const onCopy = () => {
    message.success(t('components.recordPlayer.copyLinkSuccess'));
    setVisible(false);
  };

  const menu = (
    <Menu>
      {reviewID && (
        <Menu.Item key="1">
          <CopyToClipboard text={`${window.location.origin}/reviews/${reviewID}`} onCopy={onCopy}>
            <Text>{t('components.recordPlayer.copyReview')}</Text>
          </CopyToClipboard>
        </Menu.Item>
      )}
      {destinationId && destinationType && (
        <Menu.Item key="1">
          <CopyToClipboard
            text={`${window.location.origin}/${destinationType}/${destinationId}`}
            onCopy={onCopy}
          >
            <Text>{t('components.recordPlayer.copyCommunication')}</Text>
          </CopyToClipboard>
        </Menu.Item>
      )}
    </Menu>
  );
  const IconComponent = icon || LinkOutlined;
  return (
    <>
      {reviewID ? (
        <Dropdown
          overlay={menu}
          onVisibleChange={handleVisibleChange}
          visible={visible}
          trigger={['hover']}
        >
          <Tooltip title={t('components.recordPlayer.copyLink')} placement="top">
            <IconButton icon={<Icon icon={IconComponent} {...iconStyle} />} size="small" />
          </Tooltip>
        </Dropdown>
      ) : (
        <CopyToClipboard
          text={`${window.location.origin}/${destinationType}/${destinationId}`}
          onCopy={onCopy}
        >
          <Tooltip title={t('components.recordPlayer.copyLink')} placement="top">
            <IconButton icon={<Icon icon={IconComponent} {...iconStyle} />} size="small" />
          </Tooltip>
        </CopyToClipboard>
      )}
    </>
  );
};

export default CopyLinkButton;
