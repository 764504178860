import { Col, Dropdown, Icon, Menu, Row, Typography, Tooltip, Switch } from 'antd';
import IconButton from 'components/IconButton';
import { ExternalLink } from 'react-feather';
import CommunicationInfoModal from 'components/CommunicationInfoModal';
import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { isEmpty, uniq } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CopyLinkButton from 'components/CopyLinkButton';
import { CLIENT_INTERACTIONS_TYPES_LITERALS } from 'core/utils/constants';
import SRow from 'components/Standard/SRow';
import DOMPurify from 'dompurify';

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ConversationInfo = ({
  loading,
  textCommunication,
  reviewId,
  shouldLoad,
  setOperatorId,
  canChangeOperator,
  selectedOperatorId,
  parts,
  showSystemEvents,
  setShowSystemEvents
}) => {
  const {
    operatorId,
    startedAt,
    communicationId,
    communicationType,
    email,
    externalUrl
  } = textCommunication;

  const operatorsIds = uniq(
    parts.reduce((acc, part) => {
      if (!part?.operatorId) return acc;
      return [...acc, part?.operatorId];
    }, [])
  );
  const handleSelectOperator = ({ key }) => setOperatorId(key);
  const showOperatorsMenu = operatorsIds.length > 1 && canChangeOperator;

  const menu = (
    <Menu onClick={handleSelectOperator} selectedKeys={[selectedOperatorId || operatorId]}>
      {operatorsIds.map(id => (
        <Menu.Item key={id}>
          <span>
            <UserPreview disabled showAvatar userId={id} />
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const { Text } = Typography;
  const { t } = useTranslation();

  const textCommunicationTitle = DOMPurify.sanitize(textCommunication.title, {
    USE_PROFILES: { html: false }
  });

  return (
    <SCard
      loading={loading || isEmpty(textCommunication)}
      bodyPadding="14px 16px 13px 16px"
      style={{ marginBottom: 4 }}
    >
      <Row type="flex" align="middle" justify="flex-between">
        <Col style={{ marginTop: 6, flex: 'auto' }}>
          <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
            <Col>
              {showOperatorsMenu ? (
                <Dropdown overlay={menu} trigger={['click']}>
                  <DropdownContainer>
                    <UserPreview
                      userId={selectedOperatorId || operatorId}
                      showAvatar
                      disabled
                      margin="1px 4px 2px 0"
                    />

                    <Icon type="down" />
                  </DropdownContainer>
                </Dropdown>
              ) : (
                <UserPreview
                  userId={selectedOperatorId || operatorId}
                  showAvatar
                  showUnit
                  disabled
                  margin="1px 0 2px 0"
                />
              )}
            </Col>
          </Row>
          <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
            <Col style={{ paddingTop: 2 }}>
              <Text style={{ marginRight: 8 }}>
                <Text strong>{t('components.textCommunicationContent.conversationInfo.date')}</Text>
                {moment(startedAt).format('DD/MM/YYYY HH:mm')}
              </Text>
            </Col>
            <Col style={{ paddingTop: 2 }}>
              <Text style={{ marginRight: 8 }}>
                <Text strong>
                  <Trans i18nKey={CLIENT_INTERACTIONS_TYPES_LITERALS[communicationType]} />
                  {email || communicationId ? ': ' : ''}
                </Text>
                {communicationType === 'email' ? email || communicationId : communicationId}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row type="flex" align="end" justify="flex-end" gutter={[16, 0]}>
            <Col>
              <AttachTag reviewId={reviewId} block={false} shouldLoad={shouldLoad} />
            </Col>
            <Col>
              <CopyLinkButton
                reviewId={reviewId}
                destinationId={textCommunication?.id}
                destinationType="text-communications"
              />
            </Col>
            {externalUrl && (
              <Col>
                <Tooltip title={t('components.recordPlayer.externalLink')}>
                  <a href={externalUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton
                      shape="external-link"
                      icon={<ExternalLink size="16" />}
                      size="small"
                    />
                  </a>
                </Tooltip>
              </Col>
            )}
            {!isEmpty(textCommunication.customFields) && (
              <Col>
                <CommunicationInfoModal
                  customFields={textCommunication?.customFields}
                  loading={loading}
                  id={textCommunication?.id}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {!isEmpty(textCommunicationTitle) && (
        <Row type="flex" align="start" justify="flex-start" gutter={[16, 0]}>
          <Col style={{ paddingTop: 2 }}>
            <Text style={{ marginRight: 8 }}>
              <Text strong>
                <Trans i18nKey="components.textCommunicationContent.conversationInfo.title" />
              </Text>
              {textCommunicationTitle}
            </Text>
          </Col>
        </Row>
      )}

      {!isEmpty(textCommunication?.email) && (
        <Row type="flex" align="start" justify="flex-start" gutter={[16, 0]}>
          <Col style={{ paddingTop: 2 }}>
            <Text style={{ marginRight: 8 }}>
              <Text strong>{`${t('general.email')}: `}</Text>
              {textCommunication?.email}
            </Text>
          </Col>
        </Row>
      )}

      <SRow type="flex" align="end" justify="flex-start" gutter={[16, 0]} paddingTop="2px">
        {!isEmpty(textCommunication?.communicationStatus) && (
          <Col flex="1 1 auto">
            <Text style={{ marginRight: 8 }}>
              <Text strong>{`${t('general.status')}: `}</Text>
              {textCommunication?.communicationStatus}
            </Text>
          </Col>
        )}
        <Col flex="0 1 auto">
          <Text>{t('general.showDetails')}</Text>
          &nbsp;
          <Switch checked={showSystemEvents} onChange={setShowSystemEvents} />
        </Col>
      </SRow>
    </SCard>
  );
};

export default ConversationInfo;
