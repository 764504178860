import React from 'react';
import { BookOpen, FileText, Map, Youtube } from 'react-feather';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import Icon from 'components/Icon';

const HowToItUseButton = ({ videoLink, docsLink, tourCode }) => {
  const { t } = useTranslation();
  const startTour = () => {
    if ('Intercom' in window) window.Intercom('startTour', tourCode);
  };

  const IconLink = ({ type, icon, text, link }) => {
    return (
      <SButton
        type="link"
        onClick={() => (type === 'tour' ? startTour() : window.open(link))}
        padding="0px 4px"
      >
        <SRow>
          <SCol display="flex" alignItems="center">
            <Icon
              icon={icon}
              marginTop="1px"
              marginRight="6px"
              size="20px"
              color="var(--black_6)"
            />
            <SText color="var(--black_primary)">{text}</SText>
          </SCol>
        </SRow>
      </SButton>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <IconLink
          type="video"
          icon={Youtube}
          text={t('workPlanTasks.howToBlock.watchVideo')}
          link={videoLink}
        />
      </Menu.Item>
      <Menu.Item>
        <IconLink
          type="docs"
          icon={FileText}
          text={t('workPlanTasks.howToBlock.readManual')}
          link={docsLink}
        />
      </Menu.Item>
      <Menu.Item>
        <IconLink type="tour" icon={Map} text={t('workPlanTasks.howToBlock.startTour')} />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <SButton type="link">
        <SRow>
          <SCol display="flex" alignItems="center">
            <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
            <SText color="var(--link-color)">{t('workPlanTasks.howToBlock.howToButton')}</SText>
          </SCol>
        </SRow>
      </SButton>
    </Dropdown>
  );
};

export default HowToItUseButton;
