import { beatifyFloat } from 'core/utils/convertFloat';
import { RATING_MODE } from 'core/utils/constants';
import { isArray } from 'lodash';

export const valueFormatter = ({ value, ratingMode }) => {
  if (!value && value !== 0) return null;
  return ratingMode === RATING_MODE.PERCENTAGE && value !== 'N/A' && isArray(value) !== true
    ? `${beatifyFloat(value)}%`
    : beatifyFloat(value);
};
