import React, { useEffect, useState } from 'react';
import { Col, Tooltip, Row, Radio, message } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, isEmpty, isEqual, sortBy } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { LoadingOutlined } from '@ant-design/icons';
import { getChecklistDefinitionQuestionsSubgroupSecond } from 'redux/selectors/checklistItems/checklistItems';
import { IconButton } from 'components/Buttons/IconButton';
import WidgetTable from 'components/Dashboard/WidgetTable';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer/ClientInteractionDrawer';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import EmptyWidget from '../../Components/EmptyWidget';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getSortString
} from '../../utils';
import { prepareColumns, prepareRows } from './prepareTableData';
import { useTableReviewsWithScoresExport } from './hooks';

const TableReviewsWithScoresAvito = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;

  const { startExport, loadingExport } = useTableReviewsWithScoresExport();
  const [initialState, setInitialState] = useState({});

  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.PERCENTAGE.value);

  const questionGroupSubgroupsResource = Object.values(
    useSelector(state => state.checklistQuestionGroupSubgroupsResource.byIds)
  );

  const questionGroupResource = Object.values(
    useSelector(state => state.questionGroupsResource.byIds)
  );
  const widgetsAnalytics = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);
  const checklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );

  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});

  const getOrder = checklistItems => {
    const targetItems = [];
    const targetItemsIds = [];
    const groups = [];
    const groupsIds = [];
    const uniqueChecklistItems = checklistItems.reduce((acc, current) => {
      const found = acc.find(item => item.id === current.id);
      if (!found) {
        acc.push(current);
      }
      return acc;
    }, []);
    const isHasGroup = uniqueChecklistItems.some(obj => obj.type === 'checklist-question-groups');
    const isHasSubgroup = uniqueChecklistItems.some(
      obj => obj.type === 'checklist-question-group-subgroups'
    );

    if (!isHasGroup && !isHasSubgroup) {
      const questions = uniqueChecklistItems.filter(item => item.type === 'checklist-questions');
      if (questions) {
        questions.forEach(questions => {
          const { questionGroup } = questions.binding;
          if (questionGroup) {
            if (!groupsIds.includes(questionGroup.id)) {
              groups.push(questionGroup);
              groupsIds.push(questionGroup.id);
            }
          }
        });
        const sortedGroups = sortBy(groups, 'position');
        if (sortedGroups) {
          sortedGroups.forEach(sortedGroup => {
            const { questionsIds } = sortedGroup;
            if (questionsIds) {
              const questions = uniqueChecklistItems.filter(item => questionsIds.includes(item.id));
              const sortedQuestions = sortBy(questions, 'binding.position');
              sortedQuestions.forEach(sortedQuestion => {
                if (!targetItemsIds.includes(sortedQuestion.id)) {
                  targetItems.push(sortedQuestion);
                  targetItemsIds.push(sortedQuestion.id);
                }
              });
            }
          });
        }
      }
      return targetItems;
    }
    return uniqueChecklistItems;
  };

  const checklistItems = useSelector(
    state => getChecklistDefinitionQuestionsSubgroupSecond(state, currentChecklist),
    shallowEqual
  );

  const sortedItems = getOrder(checklistItems);

  const widgetValue = get(widgetsAnalytics, 'data', []);
  const widgetMeta = get(widgetsAnalytics, 'meta', []);

  const tableData = {
    tableColumns: prepareColumns({
      widgetValue,
      currentChecklist,
      checklistItems: sortedItems,
      filters,
      widgetMeta,
      type
    }),
    tableRows: prepareRows({
      filters,
      currentChecklist,
      widgetValue,
      checklistItems: sortedItems,
      usersByIds,
      unitsByIds,
      questionGroupSubgroupsResource,
      questionGroupResource
    })
  };

  const onChangeViewType = viewType => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewType }
      })
    );
  };

  const onChangeViewMode = ({ viewMode }) => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewMode }
      })
    );
  };

  useEffect(() => {
    if (filters.viewMode === 'subgroups' || filters.viewMode === 'groups')
      dispatch(
        updateDashboardWidget({
          id,
          type,
          filters: { ...filters, viewType: 'percentage' }
        })
      );
  }, [filters.viewMode]);

  const onChange = async ({ pagination, sorter }) => {
    setInitialState({
      number: pagination?.current,
      size: pagination?.pageSize
    });

    const columnsKeys = tableData.tableColumns.map(item => item.key);
    const { columnKey } = sorter;

    const isNewSort = columnsKeys.includes(columnKey);

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: pagination.current,
          size: pagination.pageSize
        },
        sort: isNewSort ? getSortString({ sorter, viewMode: filters?.viewMode }) : ''
      })
    );
  };

  const isEmptyWidgetData = isEmpty(widgetData);

  return (
    <>
      {isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: false
                  })}
                  {`. Форма оценки - ${currentChecklist.name}`}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e => onChangeViewMode({ viewMode: e.target.value })}
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.SUBGROUPS.value}
                        disabled={
                          !checklistItems.some(
                            item => item.type === 'checklist-question-group-subgroups'
                          )
                        }
                      >
                        {t(WIDGET_VIEW_MODES.SUBGROUPS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.GROUPS.value}
                        disabled={checklistItems.some(
                          item =>
                            item.type === 'checklist-question-groups' &&
                            item.name === 'Критерии без группы'
                        )}
                      >
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                {filters.viewMode !== 'subgroups' && filters.viewMode !== 'groups' && (
                  <WidgetViewChanger
                    value={viewType}
                    onChange={onChangeViewType}
                    disabled={currentChecklist.ratingMode === RATING_MODE.NUMBERS}
                  />
                )}
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      widgetMeta.total_count >= 5000
                        ? message.warning(t('messages.warning.limitExportReviews'))
                        : startExport({
                            id,
                            type,
                            filters,
                            widgetMeta,
                            initialState,
                            checklistItems,
                            currentChecklist
                          }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
              </SCol>
            </Row>
            <WidgetTable
              columns={tableData.tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableData.tableRows}
              loading={loadingWidgetId === id || loadingExport}
              size="small"
              bordered
              showSorterTooltip={false}
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: widgetMeta.total_count,
                current: widgetMeta.page
              }}
              onChange={(pagination, filters, sorter) => onChange({ pagination, sorter })}
            />
            <ClientInteractionDrawer shouldLoad />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsWithScoresAvito;
