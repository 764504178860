import {Button, Checkbox, Input, Menu, Row, Select} from 'antd';
import Text from 'antd/lib/typography/Text';
import STable from 'components/Standard/STable';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const CategoryName = styled.p`
  font-weight: 600;
  font-size: var(--font-size);
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  color: var(--text);
  margin: 0;
`;

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SInput = styled(Input)`
  margin: 5px 0 0 0 !important;
`;

export const CategoryNameBlock = styled(Row)`
  &.ant-row {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const ClientInteractionsStyledTable = styled(STable)`
  & .ant-table {
    table {
      height: 62px !important;
    }
  }

  &.ant-table-wrapper td {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  margin-top: -16px;
`;

export const TableSelectHeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-between;
  width: 100%;
  // border: 1px solid red;
  position: absolute;
  //top: -13px;
  padding: 4px 0;
  z-index: 999;
  background-color: #fff;
`;

export const TableSelectHeaderItem = styled.div`
  margin-left: auto;
`;
export const ModalTitle = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;

export const CheckboxUnSelectAllStyled = styled(Checkbox)`
  & .ant-checkbox-inner {
    background-color: #597ef7;
    border: 1px solid #597ef7;
  }
  :after {
    display: block;
    content: '';
    height: 2px;
    width: 10px;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  position: relative;
`;

export const TextStyled = styled(Text)`
  cursor: pointer;
  margin: 0 0 0 20px;
`;

export const ButtonStyledCurrentPage = styled(Button)`
  &.ant-btn {
    font-weight: bold;
  }
`;

export const ButtonStyledActiveFilter = styled(Button)`
  &.ant-btn {
    margin-right: 20px;
    margin-bottom: 10px;
    opacity: 0.7;
    border: none;
  }
`;

export const CloseOutlinedStyled = styled(CloseOutlined)`
  &.anticon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
    cursor: pointer;
  }
`;

export const BackgroundModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const ModalWindow = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 550px;
`;
