export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'успешно загружен',
      loadFailed: 'Не удалось загрузить файл',
      fileFormat: 'Вы можете загрузить файлы в формате mp3 или wav, размером до 30 мегабайт/файл'
    },
    buttons: {
      loadCalls: 'Загрузить звонки'
    },
    table: {
      columns: {
        operator: 'Оператор',
        direction: 'Тип звонка',
        startedAt: 'Дата звонка',
        startedAtPlaceholder: 'Выберите время',
        clientPhoneNumber: 'Номер телефона',
        duration: 'Длина звонка',
        actions: 'Действия'
      },
      uinstalledCallDirection: 'Не установлен',
      confirmChange: {
        title: 'Изменения не сохранятся',
        ok: 'Продолжить',
        cancel: 'Отмена'
      },
      buttons: {
        cancel: 'Отмена',
        edit: 'Редактировать',
        delete: 'Удалить'
      },
      confirmDelete: {
        title: 'Уверены?',
        ok: 'Удалить',
        cancel: 'Отмена'
      }
    }
  }
};
