import Icon from 'components/Icon';
import AddUsersModal from 'components/AddUsersModal';
import GrayButton from 'components/Buttons/GrayButton';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SInput from 'components/Standard/SInput';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React, { useState } from 'react';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from 'core/utils/constants';

const emptyArray = [];
export const AssignRule = ({ rule, updateRule, deleteRule, disabledUsersIds = [] }) => {
  const { t } = useTranslation();
  const [showassignersModal, setShowAssignersModal] = useState(false);
  const [showAppellantsModal, setShowAppellantsModal] = useState(false);
  const [appellantsIds, setAppellantsIds] = useState(rule.appellantsIds || emptyArray);
  const [assignersIds, setAssignersIds] = useState(rule.assignersIds || emptyArray);

  const onChangeDescription = e => updateRule({ id: rule.id, description: e.target.value });

  return (
    <SCol span={24}>
      <SRow gutter={[8, 0]} flexWrap="nowrap">
        <SCol width="300px">
          <SRow>
            <SCol span={24}>
              <SText>{t('appealsPage.settings.assignRule.appellants')}</SText>
            </SCol>
            <SCol>
              <SCol
                border="1px solid var(--gray-border)"
                borderRadius="4px"
                height="40px"
                style={{ padding: '0 4px 0 11px' }}
              >
                <SRow justify="flex-between" align="middle" gutter={[20, 0]} height="100%">
                  <SCol>
                    {`${t('workPlanTaskConfigurationEditor.filters.usersAdded')}: ${
                      appellantsIds.length
                    }`}
                  </SCol>
                  <SCol>
                    <GrayButton onClick={() => setShowAppellantsModal(true)}>
                      {t('workPlanTaskConfigurationEditor.filters.add')}
                    </GrayButton>
                  </SCol>
                </SRow>
              </SCol>
            </SCol>
          </SRow>
        </SCol>

        <SCol width="300px">
          <SRow>
            <SCol span={24}>
              <SText>{t('appealsPage.settings.assignRule.assigners')}</SText>
            </SCol>
            <SCol
              border="1px solid var(--gray-border)"
              borderRadius="4px"
              height="40px"
              style={{ padding: '0 4px 0 11px' }}
            >
              <SRow justify="flex-between" align="middle" gutter={[20, 0]} height="100%">
                <SCol>
                  {`${t('workPlanTaskConfigurationEditor.filters.usersAdded')}: ${
                    assignersIds.length
                  }`}
                </SCol>
                <SCol>
                  <GrayButton onClick={() => setShowAssignersModal(true)}>
                    {t('workPlanTaskConfigurationEditor.filters.add')}
                  </GrayButton>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCol>

        <SCol>
          <SText>{t('appealsPage.settings.assignRule.note')}</SText>
          <SInput
            height="40px"
            value={rule.description}
            onChange={onChangeDescription}
            width="100%"
          />
        </SCol>

        <SCol display="flex" alignItems="flex-end">
          <SButton
            icon={<Icon icon={Trash2} />}
            border="none"
            height="40px"
            onClick={() => deleteRule(rule)}
          />
        </SCol>
      </SRow>
      <AddUsersModal
        onAdd={ids => {
          setAppellantsIds(ids);
          setShowAppellantsModal(false);
          updateRule({ id: rule.id, appellantsIds: ids });
        }}
        selectedUsersIds={appellantsIds}
        disabledUsersIds={[...disabledUsersIds, ...assignersIds]}
        visible={showAppellantsModal}
        onCancel={() => setShowAppellantsModal(false)}
        okText={t('general.add')}
        addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_CREATE_APPELLATIONS] }}
      />
      <AddUsersModal
        onAdd={ids => {
          setAssignersIds(ids);
          setShowAssignersModal(false);
          updateRule({ id: rule.id, assignersIds: ids });
        }}
        disabledUsersIds={[...disabledUsersIds, ...appellantsIds]}
        selectedUsersIds={assignersIds}
        visible={showassignersModal}
        onCancel={() => setShowAssignersModal(false)}
        okText={t('general.add')}
        addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_WATCH_APPELLATIONS] }}
      />
    </SCol>
  );
};
