import { post } from 'redux-bees';

const exportsEndpointsV3 = apiPath => ({
  exportCommunicationTableV3: { method: 'post', path: `${apiPath}/export` },
  exportCommunicationTableSendMailExport: { method: 'post', path: `${apiPath}/export/send_mail` },
  exportCommunicationTableStatus: { method: 'post', path: `${apiPath}/export_status` },
  exportCommunicationTableResult: { method: 'post', path: `${apiPath}/export_status/get_result` }
});

export default exportsEndpointsV3;
