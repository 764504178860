import { DownOutlined, RightOutlined } from '@ant-design/icons';
import Icon from 'components/Icon';
import { Col, Input, Row, Select } from 'antd';
import FilterName from 'pages/ClientInteractionsPage/components/FilterName';
import RangeInputs from 'components/Inputs/RangeInputs';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import {
  find,
  flatMap,
  head,
  intersectionWith,
  isEmpty,
  isNil,
  pickBy,
  some,
  sortBy,
  uniqBy
} from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import DurationPicker from 'components/Inputs/DurationPicker';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { CategoryName, CategoryNameBlock, FilterContainer, StyledSelect } from './styled';

const { Option, OptGroup } = Select;

const getCustomFieldNameByKey = ({ customFieldsByIds, key }) =>
  head(Object.values(pickBy(customFieldsByIds, { key })))?.name;

const CustomFieldsFilters = ({
  disabled,
  updateCustomFieldFilters,
  updateFilters,
  filters,
  customFieldFilters = {},
  salePipelinesByIds = {},
  salePipelines = [],
  customFields = [],
  statusesByIds = {},
  customFieldsByIds = {},
  toogleFilterGroup,
  collapsedFilterGroups = []
}) => {
  const { t } = useTranslation();
  if (isEmpty(customFields) && isEmpty(salePipelinesByIds)) return null;
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('customFieldsFilters');

  const renderCustomFieldFilter = ({
    id,
    fieldType,
    key,
    name,
    options,
    dependsOnId,
    settings
  }) => {
    const getComponent = () => {
      if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <DurationPicker
              from={customFieldFilters[key]?.from}
              to={customFieldFilters[key]?.to}
              disabled={disabled}
              size="medium"
              style={{ width: '100%' }}
              allowClear
              onChange={({ from, to }) => {
                updateCustomFieldFilters({
                  [key]: {
                    from: isNil(from) ? undefined : `${from}`,
                    to: isNil(to) ? undefined : `${to}`
                  }
                });
              }}
            />
          </>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <StyledSelect
              disabled={disabled}
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder={name}
              value={customFieldFilters[key]?.eq || undefined}
              onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {[
                { label: t('general.yes'), value: 'true' },
                { label: t('general.no'), value: 'false' }
              ].map(({ label, value }) => (
                <Option key={label} value={value} label={label}>
                  {label}
                </Option>
              ))}
            </StyledSelect>
          </>
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.STRING ||
        (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY && isEmpty(options))
      ) {
        return (
          <Input
            disabled={disabled}
            value={customFieldFilters[key]?.contains || undefined}
            onChange={e => updateCustomFieldFilters({ [key]: { contains: e.target.value } })}
            placeholder={name}
          />
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
      ) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <RangeInputs
              disabled={disabled}
              value={customFieldFilters[key]}
              precision={0}
              onChange={({ from, to }) => updateCustomFieldFilters({ [key]: { from, to } })}
            />
          </>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <CustomDatePicker
              onChange={value => updateCustomFieldFilters({ [key]: value })}
              value={customFieldFilters[key] || {}}
              mode={DATE_PICKER_MODES.custom.customFields}
              isClientInteractionPage
              allowClear
              loading={disabled}
              disabled={disabled}
            />
          </>
        );
      }

      if (!isEmpty(settings)) {
        // id, fieldType, key, name, options, dependsOnId, settings
        const parentKey = customFieldsByIds[dependsOnId]?.key;
        const parentSelectedValue = customFieldFilters[parentKey]?.eq;

        // * allow options on parent key or allow all
        const options = parentSelectedValue
          ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
          : uniqBy(settings, 'key');

        const setControlledValueToUndefined = () => {
          if (customFieldFilters[key]?.eq) {
            updateCustomFieldFilters({ [key]: { eq: undefined } });
          }

          const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
          if (childKey && customFieldFilters[childKey]?.eq) {
            updateCustomFieldFilters({ [childKey]: { eq: undefined } });
          }

          return undefined;
        };

        // * if value should be controlled - check if that option exists. if not - set to undefined
        const controlledValue =
          some(options, ({ key: allowedKey }) => allowedKey === customFieldFilters[key]?.eq) ||
          !parentKey
            ? customFieldFilters[key]?.eq
            : undefined;

        if (controlledValue !== customFieldFilters[key]?.eq) {
          setControlledValueToUndefined();
        }

        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={parentSelectedValue ? controlledValue : customFieldFilters[key]?.eq || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {sortBy(options, ['text']).map(({ key, text }) => (
              <Option key={key} value={key} label={text}>
                {text}
              </Option>
            ))}
          </StyledSelect>
        );
      }

      if (!isEmpty(options)) {
        const compareType = fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY ? 'contains' : 'eq';
        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={customFieldFilters[key]?.[compareType] || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { [compareType]: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {options.map(option => (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        );
      }
    };

    return <FilterContainer key={id}>{getComponent()}</FilterContainer>;
  };

  const filterSalePipelines = pipelinesIds => {
    const allowedPipelinesStatusesIds = flatMap(
      salePipelines.filter(({ id }) => pipelinesIds.includes(id)),
      'statusesIds'
    );

    updateFilters({
      pipelinesIds,
      pipelineStatusesIds:
        isEmpty(filters.pipelineStatusesIds) || isEmpty(pipelinesIds)
          ? filters.pipelineStatusesIds
          : filters.pipelineStatusesIds.filter(statusId => {
              return allowedPipelinesStatusesIds.includes(statusId);
            })
    });
  };

  const selectedSalePipelines = intersectionWith(
    salePipelines,
    isEmpty(filters.pipelinesIds) ? Object.keys(salePipelinesByIds) : filters.pipelinesIds,
    ({ id }, filterId) => id === filterId
  );

  const { otherCustomFields, amoCustomFields } = customFields.reduce(
    (acc, field) => {
      if (field?.entityType) {
        return { ...acc, amoCustomFields: [...acc.amoCustomFields, field] };
      }

      return { ...acc, otherCustomFields: [...acc.otherCustomFields, field] };
    },
    { otherCustomFields: [], amoCustomFields: [] }
  );

  // Сортировка кастомных полей по ключу sort
  const sortedOtherCustomFields = otherCustomFields.sort((a, b) => a.sort - b.sort);

  const salesPipelineStatusPlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline_status'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder');

  const salesPipelinePlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder');

  return (
    <SRow gutter={[0, 16]} style={{ marginTop: '8px', marginBottom: '-8px' }}>
      <SCol span={24}>
        <Row>
          <Col span={24}>
            <CategoryNameBlock
              type="flex"
              align="middle"
              justify="space-between"
              onClick={() => toogleFilterGroup('customFieldsFilters')}
            >
              <Col span={22}>
                <CategoryName>
                  {t('clientInteractionsPage.tableFilters.customFieldFilters.title')}
                </CategoryName>
              </Col>
              <Col span={2}>
                {isCollapsedFilterGroup ? (
                  <Icon icon={DownOutlined} />
                ) : (
                  <Icon icon={RightOutlined} />
                )}
              </Col>
            </CategoryNameBlock>
          </Col>

          {isCollapsedFilterGroup && (
            <Col span={24}>
              {!isEmpty(salePipelines) && (
                <FilterContainer>
                  <StyledSelect
                    mode="multiple"
                    disabled={disabled}
                    showSearch
                    allowClear
                    optionLabelProp="label"
                    maxTagCount={0}
                    maxTagPlaceholder={selectedKeys =>
                      `${salesPipelinePlaceholder}: ${selectedKeys.length}`
                    }
                    placeholder={salesPipelinePlaceholder}
                    value={filters.pipelinesIds || undefined}
                    onChange={filterSalePipelines}
                    filterOption={(input, option) =>
                      selectSearch({ input, option, searchProp: 'children' })
                    }
                  >
                    {salePipelines.map(({ name, id }) => (
                      <Option value={id} key={id} label={name}>
                        {name}
                      </Option>
                    ))}
                  </StyledSelect>
                </FilterContainer>
              )}

              {!isEmpty(statusesByIds) && (
                <FilterContainer>
                  <StyledSelect
                    disabled={disabled}
                    mode="multiple"
                    style={{ width: '100%' }}
                    optionLabelProp="label"
                    allowClear
                    placeholder={salesPipelineStatusPlaceholder}
                    maxTagCount={0}
                    maxTagPlaceholder={selectedKeys =>
                      `${salesPipelineStatusPlaceholder}: ${selectedKeys.length}`
                    }
                    onChange={pipelineStatusesIds => updateFilters({ pipelineStatusesIds })}
                    value={filters.pipelineStatusesIds || []}
                    filterOption={(input, option) =>
                      selectSearch({ input, option, searchProp: 'children' })
                    }
                  >
                    {selectedSalePipelines.map(({ id, name, statusesIds = [] }) => (
                      <OptGroup key={id} label={name}>
                        {statusesIds.reduce((acc, id) => {
                          const status = statusesByIds[id];
                          return status
                            ? [
                                ...acc,
                                <Option value={status.id} key={status.id} label={status.name}>
                                  {status.name}
                                </Option>
                              ]
                            : acc;
                        }, [])}
                      </OptGroup>
                    ))}
                  </StyledSelect>
                </FilterContainer>
              )}
              {sortedOtherCustomFields.map(renderCustomFieldFilter)}
            </Col>
          )}
        </Row>
      </SCol>

      {isCollapsedFilterGroup && (
        <SCol span={24}>
          <SRow>
            <Col span={24}>
              <CategoryNameBlock type="flex" align="middle" justify="space-between">
                <Col span={24}>
                  <CategoryName>
                    {t('clientInteractionsPage.tableFilters.tableCategoryName')}
                  </CategoryName>
                </Col>
              </CategoryNameBlock>
            </Col>
            <Col span={24}>{amoCustomFields.map(renderCustomFieldFilter)}</Col>
          </SRow>
        </SCol>
      )}
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const customFieldsByIds = getCustomFieldsByIds(state, true);
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  const { byIds: statusesByIds } = state.salesPipelineStatusesResource;
  const { customFieldFilters, loading, filters } = state.clientInteractionsList;
  const { tableLoading, tableId, collapsedFilterGroups } = state.uiClientInteractions;

  return {
    disabled:
      tableLoading ||
      loading ||
      isEmpty(tableId) ||
      ownProps?.selectedWorkPlanTaskConfigurationId ||
      ownProps?.hasConflicts,
    salePipelinesByIds,
    salePipelines: Object.values(salePipelinesByIds),
    customFields: Object.values(customFieldsByIds)
      .filter(customField => customField?.usedForFilters)
      .sort((a, b) => {
        if (a.id === b?.dependsOnId) {
          return -1;
        }

        if (b.id === a?.dependsOnId) {
          return 1;
        }

        return 0;
      }),
    customFieldFilters: ownProps?.hasConflicts ? {} : customFieldFilters,
    filters: ownProps?.hasConflicts ? {} : filters,
    customFieldsByIds,
    statusesByIds,
    collapsedFilterGroups
  };
};

const mapDispatchToProps = {
  updateCustomFieldFilters: actions.updateCustomFieldFilters,
  updateFilters: actions.updateFilters,
  toogleFilterGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsFilters);
