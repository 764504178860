import { Button, Col, Form, Row, Select } from 'antd';
import DateSpecificSelector from 'components/Inputs/DateSpecificSelector';
import SecondsSpecificSelector from 'components/Inputs/SecondsSpecificSelector';
import SpecificInput from 'components/Inputs/SpecificInput';
import SpecificSelect from 'components/Inputs/SpecificSelect';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { getUserName } from 'components/UserPreview/getUserName';
import {
  CHECKLIST_MANAGER_STATES,
  CLIENT_INTERACTIONS_TYPES,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  COMMUNICATION_DIRECTIONS,
  PERMISSIONS
} from 'core/utils/constants';
import { getInitialValues } from 'core/utils/form';
import { selectSearch } from 'core/utils/selectSearch';
import { debounce, get, isEmpty, isNil, keyBy, some } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getCurrentUser,
  getUsersByIds,
  getUsersByUnitIdsWithPermissions
} from 'redux/selectors/users';
import { clearCustomCommunicationForm } from 'redux/ui/customCommunicationReviewPage/reducer';
import styled from 'styled-components';
import { CommunicationIdInput } from './CommunicationIdInput';
import DynamicFields from './DynamicFields';

const CustomCommunicationForm = ({
  currentUser,
  communication,
  communicationType,
  disabled,
  operators = [],
  reviewer,
  reviewId,
  usersByIds,
  onChange,
  form,
  clearCustomCommunicationForm
}) => {
  const { Option } = Select;

  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(communication)) {
      clearCustomCommunicationForm();
      form.resetFields();
    }
  }, []);

  const keyByIterator = ({ name }) =>
    Array.isArray(name) && name[1] ? `${name[0]}[${name[1]}]` : name;

  const onFieldsChange = debounce((changedValues, allFields) => {
    if (some(changedValues, ({ name }) => name[0] === 'communicationType')) {
      onFieldsChange.flush();
    }

    onChange(keyBy(allFields, keyByIterator));
  }, 300);

  const initialValues = getInitialValues({
    ...(communication || {}),
    duration: moment()
      .startOf('day')
      .add(communication.duration, 'seconds'),
    metadataAdditional: communication?.metadata?.additional,
    createdAt: moment(communication?.createdAt),
    startedAt: moment(communication?.startedAt)
  });

  return (
    <FormContainer>
      <SCol span={24} marginBottom="16px">
        <Row type="flex" align="bottom">
          <SCol marginRight="4px">
            <SText strong>{`${t('general.author')}: `}</SText>
          </SCol>
          <Col>
            {reviewId ? (
              <UserPreview userId={reviewer?.id} disabled hidden />
            ) : (
              <UserPreview userId={currentUser?.id} disabled />
            )}
          </Col>
        </Row>
      </SCol>
      <Form
        scrollToFirstError
        style={{ width: '100%' }}
        form={form}
        onFieldsChange={onFieldsChange}
        initialValues={initialValues}
      >
        <Row gutter={[16, 16]}>
          {
            //! 1 col
          }
          <Col span={8}>
            {disabled ? (
              <Form.Item>
                <SpecificInput
                  value={getUserName({ user: localStorage.getItem('operid') ? usersByIds[localStorage.getItem('operid')] : usersByIds[communication?.operatorId] })}
                  disabled
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="operatorId"
                rules={[
                  {
                    required: true,
                    message: t('customCommunicationPage.form.messages.requiredOperator')
                  }
                ]}
              >
                <SpecificSelect
                  placeholder={t('customCommunicationPage.form.fields.operator')}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    selectSearch({ input, option, searchProp: 'children' })
                  }
                  disabled={disabled}
                >
                  {operators.map(user => (
                    <Option key={user.id} value={user.id}>
                      {getUserName({ user })}
                    </Option>
                  ))}
                </SpecificSelect>
              </Form.Item>
            )}

            <Form.Item
              name="communicationType"
              rules={[
                {
                  required: true,
                  message: t('customCommunicationPage.form.messages.requiredCommunicationType')
                }
              ]}
            >
              <SpecificSelect
                placeholder={t('customCommunicationPage.form.fields.communicationType')}
                allowClear
                disabled={disabled}
              >
                <Option value={CLIENT_INTERACTIONS_TYPES.PHONE_CALL}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.phone_call)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.EMAIL}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.email)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.CHAT}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.chat)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.TICKET}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.ticket)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.communication_chain)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.VIDEO}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.video)}
                </Option>
                <Option value={CLIENT_INTERACTIONS_TYPES.OTHER}>
                  {t(CLIENT_INTERACTIONS_TYPES_LITERALS.other)}
                </Option>
              </SpecificSelect>
            </Form.Item>

            {communicationType === CLIENT_INTERACTIONS_TYPES.PHONE_CALL ? (
              <Form.Item
                name="clientPhoneNumber"
                rules={[
                  {
                    required: true,
                    message: t('customCommunicationPage.form.messages.requiredClientPhoneNumber')
                  }
                ]}
              >
                <SpecificInput
                  placeholder={t('customCommunicationPage.form.fields.clientPhoneNumber')}
                  disabled={disabled}
                />
              </Form.Item>
            ) : null}
          </Col>

          {
            //! 2 col
          }

          <Col span={8}>
            <Form.Item
              name="startedAt"
              rules={[
                {
                  required: true,
                  message: t('customCommunicationPage.form.messages.requiredCommunicationDate')
                }
              ]}
            >
              <DateSpecificSelector
                allowClear
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
                showToday
                placeholder={t('customCommunicationPage.form.fields.communicationDate')}
                disabledDate={current => current && current > moment().endOf('day')}
                disabled={disabled}
              />
            </Form.Item>
            {[
              CLIENT_INTERACTIONS_TYPES.PHONE_CALL,
              CLIENT_INTERACTIONS_TYPES.VIDEO,
              CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN,
              CLIENT_INTERACTIONS_TYPES.OTHER
            ].includes(communicationType) ? (
              <Form.Item name="duration">
                <SecondsSpecificSelector
                  allowClear
                  format="HH:mm:ss"
                  style={{ width: '100%' }}
                  defaultOpenValue={moment().startOf('day')}
                  placeholder={t('customCommunicationPage.form.fields.duration')}
                  disabled={disabled}
                  changeOnSelect
                />
              </Form.Item>
            ) : null}
            {communicationType === CLIENT_INTERACTIONS_TYPES.PHONE_CALL ? (
              <Form.Item
                name="direction"
                rules={[
                  {
                    required: true,
                    message: t('customCommunicationPage.form.messages.requiredDirection')
                  }
                ]}
              >
                <SpecificSelect
                  placeholder={t('customCommunicationPage.form.fields.direction')}
                  allowClear
                  disabled={disabled}
                >
                  {Object.values(COMMUNICATION_DIRECTIONS).map(item => (
                    <Option key={item.value} value={item.value}>
                      {t(item.title)}
                    </Option>
                  ))}
                </SpecificSelect>
              </Form.Item>
            ) : null}
            {communicationType === CLIENT_INTERACTIONS_TYPES.EMAIL ? (
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('customCommunicationPage.form.messages.requiredEmail')
                  },
                  {
                    type: 'email',
                    message: t('customCommunicationPage.form.messages.wrongEmail')
                  }
                ]}
              >
                <SpecificInput
                  placeholder={t('customCommunicationPage.form.fields.email')}
                  disabled={disabled}
                />
              </Form.Item>
            ) : null}
          </Col>

          {
            //! 3 col
          }
          <Col span={8}>
            <Form.Item name="metadataAdditional">
              <SpecificInput
                placeholder={t('customCommunicationPage.form.fields.additional')}
                disabled={disabled}
              />
            </Form.Item>

            <Form.Item
              name="communicationId"
              rules={[
                {
                  required: [
                    CLIENT_INTERACTIONS_TYPES.CHAT,
                    CLIENT_INTERACTIONS_TYPES.TICKET,
                    CLIENT_INTERACTIONS_TYPES.VIDEO,
                    CLIENT_INTERACTIONS_TYPES.OTHER
                  ].includes(communicationType),
                  message: t('customCommunicationPage.form.messages.requiredCommunicationId')
                }
              ]}
            >
              <CommunicationIdInput disabled={disabled} />
            </Form.Item>

            <>
              {disabled ? (
                get(communication, 'metadata.mediaUrls', []).map(item => (
                  <Col key={item}>
                    <Button type="link" onClick={() => window.open(item)} style={{ padding: 0 }}>
                      {t('customCommunicationPage.form.buttons.openFile')}
                    </Button>
                  </Col>
                ))
              ) : (
                <DynamicFields form={form} />
              )}
            </>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled(Row)`
  &.ant-row {
    background-color: var(--gray-light);
    padding: 16px;
    margin-bottom: 16px;
  }
`;

const mapStateToProps = (state, ownProps) => {
  const { checklistManagerState } = state.uiChecklistManager;
  const currentUser = getCurrentUser(state);
  const reviewsByIds = state.reviewsResource.byIds;
  
  const reviewer = getUsersByIds(state)[get(reviewsByIds, `${ownProps.reviewId}.reviewerId`, null)];
  // const user = localStorage.getItem('operid');
  const operatorsByIds = getUsersByUnitIdsWithPermissions(state, {
    permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION],
    unitIds: undefined
  });

  if (
    !isEmpty(ownProps.communication) &&
    isEmpty(operatorsByIds[ownProps.communication?.operatorId])
  ) {
    operatorsByIds[ownProps.communication?.operatorId] =
      state.usersResource.byIds[ownProps.communication?.operatorId];
  }

  const communicationType =
    !isNil(ownProps.communication) && checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED
      ? ownProps.communication.communicationType
      : state.uiCustomCommunicationReview.form.communicationType.value;

  const operators = Object.values(operatorsByIds).filter(
    operator => operator.active && operator.id !== get(currentUser, 'id', '')
  );
  const usersByIds = state.usersResource.byIds;

  return {
    currentUser,
    fields: state.uiCustomCommunicationReview.form,
    operators,
    usersByIds,
    communicationType,
    reviewer
  };
};

const mapDispatchToProps = {
  clearCustomCommunicationForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCommunicationForm);
