import addScopeToObjectKeys from 'core/utils/addScopeToObject';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { filter, find, get, isEmpty, isNil, keyBy } from 'lodash';
import { actions as clientInteractionsListActions } from 'redux/lists/clientInteractionsList';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import axios from 'axios';
import { getCurrentUser } from 'redux/selectors/users';
import { updateResourceAfterDelete } from 'core/redux/resources/updateResource';
import * as actions from './reducer';

const findAndSetTable = async ({ getState }) => {
  const state = await getState();
  const currentUserId = getCurrentUser(state)?.id;
  const communicationTables = Object.values(state.communicationTablesResource.byIds);
  const LSTableId = localStorage.getItem('DEALAPP_ACTIVE_TABLE_ID');
  const savedTable = find(communicationTables, { id: LSTableId });

  const tablesWhereCurrentUserIsAuthor = communicationTables.filter(
    table => table.creatorId === currentUserId
  );

  const sharedforCurrentUsertables = communicationTables.filter(
    table => table.creatorId !== currentUserId && table?.usersIds?.includes(currentUserId)
  );

  if (savedTable) return savedTable;

  if (!savedTable && !isEmpty(tablesWhereCurrentUserIsAuthor)) {
    localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', tablesWhereCurrentUserIsAuthor[0]?.id);
    return tablesWhereCurrentUserIsAuthor[0];
  }

  if (!savedTable && isEmpty(tablesWhereCurrentUserIsAuthor)) {
    localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', sharedforCurrentUsertables[0]?.id);
    return sharedforCurrentUsertables[0];
  }
};

export const loadCommunicationTables = () => async (dispatch, getState) => {
  const communicationTables = await dispatch(
    communicationTablesResource.operations.load({ include: 'creator' })
  );

  const activeTable = await findAndSetTable({ getState });

  dispatch(actions.setActiveTable(activeTable));

  dispatch(
    clientInteractionsListActions.setCustomFieldFilters(activeTable.customFieldFilters || {})
  );
  dispatch(clientInteractionsListActions.setFilters(activeTable.filters || {}));
};

export const tableRowFromClientInteraction = (
  clientInteraction,
  state,
  reviews = [],
  comments = [],
  tasks = []
) => {
  const operator = get(state.usersResource.byIds, clientInteraction?.operatorId, {});
  const unit = get(state.unitsResource.byIds, clientInteraction.unitId, {});

  const client = get(state.clientsResource.byIds, clientInteraction.clientId, {});
  const communicationType = clientInteraction?.communicationType;
  const customFields = clientInteraction?.customFields;
  const clientInteractionType = clientInteraction?.clientInteractionType;

  const clientInteractionReviews = reviews.reduce((reviewsResult, review) => {
    // * skip review if it's not loaded or not binded with interaction
    if (!review || review?.clientInteractionId !== clientInteraction.id) return reviewsResult;

    // * extract checklist with score
    let checklist = state.checklistsResource.byIds[review.checklistId] || {};
    checklist = {
      ...checklist,
      score:
        state.checklistScoresResource.byIds[checklist?.checklistScoreId]?.score ?? checklist.score
    };

    const checklistDefinition =
      state.checklistDefinitionsResource.byIds[checklist?.checklistDefinitionId];

    // * extract tasks
    const reviewTasks = tasks.reduce((result, task) => {
      if (task.reviewId !== review.id) {
        return result;
      }

      return [
        ...result,
        {
          ...task,
          taskDefinition: state.taskDefinitionsResource.byIds[task.taskDefinitionId]
        }
      ];
    }, []);

    // * extract comments
    const reviewComments = filter(comments, { commentableId: review.id });

    const reviewWithScope = {
      ...addScopeToObjectKeys('review', {
        ...review,
        comments: reviewComments,
        checklist,
        checklistDefinition
      }),
      operator,
      communicationType,
      clientInteractionType
    };

    reviewsResult.push({
      ...reviewWithScope,
      tasks: reviewTasks
    });

    return reviewsResult;
  }, []);

  const tableRow = {
    ...clientInteraction,
    ...addScopeToObjectKeys('customField', customFields),
    operator,
    unit,
    reviews: clientInteractionReviews,
    client,
    reviewId: null
  };

  //  * merge in one row first review and communication
  if (clientInteractionReviews.length > 1) {
    return {
      ...tableRow,
      ...clientInteractionReviews[0],
      children: clientInteractionReviews.slice(1)
    };
  }

  return {
    ...tableRow,
    ...clientInteractionReviews[0],
    children: undefined
  };
};

export const updateTableRow = ({ clientInteractionId }) => async (dispatch, getState) => {
  const state = getState();
  const clientInteraction = state.clientInteractionsResource.byIds[clientInteractionId];
  if (!clientInteraction) return;

  const reviews = Object.values(state.reviewsResource.byIds);
  const comments = Object.values(state.commentsResource.byIds);
  const tasks = Object.values(state.tasksResource.byIds);

  return dispatch(
    actions.updateTableRow(
      tableRowFromClientInteraction(clientInteraction, state, reviews, comments, tasks)
    )
  );
};

export const buildTableRowsOnLoad = ({ table, page, filters }) => (dispatch, getState) => {
  const state = getState();

  const clientInteractions = state.uiClientInteractions.meta?.clientInteractions || [];

  if (table?.customFieldFilters)
    dispatch(clientInteractionsListActions.setCustomFieldFilters(table.customFieldFilters || {}));

  // * update list reducer
  if (!isEmpty(filters?.taskAssignmentsIds)) {
    dispatch(
      clientInteractionsListActions.loadedData({
        ids: Object.keys(keyBy(table?.included?.clientInteractions, 'id')),
        totalCount: get(table?.meta, 'totalCount', '25'),
        totalPages: get(table?.meta, 'totalPages', '1'),
        page
      })
    );
  }
  if (isEmpty(filters?.taskAssignmentsIds)) {
    dispatch(
      clientInteractionsListActions.loadedData({
        ids: Object.keys(keyBy(clientInteractions, 'id')),
        totalPages: get(state.uiClientInteractions.meta, 'totalPages', '1'),
        totalCount: get(state.uiClientInteractions.meta, 'totalCount', '25')
      })
    );
  }

  // * set empty table if no interactions found
  if (isEmpty(clientInteractions)) {
    dispatch(actions.setTableRows([]));
    return [];
  }

  const reviews = state.uiClientInteractions.meta?.reviews || [];
  const comments = state.uiClientInteractions.meta?.comments || [];
  const tasks = state.uiClientInteractions.meta?.tasks || [];

  // * build row by row
  const tableRows = clientInteractions.map(clientInteraction =>
    tableRowFromClientInteraction(clientInteraction, state, reviews, comments, tasks)
  );

  // const updatedTableRows = [];
  // for (const item of tableRows) {
  //   let newItem = {...item};
  //   let uniqueReviews = [];
  //   let formulaResults = [];
  //   let newChildren = [];
  //   let children = undefined;
  //   let reviews = [];
  //   item.reviews.forEach((review) => {
  //     let review_ = {...review}
  //     if (Array.isArray(review.reviewFormulaResult) && review.reviewFormulaResult.length > 0) {
  //       review.reviewFormulaResult.forEach((result) => {
  //         if (result.name.includes("авыки продаж")) {
  //           review_.reviewChecklist.ss = result.result
  //         }
  //         if (result.name.includes("онсультационные навыки")) {
  //           review_.reviewChecklist.cs = result.result
  //         }
  //         if (result.name.includes("тоговая оценка")) {
  //           review_.reviewChecklist.fr = result.result
  //         }
  //       });
  //     }
  //     reviews.push(review_);
  //   });
  //   newItem.reviews = reviews;

  //   // formulaResults.forEach((review) => {
  //   //   if (review.reviewFormulaResult && review.reviewFormulaResult.length > 0) {
  //   //     review.reviewFormulaResult.forEach((result) => {
  //   //       formulaResults.push(result);
  //   //     });
  //   //   }
  //   // });


  //   // for (const iterator of formulaResults) {
  //   //       if (iterator.name.includes("авыки продаж")) {
  //   //         newItem.reviewChecklist.ss = iterator.result;
  //   //         break;
  //   //       }
  //   // }
  //   // uniqueReviews = item.reviews.filter((item, index, self) => {
  //   //   let firstIndex = self.findIndex((obj) => obj.reviewId === item.reviewId && obj.reviewChecklist.score === item.reviewChecklist.score);
  //   //   return index === firstIndex;
  //   // });

  //   // formulaResults = formulaResults.filter((item, index, self) => {
  //   //   let firstIndex = self.findIndex((obj) => obj.id === item.id);
  //   //   return index === firstIndex;
  //   // });

  //   // if (Array.isArray(item.children)) {
  //   //   children = item.children.filter((item, index, self) => {
  //   //     let firstIndex = self.findIndex((obj) => obj.reviewId === item.reviewId);
  //   //     return index === firstIndex;
  //   //   });
  //   // }

  //   // let i = 0;
  //   // for (const formula of formulaResults) {
  //   //   if (!isEmpty(uniqueReviews[i])) {
  //   //     uniqueReviews[i].reviewFormulaResult = [formula];
  //   //     i++;
  //   //   }
  //   // }

  // //   let fRes = formulaResults.slice(i);
  // //   for (const formula of fRes) {
  // //     uniqueReviews.push({
  // //     ...uniqueReviews[0],
  // //     reviewChecklist: {},
  // //     reviewFormulaResult: [formula]
  // //   })}

  // //   if (Array.isArray(item.children)) {
  // //     for (const child of item.children) {
  // //       let x = {
  // //         ...child,
  // //         // reviewChecklist: {},
  // //         reviewFormulaResult: {}
  // //       }
  // //       newChildren.push(x);
  // //     }
  // //   }

  // //   i = 0;
  // //   let fResult = formulaResults.slice(1);
  // //   for (const formula of fResult) {
  // //     if (!isEmpty(newChildren[i])) {
  // //       newChildren[i].reviewFormulaResult = [formula];
  // //       i++;
  // //     }
  // //   }
  // //   fResult = fResult.slice(i);
  // //   for (const formula of fResult) {
  // //     newChildren.push({
  // //       // ...newChildren[0],
  // //       reviewFormulaResult: [formula]
  // //     });
  // //   }

  // //   let firstFormula = !isEmpty(formulaResults[0]) ? [formulaResults[0]] : {}
  // //   newItem = {
  // //     ...item,
  // //     reviewFormulaResult: firstFormula,
  // //     children: newChildren,//.slice(1),
  // //     reviews: uniqueReviews
  // //   }

  //   updatedTableRows.push(newItem);
  // }
  
  dispatch(actions.setTableRows(tableRows));
  return tableRows;
};

export const loadTableData = options => async dispatch => {
  dispatch(actions.setTableLoading(true));

  const table = await dispatch(
    communicationTablesResource.operations.loadById(
      parseOptionsForApi({ page: options.page, id: options.tableId, sort: options.sort, pagination: options.pagination })
    )
  );

  dispatch(buildTableRowsOnLoad({ table }));
  dispatch(actions.setTableLoading(false));
};

export const updateTable = options => async dispatch => {
  dispatch(actions.setTableLoading(true));
  let table;

  if (!isEmpty(options?.filters?.taskAssignmentsIds)) {
    table = await dispatch(communicationTablesResource.operations.getEmpty(options));
  } else {
    table = await dispatch(communicationTablesResource.operations.updateById(options));
  }

  dispatch(
    buildTableRowsOnLoad({
      table,
      page: options?.page,
      filters: options?.filters,
      pagination: options?.pagination
    })
  );
  dispatch(actions.setTableLoading(false));
};

export const createCommunicationTable = ({ ...payload }) => {
  return async dispatch => {
    const newTable = await dispatch(communicationTablesResource.operations.create({ ...payload }));
    await dispatch(actions.setActiveTable(newTable));
    return newTable;
  };
};

export const deleteCommunicationTable = ({ id }) => {
  return async (dispatch, getState) => {
    await dispatch(communicationTablesResource.operations.deleteById({ id }));
    const state = getState();
    const communicationTablesByIds = state.communicationTablesResource.byIds;

    if (!isEmpty(communicationTablesByIds)) {
      const activeTable = await findAndSetTable({ getState });

      await dispatch(actions.setActiveTable(activeTable));
      dispatch(clientInteractionsListActions.setFilters(activeTable?.filters || {}));
    }
  };
};

export const removeCommunicationTableFromList = ({ id }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const activeTable = state.communicationTablesResource.byIds[id];
    const currentUserId = getCurrentUser(state)?.id;
    const communicationTablesByIds = state.communicationTablesResource.byIds;

    await dispatch(
      communicationTablesResource.operations.shareTableById({
        id,
        usersIds: activeTable?.usersIds.filter(userId => userId !== currentUserId)
      })
    );

    await updateResourceAfterDelete({
      resourceElementId: id,
      dispatch,
      type: 'communicationTables'
    });

    if (!isEmpty(communicationTablesByIds)) {
      const newTable = await findAndSetTable({ getState });

      await dispatch(actions.setActiveTable(newTable));
      dispatch(clientInteractionsListActions.setFilters(newTable?.filters || {}));
    }
  };
};

export const getCollapsedGroups = () => {
  let filterGroupsFromLS;
  try {
    filterGroupsFromLS = JSON.parse(localStorage.getItem('collapsedFilterGroups'));
  } catch (error) {
    console.log(error);
  }

  if (isNil(filterGroupsFromLS)) {
    try {
      localStorage.setItem(
        'collapsedFilterGroups',
        JSON.stringify(['tableGeneralFilters', 'tableReviewsFilters', 'customFieldsFilters'])
      );
      return ['tableGeneralFilters', 'tableReviewsFilters', 'customFieldsFilters'];
    } catch (error) {
      console.log(error);
    }
  }

  return filterGroupsFromLS;
};

export const loadTableMeta = ({ options = {} }) => async (dispatch, getState) => {
  const state = getState();

  const { axiosToken } = state.uiClientInteractions;
  if (axiosToken) {
    axiosToken.cancel();
  }

  const cancelTokenSource = axios.CancelToken.source();
  // dispatch(actions.updateAxiosToken(cancelTokenSource));
  dispatch({ type: 'uiClientInteractionPage/updateAxiosToken', payload: cancelTokenSource });

  dispatch(actions.setLoadingMeta(true));
  const meta = await dispatch(
    clientInteractionsResource.operations.loadMeta({ options, cancelTokenSource })
  );

  if (meta) {
    dispatch(actions.setTableMeta(meta));
    return meta;
  }
  return null;
};
