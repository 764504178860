import { message, Spin } from 'antd';
import { BACKGROUND_JOB_STATUSES } from 'core/utils/constants';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { checkBackgroundJob, pendingStatuses } from 'redux/entities/backgroundJob/operations';
import styled from 'styled-components';
import '../../i18next';

const BackgroundJob = ({
  text = i18n.t('backgroundJob.title'),
  description = i18n.t('backgroundJob.description'),
  spinning = true,
  onDone,
  onFailed
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeoutId = useRef(null);
  const currentBj = useSelector(state => state.backgroundJob.currentBackgroundJob);

  const processBJ = async () => {
    const bj = await dispatch(checkBackgroundJob());

    if (isEmpty(bj)) {
      clearTimeout(timeoutId.current);
      onDone();
      return true;
    }

    if (bj.status === BACKGROUND_JOB_STATUSES.FAILED) {
      message.error(t('backgroundJob.messages.error'));
      clearTimeout(timeoutId.current);
      onFailed(bj);
      return true;
    }

    if (!pendingStatuses.includes(bj.status)) {
      clearTimeout(timeoutId.current);
      onDone(bj);
      return true;
    }
  };

  useEffect(() => {
    if (currentBj?.id) {
      processBJ();
      timeoutId.current = setTimeout(function run() {
        processBJ();
        timeoutId.current = setTimeout(run, 2000);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [currentBj?.id]);

  return (
    <Container>
      <Content>
        {spinning && <Spin />}
        <Text>{text}</Text>
        <Text>{description}</Text>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  text-align: center;
  padding: 4px;
`;

export default BackgroundJob;
