import AppLayout from 'layouts/AppLayout/MainLayout/MainLayout';
import AuthLayout from 'layouts/AuthLayout';
import ErrorLayout from 'layouts/ErrorLayout';
import AmoRegisterPage from 'pages/AuthPages/AmoRegisterPage';
import LoginPage from 'pages/AuthPages/LoginPage';
import RecoverPasswordPage from 'pages/AuthPages/RecoverPasswordPage/RecoverPasswordPage';
import RecoverPasswordSuccessPage from 'pages/AuthPages/RecoverPasswordPage/RecoverPasswordSuccessPage';
import RegisterPage from 'pages/AuthPages/RegisterPage/RegisterPage';
import SetNewPasswordPage from 'pages/AuthPages/SetNewPasswordPage/SetNewPasswordPage';
import NotFound from 'pages/ErrorPages/NotFound';
import { VersionPage } from 'pages/VersionPage/VersionPage';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import '../../i18next';
import {
  ProtectedCalibrationResultPage,
  ProtectedCalibrationSettingsPage,
  ProtectedCalibrationsPage,
  ProtectedCallDetailsPage,
  ProtectedChainDetailsPage,
  ProtectedChecklistPage,
  ProtectedChecklistsAndQuestionsPage,
  ProtectedChecklistSettingsPage,
  ProtectedCustomCommunicationReviewPage,
  ProtectedDashboardPage,
  ProtectedEditTaskDefinitionCardPage,
  ProtectedInteractionsPage,
  ProtectedLibraryPage,
  ProtectedReviewDetailsPage,
  ProtectedRootPage,
  ProtectedSetupOrganizationPage,
  ProtectedTaskDefinitionsPage,
  ProtectedTeamPage,
  ProtectedTextConversationDetailsPage,
  ProtectedUploadCallsPage,
  ProtectedUserAnalyticsPage,
  ProtectedWorkPlanTaskConfigurationEditorPage,
  ProtectedWorkPlanTaskConfigurationsPage,
  ProtectedWorkPlanTasksPage,
  ProtectedWorkPlanTaskAssignmentsPage,
  ProtectedWorkPlanTaskItemsPage,
  ProtectedAppealPage,
  ProtectedAppealsPage,
  ProtectedAppealsSettingsPage,
  ProtectedAppealsAnalyticsPage,
  ProtectedUnitsAnalyticsPage,
  ProtectedUnitsByTypeAnalyticsPage
  // ProtectedAutoFailScoresPage
} from './pages';
import { ProtectedCrmEntityDetailsPage } from 'core/routes/pages';

const AppRoutes = ({ history, integratedRoutes = [] }) => (
  <Router history={history}>
    <Switch>
      {integratedRoutes.map(route => (
        <Route {...route} />
      ))}
      <Route exact path="/" component={ProtectedRootPage} />

      <Route exact path="/register">
        <AuthLayout>
          <RegisterPage />
        </AuthLayout>
      </Route>

      <Route exact path="/set-new-password">
        <AuthLayout>
          <SetNewPasswordPage />
        </AuthLayout>
      </Route>

      <Route exact path="/recover-password">
        <AuthLayout>
          <RecoverPasswordPage />
        </AuthLayout>
      </Route>

      <Redirect
        from="/accept-invitation"
        to={{ pathname: 'set-new-password', search: `${history.location.search}&newUser=true` }}
      />

      <Redirect
        from="/reset-password"
        to={{ pathname: 'set-new-password', search: history.location.search }}
      />

      <Route exact path="/recover-password/success">
        <AppLayout>
          <RecoverPasswordSuccessPage />
        </AppLayout>
      </Route>

      <Route exact path="/dashboards">
        <AppLayout cardComponents>
          <ProtectedDashboardPage />
        </AppLayout>
      </Route>
      <Route exact path="/checklists">
        <AppLayout cardComponents>
          <ProtectedChecklistsAndQuestionsPage />
        </AppLayout>
      </Route>
      <Route exact path="/checklist-editor/:id">
        <AppLayout cardComponents>
          <ProtectedChecklistPage />
        </AppLayout>
      </Route>
      <Route exact path="/checklist-settings/:id">
        <AppLayout cardComponents>
          <ProtectedChecklistSettingsPage />
        </AppLayout>
      </Route>
      <Route exact path="/checklist-settings">
        <AppLayout cardComponents>
          <ProtectedChecklistSettingsPage />
        </AppLayout>
      </Route>
      <Route exact path="/text-communications/:id">
        <AppLayout cardComponents>
          <ProtectedTextConversationDetailsPage
            tab="textCommunication"
            contentType="textCommunication"
          />
        </AppLayout>
      </Route>

      <Redirect from="/calibrations" exact to="/calibrations/pending" />
      <Route exact path="/calibration/:id">
        <AppLayout cardComponents>
          <ProtectedCalibrationSettingsPage />
        </AppLayout>
      </Route>
      <Route exact path="/calibrations/folders">
        <AppLayout cardComponents>
          <ProtectedCalibrationsPage tab="folders" />
        </AppLayout>
      </Route>
      <Route exact path="/calibrations/pending">
        <AppLayout cardComponents>
          <ProtectedCalibrationsPage tab="pending" />
        </AppLayout>
      </Route>
      <Route exact path="/calibrations/done">
        <AppLayout cardComponents>
          <ProtectedCalibrationsPage tab="done" />
        </AppLayout>
      </Route>
      <Route exact path="/calibration-result/:id">
        <AppLayout cardComponents>
          <ProtectedCalibrationResultPage />
        </AppLayout>
      </Route>

      <Route exact path="/work-plan/task-configurations">
        <AppLayout cardComponents>
          <ProtectedWorkPlanTaskConfigurationsPage />
        </AppLayout>
      </Route>

      <Route exact path="/work-plan/task-configurations/editor/:id">
        <AppLayout cardComponents>
          <ProtectedWorkPlanTaskConfigurationEditorPage />
        </AppLayout>
      </Route>

      <Route exact path="/work-plan/task-configurations/:id/tasks">
        <AppLayout cardComponents>
          <ProtectedWorkPlanTasksPage />
        </AppLayout>
      </Route>

      <Route exact path="/work-plan/task-configurations/:id/tasks/:task_id">
        <AppLayout cardComponents>
          <ProtectedWorkPlanTaskAssignmentsPage />
        </AppLayout>
      </Route>

      <Route
        exact
        path="/work-plan/task-configurations/:id/tasks/:task_id/task-assignment/:assignment_id"
      >
        <AppLayout cardComponents>
          <ProtectedWorkPlanTaskItemsPage />
        </AppLayout>
      </Route>

      <Redirect
        from="/work-plan/task-configurations/editor"
        exact
        to="/work-plan/task-configurations/editor/new"
      />

      <Redirect from="/organization-structure" exact to="/organization-structure/units" />
      <Route exact path="/client-interactions">
        <AppLayout cardComponents style={{ background: 'white', padding: '16px', margin: 0 }}>
          <ProtectedInteractionsPage />
        </AppLayout>
      </Route>
      <Route exact path="/calls/:id">
        <AppLayout cardComponents>
          <ProtectedCallDetailsPage tab="call" contentType="call" />
        </AppLayout>
      </Route>
      <Route exact path="/communication-chains/:id">
        <AppLayout cardComponents>
          <ProtectedChainDetailsPage tab="communication_chain" contentType="communication_chain" />
        </AppLayout>
      </Route>
      <Route path="/user/:id/:tabName">
        <AppLayout cardComponents>
          <ProtectedUserAnalyticsPage />
        </AppLayout>
      </Route>
      <Route path="/user/:id/organization-settings/:settings-tab">
        <AppLayout cardComponents>
          <ProtectedUserAnalyticsPage />
        </AppLayout>
      </Route>
      <Redirect from="/user/:id" exact to="/user/:id/charts" />
      <Route exact path="/reviews/:id">
        <AppLayout cardComponents>
          <ProtectedReviewDetailsPage tab="review" />
        </AppLayout>
      </Route>
      <Route exact path="/crm-entity/:id">
        <AppLayout cardComponents>
          <ProtectedCrmEntityDetailsPage tab="crm-entity" />
        </AppLayout>
      </Route>
      <Route exact path="/version">
        <AuthLayout>
          <VersionPage />
        </AuthLayout>
      </Route>
      <Route exact path="/library">
        <AppLayout cardComponents>
          <ProtectedLibraryPage />
        </AppLayout>
      </Route>
      <Route exact path="/upload">
        <AppLayout>
          <ProtectedUploadCallsPage />
        </AppLayout>
      </Route>
      <Route exact path="/login">
        <AuthLayout>
          <LoginPage />
        </AuthLayout>
      </Route>
      <Route exact path="/amo/register">
        <AuthLayout>
          <AmoRegisterPage />
        </AuthLayout>
      </Route>
      <Route exact path="/amo/login">
        <AuthLayout>
          <LoginPage />
        </AuthLayout>
      </Route>
      <Route exact path="/setup-organization/:id">
        <AuthLayout>
          <ProtectedSetupOrganizationPage />
        </AuthLayout>
      </Route>

      <Route exact path="/settings/triggers">
        <AppLayout cardComponents>
          <ProtectedTaskDefinitionsPage />
        </AppLayout>
      </Route>
      <Route exact path="/settings/triggers/edit/task-definition">
        <AppLayout cardComponents>
          <ProtectedEditTaskDefinitionCardPage />
        </AppLayout>
      </Route>
      <Route exact path="/settings/triggers/edit/task-definition/:id">
        <AppLayout cardComponents>
          <ProtectedEditTaskDefinitionCardPage />
        </AppLayout>
      </Route>
      <Route exact path="/add-review">
        <AppLayout cardComponents>
          <ProtectedCustomCommunicationReviewPage />
        </AppLayout>
      </Route>
      <Route exact path="/team">
        <AppLayout cardComponents>
          <ProtectedTeamPage />
        </AppLayout>
      </Route>
      <Route exact path="/appeals">
        <AppLayout cardComponents>
          <ProtectedAppealsPage />
        </AppLayout>
      </Route>
      <Route exact path="/appeals-settings">
        <AppLayout cardComponents>
          <ProtectedAppealsSettingsPage />
        </AppLayout>
      </Route>
      <Route exact path="/appeals-analytics">
        <AppLayout cardComponents>
          <ProtectedAppealsAnalyticsPage />
        </AppLayout>
      </Route>
      <Route exact path="/appeals/:id">
        <AppLayout cardComponents>
          <ProtectedAppealPage />
        </AppLayout>
      </Route>
      <Route exact path="/units-analytics">
        <AppLayout cardComponents>
          <ProtectedUnitsAnalyticsPage />
        </AppLayout>
      </Route>
      <Route exact path="/units-by-type-analytics">
        <AppLayout cardComponents>
          <ProtectedUnitsByTypeAnalyticsPage />
        </AppLayout>
      </Route>
      {/* <Route exact path="/autofail-scores">
        <AppLayout cardComponents>
          <ProtectedAutoFailScoresPage />
        </AppLayout>
      </Route> */}
      <Route>
        <ErrorLayout>
          <NotFound />
        </ErrorLayout>
      </Route>
    </Switch>
  </Router>
);

export default AppRoutes;
