import React from 'react';
import {isEmpty, get, find, groupBy, reduce, keyBy, sortBy} from 'lodash';
import { Row, Col, Empty, Typography } from 'antd';
import styled from 'styled-components';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { beatifyFloat } from 'core/utils/convertFloat';
import { getChecklistsWithAnswersByIds } from 'redux/selectors/checklists';
import {
  getChecklistDefinitionQuestions,
  getChecklistDefinitionQuestionsGroupsSubgroupOrder
} from 'redux/selectors/checklistItems/checklistItems';
import UserPreview from 'components/UserPreview';
import STable from 'components/Standard/STable';
import { prepareTableColumns } from './prepareTableColumns';
import { prepareTableRows } from './prepareTableRows';

const NewCalibrationResultTable = ({
  members,
  questionsDifferenceByIds,
  totalDifference,
  session = {},
  mappedMembersByAnswers,
  mappedChecklistItems
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const tableRows = prepareTableRows({
    members,
    questionsDifferenceByIds,
    totalDifference,
    mappedMembersByAnswers,
    mappedChecklistItems
  });
  const tableColumns = prepareTableColumns({ members });
  const scroll = { x: 1500, y: undefined };

  if (tableColumns.length <= 2) {
    return (
      <Row>
        <Col span={24}>
          <SCard>
            <StyledEmpty
              description={t('calibrationResultPage.messages.emptyReviews')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </SCard>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <SCard bodyPadding="16px 16px 8px 16px" bordered shadowed>
          <Row>
            <Col span={24}>
              <Text strong>{session.name}</Text>
            </Col>
            <Col span={24}>
              <Row type="flex" gutter={[4, 4]} align="middle">
                <Col>
                  <Text>{t('calibrationResultPage.table.organizer')}</Text>
                </Col>
                <Col>
                  <UserPreview disabled userId={session.authorId} />
                </Col>
              </Row>
            </Col>
          </Row>
        </SCard>
      </Col>
      <Col span={24}>
        <SCard bordered shadawed bodyPadding="8px">
          <STable
            rowKey="key"
            dataSource={tableRows}
            columns={tableColumns}
            pagination={false}
            size="small"
            scroll={scroll}
          />
        </SCard>
      </Col>
    </Row>
  );
};

const StyledEmpty = styled(Empty)`
  &&.ant-empty.ant-empty-normal {
    margin: 8px 0;
  }
`;

const mapStateToProps = (state, { session }) => {
  const checklistDefinition = get(
    state.checklistDefinitionsResource.byIds,
    session.checklistDefinitionId,
    {}
  );
  const checklistItems = getChecklistDefinitionQuestionsGroupsSubgroupOrder(
    state,
    checklistDefinition
  );
  const checklistItemsByIds = keyBy(checklistItems, 'id');
  const { viewedItem } = state.uiCalibrationResultPage;
  const checklistDefinitionQuestions = getChecklistDefinitionQuestions(state, checklistDefinition);
  const checklistDefinitionQuestionsByIds = keyBy(checklistDefinitionQuestions, 'id');
  const { clientInteractionId } = get(state.folderItemsResource.byIds, viewedItem, {});
  const checklistsWithAnswersByIds = getChecklistsWithAnswersByIds(state);

  const sessionReviewsByIds = session.reviewsIds.reduce((acc, id) => {
    const review = get(state.reviewsResource.byIds, id, {});

    if (isEmpty(review) || review.clientInteractionId !== clientInteractionId) {
      return acc;
    }

    return { ...acc, [id]: review };
  }, {});

  const members = session.membersIds.reduce((acc, id) => {
    const user = get(state.usersResource.byIds, id, {});
    const review = find(sessionReviewsByIds, ({ reviewerId }) => id === reviewerId);

    if (!review || isEmpty(user)) {
      return acc;
    }

    const checklist = get(checklistsWithAnswersByIds, review.checklistId, {});
    const comments = review.commentsIds.map(id => get(state.commentsResource.byIds, id, {}));
    return [...acc, { ...user, review, checklist, comments }];
  }, []);

  const getOrder = checklistItems => {
    const targetItems = [];
    const targetItemsIds = targetItems.map(item => item.id);
    // const groups = [];
    const isHasGroup = checklistItems.some(obj => obj.type === 'checklist-question-groups');
    const isHasSubgroup = checklistItems.some(
      obj => obj.type === 'checklist-question-group-subgroups'
    );

    if (!isHasSubgroup) {
      if (isHasGroup) {
        const groups = checklistItems.filter(item => item.type === 'checklist-question-groups');
        const sortedGroups = sortBy(groups, 'position');
        sortedGroups.forEach(sortedGroup => {
          if (!targetItemsIds.includes(sortedGroup.id)) {
            targetItems.push(sortedGroup);
            targetItemsIds.push(sortedGroup.id);
          }
          const { questionsIds } = sortedGroup;
          if (questionsIds) {
            const questions = checklistItems.filter(item => questionsIds.includes(item.id));
            if (questions) {
              const sortedQuestions = sortBy(questions, 'binding.position');
              sortedQuestions.forEach(sortedQuestion => {
                if (!targetItemsIds.includes(sortedQuestion.id)) {
                  targetItems.push(sortedQuestion);
                  targetItemsIds.push(sortedQuestion.id);
                }
              });
            }
          }
        });
      } // end isHasGroup

      // Нет групп, подгрупп.
      const questions = checklistItems.filter(item => item.type === 'checklist-questions');
      const sortedQuestions = sortBy(questions, 'binding.position');
      sortedQuestions.forEach(sortedQuestion => {
        if (!targetItemsIds.includes(sortedQuestion.id)) {
          targetItems.push(sortedQuestion);
          targetItemsIds.push(sortedQuestion.id);
        }
      });
    }

    // Есть группы подгруппы
    if (isHasGroup && isHasSubgroup) {
      const groups = checklistItems.filter(item => item.type === 'checklist-question-groups');
      const sortedGroups = sortBy(groups, 'position');
      sortedGroups.forEach(sortedGroup => {
        if (!targetItems.includes(sortedGroup.id)) {
          targetItems.push(sortedGroup);
          targetItemsIds.push(sortedGroup.id);
        }
        const subgroupsIds = sortedGroup.questionGroupSubgroupsIds;
        if (subgroupsIds) {
          const subgroups = checklistItems.filter(item => subgroupsIds.includes(item.id));
          const sortedSubgroups = sortBy(subgroups, 'position');
          sortedSubgroups.forEach(sortedSubgroup => {
            if (!targetItems.includes(sortedSubgroup.id)) {
              targetItems.push(sortedSubgroup);
              targetItemsIds.push(sortedSubgroup.id);
            }
            const { questionsIds } = sortedSubgroup;
            if (questionsIds) {
              const questions = checklistItems.filter(item => questionsIds.includes(item.id));
              const sortedQuestions = sortBy(questions, 'binding.position');
              sortedQuestions.forEach(sortedQuestion => {
                if (!targetItemsIds.includes(sortedQuestion.id)) {
                  targetItems.push(sortedQuestion);
                  targetItemsIds.push(sortedQuestion.id);
                }
              });
            }
          });
        }

        // В группе нет подгрупп
        const { questionsIds } = sortedGroup;
        if (questionsIds) {
          const questions = checklistItems.filter(item => questionsIds.includes(item.id));
          const sortedQuestions = sortBy(questions, 'binding.position');
          sortedQuestions.forEach(sortedQuestion => {
            if (!targetItemsIds.includes(sortedQuestion.id)) {
              targetItems.push(sortedQuestion);
              targetItemsIds.push(sortedQuestion.id);
            }
          });
        }
      });
    }
    return targetItems;
  };

  const sortedItems = getOrder(checklistItems);

  const sessionChecklistsByIds = reduce(
    sessionReviewsByIds,
    (acc, review) => {
      const checklist = get(checklistsWithAnswersByIds, review.checklistId);

      if (!checklist) {
        return acc;
      }

      return { ...acc, [checklist.id]: checklist };
    },
    {}
  );

  const mappedMembersByAnswers = questionId =>
    members.reduce(
      (acc, item) => {
        const data = {
          answers: {
            ...acc.answers,
            [item.id]: {
              value: get(item.checklist, ['questionsWithAnswersByIds', questionId, 'value'], null),
              question: get(checklistItemsByIds, questionId, {}),
              comments: item.comments.filter(
                comment => comment.metadata && comment.metadata.questionId === questionId
              )
            }
          },
          comments: {
            ...acc.comments,
            [item.id]: item.comments
          },
          result: {
            ...acc.result,
            [item.id]: item.checklist.score
          }
        };

        return data;
      },
      { answers: {}, comment: {}, result: {} }
    );

  // Собираем объект в котором будут все критерии из групп и критерии из подгрупп
  const checkListDefinitionQuestionSubQuestionsByIds = {};
  const targetIds = sortedItems
    .filter(item => item.type === 'checklist-questions' && item.id)
    .map(item => item.id);
  const targetObjects = sortedItems.filter(obj => targetIds.includes(obj.id));

  if (targetObjects) {
    targetObjects.forEach(item => {
      checkListDefinitionQuestionSubQuestionsByIds[item.id] = item;
    });
  }

  const questionsDifferenceByIds = reduce(
    // checklistDefinitionQuestionsByIds,
    checkListDefinitionQuestionSubQuestionsByIds,
    (acc, question, questionId) => {
      // * 1. берём общще количество оценок по вопросу - T
      const totalAnswers = reduce(
        sessionChecklistsByIds,
        (acc, checklist) => {
          const answer = get(checklist, ['questionsWithAnswersByIds', questionId, 'value']);

          return [...acc, answer];
        },
        []
      );
      const totalAnswersCount = totalAnswers.length;

      // * 2. находим наиболее часто повторяющиеся оценки - N
      const groupedAnswers = groupBy(totalAnswers);

      if (isEmpty(groupedAnswers)) {
        return { ...acc, [questionId]: '0%' };
      }
      const baseAnswer = Object.keys(groupedAnswers).reduce((a, b) =>
        groupedAnswers[a].length > groupedAnswers[b].length ? a : b
      );
      const baseAnswerCount = groupedAnswers[baseAnswer].length;

      // * 3. если N > 1 то совпадение = N/T * 100 %, иначе совпадение = 0
      const difference =
        baseAnswerCount > 1
          ? `${beatifyFloat((baseAnswerCount / totalAnswersCount) * 100)}%`
          : '0%';

      return { ...acc, [questionId]: difference };
    },
    {}
  );

  const mappedChecklistItems = sortedItems.map(item => ({
    key: item.id,
    name: item.name,
    type: item.type,
    coincidence: questionsDifferenceByIds[item.id],
    ...mappedMembersByAnswers(item.id).answers
  }));

  // * общая разницы считается так же как разница по вопросам, только по скорам чек-листа
  const totalDifference = (() => {
    const checklists = Object.values(sessionChecklistsByIds);
    const groupedChecklists = groupBy(checklists, 'score');

    if (isEmpty(groupedChecklists)) {
      return '0%';
    }

    const baseChecklistScore = Object.keys(groupedChecklists).reduce((a, b) =>
      groupedChecklists[a].length > groupedChecklists[b].length ? a : b
    );

    const baseChecklistsCount = groupedChecklists[baseChecklistScore].length;
    return baseChecklistsCount > 1
      ? `${beatifyFloat((baseChecklistsCount / checklists.length) * 100)}%`
      : '0%';
  })();

  return {
    members,
    totalDifference,
    questionsDifferenceByIds,
    checklistDefinitionQuestionsByIds,
    mappedMembersByAnswers,
    mappedChecklistItems
  };
};

export default withRouter(connect(mapStateToProps, null)(NewCalibrationResultTable));
