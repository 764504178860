import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'core/utils/constants';
import React, { useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import IconButton from 'components/IconButton';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

const DownloadButton = ({ callId, recordUrl, basicAuthUrl, basicAuthCredentials }) => {
  const { t } = useTranslation();
  const userHasPermission = useSelector(state =>
    getCurrentUserPermissions(state).includes(PERMISSIONS.CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA)
  );
  const [saving, setSaving] = useState(false);

  const handleDownload = useCallback(async () => {
    setSaving(true);
    try {
      const res = recordUrl.includes(basicAuthUrl)
        ? await fetch(recordUrl, {
            method: 'GET',
            headers: {
              Authorization: basicAuthCredentials
            }
          })
        : await fetch(recordUrl);
      const blob = await res.blob();
      saveAs(blob, callId);
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  }, [callId, recordUrl]);

  if (!userHasPermission) return null;

  if (saving) {
    return <LoadingOutlined />;
  }
  return (
    <Tooltip title={t('components.recordPlayer.download')}>
      <IconButton
        icon={<DownloadOutlined />}
        shape="circle-outline"
        size="small"
        onClick={handleDownload}
      />
    </Tooltip>
  );
};

export default DownloadButton;
