import { ExclamationCircleOutlined, InfoCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import { IconButton } from 'components/Buttons';
import CommentsList from 'components/Comments/CommentsList';
import CommentsPanel from 'components/Comments/CommentsPanel';
import RateValues from 'components/Inputs/RateValues';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { getStandardCommentsKeyByRate } from 'core/utils/standardComments';
import { compact, get, isEmpty, isEqual, isNil, some } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  setAddingCommentsToQuestionId,
  setQuestionIdDescriptionModal,
  updateAnswer,
  updateQuestionStandardComments
} from 'redux/ui/checklistManager/reducer';
import styled from 'styled-components';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import Icon from 'components/Icon';
import QuestionAppealStatus from './QuestionAppealStatus';
import QuestionAppealComment from './QuestionAppealComment';

const { Option } = Select;

const StyledCard = styled(SCard)`
  & {
    .ant-card-head {
      background: inherit;
      border-radius: inherit;
      margin-bottom: ${({ isEmptyBody = false }) => (isEmptyBody ? '0px' : '-1px')};
    }
    .ant-card-body {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      background: #ffffff;
      &:empty {
        padding: 0;
        border-radius: inherit;
      }
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--blue_4);
    }
  }
`;

const Question = ({
  id,
  name,
  value,
  ratingValues,
  ratingValuesViews,
  binding,
  standardComments,
  disabled = false,
  selectedStandardComments = [],
  checklistDefinition,
  onAutoFail,
  customCommunicationView,
  colorZones,
  questionComments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  onCommentSave,
  appealId,
  appealObjection,
  appealComment,
  hasComments
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardRef = React.useRef();
  const [isCollapsedComments, setCollapsedComments] = useState(false);
  const [isVisibleEditor, setVisibleEditor] = useState(false);

  const onChange = useCallback(
    newValue => {
      dispatch(updateAnswer({ questionId: id, value: newValue }));

      if (newValue !== value) {
        dispatch(updateQuestionStandardComments({ questionId: id, standardComments: undefined }));
      }

      if (
        get(binding, 'checklistAutofailValues', []).includes(newValue) &&
        binding?.checklistAutofailEnabled
      ) {
        onAutoFail();
      }
    },
    [dispatch, id, value]
  );

  const onInfo = useCallback(() => dispatch(setQuestionIdDescriptionModal(id)), [id, dispatch]);

  const onStandardCommentChange = comments => {
    dispatch(updateQuestionStandardComments({ questionId: id, comments }));
  };

  const standardCommentKey = getStandardCommentsKeyByRate(value);
  const showStandardCommentSelector =
    !isEmpty(standardComments) && !isEmpty(standardComments[standardCommentKey]) && !disabled;

  const showStandardComments = disabled && !isEmpty(selectedStandardComments);

  const autofailed =
    (get(binding, 'checklistAutofailValues', []).includes(value) &&
      binding?.checklistAutofailEnabled) ||
    (get(binding, 'groupAutofailValues', []).includes(value) && binding?.groupAutofailEnabled);

  const colorZonesValidated = () => {
    if (
      checklistDefinition.scaleType === 'max_2' ||
      ratingValues.length === 1 ||
      ratingValues.length === 2
    ) {
      return !some([get(colorZones, 'low.stop')], isNil);
    }
    return !some([get(colorZones, 'low.stop'), get(colorZones, 'medium.stop')], isNil);
  };

  const selectedStandardCommentsText = selectedStandardComments.map(
    id => get(standardComments, standardCommentKey, {})[id]
  );

  // TODO: refactor
  const canComment = isVisibleEditor && !customCommunicationView && !appealId;
  const selectedToAppeal = appealId && appealObjection;

  const getMessageIconColor = () => {
    // if (selectedToAppeal) return 'white';
    if ((hasComments && selectedToAppeal) || hasComments) return 'var(--link-color)';
    return '#82858c';
  };

  const isHideBody =
    canComment === false &&
    (!isEmpty(questionComments) && !customCommunicationView) === false &&
    ((isEmpty(questionComments) || appealObjection?.new || false) && !isEmpty(appealObjection)) ===
      false;

  // Вернуть null, если нечего отрисовывать, что бы срабол CSS :empty. См. выше в StyledCard
  const ConditionSRow = withConditionalRender(
    {
      hide: isHideBody
    },
    SRow
  );

  return (
    <SCol span={24}>
      <StyledCard
        rounded
        shadowed={false}
        bordered={selectedToAppeal ? '1px solid #839df2' : true}
        background={selectedToAppeal ? '#eff3fe' : '#f5f6f7'}
        bodyBG="#ffffff"
        isEmptyBody={isHideBody}
        title={
          <>
            <SRow
              gutter={[8, 8]}
              style={{ margin: '-4px' }}
              type="flex"
              align="middle"
              {...(customCommunicationView && { justify: 'end' })}
            >
              <SCol {...(customCommunicationView ? { style: { flex: 'auto' } } : { span: 24 })}>
                <SRow display="flex" justify="space-between" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="flex-start" wrap paddingRight="32px">
                    {autofailed && (
                      <Tooltip
                        title={t('components.checklistManager.question.autoFailTooltip.title')}
                      >
                        <Icon
                          icon={ExclamationCircleOutlined}
                          color="var(--red_6)"
                          style={{ padding: '0px 2px 0', marginTop: '1px' }}
                        />
                      </Tooltip>
                    )}
                    <SText
                      style={{
                        whiteSpace: 'pre-wrap'
                      }}
                      title={name}
                      fontSize="14px"
                      fontWeight={600}
                      lineHeight="20px"
                      width="100%"
                    >
                      {name}
                    </SText>
                  </SCol>

                  <SCol display="flex" flex="none">
                    <SRow
                      type="flex"
                      justify="space-between"
                      align="flex-start"
                      width="48px"
                      style={{ flexWrap: 'nowrap' }}
                    >
                      <IconButton
                        button={{
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          icon: <MessageOutlined style={{ fontSize: '18px' }} />,
                          onClick: () => {
                            dispatch(setAddingCommentsToQuestionId(id));
                            setVisibleEditor(!isVisibleEditor);
                          },
                          color: getMessageIconColor(),
                          disabled: !allowCommenting
                        }}
                      />
                      <IconButton
                        button={{
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          icon: <InfoCircleOutlined style={{ fontSize: '18px' }} />,
                          onClick: onInfo,
                          disabled: !colorZonesValidated()
                        }}
                      />
                    </SRow>
                  </SCol>
                </SRow>
              </SCol>

              <SCol span={24}>
                <RateValues
                  parentRef={cardRef?.current}
                  onChange={onChange}
                  disabled={disabled}
                  ratingValues={ratingValues}
                  ratingValuesViews={ratingValuesViews}
                  value={isNil(value) ? null : value}
                  valuesDisplayType={checklistDefinition.valuesDisplayType}
                  scaleType={checklistDefinition.scaleType}
                />
              </SCol>

              {showStandardCommentSelector && (
                <SCol display="flex" span={24}>
                  <Select
                    mode="multiple"
                    onChange={onStandardCommentChange}
                    style={{ width: '100%' }}
                    value={selectedStandardComments || undefined}
                    maxTagTextLength={10}
                    placeholder={t(
                      'components.checklistManager.question.showStandardCommentSelector.placeholder'
                    )}
                    allowClear
                  >
                    {Object.keys(standardComments[standardCommentKey]).map(commentId => (
                      <Option value={commentId}>
                        {standardComments[standardCommentKey][commentId]}
                      </Option>
                    ))}
                  </Select>
                </SCol>
              )}

              {showStandardComments && (
                <SCol display="flex" span={24}>
                  <SText color={selectedToAppeal ? 'white' : 'var(--black_6)'} fontSize="12px">
                    {selectedStandardCommentsText.join(', ')}
                  </SText>
                </SCol>
              )}
            </SRow>

            <SRow>
              <QuestionAppealStatus
                objection={appealObjection}
                questionId={id}
                appealId={appealId}
                // questionHasAnswer={!isNil(value)}
              />
            </SRow>
          </>
        }
      >
        <ConditionSRow backgroundColor="#fff">
          {canComment && (
            <SCol span={24} marginBottom="8px">
              <CommentsPanel
                showCommentsList={false}
                allowCommenting={allowCommenting}
                addingComment
                allowSelectTimestamp={false}
                onCancelComment={() => {
                  setVisibleEditor(!isVisibleEditor);
                  setAddingCommentsToQuestionId(null);
                }}
                onCommentSave={comment => {
                  setVisibleEditor(!isVisibleEditor);
                  onCommentSave({ ...comment });
                }}
                isQuestionView
              />
            </SCol>
          )}

          {compact([appealComment, ...Object.values(questionComments)]).length > 0 &&
            !customCommunicationView && (
              <SCol span={24}>
                <CommentsList
                  comments={questionComments}
                  allowActions
                  onDelete={onDeleteComment}
                  onUpdate={onUpdateComment}
                  showFirst={isCollapsedComments}
                  isQuestionView
                  appealObjection={appealObjection}
                  appealComment={appealComment}
                  questionId={id}
                  appealId={appealId}
                />
              </SCol>
            )}

          {(isEmpty(questionComments) ||
            appealObjection?.new ||
            (!isEmpty(questionComments) && customCommunicationView)) && (
            <SCol span={24} style={{ padding: 0 }}>
              <QuestionAppealComment
                appealObjection={appealObjection}
                appealComment={appealComment}
                questionId={id}
                appealId={appealId}
                onlyEditor
              />
            </SCol>
          )}
        </ConditionSRow>
      </StyledCard>
    </SCol>
  );
};

export default React.memo(Question, isEqual);
