import { createAction, createReducer } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

const defaultState = {
  loading: false,
  currentCallId: null,
  contentType: null,
  isAddingComment: false,
  editingCommentId: null,
  isAddingChecklist: false,
  addingCommentCommunicationPartId: null,
  commentsByIds: {},
  tasks: {},
  operatorId: null,
  selectedCheckbox: []
};

export const setCurrentCallId = createAction('uiClientInteractionPage/setCurrentCallId');

export const setAddingChecklist = createAction('uiClientInteractionPage/setAddingChecklist');

export const setAddingComment = createAction('uiClientInteractionPage/setAddingComment');

export const setEditingCommentId = createAction('uiClientInteractionPage/setEditingCommentId');

export const addComment = createAction('uiClientInteractionPage/Comment/Add');

export const addTasks = createAction('uiClientInteractionPage/addTasks');

export const updateComment = createAction('uiClientInteractionPage/Comment/Update');

export const setHiddenToQuestionComments = createAction(
  'uiClientInteractionPage/Comment/Update/setHiddenToQuestionComments'
);

export const deleteComment = createAction('uiClientInteractionPage/Comment/Delete');

export const setInitialState = createAction('uiClientInteractionPage/setInitialState');

export const setComments = createAction('uiClientInteractionPage/setComments');

export const setLoading = createAction('uiClientInteractionPage/setLoading');

export const setContentType = createAction('uiClientInteractionPage/setContentType');

export const setAddingCommentCommunicationPartId = createAction(
  'uiClientInteractionPage/setAddingCommentCommunicationPartId'
);

export const setAddingCommentsToQuestionId = createAction(
  'uiClientInteractionPage/setAddingCommentsToQuestionId'
);

export const setOperatorId = createAction('uiClientInteractionPage/setOperatorId');

export const setCheckbox = createAction('uiClientInteractionPage/setCheckbox');
export const setAllCheckbox = createAction('uiClientInteractionPage/setAllCheckbox');

export const clientInteractionPageReducer = createReducer(defaultState, {
  [setCurrentCallId]: (state, { payload }) => ({ ...state, currentCallId: payload }),
  [setAddingChecklist]: (state, { payload }) => ({ ...state, isAddingChecklist: payload }),
  [setAddingComment]: (state, { payload }) => ({ ...state, isAddingComment: payload }),
  [setEditingCommentId]: (state, { payload }) => ({ ...state, editingCommentId: payload }),
  [setComments]: (state, { payload }) => ({ ...state, commentsByIds: payload }),
  [addTasks]: (state, { payload }) => ({
    ...state,
    tasks: { ...payload }
  }),
  [setCheckbox]: (state, { payload }) => {
    const selectedCheckboxFilter = payload.filter(item => item.communications.length > 0);
    return { ...state, selectedCheckbox: selectedCheckboxFilter };
  },
  [setAllCheckbox]: (state, { payload }) => ({
    ...state,
    selectedCheckbox: [...state.selectedCheckbox, ...payload]
  }),
  [addComment]: (state, { payload }) => ({
    ...state,
    commentsByIds: {
      ...state.commentsByIds,
      [payload.id]: { ...payload, position: Object.keys(state.commentsByIds).length + 1 }
    }
  }),
  [updateComment]: (state, { payload }) => {
    const commentsByIds = { ...state.commentsByIds };
    commentsByIds[payload?.id] = { ...commentsByIds[payload?.id], ...payload };
    return { ...state, commentsByIds };
  },

  [setHiddenToQuestionComments]: (state, { payload }) => {
    const commentsByIds = { ...state.commentsByIds, ...payload };
    return { ...state, commentsByIds };
  },

  [deleteComment]: (state, { payload }) => {
    const commentsByIds = sortBy(Object.values({ ...state.commentsByIds }), 'position').reduce(
      (acc, comment) => {
        if (!comment) return acc;
        if (comment.id === payload.id) return acc;

        return { ...acc, [comment.id]: { ...comment, position: Object.keys(acc).length + 1 } };
      },
      {}
    );
    return { ...state, commentsByIds };
  },
  [setInitialState]: () => defaultState,
  [setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [setContentType]: (state, { payload }) => ({ ...state, contentType: payload }),
  [setAddingCommentCommunicationPartId]: (state, { payload }) => ({
    ...state,
    addingCommentCommunicationPartId: payload
  }),
  [setOperatorId]: (state, { payload }) => ({ ...state, operatorId: payload })
});
