import camelizeKeys from 'camelcase-keys-deep';
import { buildResourceApiRequest } from 'core/api';
import api from 'core/api';
import { processJsonApiCollection, processJsonApiObject } from 'core/jsonapi';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import decamilize from 'decamelize-keys-deep';
import { get, head } from 'lodash';
import { ACTIONS } from './constants';
import createCustomOperations from './createCustomOperations';
import loadIncludedObjectsToRedux from './loadIncludedObjects';

const resourceOperations = {
  load: ({ name, actions, reactors }) => {
    const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.load });
    return requestOptions => {
      return async (dispatch, getState) => {
        dispatch(actions.loadStarted(requestOptions));
        try {
          const response = await makeApiRequest(parseOptionsForApi(requestOptions));
          const resources = processJsonApiCollection(response.body.data);
          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.loadSucceed(resources));
          if (reactors.onLoadSucceed) {
            await reactors.onLoadSucceed({
              ...requestOptions,
              actions,
              resources,
              dispatch,
              getState
            });
          }
          return { resources, meta: camelizeKeys(response.body.meta) };
        } catch (error) {
          console.log(error);
          dispatch(actions.loadFailed(error.message));
        }
        // users = loadUsers();
      };
    };
  },

  loadById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.loadById });
      return async (dispatch, getState) => {
        dispatch(actions.loadByIdStarted(requestOptions));
        try {
          let response;
          if (requestOptions?.chain !== true) {
            response = await makeApiRequest(requestOptions);
          } else {
            response = await api.getCommunicationChain({ id: requestOptions.id, params: requestOptions });
          }
          const resource = processJsonApiObject(response.body.data);
          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.loadByIdSucceed(resource));
          if (reactors.onLoadByIdSucceed) {
            await reactors.onLoadByIdSucceed({
              ...requestOptions,
              resource,
              dispatch,
              getState,
              response
            });
          }

          return resource;
        } catch (error) {
          console.log(error);
          // !error.status
          //   ? document.location.replace('/not-found')
          //   : dispatch(actions.loadByIdFailed(error.message));
        }
      };
    };
  },

  create: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.create });
      return async (dispatch, getState) => {
        dispatch(actions.createStarted(requestOptions));
        try {
          const response = await makeApiRequest(decamilize(requestOptions));
          const resource = processJsonApiObject(response.body.data);
          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.createSucceed(resource));
          if (reactors.onCreateSucceed) {
            await reactors.onCreateSucceed({ ...requestOptions, resource, dispatch, getState });
          }
          return resource;
        } catch (error) {
          if (error.message) dispatch(actions.createFailed(camelizeKeys(error.message)));
          else if (error?.body?.errors)
            dispatch(
              actions.createFailed({
                errors: camelizeKeys(error.body.errors)
              })
            );
        }
      };
    };
  },

  updateById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.updateById });
      return async (dispatch, getState) => {
        dispatch(actions.updateByIdStarted(requestOptions));
        try {
          const { id, ...updateParams } = requestOptions;
          const response = await makeApiRequest({ id }, decamilize(updateParams));
          const resource = processJsonApiObject(response.body.data);
          const included = get(response, 'body.included', []);
          loadIncludedObjectsToRedux({ objects: included, dispatch });
          dispatch(actions.updateByIdSucceed(resource));
          if (reactors.onUpdateByIdSucceed) {
            await reactors.onUpdateByIdSucceed({
              ...requestOptions,
              resource,
              included,
              dispatch,
              getState,
              response
            });
          }
          return resource;
        } catch (error) {
          if (error?.body?.errors)
            dispatch(
              actions.updateByIdFailed({
                id: requestOptions.id,
                error: camelizeKeys(head(error.body.errors))
              })
            );
          else {
            dispatch(actions.updateByIdFailed({ id: requestOptions.id, error }));
          }

          return Promise.reject(error);
        }
      };
    };
  },

  deleteById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.deleteById });
      return async (dispatch, getState) => {
        dispatch(actions.deleteByIdStarted(requestOptions));
        try {
          await makeApiRequest(requestOptions);
          if (reactors.onDeleteByIdSucceed) {
            await reactors.onDeleteByIdSucceed({ ...requestOptions, dispatch, getState });
          }
          dispatch(actions.deleteByIdSucceed(requestOptions));
          return requestOptions;
        } catch (error) {
          console.log(error);
          dispatch(actions.deleteByIdFailed(error.message));
        }
      };
    };
  }
};

export const createResourceOperations = ({ name, actions, reactors, customOperations }) => {
  const allowedActionNames = Object.keys(ACTIONS);
  const operations = allowedActionNames.reduce((result, actionName) => {
    return {
      ...result,
      [actionName]: resourceOperations[actionName]({ name, actions, reactors })
    };
  }, {});
  return {
    ...operations,
    ...createCustomOperations({ resource: name, customOperations, actions })
  };
};
