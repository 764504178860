import api, { exportToExcelEndpoint, exportToExcelTableCommunicationEndpoint } from 'core/api';
import decamilize from 'decamelize-keys-deep';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { widgetNames } from 'redux/ui/teamAnalyticsPage/operations';
import { get, keyBy, reduce } from 'lodash';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import React from 'react';

export const exportCommunicationTable = exportParams => {
  return async dispatch => {
    const response = await api.exportCommunicationTable(decamilize(exportParams));
    return response;
  };
};

export const exportTeamPageByApi = exportParams => {
  return async dispatch => {
    const response = await api.exportTeamPage(decamilize(exportParams));
    return response;
  };
};

export const exportTeamPage = exportParams => {
  return async dispatch => {
    const response = await api.loadAnalyticsWidgets(
      parseOptionsForApi(decamilize({ widgetNames, ...exportParams }))
    );

    return reduce(
      response.body,
      (result, widget, widgetName) => ({ ...result, [widgetName]: keyBy(widget, 'id') }),
      {}
    );
  };
};

export const exportToExcel = exportParams => {
  return async dispatch => {
    const response = await api.exportToExcel(exportParams);
    return response;
  };
};

export const exportSendEmail = async ({ body, successMessageKey, errorMessageKey }) => {
  try {
    await axios.post(
      body.action === 'export'
        ? `${exportToExcelTableCommunicationEndpoint}/send_mail`
        : exportToExcelEndpoint.path,
      body,
      {
        headers: {
          uid: localStorage.getItem('uid'),
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client')
        }
      }
    );
    message.success(<Trans i18nKey={successMessageKey} />);
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
  }
};

export const getRequestID = async ({ body, errorMessageKey }) => {
  try {
    const result = await axios.post(exportToExcelTableCommunicationEndpoint, body, {
      headers: {
        uid: localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client')
      }
    });
    return result?.data?.requestId;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};

export const checkExportStatus = async ({ body, errorMessageKey }) => {
  try {
    const result = await axios.post(`${exportToExcelTableCommunicationEndpoint}_status`, body);
    return result.data.progress;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};

export const downloadFileAfterExport = async ({ body, errorMessageKey }) => {
  try {
    const response = await axios.post(
      `${exportToExcelTableCommunicationEndpoint}_status/get_result `,
      body,
      { responseType: 'blob' }
    );
    fileDownload(response.data, 'export.xlsx');
    return response.data;
  } catch (error) {
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
    throw error;
  }
};
