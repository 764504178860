import { createSelector } from '@reduxjs/toolkit';
import { get, orderBy, pickBy, some, uniq, isEmpty, max, find, sortBy } from 'lodash';
import { getChecklistsDefinitionsByIds } from '../checklists';

export const getBindingsByIds = state => get(state, 'questionToGroupBindingsResource.byIds', {});
export const getSubgroupBindingsByIds = state => get(state, 'questionToGroupSubgroupBindingsResource.byIds', {});
export const getQuestionsByIds = state => get(state, 'questionsResource.byIds', {});
export const getQuestionGroupsByIds = state => get(state, 'questionGroupsResource.byIds', {});
export const getQuestionSubGroupsByIds = state => get(state, 'checklistQuestionGroupSubgroupsResource.byIds', {});
export const getCalculationFurmulas = state => get(state, 'checklistFormulasResource.byIds', []);
export const getUiChecklist = state => get(state, 'uiChecklistEditor', {});

export const getChecklistDefinitionQuestionSubGroups = createSelector(
  getQuestionSubGroupsByIds,
  (_, checklistDefinition) => checklistDefinition,
  (questionSubGroupsByIds, checklistDefinition) => {
    return orderBy(
      Object.values(questionSubGroupsByIds).reduce(
        (acc, group) =>
          group.checklistDefinitionId === checklistDefinition?.id ? [...acc, group] : acc,
        []
      ),
      'position'
    )}
);
export const getQuestionGroupSubgroupBindings = createSelector(
  getSubgroupBindingsByIds,
  (_, questionSubgroup) => questionSubgroup,
  (bindings, questionSubgroup) =>
    orderBy(pickBy(bindings, { questionGroupSubgroupId: questionSubgroup?.id }), 'position')
);

export const getQuestionGroupSubgroupBindingsIds = createSelector(getQuestionGroupSubgroupBindings, res =>
  res.map(({ id }) => id)
);

export const getQuestionGroupSubgroupQuestions = createSelector(
  getQuestionsByIds,
  getQuestionGroupSubgroupBindings,
  (questionsByIds, questionGroupSubgroupBindings) =>
    questionGroupSubgroupBindings.reduce((result, binding) => {
      const question = get(questionsByIds, binding.questionId);

      if (!question) {
        return result;
      }

      return [...result, { ...question, binding }];
    }, [])
);

export const getQuestionGroupBindings = createSelector(
  getBindingsByIds,
  (_, questionGroup) => questionGroup,
  (bindings, questionGroup) =>
    orderBy(pickBy(bindings, { questionGroupId: questionGroup?.id }), 'position')
);

export const getQuestionGroupBindingsIds = createSelector(getQuestionGroupBindings, res =>
  res.map(({ id }) => id)
);

export const getQuestionGroupQuestions = createSelector(
  getQuestionsByIds,
  getQuestionGroupBindings,
  (questionsByIds, questionGroupBindings) =>
    questionGroupBindings.reduce((result, binding) => {
      const question = get(questionsByIds, binding.questionId);

      if (!question) {
        return result;
      }

      return [...result, { ...question, binding }];
    }, [])
);

export const getChecklistDefinitionCalculationFurmulas = createSelector(
  getCalculationFurmulas,
  (_, checklistDefinition) => checklistDefinition,
  (_, checklistDefinition) => checklistDefinition,
  (questionGroupsByIds, checklistDefinition) => {
    return orderBy(
      Object.values(questionGroupsByIds).reduce(
        (acc, group) =>
          group.checklistDefinitionId === checklistDefinition?.id ? [...acc, group] : acc,
        []
      ),
      'createdAt'
    )
      }
);

export const getChecklistDefinitionQuestionGroups = createSelector(
  getQuestionGroupsByIds,
  (_, checklistDefinition) => checklistDefinition,
  (questionGroupsByIds, checklistDefinition) =>
    orderBy(
      Object.values(questionGroupsByIds).reduce(
        (acc, group) =>
          group.checklistDefinitionId === checklistDefinition?.id ? [...acc, group] : acc,
        []
      ),
      'position'
    )
);

export const getChecklistDefinitionQuestionGroupsWithQuestions = (state, checklistDefinition) =>
  getChecklistDefinitionQuestionGroups(state, checklistDefinition).map(group => ({
    ...group,
    questions: getQuestionGroupQuestions(state, group),
    subgroups: getSubgroupsInGroup(state, group)
}));

export const getSubgroupsInGroup = (state, group) => {
  return Object.values(getQuestionSubGroupsByIds(state)).filter((e) => {
    if (e.questionGroupId === group.id) {
        return e;
    }
  }).map(e => ({...e, questions: getGroupSubgroupQuestionsById(state, e)})).sort((a,b) => a.position - b.position);
}

export const getGroupSubgroupQuestionsById = (state, subgroup) => {
  return getQuestionGroupSubgroupBindings(state, subgroup).map(j => getQuestionsByIds(state)[j.questionId]);
}

export const getWeight = createSelector(
  getUiChecklist,
  (groupid) => {
    return groupid;
  }
);

export const getChecklistDefinitionBindings = createSelector(
  getBindingsByIds,
  getChecklistDefinitionQuestionGroups,
  (bindingsByIds, questionGroups) => {
    return orderBy(
      Object.values(bindingsByIds).filter(binding =>
        some(questionGroups, { id: binding.questionGroupId })
      ),
      'position'
    );
  }
);

export const getChecklistDefinitionBindingsSubgroup = createSelector(
  getSubgroupBindingsByIds,
  getChecklistDefinitionQuestionSubGroups,
  (bindingsByIds, questionGroups) => {
    return orderBy(
      Object.values(bindingsByIds).filter(binding =>
        some(questionGroups, { id: binding.questionGroupSubgroupId })
      ),
      'position'
    );
  }
);

export const getChecklistDefinitionQuestions = createSelector(
  getQuestionsByIds,
  getQuestionGroupsByIds,
  getChecklistDefinitionBindings,
  (questionsByIds, questionGroupsByIds, bindings) =>
    bindings.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionGroup: questionGroupsByIds[binding.questionGroupId] }
      };

      return [...acc, data];
    }, [])
);

export const getChecklistDefinitionQuestionsSubgroup = createSelector(
  getQuestionsByIds,
  getQuestionGroupsByIds,
  getChecklistDefinitionBindings,
  getQuestionSubGroupsByIds,
  getChecklistDefinitionBindingsSubgroup,
  (questionsByIds, questionGroupsByIds, bindings, questionSubgroupsByIds, bindingsSubgroup) => {
    let data = [];
    data = bindingsSubgroup.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionSubgroup: questionSubgroupsByIds[binding.questionGroupSubgroupId] }
      };

      return [...acc, data];
    }, data);
    return bindings.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionGroup: questionGroupsByIds[binding.questionGroupId] }
      };

      return [...acc, data];
    }, data);
  }
);

export const getChecklistDefinitionQuestionsSubgroupSecond = createSelector(
  getQuestionsByIds,
  getQuestionGroupsByIds,
  getChecklistDefinitionBindings,
  getQuestionSubGroupsByIds,
  getChecklistDefinitionBindingsSubgroup,
  (questionsByIds, questionGroupsByIds, bindings, questionSubgroupsByIds, bindingsSubgroup) => {
    let data = [];
    const groupWithSubgroupsArray = [];
    const subgroupsArray = [];
    const groupsArray = [];

    data = bindingsSubgroup.reduce((acc, binding) => {
      const filteredGroupSubgroups = Object.values(questionSubgroupsByIds).filter(
        item => item.id === binding.questionGroupSubgroupId
      );
      filteredGroupSubgroups.forEach(item => {
        const filteredGroups = Object.values(questionGroupsByIds).filter(
          filter => filter.id === item.questionGroupId
        );

        filteredGroups.forEach(group => {
          if (!groupWithSubgroupsArray.find(obj => obj.id === group.id)) {
            groupWithSubgroupsArray.push(...filteredGroups);
          }
        });

        if (!subgroupsArray.find(obj => obj.id === item.id)) {
          subgroupsArray.push(...filteredGroupSubgroups);
        }
      });

      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: {
          ...binding,
          questionSubgroup: questionSubgroupsByIds[binding.questionGroupSubgroupId]
        }
      };

      return [...acc, data];
    }, data);

    // Группы
    bindings.forEach(binding => {
      const filteredGroup = Object.values(questionGroupsByIds).filter(
        item => item.id === binding.questionGroupId
      );

      filteredGroup.forEach(item => {
        if (!groupsArray.find(obj => obj.id === item.id)) {
          groupsArray.push(item);
        }
      });
    });

    data.push(...groupWithSubgroupsArray);
    data.push(...subgroupsArray);
    data.push(...groupsArray);

    return bindings.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionGroup: questionGroupsByIds[binding.questionGroupId] }
      };

      return [...acc, data];
    }, data);
  }
);

export const getGroups = createSelector(getChecklistDefinitionQuestionGroups, groups => {
  return orderBy(groups, 'position');
});

export const getSubgroups = createSelector(getChecklistDefinitionQuestionSubGroups, subgroups => {
  return orderBy(subgroups, 'position');
});

export const getChecklistDefinitionQuestionsGroupsSubgroupOrder = createSelector(
  getQuestionsByIds,
  getQuestionGroupsByIds,
  getChecklistDefinitionBindings,
  getGroups,
  getQuestionSubGroupsByIds,
  getChecklistDefinitionBindingsSubgroup,
  getSubgroups,
  (
    questionsByIds,
    questionGroupsByIds,
    bindings,
    groups,
    questionSubgroupsByIds,
    bindingsSubgroup,
    subgroups
  ) => {
    let data = [];

    data = bindingsSubgroup.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: {
          ...binding,
          questionSubgroup: questionSubgroupsByIds[binding.questionGroupSubgroupId]
        }
      };

      return [...acc, data];
    }, data);

    data.push(...groups);
    data.push(...subgroups);

    return bindings.reduce((acc, binding) => {
      const question = get(questionsByIds, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionGroup: questionGroupsByIds[binding.questionGroupId] }
      };

      return [...acc, data];
    }, data);
  }
);

export const getChecklistDefinitionQuestionGroupsBeforeQuestions = createSelector(
  getChecklistDefinitionQuestionGroups,
  getBindingsByIds,
  getQuestionsByIds,
  (_, currentChecklist) => currentChecklist,
  (questionGroups, bindingsByIds, questionsByIds, checklist) => {
    const uniqueQuestionGroups = questionGroups.map(questionGroup => ({
      ...questionGroup,
      questionsIds: [...new Set(questionGroup.questionsIds)]
    }));
    return uniqueQuestionGroups.reduce((acc, questionGroup) => {
      const questions = orderBy(
        Object.values(bindingsByIds).reduce((result, binding) => {
          if (binding.questionGroupId !== questionGroup.id) {
            return result;
          }

          const question = get(questionsByIds, binding.questionId);

          return question ? [...result, { ...question, binding }] : result;
        }, []),
        'binding.position'
      );

      const uniqueQuestions = questions.filter((question, index, self) =>
        index === self.findIndex((q) => (
          q.id === question.id
        ))
      );

      return checklist.isGroupable
        ? [...acc, questionGroup, ...uniqueQuestions]
        : [...acc, ...uniqueQuestions];
    }, []);
  }
);

export const getQuestionBindings = createSelector(
  getBindingsByIds,
  (_, { question }) => question,
  (bindingsByIds, question) => {
    return Object.values(bindingsByIds).reduce(
      (result, binding) => (binding.questionId === question.id ? [...result, binding] : result),
      []
    );
  }
);

export const getQuestionChecklistDefinitions = createSelector(
  getChecklistsDefinitionsByIds,
  getBindingsByIds,
  getQuestionGroupsByIds,
  (_, question) => question,
  (checklistDefinitionsByIds, bindingsByIds, questionGroupsByIds, question) => {
    const questionBindings = Object.values(bindingsByIds).reduce(
      (result, binding) => (binding?.questionId === question?.id ? [...result, binding] : result),
      []
    );

    const questionGroups = Object.values(questionGroupsByIds).reduce(
      (result, questionGroup) =>
        some(questionBindings, { questionGroupId: questionGroup?.id })
          ? [...result, questionGroup]
          : result,
      []
    );

    const checklistDefinitions = uniq(
      questionGroups.map(
        ({ checklistDefinitionId }) => checklistDefinitionsByIds[checklistDefinitionId]
      )
    );
    return checklistDefinitions;
  }
);

export const getQuestionChecklistDefinitionsSubgroups = createSelector(
  getChecklistsDefinitionsByIds,
  getSubgroupBindingsByIds,
  getQuestionSubGroupsByIds,
  (_, question) => question,
  (checklistDefinitionsByIds, bindingsByIds, questionGroupsByIds, question) => {
    const questionBindings = Object.values(bindingsByIds).reduce(
      (result, binding) => (binding?.questionId === question?.id ? [...result, binding] : result),
      []
    );

    const questionGroups = Object.values(questionGroupsByIds).reduce(
      (result, questionGroup) =>
        some(questionBindings, { questionGroupSubgroupId: questionGroup?.id })
          ? [...result, questionGroup]
          : result,
      []
    );

    const checklistDefinitions = uniq(
      questionGroups.map(
        ({ checklistDefinitionId }) => checklistDefinitionsByIds[checklistDefinitionId]
      )
    );
    return checklistDefinitions;
  }
);


export const getAllRatingValues = (questions = [], bindings = []) => {
  return questions.map(question => ({
    ...question,
    value: max(question.ratingValues),
    percentage: find(bindings, { questionId: question.id }).percentage
  }));
};

export const getSubgroupsQuestionsBindingsInGroup = (state, currentChecklist, questionGroup) => {
    const currentChecklistQuestionSubGroups = sortBy(getChecklistDefinitionQuestionSubGroups(state, currentChecklist), 'position');
    let questionSubgroupsBindings = [];
    let questionsubgroupsQuestions = [];
    const currentGroupSubGroups = currentChecklistQuestionSubGroups.filter((questionSubGroup) => {
        if (questionSubGroup.questionGroupId === questionGroup.id) {
            questionSubgroupsBindings = [...questionSubgroupsBindings, ...getQuestionGroupSubgroupBindings(state, questionSubGroup)];
            questionsubgroupsQuestions = [...questionsubgroupsQuestions, ...getQuestionGroupSubgroupQuestions(state, questionSubGroup)];
            return questionSubGroup;
        }
    }).sort((a,b) => a.position - b.position);
    return {
      currentGroupSubGroups,
      questionSubgroupsBindings,
      questionsubgroupsQuestions
    }
  }
