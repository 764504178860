import {
  CaretRightOutlined,
  PauseOutlined,
  BackwardOutlined,
  ForwardOutlined
} from '@ant-design/icons';
import { Col, Row, Select, Tooltip } from 'antd';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import React, { useCallback } from 'react';
import CommunicationInfoModal from 'components/CommunicationInfoModal';
import { useTranslation } from 'react-i18next';
import { withMediaProps } from 'react-media-player';
import { togglePlaying, updatePlayerState } from 'redux/ui/recordPlayer/reducer';
import { isEmpty, isEqual, pick } from 'lodash';
import IconButton from 'components/IconButton';
import useEvent from '@react-hook/event';
import { useSelector, useDispatch } from 'react-redux';
import AddToLibrary from './AddToLibrary';
import CopyLinkButton from '../../CopyLinkButton';
import AttachTag from './AttachTag';
import StatusSelector from './StatusSelector';
import { PlaybackRateSelect } from './styled';
import DownloadButton from './DownloadButton';
import ReloadButton from './ReloadButton';
import CommunicationChainsButton from '../../CommunicationChainsButton';

const LibraryButton = withConditionalRender(
  { allowedPermissions: [PERMISSIONS.CAN_ADD_LIBRARY_ITEMS] },
  AddToLibrary
);
function isJsonParsable(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
}
const RecordControls = ({
  call,
  reviewId,
  setTime,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true,
  recordUrl,
  reloadFunction,
  basicAuthUrl,
  basicAuthCredentials
}) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { isPlaying, playedSeconds, playbackRate } = useSelector(
    state => pick(state.uiRecordPlayer, ['isPlaying', 'playedSeconds', 'playbackRate']),
    isEqual
  );
  const dispatch = useDispatch();
  const changePlaybackRate = useCallback(
    rate => dispatch(updatePlayerState({ playbackRate: rate })),
    [dispatch]
  );
  const handleTogglePlaying = useCallback(() => dispatch(togglePlaying()), [dispatch]);
  const showAdditionalModal = !isEmpty(call.customFields) || call?.phoneCallChainId;
  const communicationChainsIds = isJsonParsable(call.communicationChainId)
    ? JSON.parse(call.communicationChainId)
    : null;

  const jumpOnTime = duration => {
    setTime(playedSeconds + duration < 0 ? 0 : playedSeconds + duration);
  };
  const handleJumpForward = e => {
    e.preventDefault();
    jumpOnTime(15);
  };
  const handleJumpBackward = e => {
    e.preventDefault();
    jumpOnTime(-15);
  };
  const handleKeyboardPress = e => {
    const isEditorKeyPress = e => {
      return (
        e.target.classList.contains('public-DraftEditor-content') ||
        e.target.classList.contains('ant-input') ||
        e.target.classList.contains('ant-select-selection-search-input')
      );
    };

    const keysToEvents = {
      ArrowLeft: e => handleJumpBackward(e),
      Space: e => {
        e.preventDefault();
        handleTogglePlaying();
      },
      ArrowRight: e => handleJumpForward(e),
      ArrowUp: () => {
        e.preventDefault();
        changePlaybackRate(playbackRate < 2 ? playbackRate + 0.25 : 2);
      },
      ArrowDown: () => {
        e.preventDefault();
        changePlaybackRate(playbackRate > 0.5 ? playbackRate - 0.25 : 0.5);
      }
    };

    if (!Object.keys(keysToEvents).includes(e.code)) return;
    if (e.code === 'Space' && e.shiftKey) return;

    return isEditorKeyPress(e) ? null : keysToEvents[e.code](e);
  };
  useEvent(document, 'keydown', handleKeyboardPress);

  return (
    <Row style={{ marginTop: 22 }} type="flex" align="middle" justify="space-between">
      <Col>
        <Row align="middle" justify="space-between">
          <Col>
            <Tooltip title={t('components.recordPlayer.recordControls.backward')}>
              <IconButton
                icon={<BackwardOutlined style={{ fontSize: 16 }} />}
                shape="circle-outline"
                size="small"
                onClick={handleJumpBackward}
              />
            </Tooltip>
            <Tooltip title={t('components.recordPlayer.recordControls.play')}>
              <IconButton
                icon={
                  isPlaying ? (
                    <PauseOutlined style={{ fontSize: 16 }} />
                  ) : (
                    <CaretRightOutlined style={{ fontSize: 16 }} />
                  )
                }
                shape="circle-outline"
                size="small"
                onClick={handleTogglePlaying}
                margin="0 8px"
              />
            </Tooltip>
            <Tooltip title={t('components.recordPlayer.recordControls.forward')}>
              <IconButton
                icon={<ForwardOutlined style={{ fontSize: 16 }} />}
                shape="circle-outline"
                size="small"
                onClick={handleJumpForward}
              />
            </Tooltip>
            <PlaybackRateSelect value={playbackRate || 1.0} onChange={changePlaybackRate}>
              <Option value={0.5}>0.50</Option>
              <Option value={0.75}>0.75</Option>
              <Option value={1.0}>1.00</Option>
              <Option value={1.25}>1.25</Option>
              <Option value={1.5}>1.50</Option>
              <Option value={1.75}>1.75</Option>
              <Option value={2.0}>2.00</Option>
            </PlaybackRateSelect>
          </Col>
          <Col>
            <Row type="flex" align="middle" justify="start" gutter={[6, 0]}>
              <Col>
                <LibraryButton reviewId={reviewId} />
              </Col>
              <Col>
                <DownloadButton
                  recordUrl={recordUrl}
                  callId={call?.id}
                  basicAuthUrl={basicAuthUrl}
                  basicAuthCredentials={basicAuthCredentials}
                />
              </Col>
              <Col>
                <CopyLinkButton
                  reviewId={reviewId}
                  destinationId={call?.id}
                  destinationType="calls"
                />
              </Col>
              <Col>
                <CommunicationChainsButton communicationChainsIds={communicationChainsIds} />
              </Col>
              <Col>
                <ReloadButton reloadFunction={reloadFunction} />
              </Col>
              {showAdditionalModal && (
                <Col>
                  <CommunicationInfoModal
                    customFields={call?.customFields}
                    phoneCallChainId={call?.phoneCallChainId}
                    id={call?.id}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row align="middle" justify="space-between" gutter={[8, 0]}>
          <Col>
            <AttachTag
              allowAttachTags={allowAttachTags}
              showTags={showTags}
              reviewId={reviewId}
              shouldLoad={shouldLoad}
              truncate={16}
            />
          </Col>
          <Col>
            <StatusSelector call={call} allowLoadStatuses={shouldLoad} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withMediaProps(RecordControls);
