import { Col, message, Modal, Space, Tooltip } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useCallback, useEffect, useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { useTranslation } from 'react-i18next';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import moment from 'moment';
import { operations } from 'redux/lists/usersListReducer';
import { useDispatch, useSelector } from 'react-redux';
import Users from 'components/CrmEntityContent/Users';
import { get, isEmpty, isEqual, omit, reduce, snakeCase } from 'lodash';
import ClientInteractionLink from 'components/ClientInteractionLink';
import ReviewRow from 'components/CrmEntityContent/ReviewRow';
import { customFieldsResource } from 'redux/resources/customFields';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteCommunicationFromChainEndpoint } from 'core/api';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { utils } from 'react-media-player';
import ChainTable from './ChainTable';
import withConditionalRender from '../../components/WithConditionalRender/withConditionalRender';
import SButton from '../../components/Standard/SButton';
import CommentsPanel from '../../components/Comments/CommentsPanel';
import {
  addComment,
  deleteComment,
  setAddingComment,
  updateComment
} from '../../redux/ui/clientInteractionPage/reducer';
import { commentsResource } from '../../redux/resources/comments';
import CommunicationPageHead from './CommunicationPageHead';
import { getUserWithRoleAndUnitById } from '../../redux/selectors/users';

const CommunicationEntityPage = ({
  crmEntityId,
  clientInteraction,
  review,
  communicationChainId
}) => {
  const { t } = useTranslation();

  const clientInteractionsResources = useSelector(state => state.clientInteractionsResource.byIds);
  const chainedInteractions = clientInteraction.phoneCallsIds;
  const [clientInteractions, setClientInteractions] = useState([]);

  useEffect(() => {
    const filteredInteractions = Object.keys(clientInteractionsResources)
      .filter(key => chainedInteractions.includes(key))
      .map(key => clientInteractionsResources[key]);

    const sortedInteractions = filteredInteractions.sort(
      (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
    );

    setClientInteractions(sortedInteractions);
  }, [clientInteractionsResources, chainedInteractions]);

  // const clientInteractionsResources = useSelector(state => state.clientInteractionsResource.byIds);
  // const chainedInteractions = clientInteraction.phoneCallsIds;
  // let clientInteractions = [];
  // Object.keys(clientInteractionsResources).forEach(key => {
  //   if (chainedInteractions.includes(key)) {
  //     clientInteractions.push(clientInteractionsResources[key]);
  //   }
  // });
  // clientInteractions = clientInteractions.sort(
  //   (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
  // );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const ConditionalAddCommentButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    SButton
  );
  const [loadingDeleteCommunication, setLoadingDeleteCommunication] = useState(false);
  const crmEntitiesByIds = useSelector(state => state.crmEntitiesResource.byIds);
  const reviewsByIds = useSelector(state => state.reviewsResource.byIds);
  const crmEntity = crmEntitiesByIds[crmEntityId];
  const rolesByIds = useSelector(state => state.rolesResource.byIds);
  const userId = useSelector(state => get(state, 'reduxTokenAuth.currentUser.attributes.id'));
  const user = useSelector(state => getUserWithRoleAndUnitById(state, userId), isEqual);

  const isAdmin = rolesByIds[user?.roleId]?.isAdmin;

  const initialLoad = async () => {
    setLoading(true);
    await Promise.all([
      await dispatch(
        clientInteractionsResource.operations.load({
          filters: { communicationChainId: clientInteraction.id },
          pagination: false,
          include:
            'reviews.checklist,reviews.comments,unit.users,operator.unit,unit,reviews.reviewer,reviews.tasks'
        })
      ),
      await dispatch(
        operations.load({
          pagination: 'false',
          include: 'unit,role.levels',
          sort: 'last_name'
        })
      ),
      await dispatch(customFieldsResource.operations.load({ pagination: false }))
    ]);
    setLoading(false);
  };

  useEffect(() => {
    initialLoad().then(r => r);
  }, []);

  const clientInteractionsCustomFields = clientInteractions.reduce(
    (acc, { customFields }) => ({
      ...acc,
      ...reduce(customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    }),
    {}
  );

  const customFields = omit(
    {
      ...clientInteractionsCustomFields,
      ...reduce(crmEntity?.customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    },
    ['sales-pipeline', 'sales-pipeline-status', 'sales_pipeline', 'sales_pipeline_status']
  );
  const { checklistManagerState } = useSelector(state => state.uiChecklistManager, isEqual);
  const addingComment = useSelector(state => state.uiClientInteractionPage.isAddingComment);
  const onAddCommentButton = useCallback(() => {
    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
    dispatch(setAddingComment(true));
  }, [dispatch]);

  const onCancelComment = useCallback(() => {
    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL));
    dispatch(setAddingComment(false));
  }, [dispatch]);
  const onCommentSave = useCallback(
    comment => {
      dispatch(addComment(comment));
      if (isEmpty(comment?.metadata?.questionId)) dispatch(setAddingComment(false));
    },
    [dispatch]
  );
  const canComment = isEmpty(review) || checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING;
  const commentsByIds = useSelector(state => state.uiClientInteractionPage.commentsByIds, isEqual);

  const loadingComments = useSelector(state => state.commentsResource.loading);
  const onDeleteComment = useCallback(
    ({ id, commentType }) => {
      if (
        checklistManagerState !== CHECKLIST_MANAGER_STATES.EDITING &&
        commentType === 'review_comment'
      ) {
        return message.warn(t('clientInteractionPage.messages.needToChangeEditMode'));
      }

      if (Object.values(commentsByIds).length < 2) {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL));
        dispatch(setAddingComment(false));
      }
      dispatch(deleteComment({ id }));
    },
    [dispatch, commentsByIds, checklistManagerState]
  );

  const handleRemove = (id, chainId) => {
    Modal.confirm({
      title: 'Удалить коммуникацию из цепочки?',
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
      loading: loadingDeleteCommunication,
      maskClosable: true,
      onOk: async () => {
        try {
          setLoadingDeleteCommunication(true);
          const headers = {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid')
          };
          fetch(deleteCommunicationFromChainEndpoint, {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              communication_id: id,
              communication_chain_id: chainId
            })
          })
            .then(response => {
              if (response.status === 200) {
                setLoadingDeleteCommunication(false);
                const updatedIteractions = clientInteractions.filter(item => item.id !== id);
                setClientInteractions(updatedIteractions);
                message.success('Коммуникация удалена');
              }
              if (response.status !== 200) {
                throw new Error('Network response was not ok');
              }
            })
            .catch(err => {
              message.error(t('errorPages.internalServerError.title'));
              setLoadingDeleteCommunication(false);
              console.log('error', err);
            });
        } catch (err) {
          message.error(t('errorPages.internalServerError.title'));
          setLoadingDeleteCommunication(false);
          console.log('error', err);
        }
      }
    });
  };

  const onUpdateComment = useCallback(
    async comment => {
      let newComment = comment;
      if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
        try {
          newComment = await dispatch(commentsResource.operations.updateById(comment));
          message.success(t('clientInteractionPage.messages.commentSuccessfullyUpdated'));
        } catch (error) {
          message.error(t('clientInteractionPage.messages.updateCommentFailed'));
        }
      } else {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
      }

      dispatch(updateComment(newComment));
    },
    [dispatch, checklistManagerState]
  );

  const getSlicedClientPhoneNumber = value => {
    if (!value) {
      return '';
    }
    const string = value.trim();
    const currentLength = string?.length; // Длина переданной строки
    const maxLength = 5; // Ограничение длины строки в 15 символов
    const substring = `${string.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
    const stringToRender = currentLength > maxLength ? substring : string; // Строка которая пойдёт на рендер
    const directionText = clientInteraction.direction === 'incoming' ? 'Вх. ' : 'Исх. ';

    return (
      <div>
        <Tooltip title="Направление">{directionText}</Tooltip>
        <Tooltip title={string}>
          <SText ellipsis>{stringToRender}</SText>
        </Tooltip>
      </div>
    );
  };

  return (
    <SRow padding="0 16px 16px 16px" minWidth="600px">
      <Col span={24}>
        <CommunicationPageHead
          call={clientInteraction}
          clientInteractions={clientInteractions}
          reviewId={review?.id}
          customFields={customFields}
        />
        <ChainTable clientInteraction={clientInteraction} clientInteractions={clientInteractions} />
        <SCard
          bordered="1px solid #e4e6ed"
          shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
          headPadding="8px 24px"
          rounded="8px"
          loading={loading}
          title={
            !loading && (
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <SText strong fontSize="21px">
                  {t('crmEntity.communications.title')}
                </SText>
                {/*<SText>*/}
                {/*  <Button type="primary">Добавить коммуникацию</Button>*/}
                {/*</SText>*/}
              </div>
            )
          }
        >
          <Space direction="vertical" style={{ display: 'block' }} size={24}>
            {clientInteractions.map(clientInteraction => {
              let reviews = get(clientInteraction, 'reviewsIds', []).map(id => reviewsByIds[id]);
              reviews = reviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              return (
                <React.Fragment key={64534242}>
                  <Space direction="vertical" style={{ display: 'block' }} size={8}>
                    <SRow
                      style={{ border: '1px solid #DFE1E8', borderRadius: '6px' }}
                      padding="16px"
                      backgroundColor="#f2f5ff"
                    >
                      <SCol md={4} xs={24} margin="auto 0">
                        <SRow>
                          {/*<SCol margin="auto 0" style={{ lineHeight: 0 }}>*/}
                          {/*  */}
                          {/*</SCol>*/}
                          <SCol margin="auto 0" flex="auto" display="flex">
                            <ClientInteractionLink
                              isReview={false}
                              type={
                                clientInteraction.clientInteractionType === 'phone-call'
                                  ? 'calls'
                                  : 'text-communications'
                              }
                              id={clientInteraction?.id}
                              communication={clientInteraction}
                            />
                            <SText
                              // title="Номер телефона"
                              ellipsis
                              marginLeft="8px"
                              marginTop="auto"
                              marginBottom="auto"
                              fontSize="15px"
                            >
                              {getSlicedClientPhoneNumber(clientInteraction.clientPhoneNumber)}
                            </SText>
                          </SCol>
                        </SRow>
                      </SCol>
                      <SCol md={4} xs={24} margin="auto 0">
                        <SRow>
                          <SCol margin="auto 0" flex="auto" display="flex">
                            <SText
                              // title="Продолжительность"
                              ellipsis
                              marginLeft="8px"
                              marginTop="auto"
                              marginBottom="auto"
                              fontSize="15px"
                            >
                              <Tooltip title="Продолжительность">
                                <span>{utils.formatTime(clientInteraction?.duration) || '-'}</span>
                              </Tooltip>
                            </SText>
                          </SCol>
                        </SRow>
                      </SCol>
                      <SCol md={8} xs={24} margin="auto 0" fontSize="15px" paddingRight="8px">
                        {moment(clientInteraction?.startedAt).format('DD/MM/YYYY, HH:mm')}
                      </SCol>
                      <SCol md={7} xs={16} margin="auto 0" paddingRight="8px">
                        <Users
                          fontSize={15}
                          key={clientInteraction?.operatorId}
                          operatorId={clientInteraction?.operatorId}
                        />
                      </SCol>
                      {isAdmin && (
                        <SCol
                          md={1}
                          xs={6}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => handleRemove(clientInteraction.id, communicationChainId)}
                            style={{
                              fontSize: '16px',
                              cursor: 'pointer',
                              color: 'red'
                            }}
                          />
                        </SCol>
                      )}
                    </SRow>
                    {reviews.map(review => {
                      return (
                        <ReviewRow
                          key={review.id}
                          review={review}
                          clientInteraction={clientInteraction}
                        />
                      );
                    })}
                  </Space>
                </React.Fragment>
              );
            })}
          </Space>
        </SCard>
      </Col>
      <SRow paddingTop="16px" width="100%">
        <Col span={24}>
          <CommentsPanel
            loading={loadingComments}
            showCommentsList
            allowSelectTimestamp={false}
            useAffix
            allowCommenting={canComment}
            onAddCommentButton={onAddCommentButton}
            onCancelComment={onCancelComment}
            addingComment={addingComment}
            onCommentSave={comment => {
              onCommentSave(comment);
            }}
            onDeleteComment={onDeleteComment}
            comments={Object.values(commentsByIds)}
            onUpdateComment={onUpdateComment}
          />
        </Col>
      </SRow>
    </SRow>
  );
};

export default CommunicationEntityPage;
