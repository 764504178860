import { Col, Row, Select, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import UserPreview from 'components/UserPreview';
import moment from 'core/moment';
import {
  CLIENT_INTERACTION_DIRECTIONS,
  CLIENT_INTERACTIONS_TYPES,
  PERMISSIONS
} from 'core/utils/constants';
import { isEqual, isEmpty, get, findIndex, compact } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import { operations } from 'redux/lists/usersListReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { getCurrentUserPermissions, getUsersWithPermissions } from 'redux/selectors/users';
import SCard from 'components/Standard/SCard';
import pluralize from 'pluralize';
import { IconButton } from 'components/Buttons';
import { StyledSearch, StyledSelect, StyledCol } from 'components/PhoneCallPlayer/styled';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

const loadUsers = ({ dispatch }) =>
  dispatch(
    operations.load({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name'
    })
  );

const CustomSelectDropdown = React.memo(({ menu, onSearch, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoading = useSelector(state => state.usersResource.loading);

  useEffect(() => {
    loadUsers({ dispatch });
  }, []);

  return (
    <SCard
      loading={userLoading}
      headPadding="0 12px"
      bodyPadding="0px"
      rounded="4px"
      shadowed
      width="100%"
      maxWidth="340px"
      minWidth="340px"
      title={
        <StyledSearch
          allowClear
          placeholder={t('components.recordPlayer.phoneCallInformation.searchPlaceholder')}
          onSearch={value => onSearch && onSearch(value)}
          onChange={e => onChange && onChange(e.target.value)}
        />
      }
    >
      {menu}
    </SCard>
  );
});

const PhoneCallInformation = ({ call }) => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const operator = useSelector(state => state.usersResource.byIds[call?.operatorId], isEqual);
  const { loading: userLoading, failed } = useSelector(state => state.usersResource);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  // const rolesByIds = useSelector(state => state.rolesResource.byIds, isEqual);
  const reviewersById = useSelector(state =>
    getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION] })
  );
  const reviewers = Object.values(reviewersById).filter(({ active }) => active);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // * hacking BE properties
  const callType = (
    call?.clientInteractionType ||
    call?.communicationType ||
    pluralize.singular(call?.type || '')
  ).replace('-', '_');

  const canChangeOperator =
    currentUserPermissions.includes(PERMISSIONS.CAN_UPDATE_CLIENT_INTERACTION_OPERATOR) &&
    CLIENT_INTERACTIONS_TYPES.PHONE_CALL === callType &&
    get(history, 'location.pathname', '') !== '/library';

  const onSelectNewOperator = userId => {
    dispatch(
      clientInteractionsResource.operations.updateClientInteractionOperator({
        id: call.id,
        operatorId: userId
      })
    );
  };

  const addOperatorToReviewers = findIndex(reviewers, ({ id }) => id === operator?.id) === -1;

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      gutter={[16, 0]}
      style={{ marginBottom: 6 }}
    >
      <Col span={24}>
        <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
          <SCol display="flex" paddingRight="24px !important">
            {canChangeOperator ? (
              <>
                <StyledSelect
                  onClick={() => {
                    if (failed) {
                      loadUsers({ dispatch });
                    }
                  }}
                  onChange={onSelectNewOperator}
                  defaultValue={
                    <UserPreview
                      userId={operator.id}
                      user={operator}
                      disabled
                      showAvatar
                      showUnit
                    />
                  }
                  value={operator.id}
                  disabled={!canChangeOperator}
                  key={operator.id}
                  loading={userLoading}
                  bordered={false}
                  showArrow={canChangeOperator}
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={node => node.parentNode}
                  dropdownClassName="change-operator-dropdown"
                  dropdownStyle={{ padding: 0 }}
                  dropdownRender={menu => (
                    <CustomSelectDropdown menu={menu} onSearch={setSearch} onChange={setSearch} />
                  )}
                >
                  {compact([addOperatorToReviewers && operator, ...reviewers])
                    .filter(
                      ({ name }) =>
                        isEmpty(search) || name?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0
                    )
                    .map(({ id }) => (
                      <Select.Option key={id} value={id}>
                        <UserPreview
                          userId={id}
                          user={reviewersById[operator]}
                          disabled
                          showAvatar
                          showUnit
                        />
                      </Select.Option>
                    ))}
                </StyledSelect>
                <IconButton
                  tooltip={{
                    title: t('components.recordPlayer.phoneCallInformation.tooltipButtonInfo'),
                    overlayInnerStyle: {
                      width: '400px'
                    }
                  }}
                  button={{
                    icon: <QuestionCircleOutlined style={{ fontSize: 16 }} />,
                    size: 'icon'
                  }}
                />
              </>
            ) : (
              <UserPreview userId={operator.id} user={operator} disabled showAvatar showUnit />
            )}
          </SCol>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callDate')}</Text>
              {moment(call.startedAt).format('DD/MM/YYYY, HH:mm')}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text strong>{t('components.recordPlayer.phoneCallInformation.callType')}</Text>
            <Text>
              {call.direction === 'incoming'
                ? t(CLIENT_INTERACTION_DIRECTIONS.INCOMING.title)
                : t(CLIENT_INTERACTION_DIRECTIONS.OUTCOMING.title)}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callNumber')}</Text>
              {call.clientPhoneNumber}
            </Text>
          </StyledCol>
        </Row>
      </Col>
    </Row>
  );
};

export default PhoneCallInformation;
