import React from 'react';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { Button } from 'antd';
import { SYMBOLIC_TIME_RANGE, SYMBOLIC_TIME_RANGE_LITERALS } from 'core/utils/constants';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.ant-btn {
    padding: 5px 12px;
    background: ${props => (props.isSelected ? '#EFF3FE' : 'white')};
  }
  &.ant-btn:focus {
    padding: 5px 12px;
    background: ${props => (props.isSelected ? '#EFF3FE' : 'white')};
  }

  &.ant-btn .ant-typography {
    color: #597ef7;
  }

  &.ant-btn:hover {
    padding: 5px 12px;
    background: #f5f6f7;
  }
`;

const PeriodPicker = ({ value, onChange }) => {
  const { t } = useTranslation();

  const filteredTimeRange = Object.values(SYMBOLIC_TIME_RANGE).filter(
    item =>
      item !== SYMBOLIC_TIME_RANGE.ALL_TIME &&
      item !== SYMBOLIC_TIME_RANGE.THIS_YEAR &&
      item !== SYMBOLIC_TIME_RANGE.PREVIOUS_YEAR
  );

  return (
    <SRow>
      <SCol span={24} marginBottom="12px">
        <SText marginLeft="24px" fontWeight="bold" color="#82858C">
          {t('general.period')}
        </SText>
      </SCol>
      {filteredTimeRange.map(timeRange => (
        <SCol key={timeRange} marginLeft="12px" marginBottom="2px" span={24}>
          <StyledButton
            type="text"
            onClick={() => onChange(timeRange)}
            className="custom-dropdown"
            key={timeRange}
            value={timeRange}
            isSelected={timeRange === value}
          >
            <SText>{t(SYMBOLIC_TIME_RANGE_LITERALS[timeRange])}</SText>
          </StyledButton>
        </SCol>
      ))}
      <SCol marginLeft="12px" span={24}>
        <StyledButton
          type="text"
          onClick={() => onChange(undefined)}
          className="period-button"
          key={undefined}
          value={undefined}
          isSelected={value === undefined}
        >
          <SText>{t('components.timeSpecificSelector.otherPeriod')}</SText>
        </StyledButton>
      </SCol>
    </SRow>
  );
};

export default PeriodPicker;
