import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { beatifyFloat } from 'core/utils/convertFloat';
import React from 'react';
import styled from 'styled-components';

const DifferenceArrow = ({ previousPeriod, currentPeriod, displayTypeString }) => {
  if (previousPeriod === 'N/A' || currentPeriod === 'N/A') return null;

  if (previousPeriod > currentPeriod) {
    const prevDiff = previousPeriod - currentPeriod;
    if (prevDiff < 0.1) return null;

    return (
      <Tooltip title={`- ${beatifyFloat(prevDiff, 1)} ${displayTypeString}`}>
        <ArrowDown />
      </Tooltip>
    );
  }

  if (previousPeriod < currentPeriod) {
    const currDiff = currentPeriod - previousPeriod;
    if (currDiff < 0.1) return null;

    return (
      <Tooltip title={`+ ${beatifyFloat(currDiff, 1)} ${displayTypeString}`}>
        <ArrowUp />
      </Tooltip>
    );
  }

  return null;
};

const ArrowUp = styled(ArrowUpOutlined)`
  &.anticon {
    color: var(--green_6);
  }
`;

const ArrowDown = styled(ArrowDownOutlined)`
  &.anticon {
    color: var(--red_6);
  }
`;

export default DifferenceArrow;
