import { RATING_METHOD, RATING_CALCULATION } from 'core/utils/constants';
import uniqid from 'uniqid';
import { isEmpty } from 'lodash';
import { operations } from 'redux/lists/checklistDefinitionsList';
import {
  updateChecklistDefinition,
  setCurrentChecklistDefinition,
  createChecklistDefinition,
  loadChecklistDefinitionsByIds
} from './checklistDefinition';
import { createQuestionGroup, loadQuestionGroupsByIds } from './questionGroups';
import { loadQuestionsByIds } from './questions';
import { checklistDefinitionsResource } from '../../resources/checklistDefinitions';

export const setCurrentChecklist = ({ id }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const checklistDefinition = state.checklistDefinitionsResource.byIds[id];

    await dispatch(loadChecklistDefinitionsByIds(state.checklistDefinitionsResource.byIds));
    await dispatch(loadQuestionGroupsByIds(state.questionGroupsResource.byIds));
    await dispatch(loadQuestionsByIds(state.questionsResource.byIds));

    await dispatch(setCurrentChecklistDefinition(checklistDefinition));
  };
};

export const createNewChecklistDefinition = () => {
  return async dispatch => {
    // creating new checklist with default settings and making it current
    const rootQuestionGroup = { id: uniqid(), questionsIds: [] };
    const newChecklist = {
      id: uniqid(),
      name: 'Новая форма оценки',
      isGroupable: false,
      ratingMethod: RATING_METHOD.SIMPLE,
      ratingCalculation: RATING_CALCULATION.SUM,
      questionGroupsIds: [rootQuestionGroup.id],
      questionGroups: [rootQuestionGroup]
    };
    dispatch(createQuestionGroup(rootQuestionGroup));
    dispatch(createChecklistDefinition(newChecklist));
  };
};

export const updateChecklist = checklist => {
  return async dispatch => dispatch(updateChecklistDefinition(checklist));
};

export const loadChecklistDefinitionsWithIncludes = checklistDefinitions => async dispatch => {
  if (isEmpty(checklistDefinitions)) {
    return dispatch(checklistDefinitionsResource.operations.loadWithInclude({ pagination: false }));
  }
};

// QUESTION GROUPS
