import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, List, Row, Typography } from 'antd';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import {
  RATING_CALCULATION,
  RATING_MODE,
  SCALE_TYPES,
  ZONE_TO_COLOR_RELATIONS
} from 'core/utils/constants';
import { find, get, isEmpty, isNil, orderBy } from 'lodash';
import React from 'react';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { Text } = Typography;
const { TextArea } = Input;

export const ColorBox = styled.div`
  width: 64px;
  height: 32px;
  border-radius: 4px;
  background: ${({ color }) => color};
`;

const ColorZonesForCustomScale = ({
  col,
  colorZones,
  scaleType = SCALE_TYPES.max_5.type,
  ratingValues = [],
  ratingMode = RATING_MODE.NUMBERS,
  ratingCalculation = RATING_CALCULATION.SUM,
  binary = false,
  unary = false,
  onChange,
  max = 1,
  min,
  disabledText,
  disabled,
  hideValues = false,
  forceWithoutMedium
}) => {
  const sortedRatingValues = React.useMemo(() => orderBy(ratingValues), [ratingValues]);

  // TODO: improve color zones with two
  const zones = isEmpty(colorZones)
    ? get(SCALE_TYPES, [scaleType, 'colorZones'], SCALE_TYPES.max_5.colorZones)
    : colorZones;

  const { t } = useTranslation();

  const withoutMedium =
    binary || ratingMode === RATING_MODE.NUMBERS
      ? !zones.medium ||
        forceWithoutMedium ||
        (ratingCalculation !== RATING_CALCULATION.SUM && max <= 2)
      : false;

  const medium = get(zones, 'medium', zones.low);

  // min - (red) - low.stop - (yellow) - medium.stop - (green) - max

  const mediumLow = zones.low.stop;
  const highLow = medium.stop;
  const mediumTop = find(
    sortedRatingValues,
    (value, index) => sortedRatingValues[index + 1] === highLow
  );
  const lowTop = find(
    sortedRatingValues,
    (value, index) => sortedRatingValues[index + 1] === mediumLow
  );

  const highLowIndex = sortedRatingValues.indexOf(highLow);
  const mediumLowIndex = sortedRatingValues.indexOf(mediumLow);
  const mediumTopIndex = highLowIndex - 1;

  const canHighUp = !isNil(sortedRatingValues[highLowIndex + 1]) && highLow < max;

  const onHighUp = () => {
    const index = highLowIndex + 1;
    onChange({ ...zones, medium: { ...zones.medium, stop: sortedRatingValues[index] } });
  };
  // const canHighUp =

  const onHighDown = () => {
    const index = highLowIndex - 1;
    onChange({ ...zones, medium: { ...zones.medium, stop: sortedRatingValues[index] } });
  };

  const canMediumLowUp =
    !isNil(sortedRatingValues[mediumLowIndex + 1]) &&
    sortedRatingValues[mediumLowIndex + 1] <= mediumTop;

  const onMediumLowUp = () => {
    const index = mediumLowIndex + 1;
    onChange({ ...zones, low: { ...zones.low, stop: sortedRatingValues[index] } });
  };

  const canMediumLowDown = !isNil(sortedRatingValues[mediumLowIndex - 1]) && lowTop > min;

  const canMediumTopDown =
    !isNil(sortedRatingValues[mediumTopIndex - 1]) && mediumTopIndex > mediumLowIndex;

  const canHighDown = canMediumTopDown;

  const onMediumLowDown = () => {
    const index = mediumLowIndex - 1;
    onChange({ ...zones, low: { ...zones.low, stop: sortedRatingValues[index] } });
  };

  const canMediumTopUp = canHighUp;

  const onMediumTopUp = () => {
    onHighUp();
  };

  const onMediumTopDown = () => {
    onHighDown();
  };
  // const canMediumLowDown =

  const canLowDown = canMediumLowDown && lowTop > min;

  const onLowDown = () => {
    onMediumLowDown();
  };

  const canLowUp = canMediumLowUp;

  const onLowUp = () => {
    onMediumLowUp();
  };

  const onHighUnary = () => {
    onChange({
      ...zones,
      low: { ...zones.low, stop: ratingValues[0] },
      medium: { text: zones.medium.text },
      high: { text: zones.high.text }
    });
  };

  const onLowUnary = () => {
    onChange({
      ...zones,
      low: { ...zones.low, stop: ratingValues[0] + 1 },
      medium: { text: zones.medium.text },
      high: { text: zones.high.text }
    });
  };

  return (
    <Row className="ant-form-item">
      <Col {...col}>
        <List
          header={
            <Row>
              <Col span={4}>
                <Text strong>{t('components.colorZones.list.name')}</Text>
              </Col>
              <Col span={2}>
                <Text strong>{t('components.colorZones.list.color')}</Text>
              </Col>
              <Col span={12}>
                <Text strong>{t('components.colorZones.list.description')}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{t('components.colorZones.list.range')}</Text>
              </Col>
            </Row>
          }
          dataSource={[
            <Row type="flex" align="middle" gutter={[16]} style={{ width: '100%' }}>
              <Col span={4}>{t('components.colorZones.scores.high')}</Col>
              <Col span={2}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.high} />
              </Col>
              <Col span={12}>
                <TextArea
                  disabled={disabledText}
                  autoSize={{ minRows: 3 }}
                  value={t(zones.high.text)}
                  onChange={e =>
                    onChange({
                      ...zones,
                      high: { ...zones.high, text: e.target.value }
                    })
                  }
                />
              </Col>
              <Col span={6}>
                {!binary ? (
                  <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                    <Col span={12}>
                      <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                        <Col>
                          <Text type="secondary">{t('general.from')}: </Text>
                          <Text strong>{highLow}</Text>
                        </Col>
                        <Col span={6}>
                          <Row type="flex" align="middle">
                            <Col span={24}>
                              <Button
                                size="small"
                                type="link"
                                icon={<UpOutlined />}
                                disabled={!canHighUp || disabled}
                                onClick={onHighUp}
                              />
                            </Col>
                            <Col span={24}>
                              <Button
                                type="link"
                                size="small"
                                icon={<DownOutlined />}
                                disabled={!canHighDown || disabled}
                                onClick={onHighDown}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Text type="secondary">{t('general.to')}: </Text>
                      <Text strong>{max}</Text>
                    </Col>
                  </Row>
                ) : unary ? (
                  <Col>
                    <Text>{zones.low.stop === ratingValues[0] ? ratingValues[0] : ''}</Text>
                    <SButton
                      type="link"
                      marginLeft="8px"
                      disabled={disabled}
                      icon={
                        zones.low.stop === ratingValues[0] ? (
                          <Icon icon={X} />
                        ) : (
                          <Icon icon={Check} />
                        )
                      }
                      onClick={zones.low.stop === ratingValues[0] ? onLowUnary : onHighUnary}
                    />
                  </Col>
                ) : (
                  <InputNumber value={max} disabled />
                )}
              </Col>
            </Row>,
            <Row type="flex" align="middle" gutter={[16]} style={{ width: '100%' }}>
              {!withoutMedium && (
                <>
                  <Col span={4}>{t('components.colorZones.scores.medium')}</Col>
                  <Col span={2}>
                    <ColorBox color={ZONE_TO_COLOR_RELATIONS.medium} />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      disabled={disabledText}
                      autoSize={{ minRows: 3 }}
                      value={t(medium.text)}
                      onChange={e =>
                        onChange({
                          ...zones,
                          medium: { ...medium, text: e.target.value }
                        })
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                      <Col span={12}>
                        <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                          <Col>
                            <Text type="secondary">{`${t('general.from')}: `}</Text>
                            <Text strong>{mediumLow}</Text>
                          </Col>
                          <Col span={6}>
                            <Row type="flex" align="middle">
                              <Col span={24}>
                                <Button
                                  size="small"
                                  type="link"
                                  icon={<UpOutlined />}
                                  disabled={!canMediumLowUp || disabled}
                                  onClick={onMediumLowUp}
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                  type="link"
                                  size="small"
                                  icon={<DownOutlined />}
                                  disabled={!canMediumLowDown || disabled}
                                  onClick={onMediumLowDown}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                          <Col>
                            <Text type="secondary">{`${t('general.to')}: `}</Text>
                            <Text strong>{mediumTop}</Text>
                          </Col>
                          <Col span={6}>
                            <Row type="flex" align="middle">
                              <Col span={24}>
                                <Button
                                  size="small"
                                  type="link"
                                  icon={<UpOutlined />}
                                  disabled={!canMediumTopUp || disabled}
                                  onClick={onMediumTopUp}
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                  type="link"
                                  size="small"
                                  icon={<DownOutlined />}
                                  disabled={!canMediumTopDown || disabled}
                                  onClick={onMediumTopDown}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>,
            <Row type="flex" align="middle" gutter={[16]} style={{ width: '100%' }}>
              <Col span={4}>{t('components.colorZones.scores.low')}</Col>
              <Col span={2}>
                <ColorBox color={ZONE_TO_COLOR_RELATIONS.low} />
              </Col>
              <Col span={12}>
                <TextArea
                  disabled={disabledText}
                  autoSize={{ minRows: 3 }}
                  value={t(zones.low.text)}
                  onChange={e =>
                    onChange({
                      ...zones,
                      low: { ...zones.low, text: e.target.value }
                    })
                  }
                />
              </Col>
              <Col span={6}>
                {!binary ? (
                  <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                    <Col span={12}>
                      <Text type="secondary">{`${t('general.from')}: `}</Text>
                      <Text strong>{min}</Text>
                    </Col>

                    <Col span={12}>
                      <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                        <Col>
                          <Text type="secondary">{`${t('general.to')}: `}</Text>
                          <Text strong>{lowTop}</Text>
                        </Col>
                        <Col span={6}>
                          <Row type="flex" align="middle">
                            <Col span={24}>
                              <Button
                                size="small"
                                type="link"
                                icon={<UpOutlined />}
                                disabled={!canLowUp || disabled}
                                onClick={onLowUp}
                              />
                            </Col>
                            <Col span={24}>
                              <Button
                                type="link"
                                size="small"
                                icon={<DownOutlined />}
                                disabled={!canLowDown || disabled}
                                onClick={onLowDown}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : unary ? (
                  <Col>
                    <Text>{zones.low.stop === ratingValues[0] + 1 ? ratingValues[0] : ''}</Text>
                    <SButton
                      marginLeft="8px"
                      type="link"
                      disabled={disabled}
                      icon={
                        zones.low.stop === ratingValues[0] + 1 ? (
                          <Icon icon={X} />
                        ) : (
                          <Icon icon={Check} />
                        )
                      }
                      onClick={zones.low.stop === ratingValues[0] + 1 ? onHighUnary : onLowUnary}
                    />
                  </Col>
                ) : (
                  <InputNumber value={min} disabled />
                )}
              </Col>
            </Row>
          ]}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Col>
    </Row>
  );
};

export default ColorZonesForCustomScale;
