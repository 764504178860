import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';
import api from 'core/api';

export const checklistFormulasResource = createReduxResource({
  name: 'checklistFormulas',
  customOperations: {
    getChecklistFormulasV2: {
      callEndpoint: api.getChecklistFormulasV2,
      onSuccess: ({ result }) => {
        return result;
      }
    }
  },
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const checklistDefinition =
        state.checklistDefinitionsResource.byIds[resource.checklistDefinitionId];

      if (checklistDefinition) {
        updateResource({
          dispatch,
          type: 'checklistDefinitions',
          resource: {
            ...checklistDefinition,
            checklistFormulasIds: [...checklistDefinition.checklistFormulasIds, resource.id]
          }
        });
      }
    }
  },
  additionalReducer: {
    // LOAD
    getChecklistFormulasV2Started: state => ({ ...state, loading: true }),
    getChecklistFormulasV2Succeed: (state, { payload }) => ({
      ...state,
      loading: false,
      byIds: { ...state.byIds, ...payload }
    }),
    getChecklistFormulasV2Failed: (state, { payload }) => ({
      ...state,
      failed: true,
      errors: [...state.errors, payload]
    })
  }
});
