import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  currentList: 'checklists'
};

export const setCurrentList = createAction('uiChecklistsAndQuestionsPage/setCurrentList');

export const checklistsAndQuestionsPageReducer = createReducer(defaultState, {
  [setCurrentList]: (state, { payload }) => ({ ...state, currentList: payload })
});
