export default {
  teamPage: {
    unitTable: {
      empty: 'No hay datos',
      infoIcons: {
        info: 'En esta tabla se refleja la analitica total por todas las evaluaciones',
        scoreInfo: 'Nota media entre todas las evaluaciones de la listas a chequear elegida',
        reviewsCountInfo:
          'Cantidad de tofas la evaluaciones realizadas por la lista a chequear elegida',
        greenZoneCountInfo:
          'Porcentaje de evaluaciones en la zona verde por la lista a chequear elegida',
        allScoresInfo: 'Todas las evaluaciones por zonas de color por la lista a chequear elegida'
      },
      columns: {
        employee: 'Empleado',
        unit: 'Departamento',
        score: 'Nota',
        reviewsCount: 'Evaluaciones',
        greenZoneCount: 'Exelente',
        allScores: 'Todas las evaluaciones',
        flags: 'Banderas'
      },
      differenceArrowScoreLiteral: 'nota'
    },
    exportTable: {
      columns: {
        employee: 'Empleado',
        unit: 'Departamento',
        averageScore: 'Puntuación media',
        totalCountReviews: 'Revisiones de recuento total',
        greenZonePercentage: 'Porcentaje de llamadas en zona verde',
        grayColorZone: 'Zona gris (sin clasificar)',
        redColorZone: 'Calificaciones en la zona roja',
        yellowColorZone: 'Calificaciones en la zona amarilla',
        greenColorZone: 'Calificaciones en la zona verde',
        countCommentsWithoutFlag: 'Número de comentarios sin bandera',
        redComments: 'Comentarios rojos',
        yellowComments: 'Comentarios amarillos',
        greenComments: 'Comentarios verdes'
      }
    },
    buttons: {
      exportTeamPageAnalytics: 'Exportar a Exel',
      units: 'Departamentos',
      employees: 'Personal'
    },
    pageFilters: {
      exportModal: {
        title: 'Analítica exportada',
        description1: 'El resultado de la exportación le llegará a su',
        description2: 'en unos minutos'
      },
      messages: { exportTableFailed: 'No se logró exportar la analítica' },
      filters: {
        unitPlaceholder: 'Elija un departamento',
        unitPlaceholderMax: 'Elegido',
        unitPlaceholderMax2: 'departamento'
      }
    }
  }
};
