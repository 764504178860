import { Alert, Form, Input, Modal, } from 'antd';
import LabelWithInfo from 'components/LabelWithInfo';
import { get, isEmpty, isEqual, last, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { basePosition } from 'core/utils/dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checklistQuestionGroupSubgroupsResource } from 'redux/resources/checklistQuestionGroupSubgroups';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import { setAddingSubGroupData } from 'redux/ui/checklistEditor/reducer';
import { getChecklistDefinitionQuestionSubGroups } from 'redux/selectors/checklistItems/checklistItems';

const defaultGroup = {};

const AddSubGroupModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentChecklist, addingSubGroupData = defaultGroup } = useSelector(
    state => state.uiChecklistEditor,
    isEqual
  );

  const subgroupsIds = useSelector(state => state.checklistQuestionGroupSubgroupsResource.byIds, isEqual);

  const layout = {
    labelCol: {
      span: 4
    },
    labelAlign: 'left',
    wrapperCol: {
      span: 20
    }
  };

  const checklistDefinitionId = get(currentChecklist, 'id', undefined);
  const visible = !isEmpty(addingSubGroupData);
  const isNew = isEmpty(get(subgroupsIds, addingSubGroupData.id, {}));

  const currentChecklistQuestionGroups = useSelector(
    state => sortBy(getChecklistDefinitionQuestionSubGroups(state, currentChecklist), 'position'),
    isEqual
  );

  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);

  const createOrEditQuestionGroupSubgroups = async ({
    name,
    checklistDefinitionId,
    position
  }) => {
    if (isNew) {
      await dispatch(
        checklistQuestionGroupSubgroupsResource.operations.create({
          "name": name,
          "question_group_id": addingSubGroupData.questionGroup.id,
          "checklist_definition_id": checklistDefinitionId,
          "organization": { "id": organizationInfo.id },
          "position": position,
          "questions": []
        })
      );
    } else {
      await dispatch(
        checklistQuestionGroupSubgroupsResource.operations.updateById({
          id: get(addingSubGroupData, 'id', ''),
          "name": name,
          "question_group_id": addingSubGroupData.questionGroupId,
          "checklist_definition_id": checklistDefinitionId,
          "organization": { "id": organizationInfo.id },
          "position": addingSubGroupData.position,
          "questions": addingSubGroupData.questions
        })
      );
    }
    
    return [];
  };

  const saveAndCloseModal = async ({ name }) => {
    const savedQuestionGroup = await createOrEditQuestionGroupSubgroups({
      name,
      checklistDefinitionId,
      position: get(last(currentChecklistQuestionGroups), 'position', 0) + basePosition,
    });

    if (savedQuestionGroup) {
      form.setFieldsValue({ name: '' });
      dispatch(setAddingSubGroupData());
      dispatch(setEditableChecklistDefinition({ id: get(currentChecklist, 'id', '') }));
    }
  };

  const handleSubmit = values => {
    if (checklistDefinitionId) {
      const { name } = values;
      saveAndCloseModal({ name });
    }
  };

  const closeModal = () => {
    form.setFieldsValue({ name: '' });
    dispatch(setAddingSubGroupData());
  };

  useEffect(() => {
    if (addingSubGroupData?.name) {
      form.setFieldsValue({ name: addingSubGroupData.name });
    }
  }, [addingSubGroupData?.name]);

  return (
    <Modal
      maskClosable
      visible={visible}
      title={t('checklistsPage.subGroup.modal.title')}
      width={768}
      onOk={form.submit}
      onCancel={closeModal}
      okText={
        isNew
          ? t('checklistsPage.editQuestionGroupModal.ok.create')
          : t('checklistsPage.editQuestionGroupModal.ok.update')
      }
      cancelText={t('checklistsPage.editQuestionGroupModal.cancel')}
    >
      <Alert
        description={t('checklistsPage.subGroup.modal.description')}
        type="info"
        style={{ marginBottom: 16 }}
      />
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{ name: addingSubGroupData?.name, percentageCalculationStartingWithZero: false }}
      >
        <Form.Item
          label={
            <LabelWithInfo
              infoTitle={t('checklistsPage.subGroup.modal.namePlaceholder')}
            >
              {t('checklistsPage.editQuestionGroupModal.form.fields.name')}
            </LabelWithInfo>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t('checklistsPage.editQuestionGroupModal.form.messages.enterName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t('checklistsPage.calculationFurmula.modal.namePlaceholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSubGroupModal;
