export default {
  checklistsPage: {
    title: 'Moduli di valutazione',
    info:
      'Premi su Crea nuovo modulo di valutazione per impostare un nuovo modulo di valutazione. E\' possibile modificare il numero di criteri, la scala e tutte le condizioni di valutazione in esso nello stato "Bozza". Per iniziare a valutare le comunicazioni per questo modulo di valutazione premi su Pubblica. Nell\'elenco seguente tutti i moduli di valutazione vengono salvati in stati diversi: Bozza, Pubblicato e Archivio. Archivia quelle forme di valutazione che non desideri piu\' valutare. Premi su Crea criteri per il modulo di valutazione per un nuovo elemento di voto che puo\' essere incluso in qualsiasi voto. Per visualizzare tutti i criteri per tutte le forme di valutazione, premi su "Criteri".',
    infoButton: 'Maggiori dettagli qui',
    buttons: {
      createChecklists: 'Crea un modulo di valutazione',
      createQuestion: 'Crea criterio',
      checklists: 'Moduli di valutazione',
      questions: 'Criteri'
    },
    messages: {
      question: 'Criteri',
      questionSuccessfullyCreated: 'creato con successo',
      questionSuccessfullyUpdated: 'aggiornato con successo',
      createQuestionfailed: 'Impossibile creare il criterio',
      updateQuestionfailed: 'Impossibile aggiornare il criterio',
      errorDragAndDropQuestion: 'Non è possibile modificare la posizione di questo criterio',
      errorDragAndDropQuestionSubgroup:
        'Non è possibile modificare la posizione di questo sottogruppo'
    },
    addQuestionModal: {
      title: 'Aggiungere il criterio',
      buttons: {
        libraryQuestion: 'Criterio dalla libreria',
        newQuestion: 'Nuovo criterio'
      },
      messages: {
        disabledByWeight:
          "Non e' possibile aggiungere un criterio perche' il peso del criterio del modulo di valutazione ha raggiunto il 100%.",
        info:
          'Crea un nuovo criterio o aggiungi un criterio dalla libreria. La libreria contiene criteri che potrebbero essere stati aggiunti ad altri moduli di valutazione.'
      }
    },
    checklistInfo: {
      emptyName: 'Nome del modulo di valutazione',
      copy: '(Copia)',
      messages: {
        copySuccessfullyCreated: 'Copia creata',
        createCopyfailed: "Copia non e' stata creata",
        checklistSuccessfullyDeleted: 'Modulo di valutazione eliminato',
        checklistSuccessfullyPublished: 'Modulo di valutazione pubblicato',
        completedQuestionsSumm:
          'La somma dei pesi dei criteri nel modulo di valutazione deve essere 100%',
        emptyChecklistQuestions:
          'Devono esserci criteri per la pubblicazione nel modulo di valutazione',
        checklistSuccessfullyArchived: 'Modulo di valutazione spostato in archivio'
      },
      confirmArchive: {
        title: "Spostare il modulo di valutazione nell'archivio?",
        description:
          'I moduli di valutazione archiviati non sono disponibili per la selezione durante la verifica',
        ok: "All'archivio",
        cancel: 'Annulla'
      },
      confirmDelete: {
        title: 'Eliminare il modulo di valutazione?',
        description:
          "Modulo di valutazione eliminato non puo' essere ripristinato. Se questo modulo di valutazione non e' attuale per te, puoi trasferirla nell'archivio",
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      confirmPublish: {
        title: 'Pubblicare il modulo di valutazione?',
        description:
          " Dopo la pubblicazione del modulo di valutazione, non sara' possibile modificare: il metodo e il calcolo della valutazione, la scala di valutazione e il peso del criterio. Assicurati che tutto sia configurato correttamente prima di pubblicare",
        ok: 'Continuare',
        cancel: 'Annulla'
      },
      menu: {
        setting: 'Vai alle impostazioni del modulo di valutazione',
        copy: 'Crea copia',
        archive: 'Sposta in archivio',
        delete: 'Elimina modulo di valutazione'
      },
      breadcrumbs: {
        checklists: 'Modulo di valutazione',
        checklistSettings: 'Impostazioni del modulo di valutazioni',
        checklistQuestions: 'Impostazioni dei criteri'
      },
      buttons: {
        publish: 'Pubblicare il modulo di valutazione',
        actions: 'Azioni'
      },
      items: {
        ratingMethod: 'Metodo di valutazione: ',
        ratingCalculation: 'Calcolo di valutazione: ',
        scaleType: 'Scala per la valutazione: ',
        communicationTypes: 'Tipi di comunicazioni: ',
        units: 'Dipartimenti: ',
        totalChecklistScore: 'Il punteggio finale per il modulo di valutazione: ',
        checklistScore: 'Punteggio sul modulo di valutazione: ',
        totalSumPercentage:
          'Il peso cumulativo di tutti i criteri sotto forma di valutazione, in percentuale',
        sumPercentage: 'Peso dei criteri'
      },
      modal: {
        title: 'Impostazione delle zone di valutazione',
        ok: 'Pubblicare',
        cancel: 'Annulla'
      }
    },
    checklistItems: {
      messages: {
        question: 'Criterio',
        questionSuccessfullyCreated: 'creato e aggiunto al modulo di valutazione',
        questionSuccessfullyUpdated: 'aggiornato',
        createQuestionFailed: 'Impossibile creare o aggiornare il criterio'
      }
    },
    editQuestionGroupModal: {
      title: 'Impostazioni del gruppo di criteri',
      ok: {
        create: 'Creare',
        update: 'Aggiornare'
      },
      cancel: 'Annulla',
      alert: {
        description:
          "Combina i criteri del modulo di valutazione in gruppi per tenere traccia dei risultati e delle loro dinamiche per un'abilita' comune del dipendente. Questo ti aiutera' a strutturare il modulo di valutazione e il sistema per valutare le conoscenze e le competenze dei dipendenti."
      },
      form: {
        fields: {
          name: 'Nome',
          namePlaceholder: 'Immetti il nome del gruppo di criteri',
          nameInfo:
            "Il nome del gruppo che verra' visualizzato durante la valutazione e nella pagina di analisi",
          percentageCalculationStartingWithZero:
            'Il gruppo potrebbe evere una percentuale negativa',
          percentageCalculationStartingWithZeroPlaceholder:
            'In questo caso il calcolo viene effettuato da zero.'
        },
        messages: { enterName: 'Inserisci un titolo' }
      }
    },
    question: {
      confirmDelete: {
        title: 'Sei sicuro di voler eliminare il criterio dal modulo di valutazione?',
        description: "Sara' ancora disponibile nell'elenco dei criteri",
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      menu: {
        edit: 'Modifica criterio',
        copy: 'Crea copia',
        delete: 'Elimina criterio'
      },
      weight: 'Peso del criterio',
      tooltip: { title: 'Criterio di autofile' }
    },
    questionGroup: {
      ratingMethod: 'Metodo di calcolo non stabilito',
      confirmDelete: {
        title: 'Eliminare il gruppo?',
        description:
          'I criteri nel gruppo eliminato veranno rimossi anche dal modulo di valutazione corrente',
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      menu: {
        edit: 'Modifica gruppo',
        copy: 'Crea copia',
        delete: 'Elimina gruppo'
      },
      sumRates: 'Punteggio di gruppo',
      sumRatesInfo: 'Punteggio complessivo di gruppo',
      sumPercentage: 'Peso del gruppo',
      sumSubPercentage: 'Peso del sottogruppo',
      sumPercentageInfo: 'Il peso totale di tutti criteri nel gruppo, in percentuale',
      buttons: {
        addQuestion: 'Aggiungi criterio',
        addQuestionGroup: 'Aggiungi gruppo'
      }
    },
    calculationFurmula: {
      buttons: {
        addCalculationFurmula: 'Aggiungi formula'
      },
      menu: {
        edit: 'Modifica formula',
        delete: 'Elimina formula',
        check: 'Verificare la formula'        
      },
      modal: {
        title: 'Impostazioni delle formule di calcolo',
        namePlaceholder: 'Inserisci il nome della formula',
        description: 'Aggiungi formule per il calcolo e monitora la dinamica dei dipendenti secondo i tuoi criteri personali.'
      },
      confirmDelete: {
        title_1: "Sei sicuro di voler eliminare la formula «",
        title_2: "» dalla scheda di valutazione?"
      },
      confirmCheck: {
        title_error: "Verifica la correttezza delle parentesi in questa parte della formula:",
        title_ok: "Non ci sono errori nella formula",
        ok: 'ok',
      }
    },
    subGroup: {
      sumRatesInfo: 'Punteggio totale del gruppo',
      buttons: {
        addSubGroup: 'Aggiungi sottogruppo'
      },
      modal: {
        title: 'Impostazioni del Gruppo dei Criteri.',
        namePlaceholder: 'Nome del gruppo.',
        description: "Combina i criteri del modulo di valutazione in gruppi per tenere traccia dei risultati e della loro dinamica basata su una competenza comune dell'impiegato. Ciò aiuta a strutturare il modulo di valutazione stesso e il sistema di valutazione delle conoscenze e delle competenze degli impiegati."
      },
      sumRates: 'Punteggio del sottogruppo',
      menu: {
        edit: 'Modifica sottogruppo',
        delete: 'Eliminare sottogruppo?'
      }
    },
    questionsFromLibraryModal: {
      title: 'Seleziona i criteri dalla libreria',
      ok: 'Aggiungere',
      cancel: 'Annulla',
      alert: {
        title: 'Questo elenco mostra i criteri solo con una scala e zone di valutazione adeguate'
      }
    }
  }
};
