export default {
  general: {
    save: 'Save',
    cancel: 'Cancel',
    canceling: 'Cancel',
    edit: 'Edit',
    answer: 'Reply',
    delete: 'Delete',
    disagree: 'I disagree',
    accept: 'Accept',
    accepted: 'Accepted',
    reject: 'Reject',
    rejected: 'Rejected',
    from: 'From',
    to: 'To',
    time: 'Time',
    hidden: 'Hidden',
    visibleToAll: 'Public',
    update: 'Update',
    add: 'Add',
    redFlag: 'Red',
    yellowFlag: 'Yellow',
    greenFlag: 'Green',
    withoutFlag: 'Without flag',
    uploadImage: 'Add image',
    loading: 'Loading',
    requiredField: 'Required field',
    continue: 'Continue',
    date: 'Date',
    dateAndTime: 'Date and time',
    direction: 'Direction',
    number: 'Number',
    phoneNumber: 'Phone number',
    tags: 'Tags',
    tag: 'Tag',
    reviewDate: 'Evaluation date',
    clientInteractionDate: 'Communication date',
    maxRate: 'Highest possible rate',
    applicationDate: 'Aplication date',
    applicator: 'Filed',
    checklistDefinition: 'Score card',
    checklistDefinitions: 'Score cards',
    checklistDefinitionPlaceholder: 'Choose scorecard',
    selectChecklist: 'Choose a checklist of assessment to start review',
    criterion: 'Question',
    criteries: 'Questions',
    clientInteractionType: 'Type',
    clientInteractionTypes: 'Types',
    status: 'Status',
    statuses: 'Statuses',
    users: 'Users',
    executor: 'Performer',
    executors: 'Performers',
    role: 'Role',
    roles: 'Roles',
    changed: 'Changed',
    change: 'changed',
    view: 'Watch',
    unit: 'Unit',
    units: 'Units',
    settings: 'Settings',
    analytics: 'Analytics',
    yes: 'Yes',
    no: 'No',
    name: 'Name',
    lastName: 'Last name',
    email: 'E-mail',
    goTo: 'Go to',
    employees: 'Employees',
    employee: 'Employee',
    all: 'All',
    initials: 'Full name',
    duration: 'Duration',
    clientInteractionsInChainCount: 'Communications in chain',
    communicationChainId: 'Communication chain',
    score: 'Score',
    flagsAndComments: 'Flags and comments',
    close: 'Finish',
    stop: 'Stop',
    download: 'Download',
    reviewer: 'Reviewer',
    reviewers: 'Reviewers',
    total: 'Total',
    author: 'Author',
    operator: 'Operator',
    result: 'Result',
    found: 'Found',
    comments: 'Comments',
    title: 'Title',
    start: 'Starting',
    finish: 'Completion',
    create: 'Create',
    created: 'Created',
    selected: 'Selected',
    progress: 'Progress',
    apply: 'Apply',
    period: 'Period',
    communication: 'communication',
    review: 'review',
    startOfPeriod: 'Start date',
    endOfPeriod: 'End date',
    displayByDate: 'Display by date: ',
    systemMessages: 'System messages',
    areHidden: 'are hidden',
    opened: 'open',
    showDetails: 'Show details',
    empty: 'No data',
    goBack: 'Back',
    send: 'Send',
    maxRate: 'Highest possible rate',
    open: 'Open'
  }
};
