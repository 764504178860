import { OutlinedButton } from 'components/Buttons';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import {
  every,
  find,
  get,
  isEmpty,
  isEqual,
  pickBy,
  sum,
  uniq,
  flatMap,
  compact,
  keyBy
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { message, Modal } from 'antd';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import {
  COMMUNICATIONS_DISTRIBUTION_METHODS,
  REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS,
  WORK_PLAN_TASK_CONFIGURATIONS_STATUSES
} from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import SText from 'components/Standard/SText';
import { getUnitsIdsCommonForUsers } from 'redux/entities/organization/operations';
import { salePipelinesResource } from 'redux/resources/salesPipelines';
import FiltersSettings from './ConfigurationEditorModules/FiltersSettings';
import ScheduleSettings from './ConfigurationEditorModules/ScheduleSettings';
import Reviewers from './ConfigurationEditorModules/Reviewers';
import { loadConfigurationToUi, mapUiDataForRequest } from './operations';

const createConfiguration = workPlanTaskConfigurationsResource.operations.create;
const runConfiguration = workPlanTaskConfigurationsResource.operations.runWorkPlanTaskConfiguration;
const updateConfiguration = workPlanTaskConfigurationsResource.operations.updateById;

const WorkPlanTaskConfigurationEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [name, setName] = useState();
  const [
    reviewersCommunicationsDistributionMethod,
    setReviewersCommunicationsDistributionMethod
  ] = useState(REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS.AUTO.value);
  const [schedule, setSchedule] = useState({});
  const [clientInteractionsTypesConfigs, setClientInteractionsConfigs] = useState({});
  const [distribution, setDistribution] = useState({});
  const [configurationsBindingsIds, setConfigurationsBindingsIds] = useState([]);
  const [reviewersIds, setReviewersIds] = useState([]);
  const [removedReviewersIds, setRemovedReviewersIds] = useState([]);
  const dispatch = useDispatch();
  const reviewerBindingsByIds = useSelector(
    state =>
      keyBy(
        pickBy(
          Object.values(state.workPlanReviewerBindingsResource.byIds).filter(binding =>
            configurationsBindingsIds.includes(binding.id)
          ),
          { taskConfigurationId: id }
        ),
        'id'
      ),
    isEqual
  );
  const isNew = id === 'new';
  const configurationLoading = useSelector(
    state =>
      state.workPlanTaskConfigurationsResource.updateByIdStarted ||
      state.workPlanTaskConfigurationsResource.createStarted
  );

  const configFiltersIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds[id]?.filtersIds
  );

  const configurationStatus = useSelector(state =>
    isNew
      ? WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value
      : get(
          state.workPlanTaskConfigurationsResource.byIds,
          [id, 'status'],
          WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value
        )
  );

  const loadConfiguration = async id => {
    setLoading(true);
    const config = await dispatch(loadConfigurationToUi(id));

    if (!isEmpty(config)) {
      setName(config?.name);
      setSchedule(config?.schedule);
      setConfigurationsBindingsIds(config?.reviewerBindingsIds);
      setClientInteractionsConfigs(config?.clientInteractionsTypesConfigs);
      setReviewersCommunicationsDistributionMethod(
        config?.reviewersCommunicationsDistributionMethod
      );
      setDistribution(config?.distribution);
      setReviewersIds(config?.reviewersIds);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && !isNew) {
      // do request
      loadConfiguration(id);
    }
  }, [id]);

  useEffect(() => {
    dispatch(salePipelinesResource.operations.load({ pagination: false, include: 'statuses' }));
  }, []);

  const nonDeletedClientInteractionsConfigs = Object.values(clientInteractionsTypesConfigs).reduce(
    (acc, config) => {
      if (get(config, '_destroy')) return acc;
      return {
        ...acc,
        [config.id]: {
          ...config
        }
      };
    },
    {}
  );

  const operatorConfigs = Object.values(nonDeletedClientInteractionsConfigs).map(
    config => find(config.fields, ({ key }) => key === 'operatorsIds')?.settings?.operatorsIds
  );

  const operatorsIds = compact(flatMap(operatorConfigs));

  useEffect(() => {
    if (!isEmpty(operatorsIds)) {
      dispatch(getUnitsIdsCommonForUsers(operatorsIds));
    }
  }, [operatorsIds]);

  const isEveryConfigHasOperatos =
    every(operatorConfigs, config => !isEmpty(config)) && !isEmpty(operatorConfigs);

  const isScheduleValid = !isEmpty(schedule?.periodTimeFrom && schedule?.periodTimeTo);

  const checkFiltersValid = () => {
    const isValidClientInteractionsMaxCount =
      !isEmpty(nonDeletedClientInteractionsConfigs) &&
      every(
        nonDeletedClientInteractionsConfigs,
        ({ clientInteractionsMaxCount }) => clientInteractionsMaxCount > 0
      ) &&
      isEveryConfigHasOperatos;

    // * Проверяется каждый settings конфигурации на isEmpty
    const isValidAdditionalFields = every(
      flatMap(
        Object.values(nonDeletedClientInteractionsConfigs).map(config => config?.fields)
      ).reduce((acc, item) => {
        if (get(item, '_destroy') === '1') return acc;
        return [...acc, item?.settings];
      }, []),
      item => !isEmpty(item)
    );

    return isValidClientInteractionsMaxCount && isValidAdditionalFields;
  };

  const commonUnitsIds = useSelector(
    state => state.organizationInfo.commonUnitsIdsforUsers,
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const isFiltersValid = checkFiltersValid();

  const isDistributionValid =
    !isEmpty(distribution) &&
    every(Object.values(distribution), ({ totalDistributed }) => totalDistributed === 100);

  const isValidName = name && !isEmpty(name?.trim());

  const isValidCommonUnits = () => {
    const reviewers = isEmpty(Object.keys(Object.values(distribution)[0]?.byIds))
      ? reviewersIds
      : Object.keys(Object.values(distribution)[0]?.byIds);

    return isEmpty(
      reviewers.filter(userId => !commonUnitsIds.includes(usersByIds[userId]?.unitId))
    );
  };

  const isValid =
    isValidName && isScheduleValid && isFiltersValid && isDistributionValid && isValidCommonUnits();

  const onSave = async () => {
    try {
      const data = mapUiDataForRequest({
        name,
        isNew,
        schedule,
        distribution,
        configFiltersIds,
        removedReviewersIds,
        reviewerBindingsByIds,
        clientInteractionsTypesConfigs,
        reviewersCommunicationsDistributionMethod
      });

      const getConfiguration = async () => {
        if (isNew) {
          const res = await dispatch(createConfiguration(data));
          if (res) {
            message.success(t('workPlanTaskConfigurationEditor.taskSaved'));
            history.push(`/work-plan/task-configurations/editor/${res.id}`);
          }
        }

        if (!isNew) {
          if (configurationStatus === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value) {
            const res = await dispatch(updateConfiguration({ ...data, id }));

            if (res) {
              loadConfiguration(id);
              message.success(t('workPlanTaskConfigurationEditor.taskSaved'));
            }
          }

          if (configurationStatus !== WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value)
            return Modal.confirm({
              maskClosable: true,
              title:
                'Изменения внесенные в данную конфигурацию задачи, будут применены только со следующего запуска',
              okText: t('general.continue'),
              cancelText: t('general.cancel'),
              onOk: async () => {
                try {
                  const res = await dispatch(updateConfiguration({ ...data, id }));

                  if (res) {
                    loadConfiguration(id);
                    message.success(t('workPlanTaskConfigurationEditor.taskSaved'));
                  }
                } catch (error) {
                  console.log(error);
                  message.warning('Не удалось обновить задачу');
                }
              }
            });
        }
      };

      const configuration = await getConfiguration();

      return configuration;
    } catch (error) {
      message.warning('Не удалось создать задачу');
      console.log(error);
    }
  };

  const onRun = async () => {
    let configurationId = id;

    const data = mapUiDataForRequest({
      name,
      isNew,
      schedule,
      distribution,
      configFiltersIds,
      removedReviewersIds,
      reviewerBindingsByIds,
      clientInteractionsTypesConfigs,
      reviewersCommunicationsDistributionMethod
    });

    if (isNew) {
      setSaveLoading(!saveLoading);
      const configuration = await dispatch(createConfiguration(data));
      configurationId = configuration?.id;
    }

    try {
      if (configurationId) {
        setSaveLoading(true);
        const res = await dispatch(runConfiguration({ id: configurationId }));

        if (res) {
          message.success(t('workPlanTaskConfigurationEditor.taskStarted'));
          setSaveLoading(!saveLoading);
          history.push(`/work-plan/task-configurations`);
        }
      }
    } catch (error) {
      console.log(error);
      message.warning('Не удалось запустить задачу');
    }
  };

  const canRun = [
    WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value,
    WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value
  ].includes(configurationStatus);

  if (loading) {
    return (
      <SCol span={24} padding="12px">
        <SCard loading />
      </SCol>
    );
  }
  const getUniqOperatorsIdsLength = () => {
    const getOperators = flatMap(Object.values(clientInteractionsTypesConfigs)).reduce(
      (acc, config) => {
        const operators = find(config?.fields, ({ key }) => key === 'operatorsIds');
        if (!operators) return acc;
        return [...acc, { ...operators }];
      },
      []
    );

    return uniq(flatMap(getOperators.map(config => config.settings.operatorsIds)))?.length || 0;
  };
  const getOperatorsLength = ({ config }) =>
    find(config?.fields, ({ key }) => key === 'operatorsIds')?.settings?.operatorsIds?.length || 0;

  const totalOperators = getUniqOperatorsIdsLength();

  const getTotalCommunications = () => {
    const totalCommunications = sum(
      Object.values(clientInteractionsTypesConfigs).reduce((acc, config) => {
        if (get(config, '_destroy') === '1') return acc;

        const data =
          schedule?.distributionType ===
          COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_NUMBER_BY_EACH_OPERATOR.value
            ? config?.clientInteractionsMaxCount * getOperatorsLength({ config })
            : config?.clientInteractionsMaxCount;

        return [...acc, data];
      }, [])
    );

    return totalCommunications;
  };

  const totalReviewers =
    Object.keys(Object.values(distribution)[0]?.byIds || []).length || reviewersIds.length || 0;

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SCard rounded={false}>
          <SRow align="middle" justify="space-between">
            <SCol className="truncated" maxWidth="calc(100% - 780px)">
              {isEmpty(name?.trim()) ? (
                <SText fontSize="21px" fontWeight="500" type="secondary" marginRight="6px">
                  {t('workPlanTaskConfigurationEditor.newTaskTitle')}
                </SText>
              ) : (
                <SText fontSize="21px" fontWeight="500" marginRight="6px">
                  {`${name?.trim()} `}
                </SText>
              )}
            </SCol>
            <SCol>
              <SRow gutter={[20, 0]} align="middle">
                <SCol>
                  <SText>
                    {`(${
                      schedule.distributionType !==
                      COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_PERCENTAGE_BY_EACH_OPERATOR
                        .value
                        ? `${t(
                            'workPlanTaskConfigurationEditor.taskInfo.totalCommunications'
                          )} - ${getTotalCommunications() || 0}, `
                        : ''
                    }${t(
                      'workPlanTaskConfigurationEditor.taskInfo.checkedOperators'
                    )} - ${totalOperators}, ${t(
                      'workPlanTaskConfigurationEditor.taskInfo.reviewers'
                    )} - ${totalReviewers})`}
                  </SText>
                </SCol>
                <SCol>
                  <OutlinedButton
                    disabled={!isValid}
                    onClick={onSave}
                    loading={configurationLoading}
                  >
                    {t('workPlanTaskConfigurationEditor.saveTask')}
                  </OutlinedButton>
                </SCol>
                {canRun && (
                  <SCol>
                    <SButton
                      type="primary"
                      disabled={!isValid}
                      onClick={onRun}
                      loading={saveLoading}
                    >
                      {t('workPlanTaskConfigurationEditor.startTask')}
                    </SButton>
                  </SCol>
                )}
              </SRow>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
      <SCol span={24} padding="16px">
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <ScheduleSettings
              isNew={isNew}
              name={name}
              onChangeName={setName}
              onChange={setSchedule}
              schedule={schedule}
              isScheduleValid={isScheduleValid}
            />
          </SCol>

          <SCol span={24}>
            <FiltersSettings
              onChange={setClientInteractionsConfigs}
              clientInteractionsConfigs={clientInteractionsTypesConfigs}
              schedule={schedule}
            />
          </SCol>

          <SCol span={24}>
            <Reviewers
              onChange={setDistribution}
              isFiltersValid={isFiltersValid}
              clientInteractionsTypesConfigs={clientInteractionsTypesConfigs}
              distribution={distribution}
              reviewersIds={reviewersIds}
              isNew={isNew}
              removedReviewersIds={removedReviewersIds}
              setRemovedReviewersIds={setRemovedReviewersIds}
              reviewersCommunicationsDistributionMethod={reviewersCommunicationsDistributionMethod}
              setReviewersCommunicationsDistributionMethod={
                setReviewersCommunicationsDistributionMethod
              }
            />
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskConfigurationEditor;
