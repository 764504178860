import React from 'react';
import { Typography, Tooltip } from 'antd';
import { mean, find, uniq, orderBy, keyBy, get, isArray } from 'lodash';
import { WIDGET_VIEW_MODES, RATING_MODE } from 'core/utils/constants';
import { Trans } from 'react-i18next';
import { getUserName } from 'components/UserPreview/getUserName';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import { stringify } from 'core/utils/queryString';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { getFilters, valueToColorZoneColor } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ selectedChecklistDefinitions, viewMode, filters, t }) => {
  const isUniqRatingModes =
    uniq(selectedChecklistDefinitions.map(checklistDefinition => checklistDefinition.ratingMode))
      .length === 1;

  const uniqRatingModeValue = uniq(
    selectedChecklistDefinitions.map(checklistDefinition => checklistDefinition.ratingMode)
  )[0];

  const firstColumnName =
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? 'dashboardPage.tableChecklistsScoresByOperators.firstColumnNames.operators'
      : 'dashboardPage.tableChecklistsScoresByOperators.firstColumnNames.units';

  const checklistDefinitionsColumns = selectedChecklistDefinitions.map(checklistDefinition => ({
    title: <Text style={{ cursor: 'pointer' }}>{checklistDefinition.name}</Text>,
    dataIndex: checklistDefinition.id,
    key: checklistDefinition.id,
    width: selectedChecklistDefinitions.length < 8 ? null : 170,
    align: 'center',
    ellipsis: true,
    render: (text, record) => {
      const getValue = ({ value }) => {
        if (!value && value !== 0) return null;
        return `${beatifyFloat(value)}%`;
      };

      return {
        props: {
          style: {
            background: text?.color
          }
        },
        children: (text || text === 0) && (
          <Text strong={record.key === 'footer'}>{getValue({ value: text?.value })}</Text>
        )
      };
    }
  }));

  return [
    {
      title: <Trans i18nKey={firstColumnName} />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      ellipsis: true,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children:
            record.key === 'header' ||
            record.key === 'footer' ||
            viewMode !== WIDGET_VIEW_MODES.OPERATORS.value ? (
              <Tooltip title={<Trans i18nKey={text} />}>
                <Text strong={record.key === 'footer'}>
                  <Trans i18nKey={text} />
                </Text>
              </Tooltip>
            ) : (
              <GetUserPreview
                userId={record.key}
                altName={record.name}
                url={`/user/${record.key}/charts${stringify({
                  filters: { ...getFilters(filters) }
                })}`}
              />
            )
        };
      }
    },
    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },
    {
      title: <Trans i18nKey="dashboardPage.dashboard.reviewsCountShort" />,
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      fixed: 'left',
      width: 80,
      align: 'center',
      render: text => text
    },
    ...checklistDefinitionsColumns,
    {
      title: <Trans i18nKey="dashboardPage.tableChecklistsScoresByOperators.columns.totalScore" />,
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: (text, record) => {
        // Получаем значение для столбца Итог
        const getTotalScore = () => {
          const score = beatifyFloat(text?.value);
          // Возвращаем N/A без %
          if (score === 'N/A') {
            return `${score}`;
          }
          return `${score}%`;
        };
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (text || text === 0) && (
            <Text strong={record.key === 'footer'}>{getTotalScore()}</Text>
          )
        };
      }
    }
  ];
};

export const prepareRows = ({
  checklistDefinitionsAverageScoresByOperators,
  checklistDefinitionAverageScoreByOperatorsIds,
  checklistDefinitionsAverageScoresByUnits,
  checklistDefinitionAverageScoreByUnitsIds,
  averageScoreByChecklistDefinitionsIds,
  checklistDefinitionAverageScore,
  selectedChecklistDefinitions,
  reviewsCountByOperators,
  reviewsCountByUnits,
  usersByIds,
  unitsByIds,
  viewMode
}) => {
  const itemKey = viewMode === WIDGET_VIEW_MODES.OPERATORS.value ? 'operator_id' : 'unit_id';

  // ! это нужно потому что в checklistDefinitionsAverageScoresByUnits нет имен отделов, и если отдел пустой, то на виджете отдел показывается как пустая строка (отделы загружаются через инклуд в юзерах)

  const getChecklistDefinitionsAverageScoresByUnitsWithNames = () => {
    const reviewsCountByUnitsIds = keyBy(reviewsCountByUnits, 'id');
    return checklistDefinitionsAverageScoresByUnits.map(item => ({
      ...item,
      name: reviewsCountByUnitsIds[item.unit_id]?.name
    }));
  };

  const widgetItem =
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? checklistDefinitionsAverageScoresByOperators
      : getChecklistDefinitionsAverageScoresByUnitsWithNames();

  const reviewsItem = keyBy(
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value ? reviewsCountByOperators : reviewsCountByUnits,
    'id'
  );

  const mapItemScores = ({ scores }) => {
    return scores.reduce(
      (acc, item) => ({
        ...acc,
        [item.checklist_definition_id]: {
          value: item.value,
          color: valueToColorZoneColor({
            value: item.value,
            colorZones: get(
              find(selectedChecklistDefinitions, {
                id: item.checklist_definition_id
              }),
              'colorZones'
            )
          })
        }
      }),
      {}
    );
  };

  const mapAverageScoreByChecklistDefinition = () => {
    const selectedChecklistDefinitionsIds = selectedChecklistDefinitions.map(
      checklistDefinition => checklistDefinition.id
    );

    return selectedChecklistDefinitionsIds.reduce((acc, checklistDefinitionId) => {
      const value = averageScoreByChecklistDefinitionsIds[checklistDefinitionId]?.value;

      return {
        ...acc,
        [checklistDefinitionId]: {
          value,
          color: valueToColorZoneColor({
            value,
            colorZones: get(
              find(selectedChecklistDefinitions, { id: checklistDefinitionId }),
              'colorZones'
            )
          })
        }
      };
    }, {});
  };

  const getCustomerNameOrUnitName = item => {
    switch (viewMode) {
      case WIDGET_VIEW_MODES.OPERATORS.value:
        if (usersByIds[item[itemKey]]) {
          return getUserName({ user: usersByIds[item[itemKey]] });
        }
        return checklistDefinitionAverageScoreByOperatorsIds[item[itemKey]]?.name;
      case WIDGET_VIEW_MODES.UNITS.value:
        return unitsByIds[item[itemKey]]?.name;
      default:
        return '';
    }
  };

  const mappedWidgetItemToRows = orderBy(
    widgetItem.map(item => ({
      key: item[itemKey],
      name: getCustomerNameOrUnitName(item),
      unitName: unitsByIds[usersByIds[item[itemKey]]?.unitId]?.name,
      ...mapItemScores({ scores: item.scores }),
      totalScore: {
        value:
          viewMode === WIDGET_VIEW_MODES.OPERATORS.value
            ? checklistDefinitionAverageScoreByOperatorsIds[item[itemKey]]?.value
            : checklistDefinitionAverageScoreByUnitsIds[item[itemKey]]?.value,
        color: undefined
      },
      reviewsCount: reviewsItem[item[itemKey]]?.value
    })),
    'name'
  );

  return [
    ...mappedWidgetItemToRows,
    {
      key: 'footer',
      name: 'dashboardPage.tableChecklistsScoresByOperators.rows.footer',
      ...mapAverageScoreByChecklistDefinition(),
      totalScore: {
        value: parseFloat(beatifyFloat(checklistDefinitionAverageScore)),
        color: undefined
      },
      reviewsCount: parseFloat(
        beatifyFloat(mean(Object.values(reviewsItem).map(item => item.value)))
      )
    }
  ];
};
