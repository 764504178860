export default {
  workPlanTaskConfigurationEditor: {
    newTaskTitle: 'New task',
    taskStarted: 'Task started',
    taskSaved: 'Task saved',
    deleteTask: 'Delete',
    saveTask: 'Save',
    startTask: 'Run',
    taskInfo: {
      totalCommunications: 'total communications',
      checkedOperators: 'checked operators',
      reviewers: 'reviewers'
    },
    delete: {
      title: 'Delete Task?',
      taskDeleted: 'Task deleted',
      cantDeleteTask: 'Failed to delete task'
    },
    schedule: {
      taskConfig: 'Task parameters',
      taskName: 'Task name',
      distribution: 'Distribution',
      distributionSettings: {
        communicationsNumberByEachOperator: 'The specified number for each operator',
        communicationsPercentageByEachOperator: 'The specified percentage for each operator',
        communicationsNumberByOperators: 'The specified number for a group of operators'
      },
      period: 'Timeframe',
      timeFrom: 'Start Date',
      timeTo: 'End date',
      repeatEvery: 'Repeat every',
      repeat: 'Repeats',
      toRepeat: 'Repeat',
      dayTimeFrom: 'Start time',
      dayTimeTo: 'End time',
      periodForTask: 'Task execution period',
      periodTask: 'Execution period',
      date: 'Date',
      time: 'Time',
      start: 'Start',
      end: 'Finish at',
      stopTask: 'Stop task',
      customPeriod: 'Customizable period',
      scheduleChangePeriodTask:
        'If you change the execution period, the repetition type will be reset to "Do not repeat"',
      info: {
        schedulePeriodTask: 'Period for which it is necessary to check the task',
        scheduleStopTask: 'Select the date when you want to stop repeating the task'
      }
    },
    filters: {
      deleteFilters: 'Delete filters',
      maxCount: 'Number of reviews',
      clientInteractionType: 'Communication type',
      addUsers: 'Add users',
      add: 'Add',
      usersAdded: 'Users',
      addFilters: 'Add communication type',
      datesRange: 'Check dates',
      invalid:
        'In order to set the reviewers, you must: add the type of communication, select the number of communications to check and select the operators to be checked',
      valid: 'To start the task, you must select the reviewers'
    },
    clientInteractionFilters: {
      operators: 'Operators',
      addFilter: 'Add filter',
      totalCountForAll: 'Total count of all',
      setConfiguration: {
        operatorsToCheck: 'Select operators to check',
        communicationsToCheck: 'Set communications for checking',
        directionOfCommunications: 'Set the direction of communications',
        durationOfCommunications: 'Set the duration of communications',
        communicationPartsCount: 'Set the number of messages'
      },
      deleteFiltersModalTitle: 'Remove the filters along with the type of communication?',
      quantityByOperator: 'Quantity by operator',
      percentageByOperator: '% by operator',
      operatorsToReview: 'Operators to review'
    },
    reviewersWithoutPermissions:
      'The reviewer cannot be added to the task because he has no access rights to one or more operator records.',
    changeTypeDistribution:
      'If you change the distribution type, the distribution values for the checkers set manually will be reset. Continue?',
    distribution: {
      addReviewer: 'Add reviewer',
      manual: 'Manually',
      auto: 'Linear'
    },
    addUsersModal: {
      users: 'Employees',
      unit: 'Unit',
      role: 'Role'
    }
  }
};
