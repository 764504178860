import { LogoutOutlined, UserOutlined, CopyOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Row, Skeleton, Typography } from 'antd';
import UserPreview from 'components/UserPreview';
import { getUserInitials, getUserName } from 'components/UserPreview/getUserName';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';
import styled from 'styled-components';
import Truncated from 'components/Truncated';
import SCol from 'components/Standard/SCol';
import { LogoutMenuItem, UserAnalyticsMenuItem } from './Components/MenuItems';
import CopyOrganizationId from "../../../components/CopyOrganizationId/CopyOrganizationIdButton";

const { Text } = Typography;

const UserMenu = () => {
  const { t } = useTranslation();

  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const clientName = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user.organization.name
  );
  const currentUserSelector = useMemo(() => getCurrentUser, []);
  const currentUser = useSelector(currentUserSelector, isEqual);

  if (!currentUserId) return <Skeleton active />;

  const doLogout = () => {
    const activeTable = localStorage.getItem('DEALAPP_ACTIVE_TABLE_ID');
    localStorage.clear();
    localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', activeTable);
    window.location.reload();
  };

  const menu = (
    <Menu>
      <UserAnalyticsMenuItem key="0" className="userMenu">
        <CustomLink to={`/user/${currentUserId}`}>
          <UserOutlined />
          <Text>{t('menu.userMenu.profile')}</Text>
        </CustomLink>
      </UserAnalyticsMenuItem>

      <Menu.Divider />

      <UserAnalyticsMenuItem key="1" className="userMenu">
        <CustomLink>
          <CopyOutlined />
          <CopyOrganizationId />
        </CustomLink>
      </UserAnalyticsMenuItem>

      <Menu.Divider />

      <LogoutMenuItem key="2" className="userMenu">
        <CustomLink onClick={doLogout}>
          <LogoutOutlined />
          <Text>{t('menu.userMenu.logout')}</Text>
        </CustomLink>
      </LogoutMenuItem>
    </Menu>
  );

  return (
    <Row type="flex" justify="end">
      <UserNameCol span={24} style={{ height: 45 }} flex="auto">
        <UserPreview userId={currentUserId} disabled disabledInitials showAvatar>
          {getUserInitials(currentUser)}
        </UserPreview>
        <StyledDropdown overlay={menu} trigger={['click']} placement="bottomCenter">
          <StyledLink className="ant-dropdown-link" href="#">
            <StyledName>
              <UserName>{getUserName({ user: currentUser })}</UserName>
              <ClientName>{clientName}</ClientName>
            </StyledName>
          </StyledLink>
        </StyledDropdown>
      </UserNameCol>
    </Row>
  );
};

const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;

  span {
    line-height: 16px;
  }
`;

const UserName = styled.span`
  color: white;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ClientName = styled.span`
  font-size: 12px;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  height: 48px;
`;

const TruncatedCol = Truncated(SCol);
const UserNameCol = props => (
  <TruncatedCol display="flex" alignItems="center" color="#a6adb4" {...props} />
);

const CustomLink = styled(Link)`
  display: flex !important;
  align-items: center;
  width: 100%;
  margin: 0px !important;
  padding: 5px 6px !important;

  & {
    .anticon {
      display: inline;
      padding-right: 6px;
      font-size: 12px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  margin-left: 10px;
  color: #a6adb4;
  :hover {
    color: white;
  }
  max-width: 155px;
`;

export default UserMenu;
