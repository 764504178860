import { useSelections } from 'ahooks';
import { Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { SListItem } from 'components/Standard/SList';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FixedSizeList } from 'react-window';
import {
  connectCustomField,
  disconnectCustomField,
  getEntityCustomFields
} from 'redux/entities/amoIntegration/operations';

const LIMIT_SELECTED_FIELDS = 8;

const AmoFields = ({ integrationId }) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected, isSelected, toggle, setSelected } = useSelections([], []);

  useEffect(() => {
    const loadFields = async () => {
      setLoading(true);
      const fields = await dispatch(getEntityCustomFields({ id: integrationId }));
      setFields(Object.values(fields));
      setSelected(
        Object.values(fields)
          .filter(field => field.connected)
          .map(field => field.id)
      );
      setLoading(false);
    };

    if (integrationId) {
      loadFields();
    }
  }, [integrationId]);

  const getTooltip = field => {
    if (!field.supported) {
      return t('integrationsSettingsPage.unsupportedField');
    }
  };

  const handleToggle = async field => {
    if (!field.supported) return;

    try {
      await dispatch(
        selected.includes(field.id)
          ? disconnectCustomField({ id: integrationId, fieldId: field.id })
          : connectCustomField({ id: integrationId, fieldId: field.id })
      );
      toggle(field.id);
    } catch (error) {
      console.log(error);
    }
  };

  const renderItem = (field, style) => {
    return (
      <SListItem style={style} borderBottom="1px solid var(--gray-border)">
        <SCol span={24} style={{ padding: '0' }}>
          <SRow type="flex" justify="space-between" align="middle">
            <SCol span={1}>
              <Tooltip title={getTooltip(field)}>
                <Checkbox
                  disabled={
                    !field.supported ||
                    (!isSelected(field.id) && LIMIT_SELECTED_FIELDS === selected.length)
                  }
                  checked={isSelected(field.id)}
                  onClick={() => handleToggle(field)}
                />
              </Tooltip>
            </SCol>
            <SCol span={9}>
              <SText {...(!field.supported && { type: 'secondary' })}>{field.name}</SText>
            </SCol>
            <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
              <SRow type="flex" style={{ maxWidth: '100%' }}>
                <SText {...(!field.supported && { type: 'secondary' })} className="truncated">
                  {field.type || field.entityType}
                </SText>
              </SRow>
            </SCol>
          </SRow>
        </SCol>
      </SListItem>
    );
  };

  return (
    <SCard bordered shadowed bodyPadding="24px">
      <SRow gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
        <SCol span={24}>
          <SRow align="middle" justify="space-between">
            <SCol>
              <SRow align="middle" gutter={[16, 0]}>
                <SCol>
                  {`${t(
                    'integrationsSettingsPage.integrationPage.limitSelectedFields'
                  )}: ${LIMIT_SELECTED_FIELDS}.`}
                </SCol>
                <SCol>
                  <SText type="secondary">{`${t('general.selected')}: ${selected.length}`}</SText>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCol>
        <SCol span={24}>
          <SListItem borderBottom="1px solid var(--gray-border)">
            <SCol span={24} style={{ padding: '0' }}>
              <SRow type="flex" justify="space-between" align="middle">
                <SCol span={1} />
                <SCol span={9}>
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.name')}
                  </SText>
                </SCol>
                <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.type')}
                  </SText>
                </SCol>
              </SRow>
            </SCol>
          </SListItem>

          <FixedSizeList itemSize={56} itemCount={fields.length} height={300}>
            {({ index, style }) => renderItem(fields[index], style)}
          </FixedSizeList>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AmoFields;
