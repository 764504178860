export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'Loaded',
      loadFailed: 'File loading failed',
      fileFormat: 'You can upload files in mp3 or wav format, up to 30 MB/file size.'
    },
    buttons: { loadCalls: 'Upload calls' },
    table: {
      columns: {
        operator: 'Operator',
        direction: 'Call direction',
        startedAt: 'Call date',
        startedAtPlaceholder: 'Choose call date',
        clientPhoneNumber: 'Client phone number',
        duration: 'Call duration',
        actions: 'Actions'
      },
      uinstalledCallDirection: 'Unknown',
      confirmChange: {
        title: 'Changes will not be saved',
        ok: 'Continue',
        cancel: 'Cancel'
      },
      buttons: {
        cancel: 'Cancel',
        edit: 'Edit',
        delete: 'Delete'
      },
      confirmDelete: {
        title: 'Are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      }
    }
  }
};
