export default {
  organizationSettingsPage: {
    tabs: {
      info: {
        tabName: 'Informazione',
        form: {
          currentPaymentPlan: 'Tariffa attuale',
          registrationDate: 'Data di registrazione',
          usersCount: 'Utenti registrati',
          monthlyReviewsCount: 'Chiamate valutale del mese corrente'
        }
      },
      organizationSettings: {
        tabName: "Impostazioni dell'azienda",
        form: {
          organizationName: "Nome dell'azienda",
          organizationAddress: 'Indirizzo legale',
          organizationEmail: 'Contatto email',
          organizationPhoneNumber: 'Numero del telefono del contatto'
        },
        customFields: { title: 'Ulteriori informazioni sulle comunicazioni' }
      },
      paymentSettings: {
        tabName: 'Impostazioni di pagamento',
        form: {
          currentPlanSlug: 'Tariffa',
          usersCount: 'Numero di utenti',
          paymentSymbolicPeriod: 'Periodo di pagamento',
          paymentCurrency: 'Valuta del pagamento'
        }
      }
    },
    buttons: {
      updateData: 'Aggiorna i dati',
      paymentPlansInfo: 'Informazioni sui piani tariffari',
      createPaymentRequest: 'Richiedere una fattura per il pagamento'
    },
    selectOptions: {
      paymentSymbolicPeriod: {
        month: '1 mese',
        year: '1 anno'
      }
    },
    textHeaders: {
      paymentSumm: 'Somma da pagare:',
      paymentPlanDescription: 'Descrizione del piano tariffario: '
    },
    modal: {
      title: "Informazioni sull'azienda",
      buttons: {
        cancel: 'Annulla',
        send: 'Inviare'
      },
      form: {
        companyName: "Nome dell'azienda",
        companyInn: 'CF',
        companyPhone: 'Numero del telefono',
        companyEmail: 'E-mail'
      }
    },
    messages: {
      dataSuccessfullyUpdated: 'Dati aggiornati',
      requestSuccessfullySended: 'Domanda inviata',
      requestSendedFailed: 'Impossibile inviare una domanda',
      form: {
        validateMessages: {
          enterOrganizationName: "Inserisci il nome dell'azienda",
          enterEmail: 'Per favore inserisci la tua e-mail',
          worngEmail: 'Hai inserito un indirizzo email non valido',
          enterPlanSlug: 'Per favore seleziona un piano tariffario',
          enterUsersCount: 'Per favore inserisci numero di utenti',
          enterPaymentSymbolicPeriod: 'Per favore seleziona un periodo di pagamento',
          enterPaymentCurrency: 'Seleziona la valuta di pagamento',
          enterInn: 'Per favore inserisci il CF',
          enterPhoneNumber: 'Per favore inserisci il numero di telefono'
        }
      }
    },
    other: {
      policyPT1: "D'accordo con",
      policyPT2: 'contratto di offerta pubblica',
      policyPT3: 'e la politica di privacy'
    }
  }
};
