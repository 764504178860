export const prepareTableRows = ({
  totalDifference,
  mappedMembersByAnswers,
  mappedChecklistItems
}) => {
  return [
    {
      key: 'header',
      ...mappedMembersByAnswers().comments
    },

    ...mappedChecklistItems,

    {
      key: 'footer',
      name: 'calibrationResultPage.table.columns.result',
      coincidence: totalDifference,
      ...mappedMembersByAnswers().result
    }
  ];
};
