import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import SCard from 'components/Standard/SCard';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { Space, Dropdown, Menu, Row, Modal } from 'antd';
import SText from 'components/Standard/SText';
import { checklistFormulasResource } from 'redux/resources/checklistFormulas';
import { Edit, MoreVertical, Trash2 } from 'react-feather';
import Icon from 'components/Icon';
import { setAddingCalculationFurmulasData, } from 'redux/ui/checklistEditor/reducer';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { QestionGroupCard, MarginLeftButton } from './styled';

const { confirm } = Modal;

export const checkBracket = str => {
  let br = '()[]';
  let stack = [];
  for (var i = 0; i < str.length; ++i) {
    let char = str[i];
    let ind = br.indexOf(char);
    if (ind >= 0) {
      if (ind & 1) {
        if (!stack.length) {
          return str.slice(0, i);
        }
        var last_br = stack.pop();
        if (last_br != br[ind - 1]) {
          return str.slice(0, i);
        }
      } else {
        stack.push(char);
      }
    }
  }
  if (!!stack.length) {
    return str;
  }
  return '';
};

const CalculationFurmulaEditor = ({
  calculationFurmula,
  setActivFormulaEditor,
  setAddingCalculationFurmulasData,
  isLastFormula,
  currentChecklist
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPublishedChecklist = currentChecklist?.status
    ? currentChecklist?.status === 'published'
    : null;
  const formulaData = !!calculationFurmula?.formulaData ? JSON.parse(calculationFurmula.formulaData) : {};
  const id = get(calculationFurmula, 'id', '');
  const name = get(formulaData, 'name', '');
  const text = get(formulaData, 'text', '');
  const checklistDefinitionId = get(calculationFurmula, 'checklistDefinitionId', undefined);
  const [sendingComment, setSendingComment] = useState(false);
  const [calculationFurmulaId, setcalculationFurmulaId] = useState(id);
  const [commentState, setCommentState] = useState({ text, selectionStart: text.length });
  if (id !== calculationFurmulaId) {
    setSendingComment(false);
    setcalculationFurmulaId(id);
    setCommentState({ text, selectionStart: text.length });
  }
  const [hasChanged, setHasChanged] = useState(false);
  if (hasChanged !== (commentState.text === text)) {
    setHasChanged(commentState.text === text);
  }
  const isEmptyComment = isEmpty(commentState.text?.trim());

  const handleSend = async event => {
    await dispatch(
      checklistFormulasResource.operations.updateById({
        id,
        checklistDefinitionId,
        formula_data: JSON.stringify({
          name,
          text: commentState.text
        })
      })
    );
  };

  const handleEditorChange = event => {
    handleEditorChangeLogic(event?.target?.value, event?.target.selectionStart);
  };

  const handleEditorChangeLogic = (text, selection) => {
    const isEmptyText = isEmpty(text.trim());
    setCommentState({ text: isEmptyText ? '' : text, selectionStart: selection });
  };

  const setSelection = event => {
    setCommentState({ text: commentState.text, selectionStart: event?.target.selectionStart });
    setActivFormulaEditor(
      { text: commentState.text, selectionStart: event?.target.selectionStart },
      handleEditorChangeLogic,
      event?.target
    );
  };

  const handlMenuClick = async e => {
    if (e.key === 'edit') {
      setAddingCalculationFurmulasData({ id, name, text: commentState.text });
    } else if (e.key === 'delete') {
      return confirm({
        okText: t('checklistsPage.questionGroup.confirmDelete.ok'),
        cancelText: t('checklistsPage.questionGroup.confirmDelete.cancel'),
        title:
          t('checklistsPage.calculationFurmula.confirmDelete.title_1') +
          name +
          t('checklistsPage.calculationFurmula.confirmDelete.title_2'),
        content: '',
        okType: 'danger',
        onOk: async () => {
          await dispatch(
            checklistFormulasResource.operations.deleteById({
              id,
              checklistDefinitionId
            })
          );
        }
      });
    } else if (e.key === 'check') {
      let error = checkBracket(commentState.text);
      let title = '';
      if (!!error) {
        title = t('checklistsPage.calculationFurmula.confirmCheck.title_error');
      } else {
        title = t('checklistsPage.calculationFurmula.confirmCheck.title_ok');
      }
      return confirm({
        okText: t('checklistsPage.calculationFurmula.confirmCheck.ok'),
        title,
        content: error,
        okType: '',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {}
      });
    }
  };

  const status = get(currentChecklist, 'status', 'archived');

  const menu = (
    <Menu onClick={e => handlMenuClick(e)}>
      <Menu.Item key="check">
        <QuestionCircleOutlined style={{ fontSize: '16px' }} />
        <span>{t('checklistsPage.calculationFurmula.menu.check')}</span>
      </Menu.Item>
      <Menu.Item key="edit" disabled={isPublishedChecklist}>
        <Icon icon={Edit} />
        <span>{t('checklistsPage.calculationFurmula.menu.edit')}</span>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <Icon icon={Trash2} />
        <span>{t('checklistsPage.calculationFurmula.menu.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <QestionGroupCard style={{ margin: '16px 0 0 0' }}>
        <Row type="flex" align="middle">
          <SCol flex="auto" wrap={false}>
            <SText ellipsis strong title={name}>
              {name}
            </SText>
          </SCol>
          <div onClick={e => e.stopPropagation()}>
            <Dropdown overlay={menu} trigger={['click']}>
              <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
            </Dropdown>
          </div>
        </Row>
      </QestionGroupCard>
      <SCard>
        <SRow align="top" gutter={[14, 0]}>
          <SCol span={24}>
            <SCol display="flex" flex="auto">
              <textarea
                style={
                  isPublishedChecklist
                    ? {
                        cursor: 'not-allowed',
                        width: '100%',
                        border: '0',
                        outline: 'none'
                      }
                    : {
                        width: '100%',
                        border: '0',
                        outline: 'none'
                      }
                }
                value={commentState.text}
                disabled={isPublishedChecklist}
                onChange={handleEditorChange}
                onClick={setSelection}
              ></textarea>
            </SCol>
            {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
              <SCol display="flex" flex="auto" justifyContent="end">
                <SRow
                  display="flex"
                  marginLeft="auto"
                  width="max-content"
                  justifyContent="end"
                  className="BraftEditor-actions"
                >
                  <Space value={4}>
                    <SButton
                      type="primary"
                      onClick={handleSend}
                      disabled={isEmptyComment || sendingComment || hasChanged}
                    >
                      {t('general.save')}
                    </SButton>
                  </Space>
                </SRow>
              </SCol>
            )}
          </SCol>
        </SRow>
      </SCard>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  setAddingCalculationFurmulasData
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationFurmulaEditor);
