import { get } from 'redux-bees';

const communicationChainsV1Endpoints = apiPath => ({
  getCommunicationChains: {
    method: get,
    path: `${apiPath}/client_interactions?filters[communications_types]=communication_chain`
  }
});

export default communicationChainsV1Endpoints;
