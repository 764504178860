import { message, Tooltip } from 'antd';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const CopyOrganizationId = () => {
    const getOrganizationId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.user['organization-id'])
    const { t } = useTranslation();
    const onCopy = () => message.success(t('menu.userMenu.copyOrganizationIdSuccess'));

    return (
        <CopyToClipboard text={getOrganizationId} onCopy={onCopy}>
            <Tooltip title={t('menu.userMenu.copyOrganizationId')} placement="top">
                {t('menu.userMenu.organizationId')}
            </Tooltip>
        </CopyToClipboard>
    );
};

export default CopyOrganizationId;