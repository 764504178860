import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { useEffect } from 'react';
import { isEmpty } from 'validate.js';
import { integrationsResource } from 'redux/resources/integrations';
import { DEFAULT_PAGE_TITLE, PERMISSIONS } from 'core/utils/constants';
import { freshchatWidgetToken } from 'core/config';
import { useTranslation } from 'react-i18next';

const checkPermission = ({ permission, user }) => {
  const currentUserPermissions = get(user, 'role.permissions', []);
  return currentUserPermissions.includes(permission);
};

const permissions = user => ({
  // Коммуницирует
  cf_can_perform_client_interaction: checkPermission({
    permission: PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION,
    user
  }),

  // Доступ_к_ЛК
  cf_can_access_user_analytics_page_charts_tab: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
    user
  }),

  // Ответ_на_комментарии
  cf_can_reply_comments: checkPermission({
    permission: PERMISSIONS.CAN_REPLY_COMMENTS,
    user
  }),

  // Доступ_ТК
  cf_can_see_unit_client_interactions: checkPermission({
    permission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS,
    user
  }),

  // Оценивает
  cf_can_make_review: checkPermission({
    permission: PERMISSIONS.CAN_MAKE_REVIEW,
    user
  }),

  // Команда
  cf_can_access_team_page: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_TEAM_PAGE,
    user
  }),

  // Аналитика
  cf_can_see_dashboards: checkPermission({
    permission: PERMISSIONS.CAN_SEE_DASHBOARDS,
    user
  }),

  // КС
  cf_can_manage_calibration_sessions: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS,
    user
  }),

  // Очереди
  cf_can_manage_work_plan_task_configurations: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS,
    user
  }),

  // Подает_пеллляции
  cf_can_create_appellations: checkPermission({
    permission: PERMISSIONS.CAN_CREATE_APPELLATIONS,
    user
  }),

  // Участник_пеллляции
  cf_can_watch_appellations: checkPermission({
    permission: PERMISSIONS.CAN_WATCH_APPELLATIONS,
    user
  }),

  // Трекер_апелляций
  cf_can_see_unit_appellations: checkPermission({
    permission: PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS,
    user
  }),

  // Настройки_апелляций
  cf_can_administrate_appellations: checkPermission({
    permission: PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS,
    user
  }),

  // Аналитика_апелляций
  cf_can_access_appellations_analytics_page: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE,
    user
  }),

  // Апелляции_ЛК
  cf_can_access_user_analytics_page_operators_appellations_tab: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB,
    user
  }),

  // Библиотека
  cf_can_access_library: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_LIBRARY,
    user
  }),

  // Добавление_библиотека
  cf_can_add_library_items: checkPermission({
    permission: PERMISSIONS.CAN_ADD_LIBRARY_ITEMS,
    user
  }),

  // Настройки_организации
  cf_can_manage_organization_settings: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
    user
  }),

  // Отделы_пользователи
  cf_can_manage_organization_structure: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE,
    user
  }),

  // Интеграции
  cf_can_manage_integrations: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_INTEGRATIONS,
    user
  }),

  // ФО
  cf_can_manage_checklist_definitions: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS,
    user
  }),

  // Теги
  cf_can_manage_task_definitions: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS,
    user
  }),

  // Настройка_библиотека
  cf_can_manage_library: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_LIBRARY,
    user
  })
});

const FreshChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoadingReduxTokenAuth = useSelector(state => state.reduxTokenAuth.currentUser.isLoading);
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);

  const integrations = useSelector(
    state => Object.values(state.integrationsResource.byIds),
    isEqual
  );
  const integrationsLenght = integrations?.length;

  useEffect(() => {
    if (isEmpty(integrations) && currentUserId) {
      dispatch(integrationsResource.operations.load());
    }
  }, [integrationsLenght, currentUserId]);

  const integrationsList = integrations.reduce((acc, integration) => {
    if (integration?.integrationType === 'undefined' || !integration?.integrationType) return acc;

    return [...acc, integration?.integrationType];
  }, []);

  const user = useSelector(state => {
    const { user: currentUser } = state.reduxTokenAuth.currentUser.attributes;

    return {
      email: get(currentUser, 'email'),
      firstName: get(currentUser, 'first-name', ''),
      lastName: get(currentUser, 'last-name', ''),
      meta: {
        cf_company: get(currentUser, 'organization.name', ''),
        cf_role: get(currentUser, 'role.name', ''),
        cf_job_title: get(currentUser, 'role.name', ''),
        cf_integrations: integrationsList.join(', '),
        cf_custom_http_telephony: integrationsList.includes('custom_http_telephony'),
        cf_custom_http_text: integrationsList.includes('custom_http_text'),
        cf_bitrix_crm: integrationsList.includes('bitrix_crm'),
        cf_amo_crm: integrationsList.includes('amocrm'),
        cf_created_at: get(currentUser, 'organization.created-at', ''),
        cf_plan: get(currentUser, 'organization.plan-slug', ''),
        cf_organization_monthly_reviews_count: get(
          currentUser,
          'organization.monthly-reviews-count'
        ),
        ...permissions(currentUser)
      }
    };
  }, isEqual);

  useEffect(() => {
    if (!isLoadingReduxTokenAuth) {
      if (currentUserId && !isEmpty(freshchatWidgetToken)) {
        window.fcSettings = {
          onInit: () => {
            window.fcWidget.user.update(user);
            window.fcWidget.user.setMeta(user.meta);
          }
        };

        window.fcWidget && window.fcWidget.setExternalId(currentUserId);

        window.fcWidget &&
          window.fcWidget.user.isExists().then(
            () => {
              window.fcWidget.user.setFirstName(user.firstName);
              window.fcWidget.user.setLastName(user.lastName);
              window.fcWidget.user.setEmail(user.email);

              window.fcWidget.user.setMeta(user.meta);
              window.fcWidget.user.update(user);
            },
            () => {
              window.fcWidget.user.create(user);

              window.fcWidget.user.setMeta(user.meta);
              window.fcWidget.user.update(user);
            }
          );
      }
    }
  }, [currentUserId, integrationsList]);

  useEffect(() => {
    const target = document.querySelector('title');

    // create an observer instance
    const observer = new MutationObserver(mutations => {
      // We need only first event and only new value of the title
      const title = mutations[0].target.innerHTML;

      if (title !== t(DEFAULT_PAGE_TITLE)) {
        if (!isEmpty(freshchatWidgetToken) && window?.fcWidget) {
          window.fcWidget.user.setMeta(user.meta);
          window.fcWidget.user.update(user);
        }
      }
    });

    // configuration of the observer:
    const config = { subtree: true, characterData: true, childList: true };

    // pass in the target node, as well as the observer options
    observer.observe(target, config);
  }, []);

  return null;
};

export default FreshChat;
